/**
 * @function parse Hex to RGB
 * @param {string} color - Hex color
 * @param {('array'|'css')} format - Format of returning value
 */
export const parseHex = (color, format = 'array') => {
  const colorData = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  const isArrayFormat = format === 'array';

  if (colorData) {
    const r = parseInt(colorData[1], 16);
    const g = parseInt(colorData[2], 16);
    const b = parseInt(colorData[3], 16);

    return isArrayFormat ? [r, g, b] : `rgb(${r},${g},${b})`;
  }

  return isArrayFormat ? [] : '';
};
