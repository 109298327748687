export const isDevelopment = process.env.NODE_ENV === 'development';

export const getValueFromLS = (value) => {
  try {
    return JSON.parse(window.localStorage.getItem(value));
  } catch (e) {
    return null;
  }
};

export const isDebugMode = (debugKey) => isDevelopment && getValueFromLS(debugKey || 'debug');
