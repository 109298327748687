import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { FormField } from '@blueprism/ui-core';

import { getFormattedFileSize } from 'app-utils';
import ImagePicker from 'app-image-picker';

export const BaseFormFieldImagePicker = (props) => {
  const {
    allowClear,
    containerClassName,
    description,
    gap,
    label,
    labelPlacement,
    maxFileSize,
    onChange,
    required,
    ...rest
  } = props;

  const [field, meta, helpers] = useField(props);
  const { name, value } = field;
  const { error, touched } = meta;
  const { setTouched, setValue } = helpers;
  const { t } = useTranslation();

  const changeFieldHandler = (val, payload) => {
    if (!touched) setTouched(true);

    setValue(val);
    onChange(val, payload);
  };

  const getActualValueAndLabel = (val) => {
    return getFormattedFileSize(val, maxFileSize);
  };

  const displayError = touched && !!error;
  const computedLabel = `${label}${required ? '\u00A0*' : ''}`;
  const fileSize = `${getActualValueAndLabel(maxFileSize).value}${getActualValueAndLabel(maxFileSize).label}`;
  const descriptionWithMaxSize = description ? `${description} (${t('MAX_VALUE', { value: fileSize })})` : '';

  return (
    <FormField
      gap={gap}
      htmlFor={name}
      errorText={error}
      error={displayError}
      label={computedLabel}
      helperText={descriptionWithMaxSize}
      className={containerClassName}
    >
      <div className={cn('input-block input-block-date', [`input-block-${labelPlacement}`])}>
        <ImagePicker
          {...rest}
          id={name}
          image={value}
          maxFileSize={maxFileSize}
          allowClear={allowClear && Boolean(value)}
          setImage={changeFieldHandler}
          clearImage={() => changeFieldHandler('')}
        />
      </div>
    </FormField>
  );
};

export const BaseFormFieldImagePickerPropTypes = {
  allowClear: PropTypes.bool,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  description: PropTypes.string,
  editButtonText: PropTypes.string,
  gap: PropTypes.string,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  maxFileSize: PropTypes.number,
  maxImageHeight: PropTypes.number,
  maxImageWidth: PropTypes.number,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

export const BaseFormFieldImagePickerShape = PropTypes.shape(BaseFormFieldImagePickerPropTypes);

BaseFormFieldImagePicker.propTypes = BaseFormFieldImagePickerPropTypes;

BaseFormFieldImagePicker.defaultProps = {
  allowClear: false,
  className: '',
  containerClassName: '',
  description: null,
  editButtonText: '',
  gap: 'xs',
  label: '',
  labelPlacement: 'top',
  maxFileSize: 10,
  maxImageHeight: 1000,
  maxImageWidth: 1000,
  onChange: () => {},
  required: false,
};
