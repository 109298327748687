import styled, { css } from 'styled-components';

export const StyledToastContainer = styled.div`
  ${({ theme }) => css`
    .Toastify__toast-container {
      position: fixed;
      z-index: 9999;
      box-sizing: border-box;
      width: 320px;
      padding: 4px;
      white-space: pre-line;
    }

    .Toastify__toast-container--top-left {
      top: 1em;
      left: 1em;
    }

    .Toastify__toast-container--top-center {
      top: 1em;
      left: 50%;
      margin-left: -160px;
    }

    .Toastify__toast-container--top-right {
      top: 1em;
      right: 1em;
    }

    .Toastify__toast-container--bottom-left {
      bottom: 1em;
      left: 1em;
    }

    .Toastify__toast-container--bottom-center {
      bottom: 1em;
      left: 50%;
      margin-left: -160px;
    }

    .Toastify__toast-container--bottom-right {
      right: 1em;
      bottom: 1em;
    }

    @media only screen and (max-width: 480px) {
      .Toastify__toast-container {
        left: 0;
        width: 100vw;
        margin: 0;
        padding: 0;
      }

      .Toastify__toast-container--top-left,
      .Toastify__toast-container--top-center,
      .Toastify__toast-container--top-right {
        top: 0;
      }

      .Toastify__toast-container--bottom-left,
      .Toastify__toast-container--bottom-center,
      .Toastify__toast-container--bottom-right {
        bottom: 0;
      }

      .Toastify__toast-container--rtl {
        right: 0;
        left: initial;
      }
    }

    .Toastify__toast {
      position: relative;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      min-height: 64px;
      max-height: 800px;
      margin-bottom: 1rem;
      padding: 8px;
      overflow: hidden;
      color: ${theme.color.text};
      direction: ltr;
      background: ${theme.color.paper};
      border-radius: 6px;
      box-shadow: 0 3px 18px 0 rgba(21, 46, 56, 0.25);
      cursor: pointer;

      &.custom-toast {
        border-left: none;
        border-radius: 1px;

        > .Toastify__close-button {
          color: ${theme.color.action};
          font-weight: 900;
        }

        &:hover {
          box-shadow: 0 0 0 4px ${theme.color.hover};
        }
      }
    }

    .Toastify__toast--rtl {
      direction: rtl;
    }

    .Toastify__toast--success {
      border-left: 10px solid #6bd9a7;

      &.custom-toast {
        border-bottom: 5px solid ${theme.color.action};
      }
    }

    .Toastify__toast--warning {
      border-left: 10px solid #f1c40f;

      &.custom-toast {
        border-bottom: 5px solid ${theme.color.warning};
      }
    }

    .Toastify__toast--error {
      border-left: 10px solid #cb6200;

      &.custom-toast {
        background-image: repeating-linear-gradient(
          -45deg,
          ${theme.color.warning},
          1px,
          transparent 2px,
          transparent 3px,
          ${theme.color.warning} 4px
        );
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: 100% 0.5rem;
        border-bottom: 5px solid ${theme.color.warning};
      }
    }

    .Toastify__toast-body {
      flex: 1 1;
      margin: auto 0;
      padding-right: 5px;
      word-wrap: break-word;
      word-break: break-word;
    }

    @media only screen and (max-width: 480px) {
      .Toastify__toast {
        margin-bottom: 0;
      }
    }

    .Toastify__close-button {
      align-self: flex-start;
      padding: 0;
      color: ${theme.color.structure};
      font-weight: normal;
      font-size: 14px;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      opacity: 0.7;
      transition: 0.3s ease;
    }

    .Toastify__close-button:hover,
    .Toastify__close-button:focus {
      opacity: 1;
    }

    @keyframes Toastify__trackProgress {
      0% {
        width: 100%;
      }

      100% {
        width: 0;
      }
    }

    @keyframes Toastify__bounceInRight {
      from,
      60%,
      75%,
      90%,
      to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      from {
        transform: translate3d(3000px, 0, 0);
        opacity: 0;
      }

      60% {
        transform: translate3d(-25px, 0, 0);
        opacity: 1;
      }

      75% {
        transform: translate3d(10px, 0, 0);
      }

      90% {
        transform: translate3d(-5px, 0, 0);
      }

      to {
        transform: none;
      }
    }

    @keyframes Toastify__bounceOutRight {
      20% {
        transform: translate3d(-20px, 0, 0);
        opacity: 1;
      }

      to {
        transform: translate3d(2000px, 0, 0);
        opacity: 0;
      }
    }

    @keyframes Toastify__bounceInLeft {
      from,
      60%,
      75%,
      90%,
      to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      0% {
        transform: translate3d(-3000px, 0, 0);
        opacity: 0;
      }

      60% {
        transform: translate3d(25px, 0, 0);
        opacity: 1;
      }

      75% {
        transform: translate3d(-10px, 0, 0);
      }

      90% {
        transform: translate3d(5px, 0, 0);
      }

      to {
        transform: none;
      }
    }

    @keyframes Toastify__bounceOutLeft {
      20% {
        transform: translate3d(20px, 0, 0);
        opacity: 1;
      }

      to {
        transform: translate3d(-2000px, 0, 0);
        opacity: 0;
      }
    }

    @keyframes Toastify__bounceInUp {
      from,
      60%,
      75%,
      90%,
      to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      from {
        transform: translate3d(0, 3000px, 0);
        opacity: 0;
      }

      60% {
        transform: translate3d(0, -20px, 0);
        opacity: 1;
      }

      75% {
        transform: translate3d(0, 10px, 0);
      }

      90% {
        transform: translate3d(0, -5px, 0);
      }

      to {
        transform: translate3d(0, 0, 0);
      }
    }

    @keyframes Toastify__bounceOutUp {
      20% {
        transform: translate3d(0, -10px, 0);
      }

      40%,
      45% {
        transform: translate3d(0, 20px, 0);
        opacity: 1;
      }

      to {
        transform: translate3d(0, -2000px, 0);
        opacity: 0;
      }
    }

    @keyframes Toastify__bounceInDown {
      from,
      60%,
      75%,
      90%,
      to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      0% {
        transform: translate3d(0, -3000px, 0);
        opacity: 0;
      }

      60% {
        transform: translate3d(0, 25px, 0);
        opacity: 1;
      }

      75% {
        transform: translate3d(0, -10px, 0);
      }

      90% {
        transform: translate3d(0, 5px, 0);
      }

      to {
        transform: none;
      }
    }

    @keyframes Toastify__bounceOutDown {
      20% {
        transform: translate3d(0, 10px, 0);
      }

      40%,
      45% {
        transform: translate3d(0, -20px, 0);
        opacity: 1;
      }

      to {
        transform: translate3d(0, 2000px, 0);
        opacity: 0;
      }
    }

    .Toastify__bounce-enter--top-left,
    .Toastify__bounce-enter--bottom-left {
      animation-name: Toastify__bounceInLeft;
    }

    .Toastify__bounce-enter--top-right,
    .Toastify__bounce-enter--bottom-right {
      animation-name: Toastify__bounceInRight;
    }

    .Toastify__bounce-enter--top-center {
      animation-name: Toastify__bounceInDown;
    }

    .Toastify__bounce-enter--bottom-center {
      animation-name: Toastify__bounceInUp;
    }

    .Toastify__bounce-exit--top-left,
    .Toastify__bounce-exit--bottom-left {
      animation-name: Toastify__bounceOutLeft;
    }

    .Toastify__bounce-exit--top-right,
    .Toastify__bounce-exit--bottom-right {
      animation-name: Toastify__bounceOutRight;
    }

    .Toastify__bounce-exit--top-center {
      animation-name: Toastify__bounceOutUp;
    }

    .Toastify__bounce-exit--bottom-center {
      animation-name: Toastify__bounceOutDown;
    }

    @keyframes Toastify__zoomIn {
      from {
        transform: scale3d(0.3, 0.3, 0.3);
        opacity: 0;
      }

      50% {
        opacity: 1;
      }
    }

    @keyframes Toastify__zoomOut {
      from {
        opacity: 1;
      }

      50% {
        transform: scale3d(0.3, 0.3, 0.3);
        opacity: 0;
      }

      to {
        opacity: 0;
      }
    }

    .Toastify__zoom-enter {
      animation-name: Toastify__zoomIn;
    }

    .Toastify__zoom-exit {
      animation-name: Toastify__zoomOut;
    }

    @keyframes Toastify__flipIn {
      from {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
        animation-timing-function: ease-in;
      }

      40% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        animation-timing-function: ease-in;
      }

      60% {
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
      }

      80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
      }

      to {
        transform: perspective(400px);
      }
    }

    @keyframes Toastify__flipOut {
      from {
        transform: perspective(400px);
      }

      30% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1;
      }

      to {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
      }
    }

    .Toastify__flip-enter {
      animation-name: Toastify__flipIn;
    }

    .Toastify__flip-exit {
      animation-name: Toastify__flipOut;
    }

    @keyframes Toastify__slideInRight {
      from {
        transform: translate3d(110%, 0, 0);
        visibility: visible;
      }

      to {
        transform: translate3d(0, 0, 0);
      }
    }

    @keyframes Toastify__slideInLeft {
      from {
        transform: translate3d(-110%, 0, 0);
        visibility: visible;
      }

      to {
        transform: translate3d(0, 0, 0);
      }
    }

    @keyframes Toastify__slideInUp {
      from {
        transform: translate3d(0, 110%, 0);
        visibility: visible;
      }

      to {
        transform: translate3d(0, 0, 0);
      }
    }

    @keyframes Toastify__slideInDown {
      from {
        transform: translate3d(0, -110%, 0);
        visibility: visible;
      }

      to {
        transform: translate3d(0, 0, 0);
      }
    }

    @keyframes Toastify__slideOutRight {
      from {
        transform: translate3d(0, 0, 0);
      }

      to {
        transform: translate3d(110%, 0, 0);
        visibility: hidden;
      }
    }

    @keyframes Toastify__slideOutLeft {
      from {
        transform: translate3d(0, 0, 0);
      }

      to {
        transform: translate3d(-110%, 0, 0);
        visibility: hidden;
      }
    }

    @keyframes Toastify__slideOutUp {
      from {
        transform: translate3d(0, 0, 0);
      }

      to {
        transform: translate3d(0, 110%, 0);
        visibility: hidden;
      }
    }

    @keyframes Toastify__slideOutDown {
      from {
        transform: translate3d(0, 0, 0);
      }

      to {
        transform: translate3d(0, -110%, 0);
        visibility: hidden;
      }
    }

    .Toastify__slide-enter--top-left,
    .Toastify__slide-enter--bottom-left {
      animation-name: Toastify__slideInLeft;
    }

    .Toastify__slide-enter--top-right,
    .Toastify__slide-enter--bottom-right {
      animation-name: Toastify__slideInRight;
    }

    .Toastify__slide-enter--top-center {
      animation-name: Toastify__slideInDown;
    }

    .Toastify__slide-enter--bottom-center {
      animation-name: Toastify__slideInUp;
    }

    .Toastify__slide-exit--top-left,
    .Toastify__slide-exit--bottom-left {
      animation-name: Toastify__slideOutLeft;
    }

    .Toastify__slide-exit--top-right,
    .Toastify__slide-exit--bottom-right {
      animation-name: Toastify__slideOutRight;
    }

    .Toastify__slide-exit--top-center {
      animation-name: Toastify__slideOutUp;
    }

    .Toastify__slide-exit--bottom-center {
      animation-name: Toastify__slideOutDown;
    }
  `}
`;
