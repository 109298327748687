import { action, observable } from 'mobx';
import * as R from 'ramda';

import { notify } from 'app-toasts';
import { MODAL_MODES } from 'app-modal';
import { SignalRService } from 'app-signalr-service';
import { ELEMENT_TYPES } from 'app-base-form/constants';
import { normalizeFormData } from 'app-base-form/normalizer';
import { FormModel } from 'app-base-form/models';

const INITIAL_FORM = {
  id: null,
  name: '',
  version: null,
  description: '',
  lastCompleted: '',
};

export class CommonFormSubmissionStore {
  @observable form = INITIAL_FORM;

  @observable pages = {};

  @observable initialFormConfig = {};

  @observable activePageId = null;

  @observable fields = {};

  @observable rules = {};

  @observable socketConnection = null;

  @observable formMode = null;

  @observable requestType = null;

  constructor(apiUrls, httpService, history, inboxStatus) {
    this.history = history;
    this.apiUrls = apiUrls;
    this.httpService = httpService;
    this.inboxStatus = inboxStatus;
  }

  getVisiblePages() {
    return R.sortBy(R.prop('order'))(Object.values(this.pages).filter((page) => !page.hidden));
  }

  @action
  setFormMode = (mode) => {
    this.formMode = mode;
  };

  @action
  setRequestType = (type) => {
    this.requestType = type;
  };

  @action
  setDataLoading = (status) => {
    this.dataLoading = status;
  };

  @action
  setActivePageId = (id) => {
    this.activePageId = id;
  };

  @action
  updateFormData = ({ changes } = {}) => {
    return new Promise((resolve) => {
      const { fChanges, pChanges } = Object.keys(changes).reduce(
        (acc, key) => {
          if (changes[key].elementType === ELEMENT_TYPES.PAGE) {
            acc.pChanges[key] = changes[key];
          } else if (changes[key].elementType === ELEMENT_TYPES.FIELD) {
            acc.fChanges[key] = changes[key];
          }

          return acc;
        },
        { fChanges: {}, pChanges: {} },
      );

      if (R.not(R.isEmpty(fChanges))) this.fields = R.mergeDeepRight(this.fields, fChanges);
      if (R.not(R.isEmpty(pChanges))) this.pages = R.mergeDeepRight(this.pages, pChanges);

      resolve({ pages: this.pages });
    });
  };

  @action
  resetStore = () => {
    this._stopSocketConnection();

    this.form = INITIAL_FORM;
    this.pages = {};
    this.fields = {};
    this.rules = {};
    this.activePageId = null;
    this.formMode = null;
    this.requestType = null;
  };

  @action
  setData = (data, payload) => {
    const _data = new FormModel(data, payload);

    const { fields, form, pages, rules } = normalizeFormData(_data);

    this.form = form;
    this.pages = pages;
    this.fields = fields;
    this.rules = rules;
    this.initialFormConfig = { pages, fields };
  };

  @action
  setInitialFormConfig = () => {
    const { fields, pages } = this.initialFormConfig;

    this.pages = pages;
    this.fields = fields;
  };

  getFormData = async (url, id) => {
    const data = await this.httpService.post(url, { id });
    return data;
  };

  @action
  updateField = ({ automationId, value }) => {
    const parsedValue = JSON.parse(value);
    const fieldConfig = this.fields[automationId];
    const fieldValue = {
      [automationId]: fieldConfig.assignValue
        ? fieldConfig.assignValue({ ...fieldConfig, value: parsedValue })
        : parsedValue,
    };

    this.form = R.mergeDeepRight(this.form, { initialValues: fieldValue });
  };

  updateConfigChanges = (payload) => {
    const changes = JSON.parse(payload.configChanges);
    this.form = { ...this.form, configChanges: changes };
    this.updateFormData({ changes });
  };

  startSocketConnection = async (id) => {
    this.setFormMode(MODAL_MODES.VIEW);

    try {
      const data = await this.httpService.post(`${this.apiUrls.inboxConnection}${id}`);

      this.socketConnection = new SignalRService(data);
      this.subscribeConnections(id);
      await this.socketConnection.start();
    } catch {
      // Handled on higher level
    }
  };

  subscribeConnections = () => {
    if (!this.socketConnection) return;

    this.socketConnection.on('updateStatus', this._updateFormStatus);
    this.socketConnection.on('updateField', this.updateField);
    this.socketConnection.on('updateConfigChanges', this.updateConfigChanges);
    this.socketConnection.on('failSubmit', this._onFailSubmission);
    this.socketConnection.onReconnecting(() =>
      notify('error', 'common:ERROR_TOASTER_CONNECTION_INTERRUPTED_TRYING_TO_REC', { autoClose: false }),
    );

    this.socketConnection.onReconnected(this._syncFormConfiguration);
    this.socketConnection.onClose(this._onCloseSocketConnection);
  };

  _onFailSubmission = () => {
    const { formName } = this.form;
    notify('error', ['common:ERROR_TOASTER_CONNECTION_INTERRUPTED_TRYING_TO_REC', { formName }], { autoClose: false });
    this._updateFormStatus();
  };

  _getSubmitter = async (formVersionId) => {
    const requestBody = {
      formVersionId,
    };

    const submitter = await this.interactHttpService.post(this.apiUrls.submitter, requestBody);
    return submitter;
  };

  _syncFormConfiguration = async () => {
    const { id } = this.form;
    this.setDataLoading(true);

    try {
      const data = await this.getFormData(this.apiUrls.openRequest, id);
      const submitter = await this._getSubmitter(data.formVersionId);
      notify('success', 'common:SUCCESS_TOASTER_CONNECTION_RESTORED', { autoClose: false });

      this.setData({ ...data, submitterId: submitter.id }, { submitters: [submitter] });

      if (String(data.status) === this.inboxStatus) this._updateFormStatus();
    } finally {
      this.setDataLoading(false);
    }
  };

  _stopSocketConnection = () => {
    if (this.socketConnection) this.socketConnection.stop(true);
  };

  _onCloseSocketConnection = (_, connectionLost) => {
    if (!connectionLost) return;

    const { id } = this.form;
    notify('error', ['ERROR_TOASTER_UNFORTUNATELY_YOUR_INTERACTION_SESSI', { submissionID: id }], { autoClose: false });
    this.history.push('/submissions');
  };

  _updateFormStatus = () => {
    const [firstPage] = this.getVisiblePages();
    this.setActivePageId(firstPage.id);
    this.setFormMode(MODAL_MODES.EDIT);
    this._stopSocketConnection();
  };
}
