import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormField, InputArea } from '@blueprism/ui-core';

export const BaseFormFieldTextArea = (props) => {
  const { t } = useTranslation();
  const {
    allowNewLine,
    className,
    description,
    disabled,
    displayRemaining,
    formDisabled,
    gap,
    height = '156px',
    label,
    maxlength,
    minlength,
    placeholder,
    required,
    style,
  } = props;

  const [field, meta, helpers] = useField(props);
  const { isSubmitting } = useFormikContext();
  const { name, onBlur, onChange, value } = field;
  const { error, touched } = meta;
  const { setTouched, setValue } = helpers;

  const computedLabel = `${label}${required ? '\u00A0*' : ''}`;
  const displayError = touched && !!error;
  const isDisabled = isSubmitting || formDisabled || disabled;

  const changeFieldHandler = useCallback(
    (val) => {
      if (!touched) setTouched(true);

      if (!allowNewLine) {
        // eslint-disable-next-line no-param-reassign
        val.target.value = val.target.value.replace(/\n/g, '');
      }
      onChange(val);
    },
    [onChange, touched],
  );

  const blurFieldHandler = useCallback(
    (val) => {
      if (!touched) setTouched(true);

      onBlur(val);
      setValue(val.target.value.trim());
      props.onBlur(val.target.value.trim());
    },
    [onBlur, setValue, props.onBlur, touched],
  );

  return useMemo(() => {
    const valueLength = maxlength - (value ?? '').length;

    return (
      <FormField
        htmlFor={name}
        className={className}
        label={computedLabel}
        helperText={description}
        errorText={error}
        error={displayError}
        gap={gap}
      >
        <>
          <InputArea
            error={touched && error}
            disabled={isDisabled}
            id={name}
            maxLength={maxlength}
            minLength={minlength}
            name={name}
            onBlur={blurFieldHandler}
            onChange={changeFieldHandler}
            placeholder={placeholder}
            readOnly={isDisabled}
            height={height}
            resizable={false}
            style={style}
            value={value}
          />
          {displayRemaining && (
            <div className="input-chars-remaining">{t('common:CHARACTERS_REMAINING', { count: valueLength })}</div>
          )}
        </>
      </FormField>
    );
  }, [
    blurFieldHandler,
    changeFieldHandler,
    description,
    isDisabled,
    displayRemaining,
    label,
    maxlength,
    minlength,
    name,
    placeholder,
    required,
    style,
    value,
  ]);
};

export const BaseFormFieldTextAreaPropTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
  displayRemaining: PropTypes.bool,
  formDisabled: PropTypes.bool,
  label: PropTypes.string,
  maxlength: PropTypes.number,
  allowNewLine: PropTypes.bool,
  minlength: PropTypes.number,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  style: PropTypes.shape({}),
  gap: PropTypes.string,
};

export const BaseFormFieldTextAreaShape = PropTypes.shape(BaseFormFieldTextAreaPropTypes);

BaseFormFieldTextArea.propTypes = BaseFormFieldTextAreaPropTypes;

BaseFormFieldTextArea.defaultProps = {
  className: '',
  description: null,
  disabled: false,
  displayRemaining: false,
  formDisabled: false,
  allowNewLine: false,
  label: '',
  maxlength: 1000,
  onBlur: () => null,
  minlength: 0,
  placeholder: '',
  required: false,
  style: null,
  gap: 'xs',
};
