export const DEFAULT_COLOR_PALETTE = {
  Red: 'rgb(255,82,82)',
  Yellow: 'rgb(255,188,0)',
  Teal: 'rgb(81,140,152)',
  Green: 'rgb(0,200,83)',
  Blue: 'rgb(0,176,255)',
  Magenta: 'rgb(213,0,249)',
  Brown: 'rgb(141,110,99)',
  Black: 'rgb(51,51,51)',
};

export const ACTION_HIGHLIGHT = 'HIGHLIGHT';
export const ACTION_HIGHLIGHT_COLOR = 'rgb(255,254,13)';
