import React from 'react';
import PropTypes from 'prop-types';

import { StyledReactSVG, StyledText } from './SVGIcon.styled';

import './SVGIcon.scss';

export const SVGIcon = (props) => {
  const { className, ...rest } = props;

  return <StyledReactSVG className={className} {...rest} />;
};

SVGIcon.propTypes = {
  fallback: PropTypes.func,
  loading: PropTypes.func,
  renumerateIRIElements: PropTypes.bool,
  wrapper: PropTypes.string,
  className: PropTypes.string,
  evalScripts: PropTypes.string,
};

SVGIcon.defaultProps = {
  evalScripts: 'always',
  fallback: () => <StyledText>Error!</StyledText>,
  loading: () => <StyledText>Loading</StyledText>,
  renumerateIRIElements: false,
  wrapper: 'span',
  className: '',
};
