import styled from 'styled-components';
import { FormField } from '@blueprism/ui-core';

export const ProfilePicture = styled.div`
  width: 24rem;
`;

export const FormFieldAvatarPicker = styled(FormField)`
  padding: '6rem 3rem';
`;
