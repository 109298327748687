export const LANGUAGES = {
  EN: 0,
  FR: 1,
  DE: 2,
  JA: 3,
  ES: 5,
  ZH: 7,
};

export const APP_LANGUAGES = [
  { id: LANGUAGES.EN, label: 'English', value: 'en' },
  { id: LANGUAGES.FR, label: 'Français', value: 'fr' },
  { id: LANGUAGES.DE, label: 'Deutsch', value: 'de' },
  { id: LANGUAGES.JA, label: '日本語', value: 'ja' },
  { id: LANGUAGES.ES, label: 'Español (Latinoamérica)', value: 'es' },
  { id: LANGUAGES.ZH, label: '中文', value: 'zh' },
];

export const APP_LANGUAGES_LIST = APP_LANGUAGES.map(({ value }) => value);
