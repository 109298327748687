import styled, { css } from 'styled-components';
import { Stack } from '@blueprism/ui-core';

export const StyledDescriptions = styled.div`
  p {
    word-break: break-word;
  }
`;

export const StyledText = styled.p(
  ({ align }) => css`
    font-size: 1.5rem;
    font-weight: 900;
    overflow: hidden;
    text-overflow: ellipsis;
    ${align &&
    css`
      text-align: ${align};
    `}
  `,
);

export const StyledStickyFormWizardNavigation = styled(Stack)`
  ${({ sticky, theme }) => css`
    ${sticky &&
    css`
      z-index: 2;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      background-color: ${theme.color.paper};
    `}

    button {
      div {
        max-width: 120px;

        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: break-word;
        }
      }
    }
  `}
`;
