import React from 'react';

export const HttpServicesContext = React.createContext();

export const provideHttpServices = (OriginComponent) => ({ children, httpServices, ...rest }) => {
  const ComponentWithProvidedHttpServices = () => {
    return (
      <HttpServicesContext.Provider value={{ httpServices }}>
        <OriginComponent {...rest} />
      </HttpServicesContext.Provider>
    );
  };

  ComponentWithProvidedHttpServices.displayName = `provideHttpServices (${ComponentWithProvidedHttpServices()})`;

  return ComponentWithProvidedHttpServices();
};
