import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Row } from '@blueprism/ui-core';

export const ModalFooter = ({ cancelButtonLabel, closeModal }) => {
  const { t } = useTranslation();

  return (
    <Row justify="end">
      <Box>
        <Button onClick={closeModal}>{t(cancelButtonLabel)}</Button>
      </Box>
    </Row>
  );
};

ModalFooter.propTypes = {
  closeModal: PropTypes.func,
  cancelButtonLabel: PropTypes.string.isRequired,
};

ModalFooter.defaultProps = {
  closeModal: () => null,
};
