import styled from 'styled-components';

import { QuantityNav } from './QuantityNav';

export const QuantityNavStyled = styled(QuantityNav)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 20px;

  .quantity-arrow {
    position: absolute;
    width: 100%;
    cursor: pointer;
    transition: all 0.1s ease 0s;

    i {
      position: absolute;
      left: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      transform: translateX(-50%);
    }

    &-top {
      top: 2px;
      bottom: 50%;

      i {
        bottom: 3px;
        border-color: transparent transparent #b1b1b1;
        border-width: 0 4px 4px;
      }
    }

    &-bottom {
      top: 50%;
      bottom: 2px;

      i {
        top: 3px;
        border-color: #b1b1b1 transparent transparent;
        border-width: 4px 4px 0;
      }
    }
  }
`;
