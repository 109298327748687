import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Row, StatusIndicator } from '@blueprism/ui-core';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { notify } from 'app-toasts';
import queryString from 'query-string';

export const ExternalProviderLogin = ({ authStore, authType, provider }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [status, setStatus] = React.useState('idle');
  const pendingFlag = React.useRef(false);

  const externalProviderLoginHandler = () => {
    const returnUrl = unescape(location.search).replace('?ReturnUrl=', '');

    authStore.externalProviderLogin({ ...provider, returnUrl }, authType);
  };

  React.useEffect(() => {
    if (authStore.externalProviderLoginPending === provider.id) {
      pendingFlag.current = true;
      setStatus('pending');
    }

    const { error_description: loginErrorCode, ReturnUrl } = queryString.parse(location.search);
    if (loginErrorCode) {
      notify('error', `common:${loginErrorCode}`).then(() => {
        const returnUrl = ReturnUrl === undefined ? '' : `?ReturnUrl=${ReturnUrl}`;
        history.push(`${location.pathname}${returnUrl}`);
      });
    }
  }, [authStore.externalProviderLoginPending]);

  return (
    <Row align="end">
      <Button disabled={authStore.loginPending} onClick={externalProviderLoginHandler} fullWidth>
        {t('LOG_IN_WITH_NAME', provider)}
      </Button>
      {authStore.externalProviderLoginPending === provider.id && (
        <Box padding="1rem">
          <StatusIndicator status={status} />
        </Box>
      )}
    </Row>
  );
};

ExternalProviderLogin.propTypes = {
  authStore: PropTypes.shape({
    loginPending: PropTypes.bool,
    externalProviderLogin: PropTypes.func,
    externalProviderLoginPending: PropTypes.number,
  }).isRequired,
  provider: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  authType: PropTypes.number.isRequired,
};
