import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { CheckBox, Switch } from '@blueprism/ui-core';

import { StyledCheckBoxField } from '../components';

export const BaseFormFieldCheckbox = (props) => {
  const {
    containerClassName,
    disabled,
    formDisabled,
    gap,
    label,
    onChange,
    required,
    tooltipMessage,
    viewType,
    ...rest
  } = props;
  const [field, meta, helpers] = useField(props);
  const { isSubmitting } = useFormikContext();
  const { name, value } = field;
  const { error, touched } = meta;
  const displayError = touched && !!error;
  const { setTouched, setValue } = helpers;
  const isDisabled = isSubmitting || formDisabled || disabled;

  const changeFieldHandler = () => {
    if (!touched) setTouched(true);

    onChange(!value);
    setValue(!value);
  };

  const computedLabel = `${label}${required ? ' *' : ''}`;
  const Component = viewType === 'checkbox' ? CheckBox : Switch;

  return (
    <StyledCheckBoxField
      gap={gap}
      errorText={error}
      error={displayError}
      className={containerClassName}
      id={name}
      data-tip={tooltipMessage}
    >
      <Component
        id={name}
        value={name}
        label={computedLabel}
        onChange={changeFieldHandler}
        checked={Boolean(value)}
        disabled={isDisabled}
        {...rest}
      />
    </StyledCheckBoxField>
  );
};

export const BaseFormFieldCheckboxPropTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  formDisabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  gap: PropTypes.string,
  viewType: PropTypes.oneOf(['checkbox', 'switch']),
  disabled: PropTypes.bool,
  tooltipMessage: PropTypes.string,
};

export const BaseFormFieldCheckboxShape = PropTypes.shape(BaseFormFieldCheckboxPropTypes);

BaseFormFieldCheckbox.propTypes = BaseFormFieldCheckboxPropTypes;

BaseFormFieldCheckbox.defaultProps = {
  tooltipMessage: '',
  className: '',
  containerClassName: '',
  formDisabled: false,
  label: '',
  onChange: () => {},
  required: false,
  gap: 'xs',
  viewType: 'checkbox',
  disabled: false,
};
