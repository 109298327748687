import styled, { css } from 'styled-components';
import { Stack } from '@blueprism/ui-core';

export const FormFieldsContainer = styled(Stack)`
  ${({ fieldWidth, fullWidth }) => css`
    div[class*='FieldContainer']:not(.multiple-selection-datalist),
    div[class*='FieldLabel']:not(.multiple-selection-datalist) {
      width: ${fieldWidth || '30rem'};
      max-width: 100%;
    }

    .multiple-selection-datalist > div[class*='FormField']:last-of-type {
      width: 100%;
    }
    .FormFieldTable {
      width: 100% !important;
    }

    .multiple-selection-datalist > :last-child > div {
      width: ${({ theme }) => (fullWidth ? '100%' : `calc(${fieldWidth || '30rem'} - ${theme.globalSizes.large})`)};
    }
  `}
`;
