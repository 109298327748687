export default {
  'image/x-icon': 'ICO',
  'image/jpeg': 'JPEG',
  'image/jpg': 'JPG',
  'image/png': 'PNG',
  'image/gif': 'GIF',
  'image/tiff': 'TIFF',
  'image/svg+xml': 'SVG',
  'image/bmp': 'BMP',
};
