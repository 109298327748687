import styled, { css } from 'styled-components';

export const StyledDatePicker = styled.div`
  width: 100%;

  ${({ disabled, theme }) => css`
    .SingleDatePickerInput {
      background-color: transparent;
    }

    td {
      vertical-align: middle;
    }

    .DateRangePicker,
    .SingleDatePicker,
    .DateInput,
    .SingleDatePickerInput {
      width: 100%;
    }

    .DateRangePickerInput {
      display: flex;
      background: inherit;
    }

    .DateRangePickerInput__showClearDates,
    .SingleDatePickerInput__showClearDate {
      padding-right: 0;
    }

    .DateInput_input {
      color: ${theme.color.text} !important;
      font-weight: 400 !important;
      font-size: 1rem !important;
      background-color: ${theme.color.highlight} !important;
      border: none !important;
      border-bottom: 0.1rem solid ${disabled ? theme.color.disabled : theme.color.action} !important;
      border-radius: ${theme.globalSizes.smallest};

      &:focus {
        box-shadow: rgb(255 195 0) 0px 0px 0px 4px;
      }
    }

    .DateRangePickerInput_arrow {
      display: flex;
      align-items: center;
      margin: 0 1.5rem;
    }

    .DateRangePickerInput_clearDates,
    .SingleDatePickerInput_clearDate {
      margin: 0;
    }

    .DateInput_fang {
      display: none;
    }

    .DateInput {
      background: transparent;
    }
  `}
`;
