export class BaseFormFieldUploadValueModel {
  constructor(rawFile, file, result) {
    this.file = rawFile;
    this.size = file.size;
    this.link = result.link;
    this.name = file.name;
    this.type = file.type;
    this.extension = result.extension;
  }
}
