import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Login } from 'pages/Login';
import { Welcome } from 'pages/Welcome';
import { ResetPassword } from 'pages/ResetPassword';
import { Registration } from 'pages/Registration';
import { CreatePassword } from 'pages/CreatePassword';
import { ForgotPassword } from 'pages/ForgotPassword';

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (isAuthenticated ? <Component {...props} /> : <Redirect to="/account/login" />)}
  />
);

const PublicRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route {...rest} render={(props) => (isAuthenticated ? <Redirect to="/welcome" /> : <Component {...props} />)} />
  );
};

export const ContentRoutes = ({ isAuthenticated }) => (
  <Switch>
    <PublicRoute isAuthenticated={isAuthenticated} path="/account/login" component={Login} />
    <PublicRoute isAuthenticated={isAuthenticated} exact path="/account/reset-password" component={ResetPassword} />
    <PublicRoute isAuthenticated={isAuthenticated} exact path="/user-registration" component={Registration} />
    <PublicRoute isAuthenticated={isAuthenticated} exact path="/account/create-password" component={CreatePassword} />
    <PublicRoute isAuthenticated={isAuthenticated} exact path="/account/forgot-password" component={ForgotPassword} />
    <PrivateRoute isAuthenticated={isAuthenticated} exact path="/welcome" component={Welcome} />
  </Switch>
);

ContentRoutes.propTypes = {
  isAuthenticated: PropTypes.bool,
};

ContentRoutes.defaultProps = {
  isAuthenticated: false,
};
