import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyle = createGlobalStyle(
  ({ theme }) => css`
    .formik {
      width: 440px;
      padding: 30px 41px;
      background: ${theme.color.paper};
      border: 1px solid transparent;
      border-radius: 9px;
      box-shadow: 0 3px 10px 0 rgba(40, 86, 101, 0.15);
      transition: 0.2s;

      @media (max-width: 1200px) {
        width: 380px;
        padding: 20px 31px;
      }
      @media (max-width: 992px) {
        width: 440px;
        padding: 30px 41px;
      }

      .form-header-title {
        color: ${theme.color.text};
      }

      .reset-password-link {
        color: ${theme.color.action};
      }

      .reset-password-link:disabled {
        color: ${theme.color.text};
      }
    }

    .button-group {
      display: flex;
      justify-content: flex-end;
    }

    .login-form-wrapper {
      background: ${theme.color.paper};
      border: 1px solid transparent;
      border-radius: 9px;
      box-shadow: 0 3px 10px 0 rgba(40, 86, 101, 0.15);

      .login-form {
        background: transparent;
        border: none;
        border-radius: non;
        box-shadow: none;

        .reset-password-link {
          min-width: auto;
          padding: 0;
          font-weight: 400;
          font-size: 15px;
          background: transparent;
          border: none;
          outline: none;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
            background: transparent;
            border: none;
          }
        }

        .button-group {
          flex-direction: row-reverse;
          align-items: center;
          justify-content: space-between;
        }
      }

      hr {
        width: 80%;
        border-top: 1px solid ${theme.color.structure};
      }
    }
  `,
);
