import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import * as R from 'ramda';

import { Logout } from './Logout';

export const LogoutContainer = R.compose(
  inject((stores) => ({
    authStore: stores.rootStore.authStore,
    configStore: stores.rootStore.configStore,
  })),
  observer,
)(Logout);
