import { prepareDraftEditorValue } from 'app-text-editor/utils';

import { INITIAL_STATE_OPTIONS } from '../constants';
import { BaseFieldModel } from './BaseFieldModel';

export class ParagraphFieldModel extends BaseFieldModel {
  constructor(field, page) {
    super(field, page);

    this.setup(field);
  }

  setup(field) {
    const HIDDEN = INITIAL_STATE_OPTIONS[3].value;

    this.initialState = field.initialState === HIDDEN;
    this.value = prepareDraftEditorValue(this.payload.value);
  }

  getRequestModel() {
    const HIDDEN = INITIAL_STATE_OPTIONS[3].value;
    const READ_ONLY = INITIAL_STATE_OPTIONS[2].value;
    return {
      ...this,
      initialState: this.initialState ? HIDDEN : READ_ONLY,
      payload: JSON.stringify({
        value: this.value.descriptionHTML,
        hidden: this.initialState,
      }),
    };
  }
}
