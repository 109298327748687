import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import uuidv4 from 'uuid/v4';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { FormField } from '@blueprism/ui-core';
import * as R from 'ramda';

import { enhancedT } from 'app-i18n';
import { notify } from 'app-toasts';
import { ProgressBar } from 'app-progress';
import { MODAL_MODES } from 'app-modal';
import { ImageLoading } from 'app-image-loading';
import { UPLOAD_TYPES_ID } from 'app-base-form/constants';
import { downloadFileFromURL, getFormattedFileSize, compareStrings, UNIT_TRANSLATE_KEYS } from 'app-utils';

import { UploadButtonField } from './components/UploadButtonField';
import { UploadImageField } from './components/UploadImageField';
import { BaseFormFieldUploadValueModel } from './models';
import { StyledDescription } from '../components';

import './BaseFormFieldUploadStyles.scss';

export const BaseFormFieldUploadComponent = observer((props) => {
  const {
    blobStore: { isUploading, readAccessToken, reset, setUploading, uploadFile, uploadingProgress },
    description,
    disabled,
    fileExtensions,
    fileSize,
    formDisabled,
    formFilesSize,
    formMode,
    imageExtensions,
    imageSize,
    label,
    labelPlacement,
    maxFileSize,
    onChange,
    required,
    uploadStrategy,
    uploadTypes,
  } = props;

  const [uuid, setUuid] = useState(null);
  const [field, meta, helpers] = useField(props);
  const { isSubmitting } = useFormikContext();

  const { name, value } = field;
  const { setTouched, setValue } = helpers;
  const { error: errorText, touched } = meta;
  const displayError = touched && !!errorText;
  const isDisabled = isSubmitting || formDisabled || disabled;

  const uploading = isUploading[uuid];
  const uploaded = uploadingProgress[uuid];
  const uploadRef = useRef();

  const allowCancel = uploading && uploaded >= 0 && uploaded !== 100;
  const allowDelete = formMode !== MODAL_MODES.VIEW || !formDisabled;
  const allowDownload = formMode === MODAL_MODES.VIEW && value && R.path(['link'], value);
  const allowUpload = formMode !== MODAL_MODES.VIEW || !formDisabled;

  const convertedFileSizeToKb = fileSize * 1024;

  const resolveFileValue = useCallback((val) => {
    if (!val) return val;

    return R.pick(['name', 'file'], val);
  }, []);

  const resolveImageValue = useCallback((val) => (val ? val.file : val), []);

  const fileValue = resolveFileValue(value);
  const imageValue = resolveImageValue(value);
  const downloadFile = () => downloadFileFromURL(fileValue.link, fileValue.name);

  const clearField = useCallback(
    (id) => {
      setValue('');
      if (uploadRef.current) uploadRef.current.value = '';

      reset(id || uuid);
    },
    [uploadRef, uuid, reset, setValue],
  );

  const setImage = useCallback(
    async (file, payload) => {
      const id = uuidv4();
      setUuid(id);
      setUploading(true, id);

      if (formFilesSize.free < payload.file.size) {
        notify('error', [
          'common:THE_FORM_SHOULD_BE_NO_BIGGER_THAN',
          {
            maxSize: `${formFilesSize.maxFilesSize.value} ${enhancedT(
              UNIT_TRANSLATE_KEYS[formFilesSize.maxFilesSize.unit],
            )}`,
          },
        ]);

        setUploading(false, id);
        return clearField(id);
      }

      let result = {};
      if (uploadStrategy) {
        const response = await uploadFile(payload.file, id, file);

        const { error } = response;
        ({ result } = response);

        if (error) {
          setUploading(false, id);
          return clearField(id);
        }
      }

      reset(id);

      const val = new BaseFormFieldUploadValueModel(file, payload, result);

      setValue(val);
      setTouched(true);
      onChange(file, val);
    },
    [
      clearField,
      formFilesSize,
      onChange,
      reset,
      setTouched,
      setUploading,
      setUuid,
      setValue,
      uploadFile,
      uploadStrategy,
      uuidv4,
    ],
  );

  const onFileUpload = useCallback(
    (event) => {
      event.preventDefault();
      event.persist();

      if (event.target.files && event.target.files[0]) {
        const id = uuidv4();
        setUuid(id);
        setUploading(true, id);

        const [file] = event.target.files;
        const currentExtension = file.name.split('.').pop();
        const isFileValid =
          fileExtensions.some((format) => compareStrings(currentExtension, format, true) && format !== 'exe') &&
          file.size <= convertedFileSizeToKb * 1024;

        if (!isFileValid) {
          const { label: fileSizeLabel, value: fileSizeValue } = getFormattedFileSize(
            convertedFileSizeToKb,
            convertedFileSizeToKb,
          );
          notify('error', [
            'common:PLEASE_SELECT_FILES_NOT_BIGGER_THAN',
            {
              mimeTypes: fileExtensions.join(', '),
              size: `${fileSizeValue.toFixed(2)} ${fileSizeLabel}`,
            },
          ]);
          return setUploading(false, id);
        }

        if (formFilesSize.free < file.size) {
          notify('error', [
            'common:THE_FORM_SHOULD_BE_NO_BIGGER_THAN',
            {
              maxSize: `${formFilesSize.maxFilesSize.value} ${enhancedT(
                UNIT_TRANSLATE_KEYS[formFilesSize.maxFilesSize.unit],
              )}`,
            },
          ]);
          return clearField(id);
        }

        const reader = new FileReader();

        reader.onload = async (renderEvent) => {
          let result = {};
          if (uploadStrategy) {
            const response = await uploadFile(file, id, renderEvent.target.result);
            const { error } = response;
            ({ result } = response);

            if (error) return clearField(id);
          }

          reset(id);

          setTouched(true);

          const val = new BaseFormFieldUploadValueModel(renderEvent.target.result, file, result);

          setValue(val);
          onChange(val);
        };

        reader.readAsDataURL(file);
      }
    },
    [
      clearField,
      convertedFileSizeToKb,
      setValue,
      setTouched,
      fileExtensions,
      formFilesSize,
      onChange,
      reset,
      setUploading,
      setUuid,
      uploadFile,
      uploadStrategy,
      uuidv4,
    ],
  );

  const [imageIsLoading, setLoadingImage] = useState(false);

  useEffect(() => {
    if (imageValue) {
      setLoadingImage(true);

      const triggerImageDownload = async () => {
        const loaded = await ImageLoading(imageValue);
        if (loaded) setLoadingImage(false);
      };

      triggerImageDownload();
    } else {
      setLoadingImage(false);
    }
  }, [imageValue, readAccessToken]);
  const computedLabel = label ? `${label}${required ? '\u00A0*' : ''}` : '';

  return useMemo(() => {
    return (
      <FormField
        label={computedLabel}
        htmlFor={name}
        errorText={errorText}
        error={displayError}
        helperText={<StyledDescription type="caption">{description}</StyledDescription>}
        gap="xs"
      >
        <div className="progress">
          <ProgressBar progress={uploaded} />
        </div>
        {uploadTypes === UPLOAD_TYPES_ID.FILE ? (
          <UploadButtonField
            allowCancel={allowCancel}
            allowDelete={allowDelete}
            allowDownload={allowDownload}
            allowUpload={allowUpload}
            clearField={clearField}
            disabled={isDisabled}
            downloadFile={downloadFile}
            fileExtensions={fileExtensions}
            onFileUpload={onFileUpload}
            uploadRef={uploadRef}
            uploaded={uploaded}
            uploading={uploading}
            value={fileValue}
          />
        ) : (
          <UploadImageField
            allowCancel={allowCancel}
            allowDownload={allowDownload}
            clearField={clearField}
            disabled={isDisabled}
            downloadFile={downloadFile}
            fileValue={fileValue}
            imageExtensions={imageExtensions}
            imageSize={imageSize}
            loading={imageIsLoading}
            maxFileSize={maxFileSize}
            setImage={setImage}
            uploaded={uploaded}
            uploading={uploading}
            value={imageValue}
          />
        )}
      </FormField>
    );
  }, [
    allowCancel,
    allowDelete,
    allowDownload,
    clearField,
    description,
    isDisabled,
    fileExtensions,
    fileSize,
    fileValue,
    imageExtensions,
    imageIsLoading,
    imageSize,
    imageValue,
    computedLabel,
    labelPlacement,
    maxFileSize,
    maxFileSize,
    name,
    onFileUpload,
    required,
    setImage,
    uploadRef,
    uploadTypes,
    uploaded,
    uploading,
  ]);
});

BaseFormFieldUploadComponent.propTypes = {
  description: PropTypes.string,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  uploadTypes: PropTypes.number.isRequired,
  required: PropTypes.bool,
  fileExtensions: PropTypes.arrayOf(PropTypes.string),
  fileSize: PropTypes.number,
  imageExtensions: PropTypes.arrayOf(PropTypes.string),
  imageSize: PropTypes.string,
  maxFileSize: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
  formDisabled: PropTypes.bool,
  blobStore: PropTypes.shape({
    reset: PropTypes.func,
    uploadFile: PropTypes.func,
    setUploading: PropTypes.func,
    isUploading: PropTypes.shape({}),
    uploadingProgress: PropTypes.shape({}),
  }).isRequired,
  formFilesSize: PropTypes.shape({
    free: PropTypes.number,
  }),
};

BaseFormFieldUploadComponent.defaultProps = {
  label: '',
  labelPlacement: 'top',
  fileSize: 0,
  required: false,
  description: '',
  fileExtensions: [],
  imageSize: '',
  imageExtensions: [],
  maxFileSize: 5120,
  onChange: () => null,
  disabled: false,
  formDisabled: false,
  formFilesSize: {
    free: Number.MAX_SAFE_INTEGER,
  },
};
