import React from 'react';
import { Provider } from 'mobx-react';

import { AddEditLinkModalContainer } from './AddEditLinkModalContainer';
import { hyperLinkStore } from '../HyperLink';

const AddEditLinkModalProvider = () => {
  return (
    <Provider hyperLinkStore={hyperLinkStore}>
      <AddEditLinkModalContainer />
    </Provider>
  );
};

export default AddEditLinkModalProvider;
