import { FIELD_TYPES } from 'app-base-form/constants';
import { ACTIVE_DEPLOYMENT, DEPLOYMENT_TYPES } from 'src/constants';

export const resetPasswordFormConfig = (t) => {
  const fields = [
    {
      type: FIELD_TYPES.HEADER,
      name: 'title',
      value: t('IMPORTANT_PASSWORD_MESSAGE'),
      hiddenForDeployments: [DEPLOYMENT_TYPES.ON_PREMISE],
    },
    {
      type: FIELD_TYPES.PASSWORD,
      value: '',
      name: 'password',
      label: t('PASSWORD'),
      labelPlacement: 'left',
      required: true,
      isStrict: true,
      maxlength: 32,
    },
    {
      type: FIELD_TYPES.CONFIRM_PASSWORD,
      value: '',
      name: 'confirmPassword',
      label: t('CONFIRM_PASSWORD'),
      labelPlacement: 'left',
      required: true,
      maxlength: 32,
    },
  ];

  return fields.filter(({ hiddenForDeployments = [] }) => !hiddenForDeployments.includes(ACTIVE_DEPLOYMENT));
};
