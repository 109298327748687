import React from 'react';
import PropTypes from 'prop-types';
import { FormField, Placeholder } from '@blueprism/ui-core';

export const BaseFieldSkeleton = ({ description, height }) => (
  <FormField
    gap="xs"
    label={<Placeholder height="24px" width="100px" />}
    helperText={description && <Placeholder height="18px" width="50%" />}
  >
    <Placeholder height={height} width="100%" />
  </FormField>
);

BaseFieldSkeleton.propTypes = {
  height: PropTypes.string,
  description: PropTypes.string,
};

BaseFieldSkeleton.defaultProps = {
  height: '41px',
  description: null,
};
