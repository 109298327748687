import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Text } from '@blueprism/ui-core';
import { Refresh } from '@blueprism/ui-icons';

import { IconContainer, StyledComboBoxOptionLoading } from './ComboBoxOption.styled';

export const ComboBoxOptionLoading = ({ disabled, visible }) => {
  const { t } = useTranslation();

  return (
    <StyledComboBoxOptionLoading padding="base">
      <Text>{t('LOADING_DOTS')}</Text>
      <IconContainer visible={visible} aria-hidden={!visible} disabled={disabled}>
        <Refresh size={16} />
      </IconContainer>
    </StyledComboBoxOptionLoading>
  );
};

ComboBoxOptionLoading.propTypes = {
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
};

ComboBoxOptionLoading.defaultProps = {
  disabled: true,
  visible: false,
};
