import React from 'react';
import PropTypes from 'prop-types';
import { Rotate } from '@blueprism/ui-icons';

import { Spinner } from 'styled-containers';

export const SpinnerIcon = ({ color, margin, size }) => (
  <Spinner margin={margin}>
    <Rotate size={size} color={color} />
  </Spinner>
);

SpinnerIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  margin: PropTypes.string,
};

SpinnerIcon.defaultProps = {
  color: '#444',
  size: 24,
  margin: '',
};
