import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import moment from 'moment';
import * as R from 'ramda';
import { FormField } from '@blueprism/ui-core';

import { SingleDatePicker } from 'app-date-picker';
import { DATE_FORMAT_IN } from 'app-time';

import { ALLOW_DATE_TYPE } from '../../constants';
import { StyledDescription } from './components';

export const BaseFormFieldDate = (props) => {
  const {
    allowDates,
    className,
    description,
    disabled,
    disabledDate,
    format: payloadFormat,
    formDisabled,
    futureDateRestriction,
    gap,
    id,
    label,
    onChange,
    pastDateRestriction,
    placeholder,
    readOnly,
    required,
    updateTime,
    ...rest
  } = props;
  const [field, meta, helpers] = useField(props);
  const { isSubmitting } = useFormikContext();
  const { name, value } = field;
  const { error, touched } = meta;
  const { setTouched, setValue } = helpers;

  const format = R.pathOr(payloadFormat, ['value'], payloadFormat);
  const isDisabled = isSubmitting || formDisabled || disabled;

  const changeFieldHandler = useCallback(
    (date) => {
      if (!touched) setTouched(true);

      if (updateTime && date) {
        const newValue = moment.utc(date).format(DATE_FORMAT_IN);
        setValue(newValue);
        onChange(newValue);
      } else {
        setValue(date);
        onChange(date);
      }
    },
    [onChange, setTouched, setValue, touched],
  );

  const disableDateByRestrictions = React.useCallback(
    (current) => {
      if (!current) {
        return false;
      }

      const pastRestriction = allowDates === ALLOW_DATE_TYPE.FUTURE ? 0 : pastDateRestriction;
      const futureRestriction = allowDates === ALLOW_DATE_TYPE.PAST ? 0 : futureDateRestriction;
      const disabledYear = 0;

      if (current.utcOffset(0).year() === disabledYear) return true;

      const futureDateInstance = moment()
        .utcOffset(0)
        .add(Number.isInteger(futureRestriction) ? futureRestriction : 0, 'days')
        .endOf('day');
      const pastDataInstance = moment()
        .utcOffset(0)
        .subtract(Number.isInteger(pastRestriction) ? pastRestriction : 0, 'days')
        .startOf('day');

      if (Number.isInteger(futureRestriction) && Number.isInteger(pastRestriction)) {
        return (
          current.utcOffset(0).valueOf() < pastDataInstance.valueOf() ||
          current.utcOffset(0).valueOf() > futureDateInstance.valueOf()
        );
      }

      if (Number.isInteger(futureRestriction)) return current.utcOffset(0).valueOf() > futureDateInstance.valueOf();
      if (Number.isInteger(pastRestriction)) return current.utcOffset(0).valueOf() < pastDataInstance.valueOf();
    },
    [allowDates, pastDateRestriction, futureDateRestriction],
  );

  const computedLabel = `${label}${required ? ' *' : ''}`;
  const displayError = touched && !!error;

  return useMemo(() => {
    return (
      <FormField
        label={computedLabel}
        htmlFor={name}
        errorText={error}
        error={displayError}
        gap={gap}
        helperText={<StyledDescription type="caption">{description}</StyledDescription>}
      >
        <SingleDatePicker
          {...rest}
          id={name}
          name={name}
          className={className}
          placeholder={placeholder}
          format={format}
          disabled={isDisabled}
          disabledDate={disabledDate || disableDateByRestrictions}
          onDateChange={changeFieldHandler}
          updateTime={updateTime}
          value={value}
          readOnly={readOnly}
        />
      </FormField>
    );
  }, [
    name,
    value,
    label,
    format,
    required,
    isDisabled,
    className,
    placeholder,
    description,
    disabledDate,
    changeFieldHandler,
    disableDateByRestrictions,
  ]);
};

export const BaseFormFieldDatePropTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  disabledDate: PropTypes.func,
  formDisabled: PropTypes.bool,
  futureDateRestriction: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  pastDateRestriction: PropTypes.number,
  required: PropTypes.bool,
  gap: PropTypes.string,
  placeholder: PropTypes.string,
  updateTime: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export const BaseFormFieldDateShape = PropTypes.shape(BaseFormFieldDatePropTypes);

BaseFormFieldDate.propTypes = BaseFormFieldDatePropTypes;

BaseFormFieldDate.defaultProps = {
  className: '',
  description: null,
  disabledDate: null,
  formDisabled: false,
  futureDateRestriction: null,
  label: '',
  onChange: () => null,
  pastDateRestriction: null,
  required: false,
  gap: 'xs',
  placeholder: '',
  updateTime: false,
  readOnly: false,
};
