import * as R from 'ramda';
import uuidv4 from 'uuid/v4';

import { generateRuleValueSchema } from '../utils';
import {
  ELEMENT_TYPES,
  RULES_OPTIONS,
  RULE_IF_VALUE_TYPES,
  RULE_THEN_VALUE_TYPES,
  DEFAULT_IF_ELEMENTS,
  DEFAULT_THEN_ELEMENTS,
  ELEMENT_GROUP_TYPES,
} from '../constants';

function createValue(operator, fields, element, payload, operatorName, option = {}) {
  let value = { operator };

  if (operatorName === 'IF') {
    const ifConfig = RULE_IF_VALUE_TYPES[element.elementType][element.type][option.value];
    value = ifConfig.resolveRuleValue({
      operator,
      element,
      value,
      payload,
    });
  } else if (operatorName === 'THEN') {
    // if (!element || !element.elementType) return; // NOTE: may be usefull
    const thenConfig = RULE_THEN_VALUE_TYPES[element.elementType][element.type][option.value];

    value = thenConfig.resolveRuleValue({
      operator,
      element,
      value,
      payload,
    });
  }

  return value;
}

function getElementsGroupByType(elementType) {
  switch (elementType) {
    case ELEMENT_TYPES.FIELD:
      return ELEMENT_GROUP_TYPES.FIELDS;
    case ELEMENT_TYPES.PAGE:
      return ELEMENT_GROUP_TYPES.PAGES;
    case ELEMENT_TYPES.SUBMISSION:
    case ELEMENT_TYPES.PRIORITY:
    case ELEMENT_TYPES.SLA:
      return ELEMENT_GROUP_TYPES.DEFAULT_ELEMENTS;
    default:
      return null;
  }
}

function resolveOperatorValue(operator) {
  let operatorValue = R.pathOr(operator.value, ['value', 'value'], operator);
  if (Array.isArray(operatorValue)) {
    operatorValue = operatorValue.map(({ value }) => value);
  }
  return operatorValue;
}

export class RuleModel {
  constructor(rule, fields, pages, payload) {
    this.setup(rule, fields, pages, payload);
  }

  setup({ id, IF, order, THEN }, fields = [], pages = [], payload = {}) {
    let ifElement = IF.element;
    let thenElement = THEN.element;

    if (IF.elementType === ELEMENT_TYPES.FIELD) {
      ifElement = fields.find((field) => field.automationId === IF.id) || ifElement;
    } else {
      ifElement = DEFAULT_IF_ELEMENTS[IF.id];
    }

    if (THEN.elementType === ELEMENT_TYPES.FIELD) {
      thenElement = fields.find((field) => field.automationId === THEN.id) || thenElement;
    } else if (THEN.elementType === ELEMENT_TYPES.PAGE) {
      thenElement = pages.find((page) => page.uniqueId === THEN.id) || thenElement;
      if (thenElement) thenElement.label = thenElement ? thenElement.name : '';
    } else {
      thenElement = DEFAULT_THEN_ELEMENTS[THEN.id];
    }

    const ifOption = RULES_OPTIONS[R.pathOr(IF.option, ['value'], IF.option)];
    const thenOption = RULES_OPTIONS[R.pathOr(THEN.option, ['value'], THEN.option)];

    this.id = id;
    this.order = order;
    this.IF = {
      ...IF,
      option: ifOption,
      element: ifElement,
      value: ifElement && createValue(IF, fields, ifElement, payload, 'IF', ifOption),
    };
    this.THEN = {
      ...THEN,
      elementGroup: getElementsGroupByType(THEN.elementType),
      option: thenOption,
      element: thenElement,
      value: thenElement && createValue(THEN, fields, thenElement, payload, 'THEN', thenOption),
    };
    this.isValid = this._validate(pages);
  }

  getRequestModel() {
    const thenValue = resolveOperatorValue(this.THEN);
    const ifValue = resolveOperatorValue(this.IF);

    return {
      id: uuidv4(),
      IF: {
        option: this.IF.option.value,
        id: this.IF.element.automationId || this.IF.element.id,
        elementType: this.IF.element.elementType,
        value: ifValue,
      },
      THEN: {
        option: this.THEN.option.value,
        id: this.THEN.element.automationId || this.THEN.element.uniqueId || this.THEN.element.id,
        elementType: this.THEN.element.elementType,
        value: thenValue,
      },
    };
  }

  _validate = (pages) => {
    const ifOperatorIsValid = this._validateOperator(this.IF, RULE_IF_VALUE_TYPES);
    const thenOperatorIsValid = this._validateOperator(this.THEN, RULE_THEN_VALUE_TYPES);
    const validations = [ifOperatorIsValid, thenOperatorIsValid];

    if (this.THEN.elementType === ELEMENT_TYPES.PAGE) {
      /**
       * Find out if the page from the rule was placed after the
       * page of the rule to avoid manipulating previous pages.
       */
      const pageFromTheRuleIsAfterRulesPage = this._validatePageFromTheRulePlacement(pages);

      validations.push(pageFromTheRuleIsAfterRulesPage);
    }

    return validations.every(Boolean);
  };

  _validateOperator = (operator, VALUE_TYPES) => {
    const { element, option, value } = operator;

    if (!element) return false;

    const valueType = R.pathOr(
      {},
      [element.elementType, element.type, R.pathOr(option, ['value'], option)],
      VALUE_TYPES,
    );
    const validationSchema = generateRuleValueSchema(valueType, element, option.value);
    const calcValue = R.pathOr(value, ['label'], value);

    if (valueType.checkValidationConflict && valueType.checkValidationConflict(element.payload)) {
      return false;
    }

    return validationSchema.isValidSync(calcValue);
  };

  _validatePageFromTheRulePlacement = (pages) => {
    const sortedPages = R.sortBy(R.prop('order'))(pages);
    const indexOfPageFromTheRule = R.findIndex(R.propEq('id', this.THEN.element.id))(sortedPages);
    const indexOfRulePage = R.findIndex(R.propEq('id', R.path(['element', 'formPageId'], this.IF)))(sortedPages);

    return indexOfPageFromTheRule >= indexOfRulePage;
  };
}
