import React from 'react';
import { FormField } from '@blueprism/ui-core';

import { RichTextTemplate } from 'app-text-editor';

import {
  BaseFormFieldCheckbox,
  BaseFormFieldCheckboxGroup,
  BaseFormFieldComboBox,
  BaseFormFieldDate,
  BaseFormFieldImagePicker,
  BaseFormFieldMonthDatePicker,
  BaseFormFieldNumberFormat,
  BaseFormFieldRadio,
  BaseFormFieldRichTextArea,
  BaseFormFieldSlider,
  BaseFormFieldSwitch,
  BaseFormFieldTable,
  BaseFormFieldText,
  BaseFormFieldTextArea,
  BaseFormFieldTime,
  BaseFormFieldUpload,
  BaseFormFieldYearDatePicker,
} from '../components/BaseFormFields';
import {
  BaseFormElementGroupHeader,
  BaseFormElementHorizontalSeparator,
  BaseFormElementTemplate,
} from '../components/BaseFormElements';
import { FIELD_TYPES } from '../constants';

function defaultEnhanceField(CustomField) {
  return (props) => {
    return <CustomField {...props} />;
  };
}

const FORM_FIELDS = {
  [FIELD_TYPES.TEXT]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormFieldText)(rest),
  [FIELD_TYPES.NUMBER]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormFieldNumberFormat)(rest),
  [FIELD_TYPES.DATE]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormFieldDate)(rest),
  [FIELD_TYPES.TIME]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormFieldTime)(rest),
  [FIELD_TYPES.HORIZONTAL_RULE]: (props) => <BaseFormElementHorizontalSeparator {...props} />,
  [FIELD_TYPES.DROPDOWN]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormFieldComboBox)(rest),
  [FIELD_TYPES.COMBOBOX]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormFieldComboBox)(rest),
  [FIELD_TYPES.RADIO_GROUP]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormFieldRadio)(rest),
  [FIELD_TYPES.CHECKBOX_GROUP]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormFieldCheckboxGroup)(rest),
  [FIELD_TYPES.TEXTAREA]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormFieldTextArea)(rest),
  [FIELD_TYPES.CHECKBOX]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormFieldCheckbox)(rest),
  [FIELD_TYPES.IMAGE]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormFieldImagePicker)(rest),
  [FIELD_TYPES.PASSWORD]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormFieldText)({ ...rest, type: 'password' }),
  [FIELD_TYPES.CONFIRM_PASSWORD]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormFieldText)({ ...rest, type: 'password' }),
  [FIELD_TYPES.SLIDER]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormFieldSlider)(rest),
  [FIELD_TYPES.HEADER]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormElementGroupHeader)(rest),
  [FIELD_TYPES.TEMPLATE]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormElementTemplate)(rest),
  [FIELD_TYPES.UPLOAD]: ({ enhanceField = defaultEnhanceField, ...rest }) => enhanceField(BaseFormFieldUpload)(rest),
  [FIELD_TYPES.TABLE]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormFieldTable)(rest),
  [FIELD_TYPES.PARAGRAPH]: (props) => (
    <FormField gap="none">
      <BaseFormElementTemplate {...props} template={RichTextTemplate} className="input-block-paragraph" />
    </FormField>
  ),
  [FIELD_TYPES.RICH_TEXTAREA]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormFieldRichTextArea)(rest),
  [FIELD_TYPES.DATE_MONTH]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormFieldMonthDatePicker)(rest),
  [FIELD_TYPES.DATE_YEAR]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormFieldYearDatePicker)(rest),
  [FIELD_TYPES.SWITCH]: ({ enhanceField = defaultEnhanceField, uploadStrategy, ...rest }) =>
    enhanceField(BaseFormFieldSwitch)(rest),
};

export class BaseFormFieldFactory {
  static applyFieldMiddlewares = ({ fieldPayload, formDisabled, formMode, uploadStrategy }) => {
    const { type, ...rest } = fieldPayload;

    return FORM_FIELDS[type]({
      ...rest,
      formDisabled,
      formMode,
      uploadStrategy,
    });
  };

  static build(props) {
    return this.applyFieldMiddlewares(props);
  }
}
