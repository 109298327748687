import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@blueprism/ui-core';

import ImagePicker from 'app-image-picker';

export const UploadImageField = ({
  active,
  allowCancel,
  allowDownload,
  clearField,
  disabled,
  downloadFile,
  id,
  imageExtensions,
  imageSize,
  loading,
  maxImageSize,
  setImage,
  uploaded,
  uploading,
  value,
}) => {
  const { t } = useTranslation();
  const [maxImageWidth, maxImageHeight] = imageSize.match(/\d+/g) || [undefined, undefined];
  const acceptedFileTypes = imageExtensions.map(({ value: extension }) => extension);
  let buttonText = value ? 'common:EDIT' : 'UPLOAD';
  if (uploading) {
    if (uploaded === 0) {
      buttonText = 'common:IMAGE_PREPARATION';
    } else if (uploaded === 100) {
      buttonText = 'common:DATA_PREPARATION';
    } else {
      buttonText = 'common:UPLOADING';
    }
  }

  return (
    <Stack gap="none">
      <ImagePicker
        active={active}
        image={value}
        setImage={setImage}
        disabled={disabled}
        id={id}
        editButtonText={t(buttonText)}
        clearImage={clearField}
        maxFileSize={maxImageSize}
        allowClear={Boolean(value)}
        mimeTypes={acceptedFileTypes}
        maxImageWidth={maxImageWidth}
        maxImageHeight={maxImageHeight}
        allowCancel={allowCancel}
        uploading={uploading}
        loading={loading}
      />
      {allowDownload && (
        <Button onClick={downloadFile} className="remove-file">
          {t('common:DOWNLOAD')}
        </Button>
      )}
    </Stack>
  );
};

UploadImageField.propTypes = {
  active: PropTypes.bool,
  allowCancel: PropTypes.bool,
  clearField: PropTypes.func.isRequired,
  allowDownload: PropTypes.bool,
  downloadFile: PropTypes.func.isRequired,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imageExtensions: PropTypes.arrayOf(PropTypes.string),
  imageSize: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  maxImageSize: PropTypes.number,
  setImage: PropTypes.func.isRequired,
  uploaded: PropTypes.number,
  uploading: PropTypes.bool,
  value: PropTypes.string,
};

UploadImageField.defaultProps = {
  active: false,
  allowCancel: false,
  allowDownload: false,
  disabled: false,
  id: undefined,
  imageExtensions: [],
  loading: false,
  maxImageSize: 5120,
  uploaded: 0,
  uploading: false,
  value: '',
};
