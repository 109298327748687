import React from 'react';
import PropTypes from 'prop-types';

import { BaseFormFieldFactory } from '../factories/BaseFormFieldFactory';

export function renderBaseFields({ fields, formDisabled }) {
  return fields.map((fieldPayload) => (
    <div key={fieldPayload.name}>
      {BaseFormFieldFactory.build({
        fieldPayload,
        fields,
        formDisabled,
      })}
    </div>
  ));
}

renderBaseFields.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formDisabled: PropTypes.bool.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
  }).isRequired,
};
