import React from 'react';
import PropTypes from 'prop-types';
import RGL, { WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';

import { GRID_ROW_HEIGHT, GRID_NUMBER_OF_COLS } from '../constants';

const ReactGridLayout = WidthProvider(RGL);
const draggableCancelAttributes = [
  '.search-field',
  '.range-field',
  '.widget-actions',
  '.columnheader',
  '.rc-select',
  '.single-stacked-bar',
  '.react-select',
  '.widget-options-button',
  '.widget-delete-button',
  '.widget-filter-button',
  '.SingleDatePicker',
  '.SingleDatePicker_picker',
  '.pagination-toolbar',
  '.modal',
];

export const GridLayout = (props) => {
  const { children, layout, onLayoutChange } = props;

  return (
    <ReactGridLayout
      draggableHandle=".isDraggable"
      draggableCancel={draggableCancelAttributes.toString()}
      layout={layout}
      onLayoutChange={onLayoutChange}
      {...props}
      onResizeStop={() => {
        setTimeout(window.dispatchEvent(new CustomEvent('resize')), 50);
      }}
    >
      {children}
    </ReactGridLayout>
  );
};

GridLayout.propTypes = {
  className: PropTypes.string,
  rowHeight: PropTypes.number,
  cols: PropTypes.number,
  onLayoutChange: PropTypes.func,
  autoSize: PropTypes.bool,
  useCSSTransforms: PropTypes.bool,
  restrictClasses: PropTypes.string,
};

GridLayout.defaultProps = {
  className: 'layout',
  rowHeight: GRID_ROW_HEIGHT,
  cols: GRID_NUMBER_OF_COLS,
  onLayoutChange: () => {},
  autoSize: true,
  useCSSTransforms: false,
  restrictClasses: '',
};
