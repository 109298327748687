import React from 'react';
import PropTypes from 'prop-types';
import { InputField, Box } from '@blueprism/ui-core';
import { CrossLarge, MagnifyingGlass, Lightbulb } from '@blueprism/ui-icons';

import { ToggleTip } from 'app-toggle-tip';

import { IconContainer, InvisibleInput } from './TextInput.styled';

export const TextInput = (props) => {
  const {
    allowClear,
    className,
    detailsText,
    disableAutoComplete,
    disabled,
    displayError,
    id,
    maskData,
    maxLength,
    name,
    onBlur,
    onChange,
    onClear,
    onInput,
    placeholder,
    searchMode,
    summaryText,
    type,
    value,
  } = props;

  const fieldId = id || name;
  const inputType = maskData ? 'password' : type;
  const displayClear = allowClear && (value && value.length) > 0;

  const clearHandler = () => {
    if (!displayClear) return;
    onChange('');
    onClear();
  };

  return (
    <Box style={{ position: 'relative' }}>
      {detailsText && summaryText && (
        <ToggleTip detailsText={detailsText} summaryText={summaryText} icon={<Lightbulb size={16} />} />
      )}
      {disableAutoComplete && <InvisibleInput type="text" tabIndex="-1" />}
      <InputField
        onInput={onInput}
        className={className}
        disabled={disabled}
        error={displayError}
        id={fieldId}
        name={fieldId}
        maxLength={maxLength}
        onBlur={({ target }) => onBlur(target.value)}
        onChange={({ target }) => onChange(target.value)}
        placeholder={placeholder}
        type={inputType}
        value={value}
      />
      {searchMode && (
        <IconContainer padding="6px" bottom={0} right={0} onClick={clearHandler}>
          {displayClear ? (
            <CrossLarge title="cross-large" size={16} />
          ) : (
            <MagnifyingGlass title="magnifying-glass" size={16} />
          )}
        </IconContainer>
      )}
    </Box>
  );
};

TextInput.propTypes = {
  allowClear: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  maskData: PropTypes.bool,
  maxLength: PropTypes.number,
  onInput: PropTypes.func,
  placeholder: PropTypes.string,
  searchMode: PropTypes.bool,
  type: PropTypes.string,
  disableAutoComplete: PropTypes.bool,
  detailsText: PropTypes.string,
  summaryText: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onClear: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayError: PropTypes.bool,
};

TextInput.defaultProps = {
  allowClear: false,
  className: '',
  detailsText: '',
  summaryText: '',
  disabled: false,
  id: null,
  displayError: false,
  value: '',
  maskData: false,
  maxLength: 1000,
  onBlur: () => null,
  onChange: () => null,
  onInput: () => null,
  onClear: () => null,
  placeholder: undefined,
  searchMode: false,
  type: 'text',
  disableAutoComplete: false,
  name: '',
};
