import React from 'react';
import PropTypes from 'prop-types';

export const QuantityNav = ({ arrowMouseDown, className, decreaseValue, increaseValue }) => (
  <div className={className}>
    <b className="quantity-arrow quantity-arrow-top" onClick={increaseValue} onMouseDown={arrowMouseDown}>
      <i />
    </b>
    <b className="quantity-arrow quantity-arrow-bottom" onClick={decreaseValue} onMouseDown={arrowMouseDown}>
      <i />
    </b>
  </div>
);

QuantityNav.propTypes = {
  increaseValue: PropTypes.func.isRequired,
  decreaseValue: PropTypes.func.isRequired,
  arrowMouseDown: PropTypes.func,
  className: PropTypes.string,
};

QuantityNav.defaultProps = {
  arrowMouseDown: () => {},
  className: '',
};
