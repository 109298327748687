import styled, { css } from 'styled-components';
import { IconButton } from '@blueprism/ui-core';

export const StyledRefreshButton = styled(IconButton)`
  ${(props) =>
    css`
      svg {
        animation: ${props.refreshing ? 'rotate 1.5s linear infinite' : 'unset'};
        height: ${props.size || '24'}px;
        width: ${props.size || '24'}px;
      }

      @keyframes rotate {
        to {
          transform: ${props.refreshing ? 'rotate(-360deg)' : 'unset'};
        }
      }
    `}
`;
