import React, { PureComponent } from 'react';
import { func, oneOfType, shape, string, node, number, bool } from 'prop-types';
import * as R from 'ramda';
import { Box, Row } from '@blueprism/ui-core';
import { Ellipsis } from '@blueprism/ui-icons';

import { MaskedInput } from '../MaskedInput';
import { patterns } from '../patterns';

import './index.scss';

const defaultInputComponent = (props) => <MaskedInput {...props} />;

const defaultValidation = () => true;
const defaultSeparator = () => (
  <Box className="separator">
    <Ellipsis size={32} />
  </Box>
);

export class RangeInput extends PureComponent {
  state = {
    value: {
      from: '',
      to: '',
    },
    // eslint-disable-next-line react/no-unused-state
    prevValue: {},
  };

  static getDerivedStateFromProps = (props, state) => {
    const isPropsChanged = props.value.from !== state.prevValue.from || props.value.to !== state.prevValue.to;

    if (isPropsChanged || props.forceUpdateStatus) {
      return { value: props.value, prevValue: props.value };
    }
    return null;
  };

  handleFromChange = (event) => {
    const value = event === null ? '' : event;
    this.updateValue('from', value);
  };

  handleToChange = (event) => {
    const value = event === null ? '' : event;
    this.updateValue('to', value);
  };

  handleCloseInput = (name) => {
    this.setState(
      (state) => ({ value: { ...state.value, [name]: '' } }),
      () => this.updateFiltersValue(),
    );
  };

  updateValue = (inputType, event) => {
    const { value } = this.state;
    const { getValueFromChangeEvent } = this.props;

    const updatedValue = {
      ...value,
      [inputType]: getValueFromChangeEvent(event),
    };

    this.setState({ value: updatedValue });
  };

  updateFiltersValue = () => {
    const { onChange, validation } = this.props;
    const { prevValue, value: stateValue } = this.state;

    const fromIsValid = validation(stateValue.from);
    const toIsValid = validation(stateValue.to);
    const isInputFieldsAreEmpty = stateValue.from === '' && stateValue.to === '';
    const isInputFieldsFilledOut = stateValue.from !== '' && stateValue.to !== '';

    const isBothFieldsAreValid = R.and(fromIsValid, toIsValid);

    if (R.equals(prevValue, stateValue)) return;

    if (isInputFieldsAreEmpty) {
      onChange('');
    }

    if (isInputFieldsFilledOut && isBothFieldsAreValid) {
      onChange(stateValue);
    }
  };

  inputComponent = () => {
    const {
      error,
      inputClassName,
      inputComponent: Input,
      isInteger,
      max,
      min,
      pattern,
      separator: Separator,
      thousandSeparator,
      type,
      validation,
    } = this.props;
    const {
      value: { from, to },
    } = this.state;

    return (
      <Row justify="between" width="100%" align="center">
        <Input
          width="45%"
          pattern={pattern}
          className={inputClassName}
          onChange={this.handleFromChange}
          onBlur={this.updateFiltersValue}
          value={from}
          type={type}
          onClose={() => this.handleCloseInput('from')}
          min={min}
          max={max}
          isInteger={isInteger}
          validation={validation}
          error={error}
          thousandSeparator={thousandSeparator}
        />
        <Separator />
        <Input
          width="45%"
          pattern={pattern}
          className={inputClassName}
          onChange={this.handleToChange}
          onBlur={this.updateFiltersValue}
          value={to}
          type={type}
          onClose={() => this.handleCloseInput('to')}
          min={min}
          max={max}
          isInteger={isInteger}
          validation={validation}
          error={error}
          thousandSeparator={thousandSeparator}
        />
      </Row>
    );
  };

  render() {
    const { className, style } = this.props;
    return (
      <div className={`range-field ${className}`} style={style}>
        {this.inputComponent()}
      </div>
    );
  }
}

RangeInput.propTypes = {
  error: bool,
  type: string,
  className: string,
  inputClassName: string,
  inputComponent: oneOfType([node, func]),
  onChange: func.isRequired,
  validation: func,
  separator: func,
  style: shape(),
  pattern: string,
  min: number,
  max: number,
  isInteger: bool,
  getValueFromChangeEvent: func,
  thousandSeparator: bool,
  // eslint-disable-next-line react/no-unused-prop-types
  value: shape({ to: oneOfType([string, number]), from: oneOfType([string, number]) }),
};

RangeInput.defaultProps = {
  error: false,
  className: '',
  inputClassName: 'input',
  inputComponent: defaultInputComponent,
  value: {
    from: '',
    to: '',
  },
  getValueFromChangeEvent: (value) => value,
  validation: defaultValidation,
  separator: defaultSeparator,
  style: {},
  pattern: patterns.time,
  type: '',
  min: 0,
  max: 999999999,
  isInteger: false,
  thousandSeparator: undefined,
};
