import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@blueprism/ui-core';

import { Skeleton } from 'app-skeletons';

import { BaseFormSkeleton } from '../BaseFormSkeleton';
import { FIELD_TYPES } from '../constants';

export function renderBaseFormBody(props) {
  const {
    buttonClassName,
    buttonHandler,
    buttonLabel,
    cancelFormHandler,
    disableCheckDirty,
    formDisabled,
    formik,
    isPending,
    renderControlsButtons,
    renderFields,
    secondaryButtonProps,
    skeletonCount,
    skeletonType,
    submitButtonClassName,
    submitButtonLabel,
    ...rest
  } = props;
  const { isSubmitting } = formik;
  return (
    <Stack gap="large">
      <Skeleton
        loading={!isSubmitting && isPending}
        skeletonCount={skeletonCount}
        skeletonType={skeletonType}
        component={BaseFormSkeleton}
      >
        {renderFields({ ...rest, formDisabled })}
        {renderControlsButtons &&
          renderControlsButtons({
            buttonClassName,
            buttonHandler,
            buttonLabel,
            cancelFormHandler,
            disableCheckDirty,
            formDisabled,
            secondaryButtonProps,
            submitButtonClassName,
            submitButtonLabel,
          })}
      </Skeleton>
    </Stack>
  );
}

renderBaseFormBody.propTypes = {
  buttonClassName: PropTypes.string,
  buttonHandler: PropTypes.func,
  buttonLabel: PropTypes.string,
  cancelFormHandler: PropTypes.func,
  disableCheckDirty: PropTypes.bool,
  formDisabled: PropTypes.bool,
  formik: PropTypes.shape({
    isSubmitting: PropTypes.bool,
  }).isRequired,
  isPending: PropTypes.bool,
  renderControlsButtons: PropTypes.func,
  renderFields: PropTypes.func.isRequired,
  secondaryButtonProps: PropTypes.shape({}),
  skeletonCount: PropTypes.number,
  skeletonType: PropTypes.number,
  submitButtonClassName: PropTypes.string,
  submitButtonLabel: PropTypes.string,
};

renderBaseFormBody.defaultProps = {
  buttonClassName: undefined,
  buttonHandler: undefined,
  buttonLabel: undefined,
  cancelFormHandler: undefined,
  disableCheckDirty: undefined,
  formDisabled: false,
  isPending: false,
  renderControlsButtons: undefined,
  secondaryButtonProps: {},
  skeletonCount: 3,
  skeletonType: FIELD_TYPES.TEXT,
  submitButtonClassName: undefined,
  submitButtonLabel: undefined,
};
