import React, { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@blueprism/ui-core';

import { StyledUploadButton } from './UploadButton.styled';

export const UploadButton = forwardRef((props, forwardedRef) => {
  const { active, buttonComponent: ButtonComponent, disabled, icon, label, showAddIcon, text, ...rest } = props;
  const ref = forwardedRef || useRef();

  return (
    <StyledUploadButton>
      <input ref={ref} type="file" title="input-upload-license" {...rest} disabled={disabled} />
      <ButtonComponent
        disabled={disabled}
        iconLeft={showAddIcon ? <i className="icon icon-Import" /> : null}
        onClick={() => {
          ref.current.value = null;
          ref.current.click();
        }}
        active={active}
        icon={icon}
        label={label}
      >
        {text}
      </ButtonComponent>
    </StyledUploadButton>
  );
});

UploadButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  accept: PropTypes.string,
  active: PropTypes.bool,
  buttonComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.shape({}), PropTypes.func]),
  disabled: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  label: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  showAddIcon: PropTypes.bool,
};

UploadButton.defaultProps = {
  accept: '',
  active: false,
  label: undefined,
  buttonComponent: Button,
  disabled: false,
  icon: undefined,
  multiple: false,
  showAddIcon: false,
  text: '',
};
