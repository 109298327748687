import { ColorPickerButton } from './colorPicker/ColorPickerButton';
import { StyleButton } from './Toolbar/StyleButton';
import { HyperLink } from './HyperLink';

export const toolbarConfig = (showLinkControls) => ({
  options: ['inline', 'colorPicker', 'link'],
  inline: {
    inDropdown: false,
    component: StyleButton,
    options: ['bold', 'italic', 'underline'],
  },
  colorPicker: {
    component: ColorPickerButton,
  },
  link: {
    component: (props) => HyperLink({ ...props, showLinkControls }),
    showOpenOptionOnHover: false,
  },
});
