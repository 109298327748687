import moment from 'moment';

export const DATE_PICKER_MODES = {
  day: {
    format: 'DD/MM/YYYY',
    isOutsideRange: (day) => day.isAfter(moment().endOf('day')),
  },
  week: {
    format: '[Week] W, YYYY',
    isOutsideRange: (day) => day.isAfter(moment().endOf('isoweek')),
    shouldHighlight: (day, dayFromTheRange) => {
      const startOfTheRange = moment(dayFromTheRange).startOf('isoweek');
      const endOfTheRange = moment(dayFromTheRange).endOf('isoweek');

      return day.isAfter(startOfTheRange) && day.isBefore(endOfTheRange);
    },
  },
  month: {
    format: 'MMMM, YYYY',
    isOutsideRange: (day) => day.isAfter(moment().endOf('month')),
    shouldHighlight: (day, dayFromTheRange) => {
      const startOfTheRange = moment(dayFromTheRange).startOf('month');
      const endOfTheRange = moment(dayFromTheRange).endOf('month');

      return day.isAfter(startOfTheRange) && day.isBefore(endOfTheRange);
    },
  },
};

export const DATE_PICKER_MODE_NAMES = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
};
