import styled, { css } from 'styled-components';
import { Button } from '@blueprism/ui-core';

export const StyledButton = styled(Button)(
  ({ disabled, theme }) => css`
    width: 35%;
    height: 15rem;
    min-width: 14rem;
    max-width: 17rem;
    background: ${theme.color.paper};
    border-radius: 0.25rem;
    box-shadow: 0 3px 10px 0 rgba(40, 86, 101, 0.15);

    ${disabled &&
    css`
      svg path.st0 {
        fill: ${theme.color.paper};
      }

      svg .icon-body {
        stroke: ${theme.color.disabled};
      }

      svg .icon-lines {
        stroke: ${theme.color.disabled};
      }
      div {
        color: ${theme.color.disabled};
      }

      opacity: 0.5;
    `}

    &:active {
      color: ${theme.color.action};
      background-color: ${theme.color.paper};
    }
  `,
);

export const ImageContainer = styled.div`
  text-align: center;

  svg {
    display: block;
    width: 143px;
    max-width: 80%;
    margin: 0 auto;
  }

  .st0 {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    stroke: ${(props) => props.theme.color.action};
  }
`;

export const Title = styled.div`
  flex: 0 0 44px;
  margin-top: 15px;
  font-weight: 700;
  font-size: 26px;
  line-height: 1.35;
  text-align: center;
`;
