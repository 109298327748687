import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import * as R from 'ramda';

import { LanguageSelect } from './LanguageSelect';

export const LanguageSelectContainer = R.compose(
  inject(({ rootStore: { languageStore } }) => ({ languageStore })),
  observer,
)(LanguageSelect);
