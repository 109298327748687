import { URLS, AUTHENTICATION_TYPES } from 'constants';

import { action, observable } from 'mobx';
import * as R from 'ramda';
import { notify } from 'app-toasts';

export class AuthStore {
  constructor(rootStore, httpService, languageStore) {
    this.configStore = rootStore.configStore;
    this.http = httpService;
    this.routerStore = rootStore.routerStore;
    this.languageStore = languageStore;
  }

  @observable loginPending = false;

  @observable activeDirectoryLoginPending = false;

  @observable activeDirectoryLoginError = false;

  @observable externalProviderLoginPending = 0;

  @observable externalProviderLoginError = false;

  @action
  login = async (data, { setSubmitting }) => {
    this.loginPending = true;

    try {
      const requestModel = { ...data, language: this.languageStore.currentLanguage };

      const urlToRedirect = await this.http.post(URLS.login, requestModel);

      if (urlToRedirect) {
        window.location.href = urlToRedirect;
        return;
      }

      await this.configStore.setupUser();

      if (this.configStore.isAuthenticated) {
        this.routerStore.push('/welcome');
        return;
      }
    } catch (err) {
      setSubmitting(false);
    } finally {
      this.loginPending = false;
      this.configStore.setPending(false);
    }
  };

  @action
  activeDirectoryLogin = async (data) => {
    this.loginPending = true;
    this.activeDirectoryLoginPending = true;
    this.activeDirectoryLoginError = false;

    try {
      const requestModel = { ...data, language: this.languageStore.currentLanguage };

      const urlToRedirect = await this.http.post(URLS.loginActiveDirectory, requestModel);

      if (urlToRedirect) {
        window.location.href = urlToRedirect;
        return;
      }

      await this.configStore.setupUser();

      if (this.configStore.isAuthenticated) {
        this.routerStore.push('/welcome');
        return;
      }
    } catch (err) {
      this.activeDirectoryLoginError = true;
    } finally {
      this.loginPending = false;
      this.activeDirectoryLoginPending = false;
      this.configStore.setPending(false);
    }
  };

  @action
  externalProviderLogin = async (data, authType) => {
    const isSaml2User = authType === AUTHENTICATION_TYPES.SAML2;
    this.loginPending = true;
    this.externalProviderLoginPending = data.id;
    this.externalProviderLoginError = false;

    try {
      const requestModel = { ...data, language: this.languageStore.currentLanguage };

      const urlToRedirect = isSaml2User
        ? await this.http.post(URLS.saml2Login, requestModel)
        : await this.http.post(URLS.oidcLogin, requestModel);

      if (urlToRedirect) {
        window.location.href = urlToRedirect;
        return;
      }

      await this.configStore.setupUser();
    } catch (err) {
      this.externalProviderLoginError = true;
    } finally {
      this.loginPending = false;
      this.externalProviderLoginPending = 0;
      this.configStore.setPending(false);
    }
  };

  @action
  createPassword = async (data, { setSubmitting }) => {
    try {
      const setPassword = await this.http.post(URLS.createPassword, data);

      notify('success', 'PASSWORD_HAS_BEEN_SETUP');

      this.routerStore.push('/account/login');
      return setPassword;
    } catch (err) {
      const invalidConfirmationLinkCode = 4;
      const errorCode = R.path(['response', 'data', 'errorCode'], err);

      if (errorCode === invalidConfirmationLinkCode) {
        this.routerStore.push('/account/login');
      }
      setSubmitting(false);
    }
  };

  @action
  registrationSubmit = async (data, { setSubmitting }) => {
    const requestModel = { ...data, language: this.languageStore.currentLanguage };

    try {
      const user = await this.http.post(URLS.registration, requestModel);
      notify('success', 'REG_REQUEST_SENT_TO_APPROVAL');

      this.routerStore.push('/account/login');
      return user;
    } catch (err) {
      setSubmitting(false);
    }
  };

  @action
  forgotPassword = async (data, { setSubmitting }) => {
    try {
      const email = await this.http.post(URLS.forgotPassword, data);
      notify('success', 'THANK_YOU_MESSAGE');

      this.routerStore.push('/account/login');
      return email;
    } catch (err) {
      setSubmitting(false);
    }
  };

  @action
  resetPassword = async (data, { setSubmitting }) => {
    try {
      const resetPwd = await this.http.post(URLS.resetPassword, data);

      notify('success', 'RESET_PASSWORD_SUCCESS');

      this.routerStore.push('/account/login');
      return resetPwd;
    } catch (err) {
      setSubmitting(false);
    }
  };

  @action
  validateResetPassword = async (data) => {
    try {
      await this.http.post(URLS.validateResetPassword, data);
    } catch (err) {
      this.routerStore.push('/account/login');
    }
  };

  @action
  logout = async (data) => {
    try {
      const urlToSignOut = await this.http.get(`${URLS.logout}${data}`);
      return urlToSignOut;
    } catch {
      // Handled on higher level
    }
  };
}
