import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton, ImageContainer, Title } from './AppButton.styled';

export const AppButton = ({ disabled, href, icon, label, onClick, tooltip }) => (
  <StyledButton type="button" onClick={onClick} disabled={disabled}>
    <span href={href} data-tip={tooltip}>
      <ImageContainer>{icon}</ImageContainer>
      <Title>{label}</Title>
    </span>
  </StyledButton>
);

AppButton.propTypes = {
  disabled: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.string,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
};

AppButton.defaultProps = {
  disabled: false,
  href: '',
  icon: undefined,
  label: '',
  onClick: () => {},
  tooltip: undefined,
};
