import { ELEMENT_TYPES } from './ElementTypes';

export const DEFAULT_IF_ELEMENTS = {
  [ELEMENT_TYPES.SUBMITTER]: {
    id: ELEMENT_TYPES.SUBMITTER,
    translatableLabel: 'SUBMITTER',
    type: ELEMENT_TYPES.SUBMITTER,
    value: ELEMENT_TYPES.SUBMITTER,
    elementType: ELEMENT_TYPES.SUBMITTER,
  },
};
