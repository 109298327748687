import i18next from 'i18next';

// We need to store i18next in window in order to be able to share a single instance of i18next
// across multiple React applications within a single page (such as Announcements/Notifications and regular plugins)

if (!window.i18nInstance) {
  window.i18nInstance = i18next;
}

export const i18n = window.i18nInstance;
