import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { EditorState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';

import AddEditLinkModal from 'app-text-editor/plugins/HyperLink/AddEditLinkModal';

import { RichEditorStyles } from './RichTextEditor.styled';
import { toolbarConfig } from '../plugins';
import { convertEditorContent, getEditorContent } from '../utils';

export const RichTextEditor = ({ disabled, error, maxLength, onBlur, onChange, showLinkControls, value, ...rest }) => {
  const {
    i18n: { language: locale },
  } = useTranslation();

  const [editorState, setEditorState] = useState(convertEditorContent(value.descriptionHTML));
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    if (getEditorContent(editorState).descriptionHTML === value.descriptionHTML) return;

    setEditorState(convertEditorContent(value.descriptionHTML));
  }, [value]);

  const handleChange = useCallback(
    (editor) => {
      setEditorState(editor);

      const editorContent = getEditorContent(editor);
      if (editorContent.descriptionHTML === value.descriptionHTML) return;

      onChange(editorContent);
    },
    [onChange],
  );

  const onBlurHandle = () => {
    onBlur();
    setIsFocus(false);
  };

  const handleBeforeInput = useCallback(() => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText().length;
    const isTextEligibleToInput = currentContentLength > maxLength - 1;

    if (isTextEligibleToInput) {
      return 'handled';
    }
  }, [editorState]);

  const handlePastedText = useCallback(
    (pastedText) => {
      if (pastedText !== null) {
        const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText().length;
        const pastedTextWithoutLineBreaks = pastedText.replace(/\n/g, ' ');
        const isTextEligibleToPaste = currentContentLength + pastedTextWithoutLineBreaks.length <= maxLength;

        const newEditorState = EditorState.push(
          editorState,
          Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            isTextEligibleToPaste
              ? pastedTextWithoutLineBreaks
              : pastedTextWithoutLineBreaks.substring(0, maxLength - currentContentLength),
          ),
        );
        handleChange(newEditorState);
      }

      return 'handled';
    },
    [editorState],
  );
  const handleLineBreak = () => 'handled';

  return (
    <>
      <RichEditorStyles disabled={disabled} error={error} isFocus={isFocus} />
      <Editor
        {...rest}
        onFocus={() => setIsFocus(true)}
        onBlur={onBlurHandle}
        readOnly={disabled}
        toolbarHidden={false}
        toolbar={toolbarConfig(showLinkControls)}
        editorState={editorState}
        editorClassName={cn('editor', { disabled })}
        toolbarClassName={cn('toolbar', { disabled })}
        wrapperClassName={cn('RichEditor-root', { disabled })}
        onEditorStateChange={handleChange}
        handleReturn={handleLineBreak}
        handlePastedText={handlePastedText}
        handleBeforeInput={handleBeforeInput}
        localisation={{ locale }}
      />
      <AddEditLinkModal />
    </>
  );
};

RichTextEditor.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  showLinkControls: PropTypes.bool,
  maxLength: PropTypes.number,
  value: PropTypes.shape({
    descriptionHTML: PropTypes.string,
    description: PropTypes.string,
  }),
  error: PropTypes.bool,
};

RichTextEditor.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  disabled: false,
  showLinkControls: false,
  maxLength: 500,
  error: false,
  value: {
    descriptionHTML: '',
    description: '',
  },
};
