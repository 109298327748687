import styled, { css } from 'styled-components';
import { Row } from '@blueprism/ui-core';

export const StyledToastRow = styled(Row)(
  ({ isSuccessful, theme }) =>
    css`
      svg {
        path {
          fill: ${isSuccessful ? theme.color.action : theme.color.warning};
        }
      }
    `,
);
