import React from 'react';
import { PropTypes } from 'prop-types';
import { Text } from '@blueprism/ui-core';

import { StyledComboBoxOptionMassage } from './ComboBoxOption.styled';

export const ComboBoxOptionMessage = ({ text }) => (
  <StyledComboBoxOptionMassage padding="base">
    <Text>{text}</Text>
  </StyledComboBoxOptionMassage>
);

ComboBoxOptionMessage.propTypes = {
  text: PropTypes.string,
};

ComboBoxOptionMessage.defaultProps = {
  text: '',
};
