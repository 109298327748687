import styled, { createGlobalStyle } from 'styled-components';
import { DialogModal } from '@blueprism/ui-core';

export const StyledCustomModal = createGlobalStyle`

  .modal-extra-small {
    width: 375px;
  }

  .modal-small {
    width: 475px;
  }

  .modal-medium {
    width: 585px;
  }

  .modal-large {
    width: 80%;
  }

  .btn-close-modal {
    position: absolute;
    top: 50%;
    right: 15px;
    font-size: 19px;
    background: transparent;
    border: none;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }

    i {
      color: ${(props) => props.theme.color.action};
    }
  }
`;

export const StyledDialogModal = styled(DialogModal)`
  header {
    word-break: break-word;
  }
`;
