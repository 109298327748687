import * as R from 'ramda';
import { HubConnectionBuilder } from '@microsoft/signalr';

import { notify } from 'app-toasts';

export class SignalRService {
  constructor({ accessToken, errorPrefix = '', url }) {
    this.accessToken = accessToken;
    this.baseURL = url;
    this.errorPrefix = errorPrefix;
    this.connectionLost = false;
    this.setup();
  }

  setup = () => {
    this.signalRConnection = new HubConnectionBuilder()
      .withUrl(this.baseURL, { accessTokenFactory: () => this.accessToken })
      .withAutomaticReconnect()
      .configureLogging('critical')
      .build();
  };

  on = (message, callback) => {
    this.signalRConnection.on(message, callback);
  };

  onReconnected = (callback) => {
    this.signalRConnection.onreconnected((payload) => {
      this.connectionLost = false;
      callback(payload);
    });
  };

  onClose = (callback) => {
    this.signalRConnection.onclose((err) => {
      this.silent = false;
      callback(err, this.connectionLost);
      this.connectionLost = false;
    });
  };

  stop = () => {
    this.signalRConnection.stop();
  };

  start = async (silent = false) => {
    this.silent = silent;
    await this.signalRConnection.start().catch((error) => {
      this.tryToNotifyError(error);

      return Promise.reject(error);
    });
  };

  onReconnecting = (callback) => {
    this.signalRConnection.onreconnecting((error) => {
      this.connectionLost = true;
      callback(error);
    });
  };

  tryToNotifyError = (error) => {
    const errorCode = R.path(['response', 'data', 'errorCode'], error);
    const defaultMessage = 'common:SOMETHING_WENT_WRONG';

    const errorMessage = R.is(Number, errorCode) ? `${this.errorPrefix}${errorCode}` : defaultMessage;

    if (!this.silent) {
      notify(error.status || 'error', errorMessage);
    }

    if (errorMessage === defaultMessage) {
      console.error('An error has occurred:', error);
    }
  };
}
