import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import * as R from 'ramda';

import { ForgotPassword } from './ForgotPassword';

export const ForgotPasswordContainer = R.compose(
  inject((stores) => ({
    authStore: stores.rootStore.authStore,
  })),
  observer,
)(ForgotPassword);
