import styled from 'styled-components';

export const ContainerStyled = styled.div`
  position: relative;
  width: ${({ width }) => width};

  .prefix {
    font-weight: 900;
    white-space: nowrap;
  }
`;
