import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

export const BaseFormElementTemplate = (props) => {
  const { className, hidden, template, ...rest } = props;

  if (hidden) return null;

  return <div className={cn('input-block', className)}>{template(rest)}</div>;
};

BaseFormElementTemplate.propTypes = {
  className: PropTypes.string,
  template: PropTypes.func,
  hidden: PropTypes.bool,
};

BaseFormElementTemplate.defaultProps = {
  className: '',
  hidden: false,
  template: () => null,
};
