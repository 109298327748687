import styled, { css } from 'styled-components';
import { Button } from '@blueprism/ui-core';

import { InlineBadge } from 'app-inline-badge';

export const RoundButton = styled(Button)`
  ${(props) => css`
    width: 7.5rem;
    height: 3.25rem;
    font-weight: 900;
    font-size: 0.75rem;
    line-height: 0;
    border-radius: 1.625rem;

    ${
      props.circle &&
      css`
        width: 3.25rem;
        min-width: 3.25rem !important;
      `
    }

    &:active ${InlineBadge} {
      color: ${props.theme.color.action};
      background-color: ${props.theme.color.paper};
    }
  `}
`;
