import React, { Component } from 'react';
import { Router } from 'react-router';
import { Provider, observer } from 'mobx-react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ThemeProvider as BluePrismThemeProvider } from '@blueprism/ui-core';
import { InitialLoader } from 'app-initial-loader';

import { Logout } from 'pages/Logout';
import { history } from 'store/history';
import { Layout } from 'components/Layout';
import { RootStore } from 'store/RootStore';

import './assets/scss/styles.scss';

const path = [
  '/account/login',
  '/welcome',
  '/account/reset-password',
  '/user-registration',
  '/account/create-password',
  '/account/forgot-password',
];

const store = new RootStore();

@observer
export class App extends Component {
  render() {
    const {
      configStore: { pending },
    } = store;

    return (
      <>
        <Provider rootStore={store}>
          <BluePrismThemeProvider theme="light">
            {pending ? (
              <InitialLoader />
            ) : (
              <Router history={history}>
                <Switch>
                  <Route exact path={path} component={Layout} />
                  <Route exact path="/account/logout" component={Logout} />
                  <Redirect to="/welcome" />
                </Switch>
              </Router>
            )}
          </BluePrismThemeProvider>
        </Provider>
      </>
    );
  }
}
