export const hoursMinutesSecondExpression = new RegExp(
  /^((?=(?:\D*\d\D*){6})(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d))$/,
);

// eslint-disable-next-line max-len
export const hostnameExpression = /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))((\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30})){1,}\.[a-zA-Z]{2,30})$/;

// eslint-disable-next-line no-useless-escape
export const emailExpression = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+[^<>()\.,;:\s@\"]{2,})$/;

export function replaceSpecialSymbols(string) {
  // First 4 is most important, don't change order
  const specialSymbols = [
    '\\\\',
    '\\/',
    '\\-',
    '\\]',
    '\\[',
    '\\^',
    '\\$',
    '\\.',
    '\\|',
    '\\?',
    '\\*',
    '\\+',
    '\\(',
    '\\)',
  ];

  return specialSymbols.reduce((acc, symbol) => acc.replace(new RegExp(symbol, 'g'), symbol), string);
}
