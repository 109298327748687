export function assignConfigChanges(form) {
  const _form = form;
  let configChanges = {};

  try {
    configChanges = JSON.parse(_form.configChanges);
  } catch (err) {
    console.error(err);
  }

  _form.pages = _form.pages.map((page) => {
    let _page = page;

    if (configChanges[_page.name]) {
      _page = { ..._page, ...configChanges[_page.name] };
    }

    _page.fields = _page.fields.map((field) => {
      let _field = field;

      const fieldId = _field.submitterId || _field.automationId;

      if (configChanges[fieldId]) {
        _field = {
          ..._field,
          payload: JSON.stringify({
            ...JSON.parse(_field.payload),
            ...configChanges[fieldId],
          }),
        };
      }

      return _field;
    });

    return _page;
  });

  return _form;
}
