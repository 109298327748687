import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

function toggleEditorStyle(style, state, onChange) {
  onChange(`${style}`, !state);
}

export const StyleButton = (props) => {
  const { buttonList, currentState, disabled, onChange } = props;

  return buttonList.map(({ buttonClassName, style, title }) => {
    const { [style]: buttonState } = currentState;

    const styleOnClick = () => {
      toggleEditorStyle(style, buttonState, onChange);
    };

    return (
      <div key={style} className={cn('buttonWrapper', { disabled })} onMouseDown={styleOnClick}>
        <div className={cn('button', buttonClassName, { active: buttonState })}>{title}</div>
      </div>
    );
  });
};

StyleButton.propTypes = {
  buttonList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      style: PropTypes.oneOf(['bold', 'italic', 'monospace', 'strikethrough', 'subscript', 'superscript', 'underline']),
      buttonClassName: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  state: PropTypes.bool,
  disabled: PropTypes.bool,
  currentState: PropTypes.shape({}).isRequired,
};

StyleButton.defaultProps = {
  state: false,
  disabled: false,
  buttonList: [
    { style: 'bold', title: 'B', buttonClassName: 'bold' },
    { style: 'italic', title: 'I', buttonClassName: 'italic' },
    { style: 'underline', title: 'U', buttonClassName: 'underline' },
  ],
};
