export function checkUniqueArrayProps(propertyName, message) {
  return this.test('unique', message, function validate(value) {
    if (!value || !value[propertyName]) {
      return true;
    }

    const options = [...this.parent];
    const currentIndex = options.indexOf(value);
    const subOptions = options.slice(0, currentIndex);

    if (subOptions.some((option) => option[propertyName] === value[propertyName])) {
      throw this.createError({
        path: `${this.path}.${propertyName}`,
        message,
      });
    }

    return true;
  });
}

export function testByPattern(patternRef, message) {
  return this.test('matchBy', message, function validate(value) {
    const pattern = new RegExp(this.resolve(patternRef), 'gm');

    return pattern.test(value);
  });
}

export function requiredRichTextarea(value) {
  return value.description?.trim();
}

export function isRichEditorHasOverCharacterCount(value) {
  return value.description?.length <= 500;
}
