import React from 'react';
import PropTypes from 'prop-types';
import { FormField, Placeholder, Row } from '@blueprism/ui-core';

export const TimeFieldSkeleton = ({ description }) => (
  <FormField
    gap="xs"
    label={<Placeholder height="24px" width="100px" />}
    helperText={description && <Placeholder height="18px" width="50%" />}
  >
    <Placeholder height="14px" width="268px" padding="1rem 0 0 0" />
    <Row gap="xs">
      {Array.from(Array(3).keys()).map((key) => (
        <Placeholder key={key} height="41px" width="85px" />
      ))}
      <Placeholder height="40px" width="70px" />
    </Row>
  </FormField>
);

TimeFieldSkeleton.propTypes = {
  description: PropTypes.string,
};

TimeFieldSkeleton.defaultProps = {
  description: null,
};
