import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import moment from 'moment';
import * as R from 'ramda';
import { FormField } from '@blueprism/ui-core';

import { YearDatePicker } from 'app-date-picker';

export const BaseFormFieldYearDatePicker = (props) => {
  const {
    allowDates,
    containerElement,
    customDate,
    description,
    disabled,
    disabledDate,
    format: payloadFormat,
    formDisabled,
    futureDateRestriction,
    gap,
    label,
    onBlur,
    onChange,
    pastDateRestriction,
    placeholder,
    required,
    ...rest
  } = props;
  const [field, meta, helpers] = useField(props);
  const { isSubmitting } = useFormikContext();
  const { name, value } = field;
  const { error, touched } = meta;
  const { setTouched, setValue } = helpers;

  const format = R.pathOr(payloadFormat, ['value'], payloadFormat);

  const changeFieldHandler = useCallback(
    (date) => {
      if (!touched) setTouched(true);

      setValue(date);
      onChange(date);
    },
    [onChange, setTouched, setValue, touched],
  );

  const blurFieldHandler = useCallback(
    (date) => {
      if (!touched) setTouched(true);

      onBlur(date);
    },
    [onBlur, setTouched, setValue, touched],
  );

  const isDisabled = isSubmitting || formDisabled || disabled;
  const displayError = touched && !!error;
  const computedLabel = label ? `${label}${required ? '\u00A0*' : ''}` : '';

  return useMemo(() => {
    return (
      <FormField
        label={computedLabel}
        htmlFor={name}
        helperText={description}
        errorText={error}
        error={displayError}
        gap={gap}
      >
        <YearDatePicker
          {...rest}
          id={name}
          placeholder={placeholder}
          disabled={isDisabled}
          onDateChange={changeFieldHandler}
          onBlur={blurFieldHandler}
          value={value ? moment(value) : null}
        />
      </FormField>
    );
  }, [
    name,
    value,
    computedLabel,
    format,
    required,
    isDisabled,
    placeholder,
    description,
    disabledDate,
    changeFieldHandler,
  ]);
};

export const BaseFormFieldYearDatePickerPropTypes = {
  description: PropTypes.string,
  disabledDate: PropTypes.func,
  onBlur: PropTypes.func,
  formDisabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  gap: PropTypes.string,
  placeholder: PropTypes.string,
};

export const BaseFormFieldYearDatePickerShape = PropTypes.shape(BaseFormFieldYearDatePickerPropTypes);

BaseFormFieldYearDatePicker.propTypes = BaseFormFieldYearDatePickerPropTypes;

BaseFormFieldYearDatePicker.defaultProps = {
  description: null,
  disabledDate: null,
  formDisabled: false,
  onBlur: () => null,
  label: '',
  onChange: () => null,
  required: false,
  gap: 'xs',
  placeholder: '',
};
