import React from 'react';
import { Provider } from 'mobx-react';

import { HyperLinkStore } from './HyperLinkStore';
import { HyperLinkControls } from './HyperLinkControls';

export const hyperLinkStore = new HyperLinkStore();

export const HyperLink = (props) => (
  <Provider store={hyperLinkStore}>
    <HyperLinkControls {...props} />
  </Provider>
);
