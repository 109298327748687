import moment from 'moment';

const formatMomentDate = (format) => (date) => (date ? moment(date).format(format) : '');

const formatMomentDateParseZone = (format) => (date) => (date ? moment.parseZone(date).format(format) : '');

const formatMomentDateUtc = (format) => (date) => (date ? moment.utc(date).format(format) : '');

const formatMomentTimeUtc = (format) => (time) => (time ? moment.utc(time, 'HH:mm:ss A').format(format) : '');

export const formatDate = formatMomentDate('DD/MM/YYYY');

export const formatDateTime = formatMomentDate('DD/MM/YYYY HH:mm:ss');

export const formatDateTimeShort = formatMomentDate('DD/MM/YYYY HH:mm');

export const formatTime = formatMomentDate('HH:mm:ss');

export const formatTimeParseZone = formatMomentDateParseZone('HH:mm:ss');

export const formatTimeShort = formatMomentDate('HH:mm');

export const formatTimeShortParseZone = formatMomentDateParseZone('HH:mm');

export const formatMonthName = formatMomentDate('MMMM');

export const getDayFromDate = formatMomentDate('D');

export const formatDateUtc = formatMomentDateUtc('DD/MM/YYYY');

export const formatDateTimeUtc = formatMomentDateUtc('DD/MM/YYYY HH:mm:ss');

export const formatDateTimePmUtc = formatMomentDateUtc('DD/MM/YYYY h:mm:ss A');

export const formatTimeShortPmUtc = formatMomentTimeUtc('h:mm A');
