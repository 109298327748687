import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css, ThemeContext } from 'styled-components';
import { Stack } from '@blueprism/ui-core';
import { clonePropsToChildren } from '@blueprism/ui-core/utils/utils';
import { globalSizes } from '@blueprism/ui-core/theme/units';

const Details = styled(Stack)(
  (props) => css`
    color: ${props.theme.color.text};
    border: 0;
    outline: none;
    cursor: pointer;
  `,
);

const Summary = styled.summary(
  (props) => css`
    background-color: ${props.active ? props.theme.color.action : 'transparent'};
    color: ${props.active ? props.theme.color.paper : props.theme.color.action};
    text-align: left;
    display: flex;
    flex-direction: row;
    border-radius: ${globalSizes.smallest};
    outline: none;
    transition: 0.2s box-shadow;
    padding: ${globalSizes.xs};
    align-items: center;
    width: auto;

    svg {
      margin-right: ${globalSizes.xs};
    }

    :hover {
      box-shadow: 0 0 0 2px ${props.theme.color.hover};
    }

    :focus {
      box-shadow: 0 0 0 4px ${props.theme.color.focus};
    }

    ::-webkit-details-marker {
      display: none;
    }
  `,
);

const Span = styled.span(
  (props) => css`
    display: block;
    padding-left: ${globalSizes.xxs};
    margin-left: ${props.icon === null ? 0 : globalSizes.medium};
  `,
);
const hideText = (text) => `Hide ${text.charAt(0).toLowerCase() + text.slice(1)}`;

/**
 *
 * ### Blue Prism styled `ToggleTip` component

 A user can click on the Toggle Tip if they require further details of a question within a form.
 Once the Toggle Tip has been clicked, helper text will appear showing details of the question.
 */
export const ToggleTip = ({ detailsText, icon, onClick, summaryText }) => {
  const { color } = React.useContext(ThemeContext);
  const [active, setActive] = useState(false);

  function handleClick(event) {
    setActive((prevActiveState) => !prevActiveState);
    onClick(event);
  }

  return (
    <Details element="details">
      <Summary data-testid="toggle-tip-summary" tabindex="0" active={active} onClick={handleClick}>
        {clonePropsToChildren(icon, { color: active ? color.paper : color.action })}
        {active ? hideText(summaryText) : summaryText}
      </Summary>
      <Span icon={icon} data-testid="toggle-tip-details-text">
        {detailsText}
      </Span>
    </Details>
  );
};

ToggleTip.defaultProps = {
  icon: null,
  onClick: () => {},
};

ToggleTip.propTypes = {
  /** Text to display on render alongside the icon. */
  summaryText: PropTypes.string.isRequired,
  /** Text to toggle at onClick. Provides extra information to user. */
  detailsText: PropTypes.string.isRequired,
  /** Icon from the icon library to render next to the summary text.
   * The component will automatically set the color to action
   * when detail text is hidden and paper when displayed. */
  icon: PropTypes.node,
  /** Callback function that will be called on click. */
  onClick: PropTypes.func,
};
