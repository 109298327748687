import * as R from 'ramda';

import { FIELD_TYPES } from 'app-base-form/constants';

import { DateFieldModel } from '../models/DateFieldModel';
import { TimeFieldModel } from '../models/TimeFieldModel';
import { TextFieldModel } from '../models/TextFieldModel';
import { TextAreaFieldModel } from '../models/TextAreaFieldModel';
import { NumberFieldModel } from '../models/NumberFieldModel';
import { ParagraphFieldModel } from '../models/ParagraphFieldModel';
import { HorizontalRuleFieldModel } from '../models/HorizontalRuleFieldModel';
import { CheckBoxGroupModel } from '../models/CheckBoxGroupModel';
import { RadioGroupModel } from '../models/RadioGroupModel';
import { DropdownFieldModel } from '../models/DropdownFieldModel';
import { TableFieldModel } from '../models/TableFieldModel';
import { UploadFieldModel } from '../models/UploadFieldModel';
import { BaseFieldModel } from '../models/BaseFieldModel';

const FIELD_TYPE_MODELS = {
  [FIELD_TYPES.TIME]: TimeFieldModel,
  [FIELD_TYPES.TEXT]: TextFieldModel,
  [FIELD_TYPES.TEXTAREA]: TextAreaFieldModel,
  [FIELD_TYPES.DATE]: DateFieldModel,
  [FIELD_TYPES.NUMBER]: NumberFieldModel,
  [FIELD_TYPES.PARAGRAPH]: ParagraphFieldModel,
  [FIELD_TYPES.HORIZONTAL_RULE]: HorizontalRuleFieldModel,
  [FIELD_TYPES.TABLE]: TableFieldModel,
  [FIELD_TYPES.UPLOAD]: UploadFieldModel,
  [FIELD_TYPES.DROPDOWN]: DropdownFieldModel,
  [FIELD_TYPES.RADIO_GROUP]: RadioGroupModel,
  [FIELD_TYPES.CHECKBOX_GROUP]: CheckBoxGroupModel,
};

export class BaseFieldModelFactory {
  static getFieldModelByType(type) {
    return R.isNil(type) ? BaseFieldModel : FIELD_TYPE_MODELS[type];
  }
}
