// TODO: add other default dimensions to this dictionary
import { FIELD_TYPES } from './fieldTypes';

export const DEFAULT_FORM_FIELD_LAYOUT_DIMENSIONS_DICTIONARY = {
  [FIELD_TYPES.TEXT]: { w: 6, h: 2 },
  [FIELD_TYPES.NUMBER]: { w: 6, h: 2 },
  [FIELD_TYPES.DROPDOWN]: { w: 6, h: 2 },
  [FIELD_TYPES.TEXTAREA]: { w: 6, h: 5 },
  [FIELD_TYPES.HORIZONTAL_RULE]: { w: 6, h: 1 },
  [FIELD_TYPES.HEADER]: { w: 6, h: 1 },
  [FIELD_TYPES.TEMPLATE]: { w: 6, h: 1 },
  undefined: { w: 6, h: 2 },
};
