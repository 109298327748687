import { toJS, action, computed, observable, values } from 'mobx';
import * as R from 'ramda';
import debounce from 'debounce';

import { ChangedFieldModel } from 'app-base-form/models';
import { ELEMENT_TYPES, RULE_IF_VALUE_TYPES, RULE_THEN_VALUE_TYPES } from 'app-base-form/constants';
import { combineValidations } from 'app-base-form/utils';
import { isDebugMode } from 'app-debug';
import { isEmpty } from 'app-utils';

const isDebugRules = isDebugMode('debug/rules');

const ELEMENTS_WITHOUT_FIELD = [
  ELEMENT_TYPES.SUBMITTER,
  ELEMENT_TYPES.SUBMISSION,
  ELEMENT_TYPES.PRIORITY,
  ELEMENT_TYPES.SLA,
];
const sortByOrder = R.sortBy(R.prop('order'));
const filterHidden = R.filter(R.propEq('hidden', false));

class BaseFormRulesManagerStore {
  @observable formik = null;

  @observable rules = [];

  @observable _fields = [];

  @observable _activePage = null;

  @observable formFieldChangeBuffer = [];

  @observable elementsChanges = {};

  @observable initialChanges = {};

  @observable elementsValues = {};

  @observable submitterId = null;

  @observable applyingPromise = null;

  @observable resolveApplyingPromise = null;

  @observable _totalRulesToApply = 0;

  @observable _totalHandledRules = 0;

  @observable _handledChanges = {};

  @observable _visiblePagesCount = 0;

  @observable _skipNextValidate = false;

  @computed get fields() {
    return R.compose(sortByOrder, filterHidden, values)(this._fields);
  }

  @action
  setFormik = (formik) => {
    this.formik = formik;
  };

  @action
  setMethods = (updateFormData) => {
    if (!this._updateFormData) this._updateFormData = updateFormData;
  };

  @action
  setPayload = ({ activePage, submitterId, visiblePages }) => {
    this._activePage = activePage;
    this.submitterId = submitterId;
    this._visiblePagesCount = visiblePages.length;
    this._skipNextValidate = false;
  };

  @action
  setFields = (fields) => {
    this._fields = Object.values(fields).reduce((acc, field) => {
      acc[field.name] = field;
      return acc;
    }, {});
  };

  @action
  setRules = (rules = {}) => {
    const serverElements = [ELEMENT_TYPES.PRIORITY, ELEMENT_TYPES.SLA, ELEMENT_TYPES.SUBMISSION];

    this.rules = Object.values(rules).reduce((acc, _rule) => {
      if (serverElements.includes(_rule.THEN.elementType)) return acc;

      acc[_rule.IF.id] = acc[_rule.IF.id] ? [...acc[_rule.IF.id], _rule] : [_rule];
      return acc;
    }, {});
  };

  @action
  setElementsChanges = (changes, isInit = false) => {
    if (isEmpty(changes)) return;

    this.elementsChanges = R.merge(this.elementsChanges, changes);

    if (isInit) this.initialChanges = this.elementsChanges;
  };

  @action
  setInitialChanges = () => {
    this.elementsChanges = this.initialChanges;
  };

  @action
  commitValueChanges = ({ elementName, value }) => {
    this.elementsValues = {
      ...this.elementsValues,
      [elementName]: value,
    };

    this.handleRule(true);
  };

  @action
  commitElementChanges = (payload) => {
    this._registerChanges('elementsChanges', payload);
    this.handleRule(true, payload);
  };

  @action
  resetStoreState = () => {
    this.formik = null;
    this.rules = [];
    this._fields = [];
    this._activePage = null;
    this.formFieldChangeBuffer = [];
    this.elementsChanges = {};
    this.initialChanges = {};
    this.elementsValues = {};
    this.submitterId = null;
    this.applyingPromise = null;
    this.resolveApplyingPromise = null;
    this._totalRulesToApply = 0;
    this._totalHandledRules = 0;
    this._handledChanges = {};
    this._visiblePagesCount = 0;
    this._skipNextValidate = false;
  };

  initialRulesApply = () => {
    this.applyElementRules();
  };

  debounceApplyRules = debounce((field) => this.applyRules(field), 200);

  @action
  _setApplyingPromiseAndResolve = () => {
    this.applyingPromise = new Promise((resolve) => {
      this.resolveApplyingPromise = resolve;
    });
  };

  @action
  handleFieldChange = async (changedField) => {
    const prevField = R.last(this.formFieldChangeBuffer);
    const isFieldChanged = prevField?.fieldPayload.name === changedField.fieldPayload.name;

    if (prevField && !isFieldChanged) {
      await this.applyingPromise;
    }

    this.formFieldChangeBuffer.push(changedField);

    this._setApplyingPromiseAndResolve();
    this.debounceApplyRules(changedField);
  };

  _updateFieldsWithValuesAndChanges = (fields, elementsValues, elementsChanges) => {
    return R.keys(fields).map((key) => {
      const field = fields[key];

      Object.assign(field, elementsChanges[key]);

      field.value = toJS(elementsValues[key]);

      return field;
    });
  };

  _getDefaultApplyingPromise = () =>
    Promise.resolve({
      getConfigChanges: () => this.elementsChanges,
      setInitialChanges: this.setInitialChanges,
      getValues: () => toJS(this.formik.values),
      validate: (valueModifier = (formValues) => formValues) => {
        const { isValid, values: formikValues } = this.formik;
        const _formValues = R.merge(toJS(formikValues), toJS(this.elementsValues));
        const updatedFields = this._updateFieldsWithValuesAndChanges(this._fields, _formValues, this.elementsChanges);
        const validationSchema = combineValidations(updatedFields);

        return validationSchema
          .validate(valueModifier(_formValues, updatedFields))
          .then(() => isValid && true)
          .catch(() => false);
      },
    });

  waitForRulesToApply = () => {
    return this.applyingPromise || this._getDefaultApplyingPromise();
  };

  @action
  _resetHandleRule = () => {
    this.elementsValues = {};
    this._totalRulesToApply = 0;
    this._totalHandledRules = 0;
    this._handledChanges = {};
  };

  @action reset = () => {
    this.elementsChanges = {};
    this.initialChanges = {};
    this.elementsValues = {};
    this.applyingPromise = null;
    this.formik = null;
    this.rules = [];
    this._visiblePagesCount = 0;

    this._resetHandleRule();
  };

  @action
  _registerChanges = (property, { elementName, elementType, value }) => {
    this[property] = {
      ...this[property],
      [elementName]: this[property][elementName]
        ? {
            ...this[property][elementName],
            ...value,
          }
        : { elementType, ...value },
    };
  };

  @action
  handleRule = async (applied, payload = {}) => {
    if (this._totalRulesToApply !== 0) {
      this._totalHandledRules += 1;

      if (applied && payload) {
        this._registerChanges('_handledChanges', payload);
      }
    }

    if (this._totalRulesToApply !== this._totalHandledRules) return;

    /**
     * Apply form values(1) and changes(2)
     */
    let visiblePages = null;
    if (this._totalRulesToApply !== 0) {
      const formValues = R.merge(toJS(this.formik.values), toJS(this.elementsValues));

      // 1
      this.formik.setValues(formValues);

      // 2
      const changes = toJS(this._handledChanges);
      let pages;

      if (R.not(R.isEmpty(changes))) {
        ({ pages } = await this._updateFormData({ changes }));
        visiblePages = Object.values(pages).filter((page) => !page.hidden);
        const sortedVisiblePages = sortByOrder(visiblePages);

        /**
         * Skip the next validation because the number of visible pages has changed
         * and a new page appeared at the end (user clicked Submit)
         */
        const numberOfVisiblePagesChanged = sortedVisiblePages.length !== this._visiblePagesCount;

        if (numberOfVisiblePagesChanged) {
          const penultimateIndex = this._visiblePagesCount - 1;
          const activePageWasLast = R.path([penultimateIndex, 'id'], sortedVisiblePages) === this._activePage?.id;

          if (activePageWasLast) {
            this._skipNextValidate = true;
          }
        }
      }

      this._resetHandleRule();
    }

    /**
     * If nothing to apply, just resolveApplyingPromise
     */
    if (!this.resolveApplyingPromise) return;

    this.resolveApplyingPromise({
      payload: {
        visiblePages,
      },
      validate: (valueModifier = (formValues) => formValues) => {
        const { isValid, values: formikValues } = this.formik;
        const _formValues = R.merge(toJS(formikValues), toJS(this.elementsValues));
        const updatedFields = this._updateFieldsWithValuesAndChanges(this._fields, _formValues, this.elementsChanges);
        const validationSchema = combineValidations(updatedFields);

        if (this._skipNextValidate) {
          this._skipNextValidate = false;
          return false;
        }

        return validationSchema
          .validate(valueModifier(_formValues, updatedFields))
          .then(() => isValid && true)
          .catch(() => false);
      },
      getValues: () => toJS(this.formik.values),
      getConfigChanges: () => this.elementsChanges,
      setInitialChanges: this.setInitialChanges,
    });
    this.visiblePages = null;
  };

  @action
  applyRules = (element) => {
    const { fieldPayload, fieldValue, payload } = element;
    const { name } = fieldPayload;
    const activeFieldRules = this.rules[name];

    if (!activeFieldRules) {
      // eslint-disable-next-line no-console
      if (isDebugRules) console.info('There are no rules for the changed field');
      return this.handleRule(false);
    }

    this._totalRulesToApply = activeFieldRules.length;

    const activeRules = this.normalizeActiveRulesByFieldName(name);
    const { errors, setFieldValue, validateForm } = this.formik;

    Object.keys(activeRules).map((fieldName) => {
      Object.values(activeRules[fieldName]).map((activeRuleList) => {
        Object.values(activeRuleList).map((activeRule) => {
          // eslint-disable-next-line max-len
          let THEN_RULE_CONFIG = RULE_THEN_VALUE_TYPES[activeRule.THEN.elementType][activeRule.THEN.type];
          THEN_RULE_CONFIG = THEN_RULE_CONFIG
            ? THEN_RULE_CONFIG[activeRule.THEN.option.value || activeRule.THEN.option]
            : null;

          // eslint-disable-next-line max-len
          let IF_RULE_CONFIG = RULE_IF_VALUE_TYPES[activeRule.IF.elementType][activeRule.IF.type];
          IF_RULE_CONFIG = IF_RULE_CONFIG ? IF_RULE_CONFIG[activeRule.IF.option.value || activeRule.IF.option] : null;

          if (!IF_RULE_CONFIG) {
            // eslint-disable-next-line no-console
            if (isDebugRules) console.info('IF_RULE_CONFIG is empty', fieldName);
            return this.handleRule(false);
          }

          if (!IF_RULE_CONFIG.checkRuleTrigger) {
            // eslint-disable-next-line no-console
            if (isDebugRules) console.info('IF_RULE_CONFIG.checkRuleTrigger is not implemented yet!', fieldName);
            return this.handleRule(false);
          }

          const isFieldInvalid = errors[name];

          if (isFieldInvalid) {
            // eslint-disable-next-line no-console
            if (isDebugRules) console.info('Field is not valid');
            return this.handleRule(false);
          }

          const ruleShouldBeTriggered = IF_RULE_CONFIG.checkRuleTrigger({
            payload,
            ruleValue: fieldValue,
            value: activeRule.IF.value,
            fieldName: activeRule.IF.name,
            setFieldValue,
          });

          if (!ruleShouldBeTriggered) {
            // eslint-disable-next-line no-console
            if (isDebugRules) console.error('!ruleShouldBeTriggered');
            return this.handleRule(false);
          }

          if (!THEN_RULE_CONFIG || !THEN_RULE_CONFIG.applyRule) {
            // eslint-disable-next-line no-console
            if (isDebugRules) console.info('THEN_RULE_CONFIG.applyRule is not implemented yet!', fieldName);
            return this.handleRule(false);
          }

          const payloadForApply = {
            ruleId: activeRule.id,
            fieldValue,
            element: activeRule.THEN,
            value: activeRule.THEN.value,
            elementName: activeRule.THEN.name,
            elementType: activeRule.THEN.elementType,
            setFieldValue: this.commitValueChanges,
            updateElement: this.commitElementChanges,
          };

          THEN_RULE_CONFIG.applyRule(payloadForApply);
        });
      });
    });

    validateForm();
  };

  @action
  applyElementRules = () => {
    Object.values(this.rules).map((ruleList) => {
      ruleList.map((rule) => {
        if ([rule.IF, rule.THEN].some((element) => element.elementType === ELEMENT_TYPES.SUBMITTER)) {
          const changedField = new ChangedFieldModel({
            value: rule.IF.value,
            payload: rule.IF.element,
            fieldPayload: rule.IF.element,
            fieldType: rule.IF.elementType,
            fieldHandlerType: 'initialApply',
          });

          this._applySubmissionRules(changedField, rule);
        }
      });
    });
    setTimeout(() => this.formik.validateForm());
  };

  normalizeActiveRulesByFieldName(fieldName) {
    return this.rules[fieldName].reduce((acc, rule) => {
      if (R.not(rule.isValid)) {
        this.handleRule(false);
        return acc;
      }

      if (ELEMENTS_WITHOUT_FIELD.some((element) => element === rule.THEN.elementType)) {
        return acc;
      }

      const ifField = this._fields[rule.IF.id];
      const thenField = this._fields[rule.THEN.id] || rule.THEN.element;

      if (!ifField) {
        console.error('ifField is not defined');
        return acc;
      }

      acc[rule.THEN.id] = acc[rule.THEN.id]
        ? {
            ...acc[rule.THEN.id],
            [rule.THEN.option.value]: {
              ...acc[rule.THEN.id][rule.THEN.option.value],
              [rule.id]: {
                id: rule.id,
                IF: {
                  type: ifField.type,
                  name: ifField.name,
                  value: rule.IF.value,
                  option: rule.IF.option,
                  elementType: rule.IF.elementType,
                },
                THEN: {
                  type: thenField.type,
                  name: thenField.name,
                  value: rule.THEN.value,
                  option: rule.THEN.option,
                  elementType: rule.THEN.elementType,
                },
              },
            },
          }
        : {
            [rule.THEN.option.value]: {
              [rule.id]: {
                id: rule.id,
                IF: {
                  type: ifField.type,
                  name: ifField.name,
                  value: rule.IF.value,
                  option: rule.IF.option,
                  elementType: rule.IF.elementType,
                },
                THEN: {
                  type: thenField.type,
                  name: thenField.name,
                  value: rule.THEN.value,
                  option: rule.THEN.option,
                  elementType: rule.THEN.elementType,
                },
              },
            },
          };

      return acc;
    }, {});
  }

  _applySubmissionRules = (element, rule) => {
    this._totalRulesToApply += 1;

    const { fieldPayload, fieldValue, payload } = element;
    const IF_RULE_CONFIG =
      RULE_IF_VALUE_TYPES[fieldPayload.elementType][fieldPayload.type][rule.IF.option.value || rule.IF.option];

    if (!IF_RULE_CONFIG) {
      console.error('IF_RULE_CONFIG is empty');
      return;
    }

    if (!IF_RULE_CONFIG.checkRuleTrigger) {
      console.error('IF_RULE_CONFIG.checkRuleTrigger is not implemented yet!');
      return;
    }

    const ruleShouldBeTriggered = IF_RULE_CONFIG.checkRuleTrigger({
      payload,
      ruleValue: rule.IF.value,
      value: this.submitterId,
      fieldName: rule.IF.name,
      setFieldValue: this.commitValueChanges,
    });

    if (!ruleShouldBeTriggered) {
      this._totalRulesToApply -= 1;

      return;
    }

    const thenField = this._fields[rule.THEN.id] || rule.THEN.element;
    const elementName = thenField.name || thenField.automationId;
    const THEN_RULE_CONFIG =
      RULE_THEN_VALUE_TYPES[rule.THEN.elementType][thenField.type][rule.THEN.option.value || rule.THEN.option];

    if (!THEN_RULE_CONFIG.applyRule) {
      console.error('THEN_RULE_CONFIG.applyRule is not implemented yet!');
      return;
    }

    const payloadForApply = {
      fieldValue,
      element: rule.THEN,
      value: rule.THEN.value,
      elementName,
      elementType: rule.THEN.elementType,
      setFieldValue: this.commitValueChanges,
      updateElement: this.commitElementChanges,
    };

    this._setApplyingPromiseAndResolve();
    setTimeout(() => {
      THEN_RULE_CONFIG.applyRule(payloadForApply);
    });
  };
}

export const baseFormRulesManagerStore = new BaseFormRulesManagerStore();
