import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { Row } from '@blueprism/ui-core';

import { ToastContainer } from 'app-toasts';
import { LanguageSelect } from 'components/LanguageSelect';
import { ContentRoutes } from 'src/routes';
import { URLS } from 'src/constants';
import BPLogo from 'src/assets/images/BP-logo.png';

import { GlobalStyle } from './GlobalStyle.styled';
import { LogoContainer, AccountForm, StyledStack } from './Layout.styled';

const routesWithoutLanguageSelector = [URLS.resetPassword, URLS.forgotPassword, URLS.createPassword, URLS.welcome];

export const Layout = ({ isAuthenticated }) => {
  const location = useLocation();
  const showLanguageSelect = routesWithoutLanguageSelector.every((routePath) => !location.pathname.includes(routePath));

  return (
    <>
      <GlobalStyle />
      <StyledStack height="100%">
        <Row justify="between" padding="large">
          <LogoContainer>
            <img src={BPLogo} alt="img" />
          </LogoContainer>
          {showLanguageSelect && <LanguageSelect />}
        </Row>
        <AccountForm>
          <ContentRoutes isAuthenticated={isAuthenticated} />
        </AccountForm>
      </StyledStack>
      <ToastContainer />
    </>
  );
};

Layout.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};
