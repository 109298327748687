import styled, { css } from 'styled-components';
import { Text } from '@blueprism/ui-core';

export const TextEllipsis = styled(Text)`
  &,
  & > *:last-child,
  & > * > *:last-child {
    ${({ lineHeight, width }) => css`
      width: ${width || '100%'};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-word;

      ${lineHeight &&
      css`
        line-height: ${lineHeight};
      `}
    `}
  }
`;
