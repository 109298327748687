import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Box, Row } from '@blueprism/ui-core';

import { isNumber } from 'app-utils';

import { NumberFormatStyled } from './NumberFormat.styled';
import { QuantityNavStyled } from './QuantityNav.styled';
import { ContainerStyled } from './Container.styled';

const UP_ARROW = 38;
const DOWN_ARROW = 40;

export function NumberFormat({
  allowEmpty,
  allowLeadingZeros,
  allowNegative,
  allowValuesLessThanMin,
  decimalScale,
  disabled,
  enableWheel,
  error,
  fixedDecimalScale,
  inputClassName,
  isInteger,
  max,
  min,
  onBlur,
  onChange,
  onDecreaseValue,
  onFocus,
  onIncreaseValue,
  prefix,
  selectOnClick,
  showArrow,
  showArrowWhileHover,
  step,
  type,
  validation,
  value,
  width,
  ...rest
}) {
  let inputRef;

  const [inHover, setHover] = React.useState(false);
  const [inFocus, setFocus] = React.useState(false);

  const increaseValue = (event) => {
    if (event) event.preventDefault();
    const inputValue = isNumber(value) ? value : 0;
    const newValue = Number(inputValue) + step;

    inputRef.focus();

    if (onIncreaseValue) return onIncreaseValue(newValue);

    if (newValue >= max) {
      onChange(max);
    } else {
      onChange(newValue);
    }
  };

  const decreaseValue = (event) => {
    if (event) event.preventDefault();
    const inputValue = isNumber(value) ? value : 0;
    const newValue = Number(inputValue) - step;

    inputRef.focus();

    if (onDecreaseValue) return onDecreaseValue(newValue);

    if (newValue <= min) {
      onChange(min);
    } else {
      onChange(newValue);
    }
  };

  const handleValueChange = ({ floatValue }) => onChange(floatValue ?? null);

  function handleBlur() {
    onBlur(value);
  }

  const isValueAllowed = ({ floatValue }) => {
    if (floatValue === undefined) return true;
    if (allowValuesLessThanMin && min >= 0 && floatValue >= 0) return floatValue <= max;

    return floatValue >= min && floatValue <= max;
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === UP_ARROW) return increaseValue(event);
    if (event.keyCode === DOWN_ARROW) return decreaseValue(event);
  };

  const calcDecimalScale = isInteger ? 0 : decimalScale;

  const handleArrowMouseDown = (event) => event.preventDefault();

  const showArrowCalc = (showArrow && !disabled) || (!disabled && showArrowWhileHover && (inHover || inFocus));

  const handleWheel = ({ deltaY }) => {
    return deltaY < 0 ? increaseValue() : decreaseValue();
  };

  return (
    <ContainerStyled
      width={width}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Row align="center">
        {prefix && (
          <Box padding="small" className="prefix">
            {prefix}
          </Box>
        )}
        <NumberFormatStyled
          value={value}
          disabled={disabled}
          decimalScale={calcDecimalScale}
          className={cn('input', inputClassName)}
          $error={error}
          {...rest}
          autoComplete="off"
          allowNegative={allowNegative}
          isAllowed={isValueAllowed}
          allowEmptyFormatting={allowEmpty}
          allowLeadingZeros={allowLeadingZeros}
          fixedDecimalScale={fixedDecimalScale}
          getInputRef={(el) => {
            inputRef = el;
          }}
          onValueChange={handleValueChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onWheel={(event) => {
            if (enableWheel) handleWheel(event);
          }}
          onClick={() => selectOnClick && inputRef?.select()}
        />
      </Row>
      {showArrowCalc && (
        <QuantityNavStyled
          increaseValue={increaseValue}
          decreaseValue={decreaseValue}
          arrowMouseDown={handleArrowMouseDown}
        />
      )}
    </ContainerStyled>
  );
}

NumberFormat.propTypes = {
  allowEmpty: PropTypes.bool,
  allowLeadingZeros: PropTypes.bool,
  allowNegative: PropTypes.bool,
  decimalScale: PropTypes.number,
  disabled: PropTypes.bool,
  enableWheel: PropTypes.bool,
  error: PropTypes.bool,
  fixedDecimalScale: PropTypes.bool,
  inputClassName: PropTypes.string,
  allowValuesLessThanMin: PropTypes.bool,
  isInteger: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onDecreaseValue: PropTypes.func,
  onFocus: PropTypes.func,
  onIncreaseValue: PropTypes.func,
  prefix: PropTypes.string,
  selectOnClick: PropTypes.bool,
  showArrow: PropTypes.bool,
  showArrowWhileHover: PropTypes.bool,
  step: PropTypes.number,
  thousandSeparator: PropTypes.bool,
  type: PropTypes.string,
  validation: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

NumberFormat.defaultProps = {
  allowEmpty: false,
  allowValuesLessThanMin: false,
  allowLeadingZeros: false,
  allowNegative: false,
  decimalScale: undefined,
  disabled: false,
  enableWheel: false,
  error: false,
  fixedDecimalScale: false,
  inputClassName: '',
  isInteger: false,
  max: 99999999999999,
  min: 0,
  onBlur: () => null,
  onDecreaseValue: null,
  onFocus: () => null,
  onIncreaseValue: null,
  prefix: null,
  selectOnClick: false,
  showArrow: true,
  showArrowWhileHover: false,
  step: 1,
  thousandSeparator: undefined,
  type: undefined,
  validation: () => null,
  value: undefined,
  width: undefined,
};
