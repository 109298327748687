import { isEmpty } from 'app-utils';

import { SELECTION_TYPES_ID, INITIAL_STATE } from '../constants';
import { BaseFieldModel } from './BaseFieldModel';
import { initializeDropdownValue, numberOr } from '../utils';

export class DropdownFieldModel extends BaseFieldModel {
  constructor(field, page) {
    super(field, page);

    this.setup();
  }

  setup() {
    this.selectionType = this.payload.selectionType || SELECTION_TYPES_ID.SINGLE;
    this.minSelection = numberOr(null, this.payload.minSelection);
    this.maxSelection = numberOr(null, this.payload.maxSelection);
    this.options = this.payload.options || [
      {
        label: '',
        checked: false,
        isIdBind: true,
        value: '',
      },
    ];
    this.isMulti = this.payload.isMulti;
    this.value = this.assignValue(this.payload);
  }

  assignValue = initializeDropdownValue;

  resolveValue = (selectedValues) => {
    if (isEmpty(selectedValues)) return '';

    if (this.selectionType === SELECTION_TYPES_ID.SINGLE) return selectedValues.value;

    if (this.selectionType === SELECTION_TYPES_ID.MULTI) return selectedValues.map(({ value }) => value);
  };

  setDefaultValue = (options, selectionType) => {
    const checkedOptions = options.filter(({ checked }) => checked);

    if (isEmpty(checkedOptions)) return '';

    if (selectionType === SELECTION_TYPES_ID.SINGLE) {
      return checkedOptions[0].value;
    }

    if (selectionType === SELECTION_TYPES_ID.MULTI) {
      return checkedOptions.map(({ value }) => value);
    }
  };

  getRequestModel() {
    return {
      ...this,
      initialState: this.initialState.value,
      payload: JSON.stringify({
        value: this.setDefaultValue(this.options, this.selectionType),
        selectionType: this.selectionType,
        options: this.options.map(({ isIdBind, ...rest }) => ({ ...rest })),
        isMulti: this.selectionType === SELECTION_TYPES_ID.MULTI,
        hidden: this.initialState.value === INITIAL_STATE.HIDDEN,
        required: this.initialState.value === INITIAL_STATE.MANDATORY,
        disabled: this.initialState.value === INITIAL_STATE.READ_ONLY,
        minSelection: this.minSelection,
        maxSelection: this.maxSelection,
      }),
    };
  }
}
