import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import { RadioButton } from '@blueprism/ui-core';

import { StyledDescription, StyledFormField } from './components';

export const BaseFormFieldRadio = (props) => {
  const { t } = useTranslation();
  const { className, description, disabled, formDisabled, isNeededLabel, label, onChange, options, required } = props;

  const [field, meta, helpers] = useField(props);
  const { isSubmitting } = useFormikContext();
  const { name, value = null } = field;
  const { error, touched } = meta;
  const { setTouched, setValue } = helpers;
  const displayError = touched && !!error;

  const changeFieldHandler = useCallback(
    (val) => {
      if (!touched) setTouched(true);

      setValue(val);
      onChange(val);
    },
    [onChange, setValue, touched],
  );

  const isDisabled = isSubmitting || formDisabled || disabled;
  const computedLabel = `${label}${required ? '\u00A0*' : ''}`;

  return useMemo(() => {
    return (
      <StyledFormField
        className={className}
        label={computedLabel}
        errorText={error}
        error={displayError}
        htmlFor={name}
        gap="xs"
        helperText={<StyledDescription type="caption">{description}</StyledDescription>}
      >
        {options.map((option, index) => (
          <RadioButton
            key={`${name}_${option.value}`}
            id={`${name}_${index}`}
            label={isNeededLabel ? t(option.translatableLabel) || option.label : null}
            value={option.value}
            checked={option.value === value}
            disabled={isDisabled || option.disabled}
            onChange={() => changeFieldHandler(option.value)}
          />
        ))}
      </StyledFormField>
    );
  }, [changeFieldHandler, description, isDisabled, label, name, options, required, value]);
};

export const BaseFormFieldRadioPropTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  formDisabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  required: PropTypes.bool,
  name: PropTypes.string,
};

export const BaseFormFieldRadioShape = PropTypes.shape(BaseFormFieldRadioPropTypes);

BaseFormFieldRadio.propTypes = BaseFormFieldRadioPropTypes;

BaseFormFieldRadio.defaultProps = {
  className: '',
  description: null,
  disabled: false,
  isNeededLabel: true,
  formDisabled: false,
  label: '',
  onChange: () => {},
  options: [],
  required: false,
};
