import * as R from 'ramda';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';

import { Layout } from './Layout';

export const LayoutContainer = R.compose(
  inject(({ rootStore }) => ({
    isAuthenticated: rootStore.configStore.isAuthenticated,
  })),
  observer,
)(Layout);
