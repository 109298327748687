import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { BaseForm } from 'app-base-form';
import { getObjectFromSearch } from 'app-utils';

import { resetPasswordFormConfig } from './resetPasswordFormConfig';

export const ResetPassword = ({ authStore }) => {
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const resetPasswordCode = urlParams.get('resetPasswordCode');
    const userId = urlParams.get('userId');

    authStore.validateResetPassword({ userId, resetPasswordCode });
  }, []);

  const resetPasswordHandler = (credentials, formActions) => {
    const searchObject = getObjectFromSearch(location);
    searchObject.emailConfirmationCode = decodeURIComponent(searchObject.emailConfirmationCode);

    authStore.resetPassword({ ...credentials, ...searchObject }, formActions);
  };

  return (
    <BaseForm
      preventUnmountIfFormIsDirty={false}
      fields={resetPasswordFormConfig(t)}
      submitButtonLabel={t('RESET_PASSWORD')}
      title={t('RESET_PASSWORD')}
      submitHandler={resetPasswordHandler}
    />
  );
};

ResetPassword.propTypes = {
  authStore: PropTypes.shape({
    resetPassword: PropTypes.func,
    validateResetPassword: PropTypes.func,
  }).isRequired,
};
