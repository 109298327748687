import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField, useFormikContext } from 'formik';
import { FormField } from '@blueprism/ui-core';

import { prepareDraftEditorValue } from 'app-text-editor/utils';
import { RichTextEditor } from 'app-text-editor';

export const BaseFormFieldRichTextArea = (props) => {
  const { t } = useTranslation();

  const {
    description,
    disabled,
    displayRemaining,
    formDisabled,
    gap,
    label,
    maxlength: maxLength,
    minlength: minLength,
    required,
    ...rest
  } = props;

  const [field, meta, helpers] = useField(props);
  const { isSubmitting } = useFormikContext();
  const { name, value } = field;
  const { error, touched } = meta;
  const { setTouched, setValue } = helpers;

  const isDisabled = isSubmitting || formDisabled || disabled;
  const isRemainingCharsCountExist = !isDisabled && displayRemaining;
  const displayError = touched && Boolean(error);
  const computedLabel = label ? `${label}${required ? '\u00A0*' : ''}` : '';

  return useMemo(() => {
    const remainingCharacters = maxLength - value.description?.length;

    return (
      <FormField
        label={computedLabel}
        htmlFor={name}
        helperText={description}
        errorText={error}
        error={displayError}
        gap={gap}
      >
        <>
          <RichTextEditor
            {...rest}
            error={displayError}
            onBlur={() => setTouched(true)}
            id={name}
            disabled={isDisabled}
            value={value}
            name={name}
            maxLength={maxLength}
            minLength={minLength}
            onChange={setValue}
          />
          {isRemainingCharsCountExist && (
            <div className="input-chars-remaining">
              {t('common:CHARACTERS_REMAINING', {
                count: remainingCharacters >= 0 ? remainingCharacters : 0,
              })}
            </div>
          )}
        </>
      </FormField>
    );
  }, [
    description,
    isDisabled,
    isRemainingCharsCountExist,
    label,
    maxLength,
    minLength,
    name,
    required,
    value,
    displayError,
    error,
  ]);
};

export const BaseFormFieldRichTextAreaPropTypes = {
  description: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
  displayRemaining: PropTypes.bool,
  formDisabled: PropTypes.bool,
  gap: PropTypes.string,
  label: PropTypes.string,
  maxlength: PropTypes.number,
  minlength: PropTypes.number,
  required: PropTypes.bool,
  value: PropTypes.shape({}),
};

export const BaseFormFieldRichTextAreaShape = PropTypes.shape(BaseFormFieldRichTextAreaPropTypes);

BaseFormFieldRichTextArea.propTypes = BaseFormFieldRichTextAreaPropTypes;

BaseFormFieldRichTextArea.defaultProps = {
  description: null,
  disabled: false,
  displayRemaining: false,
  formDisabled: false,
  gap: 'xs',
  label: '',
  maxlength: 500,
  minlength: 0,
  required: false,
  value: prepareDraftEditorValue(),
};
