import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from '@blueprism/ui-core';

import { MODES } from 'app-constants';
import { BaseFormSubmitButton } from 'app-base-form';

export const BaseFormPageButtonGroup = ({ buttonGroupWidth, disableCheckDirty, mode, submitButtonLabel }) => {
  const { t } = useTranslation();

  return (
    <>
      {MODES.ADD === mode && (
        <Row justify="end" width={buttonGroupWidth}>
          <BaseFormSubmitButton active={false} disableCheckDirty={disableCheckDirty} label={t(submitButtonLabel)} />
        </Row>
      )}
    </>
  );
};
