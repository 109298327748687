import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import throttle from 'lodash/throttle';
import { ThemeContext } from 'styled-components';
import { Datalist as BPDatalist, Option, Button, Row, Stack } from '@blueprism/ui-core';
import { Refresh } from '@blueprism/ui-icons';

import { isEmpty } from 'app-utils';

import { DataListContainer, IconContainer } from './Datalist.styled';
import { translateOptionLabel } from './utils';

const ENTER_KEY_CODE = 13;

export const Datalist = ({
  allowNonExistingValues,
  ariaLabel,
  buttonLabel,
  clearOnceSelected,
  disabled,
  enableButton,
  filterOption,
  id,
  labelPropertyName,
  loading: propLoading,
  loadOptions,
  maxLength,
  name,
  onBlur,
  onButtonClick,
  onKeyDown,
  onLoadingChange,
  onSelect,
  options,
  required,
  setOptionsLength,
  value,
  valuePropertyName,
}) => {
  const isAsyncMode = !!loadOptions;
  const [autoCompleteValue, setAutoCompleteValue] = useState('');
  const [innerOptions, setInnerOptions] = useState([]);
  const [pendingChanges, setPendingChanges] = useState(false);
  const [loading, setLoading] = useState(false);

  const theme = useContext(ThemeContext);

  const DatalistWrapper = enableButton ? Row : Stack;

  const findOptionByLabel = (selectedValue) =>
    innerOptions.find((option) => {
      const translatedLabel = translateOptionLabel(option, labelPropertyName);

      return translatedLabel === selectedValue;
    });

  const setOptions = (_options) => {
    setOptionsLength(_options.filter(filterOption).length);

    if (isEmpty(value)) return setInnerOptions(_options);

    const translatedValue = translateOptionLabel(value, labelPropertyName);
    const _innerOptions = _options.filter((option) => {
      const translatedOption = translateOptionLabel(option, labelPropertyName);

      return translatedOption !== translatedValue;
    });

    setInnerOptions(_innerOptions);
  };

  const isMountedRef = useRef(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const loadAndSetOptions = async (searchValue = '') => {
    if (!isMountedRef.current) return;

    setLoading(true);

    let _options = options;

    if (isAsyncMode) {
      try {
        _options = (await loadOptions(searchValue)) || [];
      } catch {
        _options = [];
      }
    }

    setOptions(_options);
    setLoading(false);
  };

  const debouncedLoadOptions = useCallback(throttle(loadAndSetOptions, 1000), [loadOptions]);

  const isLoading = propLoading || loading;

  useEffect(() => {
    onLoadingChange(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (options && !isAsyncMode) {
      setOptions(options);
    }
  }, [options, value]);

  useEffect(() => {
    loadAndSetOptions();
  }, [loadOptions]);

  useEffect(() => {
    if (value) {
      const translatedValue = translateOptionLabel(value, labelPropertyName);

      setAutoCompleteValue(translatedValue);
    } else {
      setAutoCompleteValue('');
    }
  }, [value]);

  const handleSearch = (event) => {
    setPendingChanges(true);
    const optionLabel = event.target.value;
    setAutoCompleteValue(optionLabel);

    const option = findOptionByLabel(optionLabel);

    if (!option) {
      const loadSelectOptions = loadOptions ? debouncedLoadOptions : loadAndSetOptions;

      loadSelectOptions(optionLabel);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY_CODE) {
      event.target.blur();
    }

    onKeyDown(event);
  };

  const handleOptionChange = (event) =>
    new Promise((resolve) => {
      const inputRef = event.target;
      let isOptionChanged = false;
      const _onSelect = (val) => {
        isOptionChanged = true;
        onSelect(val);
      };

      setTimeout(() => {
        if (!isMountedRef.current) return;
        const optionLabel = inputRef.value.trim();

        if (optionLabel === '') {
          if (!required) {
            _onSelect(null);
          } else {
            setTimeout(() => setAutoCompleteValue(value ? translateOptionLabel(value, labelPropertyName) : ''));
          }
        } else {
          const option = findOptionByLabel(optionLabel);

          if (option) {
            _onSelect(option);
          } else if (allowNonExistingValues) {
            _onSelect({ [valuePropertyName]: null, [labelPropertyName]: optionLabel });
          } else {
            setAutoCompleteValue(value ? translateOptionLabel(value, labelPropertyName) : '');
          }
        }

        if (clearOnceSelected) {
          setAutoCompleteValue('');
        }

        setPendingChanges(false);

        resolve(isOptionChanged);
      });
    });

  const handleOptionChangeAndSetOptions = async (event) => {
    if (pendingChanges) {
      const isOptionChanged = await handleOptionChange(event);

      if (!isOptionChanged) {
        setTimeout(loadAndSetOptions, 1000);
      }
    }
    onBlur(event);
  };

  const handleButtonClick = (event) => {
    handleOptionChangeAndSetOptions(event);
    onButtonClick(event);
  };

  return (
    <DatalistWrapper gap="base">
      <DataListContainer required={required} $loading={isLoading}>
        <IconContainer visible={isLoading} aria-hidden={!isLoading} disabled={disabled}>
          <Refresh color={theme.color.action} size={16} />
        </IconContainer>
        <BPDatalist
          ariaLabel={ariaLabel}
          name={name}
          id={id || name}
          value={autoCompleteValue}
          onChange={handleSearch}
          onBlur={handleOptionChangeAndSetOptions}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          maxLength={maxLength}
        >
          {innerOptions.filter(filterOption).map((option) => {
            const translatedLabel = translateOptionLabel(option, labelPropertyName);

            return <Option value={translatedLabel} key={translatedLabel} />;
          })}
        </BPDatalist>
      </DataListContainer>
      {enableButton && <Button onClick={handleButtonClick}>{buttonLabel}</Button>}
    </DatalistWrapper>
  );
};

Datalist.propTypes = {
  loadOptions: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), MobxPropTypes.observableArray]),
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
    PropTypes.number,
    PropTypes.string,
  ]),
  required: PropTypes.bool,
  allowNonExistingValues: PropTypes.bool,
  ariaLabel: PropTypes.string,
  buttonLabel: PropTypes.string,
  clearOnceSelected: PropTypes.bool,
  enableButton: PropTypes.bool,
  filterOption: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  onButtonClick: PropTypes.func,
  onSelect: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  labelPropertyName: PropTypes.string,
  valuePropertyName: PropTypes.string,
  maxLength: PropTypes.number,
  setOptionsLength: PropTypes.func,
  onLoadingChange: PropTypes.func,
  loading: PropTypes.bool,
};

Datalist.defaultProps = {
  ariaLabel: null,
  loadOptions: null,
  options: [],
  value: null,
  id: null,
  name: '',
  required: false,
  allowNonExistingValues: false,
  buttonLabel: '',
  clearOnceSelected: false,
  enableButton: false,
  filterOption: () => true,
  onKeyDown: () => {},
  onBlur: () => {},
  onButtonClick: () => {},
  onSelect: () => {},
  setOptionsLength: () => {},
  onLoadingChange: () => {},
  disabled: false,
  labelPropertyName: 'label',
  valuePropertyName: 'value',
  maxLength: 250,
  loading: false,
};
