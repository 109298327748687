import { BaseFieldModel } from './BaseFieldModel';
import { INITIAL_STATE } from '../constants';

export class RadioGroupModel extends BaseFieldModel {
  constructor(field, page) {
    super(field, page);

    this.setup();
  }

  setup() {
    this.options = this.payload.options || [
      {
        label: '',
        checked: false,
        isIdBind: true,
        value: '',
      },
    ];
    this.value = this.payload.value || null;
  }

  setDefaultValue = (options) => {
    const checkedOption = options.find(({ checked }) => checked);

    return checkedOption ? checkedOption.value : null;
  };

  getRequestModel() {
    return {
      ...this,
      initialState: this.initialState.value,
      payload: JSON.stringify({
        value: this.setDefaultValue(this.options),
        options: this.options.map(({ isIdBind, ...rest }) => ({ ...rest })),
        hidden: this.initialState.value === INITIAL_STATE.HIDDEN,
        required: this.initialState.value === INITIAL_STATE.MANDATORY,
        disabled: this.initialState.value === INITIAL_STATE.READ_ONLY,
      }),
    };
  }
}
