import React from 'react';

import { BlobStorageContext } from './provideBlobStorage';

export const withBlobStorage = (OriginComponent) => (props) => {
  const ComponentWithBlobStorage = () => {
    return (
      <BlobStorageContext.Consumer>
        {({ blobStorage } = {}) => <OriginComponent {...props} blobStore={blobStorage} />}
      </BlobStorageContext.Consumer>
    );
  };

  ComponentWithBlobStorage.displayName = `withBlobStorage (${ComponentWithBlobStorage()})`;

  return ComponentWithBlobStorage();
};
