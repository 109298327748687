/**
 * checkIfBrowserIsIE checks for userAgent
 * to detect if user's browser is IE
 * @returns {boolean} true if IE, otherwise false
 */
export const checkIfBrowserIsIE = () => {
  return window.navigator.userAgent.indexOf('MSIE') !== -1 || window.navigator.appVersion.indexOf('Trident/') !== -1;
};
/**
 * checkIfBrowserIsFirefox checks for userAgent
 * to detect if user's browser is Firefox
 * @returns {boolean} true if Firefox, otherwise false
 */
export const checkIfBrowserIsFirefox = () => {
  return window.navigator.userAgent.indexOf('Firefox') !== -1;
};
/**
 *
 * @param {HTMLElement} el
 * Checks is provided element in viewport of browser
 */
export const isElementInViewport = (el) => {
  const {
    style: { display },
  } = el;
  if (display === 'none') return false;
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
