import styled, { css } from 'styled-components';
import { Button, Box } from '@blueprism/ui-core';

export const StyledButton = styled(Button)(
  ({ fullWidth, height }) => css`
    width: ${fullWidth ? '100%' : '15rem'};
    min-height: ${height || '5rem'};
    border-radius: 0.75rem;
    color: ${(props) => props.color};
    pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
    justify-content: ${(props) => props.justify || 'flex-start'};
    align-items: start;
    word-break: break-word;

    * {
      font-size: 0.75rem;
      color: inherit;
      text-align: left;
    }

    h5 {
      line-height: 0.875rem !important;
      margin: ${(props) => props.margin || '0 0 0.5rem 0'};
    }

    p {
      line-height: 1.125rem;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;

      flex: 1 0 auto;

      path {
        fill: ${(props) => (props.isDisabled ? props.theme.color.disabled : props.theme.color.action)};
      }
    }
  `,
);

export const StyledBox = styled(Box)`
  ${({ options }) => css`
    position: relative;

    ${options &&
    css`
      &:hover > :last-child {
        left: calc(100% + 1rem);
        opacity: 1;

        button {
          display: flex;
        }
      }
    `}
  `}
`;
