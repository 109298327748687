import { STATE_IS_PROPERTY } from '../constants/ruleSelectOptions';

export function setFieldValue({ elementName, ruleId, setFieldValue: setFieldVal, value }) {
  return setFieldVal({ elementName, value, ruleId });
}

export function setElementState({ elementName, elementType, updateElement, value: initialState }) {
  return updateElement({
    elementName,
    elementType,
    value: STATE_IS_PROPERTY[initialState.value],
  });
}
