import styled, { css } from 'styled-components';
import { Row } from '@blueprism/ui-core';

export const StyledStickyRow = styled(Row)`
  ${({ margin, padding, theme, zIndex }) => css`
    z-index: ${zIndex ?? 1};
    position: sticky;
    top: -1px;
    padding: ${padding ?? '1rem 0'};
    margin: ${margin ?? '0 0 -1rem'};
    background-color: ${theme.color.paper};

    & > h1,
    h2,
    h3 {
      flex: 0 1 auto;
      word-break: break-word;
    }

    & + p {
      z-index: ${zIndex ? zIndex + 1 : 1};
    }
  `}
`;
