export function TIME_SPAN_MASK(value) {
  const chars = value.split('');
  const hours = [/[0-2]/, chars[4] === '2' ? /[0-3]/ : /[0-9]/];

  return [/\d/, /\d/, /\d/, '.', ...hours, ':', /[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
}

export const MIN_H = 1;
export const MAX_H = 12;
export const MAX_M_S = 59;
export const MIN_M_S = 0;
export const DEFAULT_TIME = '00:00:00';
export const TIME_FORMAT_IN = 'hh:mm:ss A';
export const TIME_FORMAT_OUT = 'HH:mm:ss';
export const DATE_FORMAT_IN = 'YYYY-MM-DD HH:mm:ss[Z]';
export const TYPE_TIME_FORMAT = 'hh:mm:ss';
