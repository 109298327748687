import React from 'react';

import { combineValidations } from '../utils';

export const mapValidationFieldSchema = (OriginComponent) => (props) => {
  const BaseFormComponentWithValidationFieldSchema = () => {
    const { fields = [] } = props;

    return <OriginComponent getValidationSchema={() => combineValidations(fields)} {...props} />;
  };

  BaseFormComponentWithValidationFieldSchema.displayName = `combineValidationFieldSchema(
    ${BaseFormComponentWithValidationFieldSchema()}
  )`;

  return BaseFormComponentWithValidationFieldSchema();
};
