import styled, { css } from 'styled-components';
import { Box } from '@blueprism/ui-core';

export const StyledTableContainer = styled(Box)`
  ${({ theme }) => css`
    max-height: 300px;
    overflow: auto;

    thead {
      tr {
        height: auto;
        min-height: 2rem;
      }
      th {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: ${theme.color.paper};
        max-width: 105px;

        span {
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
    tbody {
      td {
        vertical-align: middle;
        padding-top: 5px;
        padding-bottom: 5px;

        button {
          margin: 0;
        }

        fieldset > :first-child {
          display: none;
        }
      }
    }

    .field-table-text-group {
      min-width: 105px;

      fieldset {
        div:nth-child(2) {
          margin: 0;
          padding: 0;
        }
      }
    }

    .field-table-checkbox {
      position: relative;
      right: 35px;
    }
  `}
`;

export const StyledColumnDescription = styled.span`
  color: #b1b1b1;
  font-size: 9px;
  font-weight: 400;
  margin: 0 0 5px 0;
`;
