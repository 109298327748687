import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import * as R from 'ramda';
import { Lightbulb } from '@blueprism/ui-icons';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { ComboBox, MultipleComboBox } from 'app-combobox';
import { useDeepCompareMemoize } from 'app-utils';
import { ToggleTip } from 'app-toggle-tip';

import { StyledDescription, StyledFormField, StyledDetailsText } from './components';

export const BaseFormFieldComboBox = (props) => {
  const {
    allowNonExistingValues,
    dependsOn,
    description,
    detailsText,
    disabled,
    disableInput,
    formDisabled,
    gap,
    helperTextYouSelectedMaxCountValues,
    icon,
    isMulti,
    itemWrapper,
    label,
    loadOptions,
    maxValues,
    onBlur,
    onChange,
    options,
    required,
    summaryText,
    ...rest
  } = props;

  const { isSubmitting, values } = useFormikContext();
  const [field, meta, helpers] = useField(props);
  const { name, value } = field;
  const { error, touched } = meta;
  const { setTouched, setValue } = helpers;

  const { t } = useTranslation();

  const handleTouched = () => {
    if (!touched) setTouched(true);
  };

  const changeFieldHandler = useCallback(
    async (val) => {
      onChange(val);
      await setValue(val);
    },
    [onChange, setTouched, setValue],
  );

  const blurFieldHandler = useCallback(
    (val) => {
      handleTouched();

      onBlur(val);
    },
    [onBlur, setTouched],
  );

  const handleClear = () => {
    setValue({});
  };

  const loadSelectOptions = useMemo(() => {
    return (searchQuery) => loadOptions(searchQuery, value, values);
  }, useDeepCompareMemoize([loadOptions, value, R.pick(dependsOn, values)]));

  const SelectComponent = isMulti ? MultipleComboBox : ComboBox;
  const computedLabel = label ? `${label}${required ? '\u00A0*' : ''}` : '';
  const displayError = touched && !!error;
  const isDisabled = isSubmitting || formDisabled || disabled;

  const isSelectedMaxValues = isMulti && maxValues && value?.length === maxValues;

  const getHelperTextForSelect = () => {
    if (isSelectedMaxValues) {
      return t(helperTextYouSelectedMaxCountValues);
    }

    if (loadOptions && allowNonExistingValues) {
      return t('TYPE_TO_FIND_AN_EXISTING_CATEGORY_OR_CREATE_A_NEW');
    }

    return description;
  };

  return useMemo(() => {
    return (
      <StyledFormField
        label={computedLabel}
        htmlFor={name}
        icon={icon}
        helperText={<StyledDescription type="caption">{getHelperTextForSelect()}</StyledDescription>}
        errorText={error}
        error={displayError}
        gap={gap}
        className={isMulti && 'multiple-selection-datalist'}
      >
        <>
          {detailsText && summaryText && (
            <ToggleTip detailsText={detailsText} summaryText={summaryText} icon={<Lightbulb size={16} />} />
          )}
          {detailsText && !summaryText && (
            <StyledDetailsText justify="end" padding="5px 0">
              {detailsText}
            </StyledDetailsText>
          )}
          <SelectComponent
            {...rest}
            allowNonExistingValues={allowNonExistingValues}
            itemWrapper={itemWrapper}
            id={name}
            name={name}
            options={options}
            value={value}
            required={required}
            disableInput={disableInput || isSelectedMaxValues}
            disabled={isDisabled}
            onSelect={changeFieldHandler}
            onBlur={blurFieldHandler}
            onClear={handleClear}
            handleDeleteItem={handleTouched}
            loadOptions={loadOptions && loadSelectOptions}
          />
        </>
      </StyledFormField>
    );
  }, [
    blurFieldHandler,
    changeFieldHandler,
    description,
    isDisabled,
    gap,
    icon,
    label,
    name,
    options,
    required,
    value,
    itemWrapper,
  ]);
};

export const BaseFormFieldComboBoxPropTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  dependsOn: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.string),
  detailsText: PropTypes.string,
  disabled: PropTypes.bool,
  formDisabled: PropTypes.bool,
  allowNonExistingValues: PropTypes.bool,
  gap: PropTypes.string,
  helperTextYouSelectedMaxCountValues: PropTypes.string,
  icon: PropTypes.node,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  required: PropTypes.bool,
  summaryText: PropTypes.string,
};

export const BaseFormFieldComboBoxShape = PropTypes.shape(BaseFormFieldComboBoxPropTypes);

BaseFormFieldComboBox.propTypes = BaseFormFieldComboBoxPropTypes;

BaseFormFieldComboBox.defaultProps = {
  description: '',
  detailsText: '',
  disabled: false,
  disableInput: false,
  dependsOn: [],
  formDisabled: false,
  allowNonExistingValues: false,
  helperTextYouSelectedMaxCountValues: 'common:YOUVE_REACHED_THE_MAX_OPTIONS_VALUE',
  gap: 'xs',
  icon: undefined,
  isMulti: false,
  label: '',
  maxValues: null,
  onBlur: () => {},
  onChange: () => {},
  required: false,
  summaryText: '',
};
