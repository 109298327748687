import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import * as R from 'ramda';

import { Registration } from './Registration';

export const RegistrationContainer = R.compose(
  inject((stores) => ({
    authStore: stores.rootStore.authStore,
  })),
  observer,
)(Registration);
