import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Box, Text, ToolbarButton, ButtonGroup } from '@blueprism/ui-core';
import { Cross, Refresh } from '@blueprism/ui-icons';

import { StyledStickyRow } from 'styled-containers';
import { MODES } from 'app-constants';

import { BaseFormSubmitToolbarButton } from '../../components';

export const BaseFormPageHeader = ({
  customButtons,
  description,
  disableCheckDirty,
  enableSubmitButton,
  mode,
  onCancel,
  resetForm,
  title,
}) => {
  const { t } = useTranslation();
  const formikContext = useFormikContext();
  return (
    <StyledStickyRow padding="1rem 0" align="center" justify="between" zIndex={2}>
      <Box>
        <Text type="h1">{t(title)}</Text>
        <Text type="body">{t(description)}</Text>
      </Box>
      <ButtonGroup>
        {MODES.ADD === mode && resetForm && (
          <ToolbarButton
            data-testid="form-reset-button"
            onClick={() => resetForm(formikContext)}
            icon={<Refresh />}
            label={t('RESET_ALL')}
          />
        )}
        <ToolbarButton
          icon={<Cross />}
          label={t('CANCEL')}
          onClick={() => onCancel(formikContext)}
          data-testid="form-action-cancel-button"
        />
        {customButtons &&
          customButtons.map(
            ({ buttonMode, label, ...rest }) =>
              mode === buttonMode && <ToolbarButton {...rest} key={label} label={t(label)} />,
          )}
        {(MODES.EDIT === mode || enableSubmitButton) && (
          <BaseFormSubmitToolbarButton
            data-testid="form-action-save-button"
            label={t('common:SAVE')}
            disableCheckDirty={disableCheckDirty}
          />
        )}
      </ButtonGroup>
    </StyledStickyRow>
  );
};

BaseFormPageHeader.propTypes = {
  description: PropTypes.string,
  mode: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  resetForm: PropTypes.func,
  title: PropTypes.string.isRequired,
  customButtons: PropTypes.arrayOf(
    PropTypes.shape({
      buttonMode: PropTypes.string,
      icon: PropTypes.node,
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  disableCheckDirty: PropTypes.bool,
  enableSubmitButton: PropTypes.bool,
};

BaseFormPageHeader.defaultProps = {
  enableSubmitButton: false,
  disableCheckDirty: false,
  description: '',
  mode: null,
  resetForm: null,
  customButtons: null,
};
