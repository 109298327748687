import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { Switch, FormField } from '@blueprism/ui-core';

export const BaseFormFieldSwitch = (props) => {
  const {
    align,
    description,
    disabled,
    displayType,
    fieldLabel,
    formDisabled,
    gap,
    id,
    label,
    onChange,
    required,
  } = props;

  const [field, meta, helpers] = useField(props);
  const { isSubmitting } = useFormikContext();
  const { name, value } = field;
  const { error, touched } = meta;
  const { setTouched, setValue } = helpers;

  const displayError = touched && !!error;
  const computedLabel = `${fieldLabel}${required ? ' *' : ''}`;
  const isDisabled = isSubmitting || formDisabled || disabled;

  const changeFieldHandler = () => {
    if (!touched) setTouched(true);

    onChange(!value);
    setValue(!value);
  };

  return useMemo(() => {
    return (
      <FormField
        label={computedLabel}
        htmlFor={name}
        helperText={description}
        errorText={error}
        error={displayError}
        gap={gap}
        id={name}
      >
        <Switch
          type="checkbox"
          name={name}
          id={name}
          label={label}
          checked={value}
          disabled={isDisabled}
          displayType={displayType}
          align={align}
          onChange={changeFieldHandler}
        />
      </FormField>
    );
  }, [changeFieldHandler, label, description, isDisabled, id, name, required, gap, value]);
};

export const BaseFormFieldSwitchPropTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  formDisabled: PropTypes.bool,
  fieldLabel: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  gap: PropTypes.string,
  displayType: PropTypes.string,
  align: PropTypes.string,
  onChange: PropTypes.func,
};

export const BaseFormFieldSwitchShape = PropTypes.shape(BaseFormFieldSwitchPropTypes);

BaseFormFieldSwitch.propTypes = BaseFormFieldSwitchPropTypes;

BaseFormFieldSwitch.defaultProps = {
  onChange: () => {},
  description: null,
  disabled: false,
  formDisabled: false,
  fieldLabel: '',
  label: '',
  required: false,
  gap: 'xs',
  displayType: 'default',
  align: 'left',
};
