import React from 'react';
import PropTypes from 'prop-types';

import { SVGIcon } from './SVGIcon';

export function ImageManager({ className, src }) {
  return (
    <div className={className}>
      {src.includes('.svg') ? (
        <SVGIcon src={src} />
      ) : (
        <span className="app-img-icon">
          <span className="app-img-content" style={{ backgroundImage: `url(${src})` }} />
        </span>
      )}
    </div>
  );
}

ImageManager.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ImageManager.defaultProps = {
  className: '',
};
