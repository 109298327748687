import React from 'react';
import PropTypes from 'prop-types';

import { FIELD_RULE_HANDLERS } from 'app-base-form/constants';
import { ChangedFieldModel } from 'app-base-form/models';

import { BaseFormFieldFactory } from '../factories/BaseFormFieldFactory';
import { BaseFormRulesManager } from '../BaseFormRulesManager';

function wrapFieldAction(handleFieldChange, fieldPayload, props) {
  const fieldHandlerFunc = FIELD_RULE_HANDLERS[fieldPayload.type];
  const fieldHandlerType = fieldHandlerFunc ? fieldHandlerFunc(fieldPayload) : 'onChange';

  return {
    [fieldHandlerType]: (value, payload = {}) => {
      const changedField = new ChangedFieldModel({
        value,
        payload,
        fieldPayload,
        fieldHandlerType,
      });

      handleFieldChange(changedField);

      if (props[fieldHandlerType]) props[fieldHandlerType](value, payload);
    },
  };
}

function updateFieldPayload(fieldPayload, handleFieldChange) {
  return {
    ...fieldPayload,
    enhanceField: (CustomField) => {
      return (props) => {
        const action = wrapFieldAction(handleFieldChange, fieldPayload, props);

        return <CustomField {...props} {...action} />;
      };
    },
  };
}

function WizardField({ fieldPayload, handleFieldChange, ...rest }) {
  const field = BaseFormFieldFactory.build({
    fieldPayload: updateFieldPayload(fieldPayload, handleFieldChange),
    ...rest,
  });

  return field;
}

export function renderFieldsWizard({
  activePage,
  configChanges,
  fields,
  formDisabled,
  mode,
  requestType,
  rules,
  submitterId,
  updateFormData,
  uploadStrategy,
  visiblePages,
}) {
  return (
    <BaseFormRulesManager
      rules={rules}
      fields={fields}
      visiblePages={visiblePages}
      activePage={activePage}
      updateFormData={updateFormData}
      submitterId={submitterId}
      configChanges={configChanges}
    >
      {({ fields: managerFields, handleFieldChange }) => {
        return managerFields.map((fieldPayload) => {
          return (
            <div key={fieldPayload.name}>
              <WizardField
                fields={managerFields}
                requestType={requestType}
                fieldPayload={fieldPayload}
                formDisabled={formDisabled}
                uploadStrategy={uploadStrategy}
                handleFieldChange={handleFieldChange}
                formMode={mode}
              />
            </div>
          );
        });
      }}
    </BaseFormRulesManager>
  );
}

renderFieldsWizard.propTypes = {
  activePage: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  configChanges: PropTypes.shape({}),
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formDisabled: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  requestType: PropTypes.string,
  rules: PropTypes.arrayOf(PropTypes.shape({})),
  submitterId: PropTypes.string,
  updateFormData: PropTypes.func.isRequired,
  uploadStrategy: PropTypes.string,
  visiblePages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

renderFieldsWizard.defaultProps = {
  configChanges: {},
  requestType: null,
  rules: [],
  submitterId: null,
  uploadStrategy: null,
};
