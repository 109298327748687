import * as R from 'ramda';

import { isEmpty } from 'app-utils';

import { SELECTION_TYPES_ID, INITIAL_STATE } from '../constants';
import { BaseFieldModel } from './BaseFieldModel';

export class CheckBoxGroupModel extends BaseFieldModel {
  constructor(field, page) {
    super(field, page);

    this.setup();
  }

  setup() {
    this.selectionType = this.payload.selectionType || SELECTION_TYPES_ID.SINGLE;
    this.minSelection = this.payload.minSelection;
    this.maxSelection = this.payload.maxSelection;
    this.options = this.payload.options || [
      {
        label: '',
        checked: false,
        isIdBind: true,
        value: '',
      },
    ];
    this.value = this.assignValue(this.payload);
  }

  assignValue = ({ value }) => {
    if (isEmpty(value)) return null;

    return R.is(String, value) ? [value] : value;
  };

  setDefaultValue = (options) => {
    return options.filter(({ checked }) => checked).map(({ value }) => value);
  };

  getRequestModel() {
    return {
      ...this,
      initialState: this.initialState.value,
      payload: JSON.stringify({
        value: this.setDefaultValue(this.options),
        options: this.options.map(({ isIdBind, ...rest }) => ({ ...rest })),
        selectionType: this.selectionType,
        hidden: this.initialState.value === INITIAL_STATE.HIDDEN,
        required: this.initialState.value === INITIAL_STATE.MANDATORY,
        disabled: this.initialState.value === INITIAL_STATE.READ_ONLY,
        minSelection: this.minSelection,
        maxSelection: this.maxSelection,
      }),
    };
  }
}
