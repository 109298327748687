import styled, { css, keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  ${({ margin }) => css`
    animation: 0.8s linear infinite ${spin};
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: ${margin};

    svg {
      margin: 0;
    }
  `}
`;
