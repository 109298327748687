import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';

import { isDebugMode } from 'app-debug';

import { baseFormRulesManagerStore } from './BaseFormRulesManagerStore';
import './BaseFormRulesManagerStyles.scss';
import { REQUEST_TYPES } from '../constants';

const debugRules = isDebugMode('debug/rules');

// TODO: WIP
function renderDebugChangedFields(formFieldChangeBuffer) {
  return (
    <div className="debug-changed-fields-list">
      <div className="debug-changed-fields-title">Changed form fields</div>
      {formFieldChangeBuffer.length === 0 ? (
        <div>Change some form fields to see changes here</div>
      ) : (
        formFieldChangeBuffer.map((field, index) => {
          return (
            <div key={field.id}>
              <div>
                {index + 1}) {field.changedAt.getTime()} {field.fieldHandlerType}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}

// TODO: WIP
function renderRules(rules) {
  return (
    <div className="debug-normalized-rules-list">
      <div className="debug-changed-fields-title">Normalized rules</div>
      {rules.length === 0 ? (
        <div>Change some form fields to see changes here</div>
      ) : (
        <pre
          style={{
            whiteSpace: 'pre',
            padding: 5,
            overflow: 'auto',
            maxWidth: 550,
          }}
        >
          {JSON.stringify(rules, null, 3)}
        </pre>
      )}
    </div>
  );
}

export const BaseFormRulesManager = ({
  activePage,
  children,
  configChanges,
  fields,
  requestType,
  rules,
  submitterId,
  updateFormData,
  visiblePages,
}) => {
  const {
    fields: managerFields,
    formFieldChangeBuffer,
    handleFieldChange,
    initialRulesApply,
    resetStoreState,
    rules: managerRules,
    setElementsChanges,
    setFields,
    setFormik,
    setMethods,
    setPayload,
    setRules,
  } = baseFormRulesManagerStore;

  useEffect(() => {
    /**
     * TODO: PERFORMANCE
     */
    setFields(fields);
    setRules(rules);
    setMethods(updateFormData);
  }, [fields, rules]);

  useEffect(() => {
    setElementsChanges(configChanges, true);
  }, []);

  const formik = useFormikContext();

  useEffect(() => {
    setFormik(formik);
  }, [formik]);

  useEffect(() => {
    setPayload({
      activePage,
      submitterId,
      visiblePages,
    });
  }, [submitterId, visiblePages, activePage]);

  useEffect(() => {
    if (requestType === REQUEST_TYPES.CREATE || requestType === REQUEST_TYPES.ASSIGNED_BY_DIGITAL_WORKER) {
      initialRulesApply();
    }

    return resetStoreState;
  }, []);

  const renderedDebugInfo = useMemo(() => {
    if (!debugRules) return null;

    return (
      <>
        {renderRules(managerRules)}
        {renderDebugChangedFields(formFieldChangeBuffer)}
      </>
    );
  }, [debugRules]);

  const renderedChildren = useMemo(() => children({ handleFieldChange, fields: managerFields }), [
    handleFieldChange,
    managerFields,
  ]);

  return (
    <>
      {renderedChildren}
      {renderedDebugInfo}
    </>
  );
};
