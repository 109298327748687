import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import uuidv4 from 'uuid/v4';
import isAfterDay from 'react-dates/lib/utils/isAfterDay';
import { CaretRight, CrossLarge, ArrowLeft, ArrowRight } from '@blueprism/ui-icons';
import { useTranslation } from 'react-i18next';
import { Row } from '@blueprism/ui-core';

import { formatTime } from 'app-utils';
import { RoundButton } from 'app-buttons';

import { StyledDatePicker } from '../StyledDatePicker.styled';
import { getCalendarPosition } from '../utils';
import { CALENDAR_POSITION } from '../constants';
import { TIME_FORMAT_OUT, TYPE_TIME_FORMAT as type, BaseTimePicker } from '../../app-time';
import { TimePickerContainer, SeparatorContainer } from '../components/TimePickerContainer';

export const RangeDatePicker = (props) => {
  const {
    disabled,
    disabledDate,
    endDatePlaceholder,
    forceUpdateStatus,
    id,
    numberOfMonths,
    onDateChange,
    showClear,
    showTime,
    startDatePlaceholder,
    timeLabel,
    value,
  } = props;

  const { t } = useTranslation();

  const currentLanguage = moment.locale();
  const shouldUpdateMonthFormat = currentLanguage === 'ja' || currentLanguage === 'zh-cn';

  const refContainer = useRef(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);

  const setPropsToDate = (from, to, attributes) => {
    const clonedDate = to.clone();
    attributes.forEach((prop) => clonedDate[prop](from[prop]()));

    return clonedDate;
  };

  const shouldUpdateDate = (date) => {
    if (!date) {
      return true;
    }

    if (!date.from || !date.to) {
      return false;
    }

    return !date.from.isSame(value.from) || !date.to.isSame(value.to);
  };

  const _onDateChange = (date) => {
    if (shouldUpdateDate(date)) {
      onDateChange(date);
    }
  };

  useEffect(() => {
    setFromDate(value.from);
    setToDate(value.to);

    setFromTime(formatTime(value.from));
    setToTime(formatTime(value.to));
  }, [value, forceUpdateStatus]);

  const [focusedElement, changeFocusedElement] = useState(null);
  const [calendarPosition, changeCalendarPosition] = useState(CALENDAR_POSITION.down);

  const helperText = t('TOTAL_WORKTIME_ERROR', { type });

  const handleDatesChange = ({ endDate, startDate }) => {
    const rangeIsCleaned = startDate === null;

    if (rangeIsCleaned && !focusedElement) return onDateChange(null);

    const DATE_PROPS = ['year', 'month', 'date'];

    const clonedFrom = value.from || moment().startOf('day');
    const clonedTo = value.to || moment().endOf('day');

    const from = startDate && setPropsToDate(startDate, clonedFrom, DATE_PROPS);
    const to = endDate && setPropsToDate(endDate, clonedTo, DATE_PROPS);

    setFromDate(from);
    setToDate(to);

    _onDateChange({ from, to });
  };

  const handleTimeChange = (time, propName) => {
    if (propName === 'from') {
      setFromTime(time);
    }

    if (propName === 'to') {
      setToTime(time);
    }

    const momentTime = moment(time, TIME_FORMAT_OUT);
    const dateWithTime = setPropsToDate(momentTime, value[propName], ['hours', 'minutes', 'seconds']);
    _onDateChange({ ...value, [propName]: dateWithTime });
  };

  const handleDatePickerFocused = (element) => {
    const position = getCalendarPosition(refContainer);

    changeFocusedElement(element);

    if (!element && !fromDate && !toDate) {
      onDateChange(null);
    }

    if (position !== calendarPosition) {
      changeCalendarPosition(position);
    }
  };

  return (
    <StyledDatePicker ref={refContainer}>
      <DateRangePicker
        startDatePlaceholderText={startDatePlaceholder}
        endDatePlaceholderText={endDatePlaceholder}
        startDate={fromDate}
        startDateId={`${id}_start`}
        endDate={toDate}
        endDateId={`${id}_end`}
        isOutsideRange={disabledDate ? disabledDate() : (day) => isAfterDay(day, moment())}
        onDatesChange={handleDatesChange}
        focusedInput={focusedElement}
        onFocusChange={handleDatePickerFocused}
        showClearDates={showClear}
        numberOfMonths={numberOfMonths}
        monthFormat={shouldUpdateMonthFormat ? 'YYYY[年]MM[月]' : 'MMMM YYYY'}
        displayFormat="DD/MM/YYYY"
        disabled={disabled}
        transitionDuration={0}
        customCloseIcon={<CrossLarge size={16} />}
        navPrev={
          <RoundButton circle>
            <ArrowLeft size={16} />
          </RoundButton>
        }
        navNext={
          <RoundButton circle>
            <ArrowRight size={16} />
          </RoundButton>
        }
        customArrowIcon={<CaretRight size={32} />}
        openDirection={calendarPosition}
        navPosition="navPositionBottom"
        minimumNights={0}
        hideKeyboardShortcutsPanel
        noBorder
      />
      {showTime && fromDate && toDate && (
        <TimePickerContainer
          gap="xxs"
          label={t(timeLabel)}
          key={`${timeLabel}_${id}`}
          htmlFor={`${timeLabel}`}
          helperText={helperText}
        >
          <Row justify="between">
            <BaseTimePicker
              id={timeLabel}
              onChange={(time) => handleTimeChange(time, 'from')}
              value={fromTime}
              disabled={disabled}
            />
            <SeparatorContainer>
              <CaretRight size={32} />
            </SeparatorContainer>
            <BaseTimePicker
              id={timeLabel}
              onChange={(time) => handleTimeChange(time, 'to')}
              value={toTime}
              disabled={disabled}
            />
          </Row>
        </TimePickerContainer>
      )}
    </StyledDatePicker>
  );
};

RangeDatePicker.propTypes = {
  onDateChange: PropTypes.func.isRequired,
  showClear: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      from: MomentPropTypes.momentObj,
      to: MomentPropTypes.momentObj,
    }),
    PropTypes.bool,
  ]),
  numberOfMonths: PropTypes.number,
  disabledDate: PropTypes.func,
  startDatePlaceholder: PropTypes.string,
  endDatePlaceholder: PropTypes.string,
  disabled: PropTypes.bool,
  timeLabel: PropTypes.string,
  id: PropTypes.string,
  forceUpdateStatus: PropTypes.bool,
  showTime: PropTypes.bool,
};

RangeDatePicker.defaultProps = {
  value: {
    from: null,
    to: null,
  },
  disabledDate: null,
  showClear: false,
  disabled: false,
  numberOfMonths: 2,
  timeLabel: 'TIME_LABEL',
  startDatePlaceholder: '',
  endDatePlaceholder: '',
  forceUpdateStatus: false,
  id: uuidv4(),
  showTime: false,
};
