export const INITIAL_STATE = {
  OPTIONAL: 0,
  MANDATORY: 1,
  READ_ONLY: 2,
  HIDDEN: 3,
};

export const INITIAL_STATE_OPTIONS = [
  { translatableLabel: 'OPTIONAL', value: INITIAL_STATE.OPTIONAL },
  { translatableLabel: 'MANDATORY', value: INITIAL_STATE.MANDATORY },
  { translatableLabel: 'READ_ONLY', value: INITIAL_STATE.READ_ONLY },
  { translatableLabel: 'HIDDEN', value: INITIAL_STATE.HIDDEN },
];
