import { i18n } from 'app-i18n';

export const VALIDATION_MESSAGES = {
  getReqMsg: (field) => i18n.t('common:VALIDATION_IS_REQUIRED', { field }),
  getMinLenMsg: (value) => i18n.t('common:VALIDATION_MUST_CONTAIN_MIN_CHARACTERS', { value }),
  getMaxLenMsg: (value) => i18n.t('common:VALIDATION_MUST_CONTAIN_MAX_CHARACTERS', { value }),
  getEmailMsg: () => i18n.t('common:VALIDATION_INVALID_EMAIL'),
  getUrlMsg: () => i18n.t('common:VALIDATION_INVALID_URL'),
  getBase64Msg: () => i18n.t('common:VALIDATION_INVALID_BASE64'),
  getPwdErrorCharacterMsg: () => i18n.t('common:VALIDATION_PASSWORD_ERROR_CHARACTER'),
  getPwdErrorContainMsg: () => i18n.t('common:VALIDATION_PASSWORD_SHOULD_NOT_CONTAIN_A_PASSWORD_WORD'),
  getConfirmPwdMsg: () => i18n.t('common:VALIDATION_INVALID_CONFIRMATION_PASSWORD'),
  getMatchRegExMsg: (val, text) => `${text} ${val.errorMessage}`,
  getMinSelectionMsg: (value) => i18n.t('common:VALIDATION_PLEASE_SELECT_MINIMUM_OPTIONS', { value }),
  getMaxSelectionMsg: (value) => i18n.t('common:VALIDATION_PLEASE_SELECT_MAXIMUM_OPTIONS', { value }),
  getMaxNumberMsg: (value) => i18n.t('common:VALIDATION_MAXIMUM_NUMBER_IS', { value }),
  getMinNumberMsg: (value) => i18n.t('common:VALIDATION_MINIMUM_NUMBER_IS', { value }),
  getAllowedCharsMsg: (val) => i18n.t('common:VALIDATION_MUST_CONTAIN_ONLY', { value: val.split('').join(' ') }),
  getNonAllowedCharsMsg: (val) => i18n.t('common:VALIDATION_MUST_NOT_CONTAIN', { value: val.split('').join(' ') }),
  getUniqueMsg: (field) => i18n.t('common:VALIDATION_FIELD_MUST_BE_UNIQUE', { field }),
  getMessageAboutExtraCharacters: (maxAmount, amount) =>
    i18n.t('common:VALIDATION_FIELD_MUST_NOT_BE_GREATER_THAN_MAX_AMOUNT', { maxAmount, amount }),
};
