import { action, observable } from 'mobx';

import { FILE_PURPOSE } from 'app-base-form/constants';

import { getFileNameAndExtension } from '../utlis';
import { URLS } from '../constants';

export class FileService {
  @observable baseUrl;

  @observable isUploading = {};

  @observable uploadingProgress = {};

  @observable httpService = null;

  @observable _aborters = {};

  @action setHttpService = (httpService) => {
    this.httpService = httpService;
  };

  @action uploadFile = (file, uuid, content) => {
    return new Promise(async (resolve) => {
      const aborter = this._getAndSetupAborter(uuid);
      const onUploadProgress = this._onProgressChange(file.size, uuid);

      try {
        const requestData = {
          filePurpose: FILE_PURPOSE.SS,
          fileName: file.name,
          content,
        };

        const fileLink = await this.httpService.post(`${URLS.fileServerStorage}/save-file`, requestData, {
          onUploadProgress,
          cancelToken: aborter,
        });

        const payload = {
          link: fileLink,
          extension: getFileNameAndExtension(fileLink)[1],
        };

        // A little delay before sucess reset, to improve UX
        setTimeout(() => {
          resolve({ result: payload });
        }, 200);
      } catch (err) {
        resolve({ error: err });
        this.reset(uuid);
      }
    });
  };

  @action reset = (uuid) => {
    this.uploadingProgress = { ...this.uploadingProgress, [uuid]: 0 };
    this.setUploading(false, uuid);

    if (this._aborters[uuid]) {
      this._aborters[uuid].abort();
    }
  };

  @action _onProgressChange = (blobSize, uuid) => {
    return ({ loaded }) => {
      const progress = parseInt((loaded / blobSize) * 100, 10);

      this.uploadingProgress = {
        ...this.uploadingProgress,
        [uuid]: parseInt(progress, 10),
      };
    };
  };

  @action _getAndSetupAborter = (uuid) => {
    const { CancelToken } = this.httpService;

    return new CancelToken((abort) => {
      // An executor function receives a cancel function as a parameter
      this._aborters[uuid] = { abort };
    });
  };

  // TODO: Check to Axios methods
  @action setUploading = (value, uuid) => {
    this.isUploading = { ...this.isUploading, [uuid]: value };
  };

  @action
  resetStore = () => {
    this.httpService = null;
  };
}

export const FileServiceStore = new FileService();
