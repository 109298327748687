export const REQUEST_TYPES = {
  CREATE: 'CREATE',
  DRAFT: 'DRAFT',
  VIEW_DRAFT: 'VIEW_DRAFT',
  VIEW: 'VIEW',
  CLONE: 'CLONE',
  REVIEW: 'REVIEW',
  INBOX: 'INBOX',
  ASSIGNED_BY_DIGITAL_WORKER: 'ASSIGNED_BY_DIGITAL_WORKER',
  FAILED: 'FAILED',
};
