import { FIELD_TYPES } from 'app-base-form/constants';
import { emailExpression } from 'app-utils';

export const registrationFormConfig = (t) => ({
  submitButtonLabel: t('REGISTER'),
  title: t('REGISTER_FOR_INTERACT'),
  fields: [
    {
      type: FIELD_TYPES.TEXT,
      value: '',
      name: 'username',
      label: t('USERNAME'),
      labelPlacement: 'left',
      matchRegEx: [
        {
          pattern: /^[a-zA-Z0-9._-]+$/,
          errorMessage: t('CONTAIN_LETTERS_DIGITS'),
        },
        {
          pattern: /^[^._-]/,
          errorMessage: t('CANNOT_START_WITH_SYMBOLS'),
        },
      ],

      required: true,
      maxlength: 25,
    },
    {
      type: FIELD_TYPES.TEXT,
      value: '',
      name: 'firstName',
      label: t('FIRST_NAME'),
      labelPlacement: 'left',
      required: true,
      maxlength: 28,
    },
    {
      type: FIELD_TYPES.TEXT,
      value: '',
      name: 'lastName',
      label: t('LAST_NAME'),
      labelPlacement: 'left',
      required: true,
      maxlength: 60,
    },
    {
      type: FIELD_TYPES.TEXT,
      value: '',
      name: 'email',
      label: t('EMAIL_ADDRESS'),
      labelPlacement: 'left',
      email: true,
      matchRegEx: [
        {
          pattern: emailExpression,
          errorMessage: t('common:VALIDATION_INVALID_EMAIL'),
        },
      ],

      required: true,
      maxlength: 64,
    },
  ],
});
