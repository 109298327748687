import { AUTHENTICATION_TYPES } from 'constants';

import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { BaseForm } from 'app-base-form';
import { Box, Stack } from '@blueprism/ui-core';
import { renderBaseControlsButtons } from 'app-base-form/BaseForm/renderBaseControlsButtons';

import { loginFormConfig } from './loginFormConfig';
import { ActiveDirectoryLogin, ExternalProviderLogin } from './components';

export const Login = ({
  authenticationSettings: {
    activeDirectoryAuthenticationEnabled,
    nativeAuthenticationEnabled,
    oidcAuthenticationEnabled,
    saml2AuthenticationEnabled,
  },
  authStore,
  numberOfLdapUsers,
  oidcProvidersList,
  saml2PluginEnabled,
  saml2ProvidersList,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const loginHandler = (credentials, formActions) => {
    let returnUrl = unescape(location.search);
    returnUrl = returnUrl.replace('?ReturnUrl=', '');

    authStore.login({ ...credentials, returnUrl }, formActions);
  };

  const forgotHandler = () => {
    history.push('/account/forgot-password');
  };

  const translatedFormConfig = loginFormConfig(t);
  const nativeLoginFormEnabled = nativeAuthenticationEnabled || numberOfLdapUsers !== 0;
  const saml2AuthenticationAvailable =
    saml2PluginEnabled && saml2AuthenticationEnabled && saml2ProvidersList && saml2ProvidersList.length > 0;
  const oidcAuthenticationAvailable = oidcAuthenticationEnabled && oidcProvidersList && oidcProvidersList.length > 0;
  const alternativeAuthenticationAvailable =
    activeDirectoryAuthenticationEnabled || saml2AuthenticationAvailable || oidcAuthenticationAvailable;

  const renderControlsButtons = (props) => (
    <Stack>
      {nativeLoginFormEnabled && renderBaseControlsButtons(props)}
      {nativeLoginFormEnabled && alternativeAuthenticationAvailable && <hr />}
      {activeDirectoryAuthenticationEnabled && <ActiveDirectoryLogin authStore={authStore} />}
      {saml2AuthenticationAvailable &&
        saml2ProvidersList
          .filter((provider) => provider.enabled)
          .map((provider) => (
            <ExternalProviderLogin
              authStore={authStore}
              key={provider.id}
              provider={provider}
              authType={AUTHENTICATION_TYPES.SAML2}
            />
          ))}
      {oidcAuthenticationAvailable &&
        oidcProvidersList
          .filter((provider) => provider.enabled)
          .map((provider) => (
            <ExternalProviderLogin
              authStore={authStore}
              key={provider.id}
              provider={provider}
              authType={AUTHENTICATION_TYPES.OIDC}
            />
          ))}
    </Stack>
  );

  if (!nativeLoginFormEnabled) {
    translatedFormConfig.fields = [];
  }

  return (
    <Box className="login-form-wrapper">
      <BaseForm
        preventUnmountIfFormIsDirty={false}
        {...translatedFormConfig}
        initialValues={{ username: '', password: '' }}
        submitHandler={loginHandler}
        buttonHandler={forgotHandler}
        disabled={authStore.activeDirectoryLoginPending}
        renderControlsButtons={renderControlsButtons}
      />
    </Box>
  );
};

Login.propTypes = {
  authStore: PropTypes.shape({
    activeDirectoryLogin: PropTypes.func,
    activeDirectoryLoginPending: PropTypes.bool,
    login: PropTypes.func,
  }).isRequired,
  authenticationSettings: PropTypes.shape({
    activeDirectoryAuthenticationEnabled: PropTypes.bool,
    nativeAuthenticationEnabled: PropTypes.bool,
    saml2AuthenticationEnabled: PropTypes.bool,
    oidcAuthenticationEnabled: PropTypes.bool,
  }).isRequired,
  numberOfLdapUsers: PropTypes.number.isRequired,
  saml2PluginEnabled: PropTypes.bool.isRequired,
  saml2ProvidersList: MobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  oidcProvidersList: MobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};
