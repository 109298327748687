export const URLS = {
  login: 'login',
  loginActiveDirectory: 'login-with-active-directory',
  createPassword: 'api/create-password',
  registration: '/api/register',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
  validateResetPassword: 'validate-reset-password',
  logout: 'logout',
  userConfiguration: 'api/user-configuration',
  welcome: 'welcome',
  ldapUsersCount: '/api/users/number-of-ldap-users',
  authenticationSettings: '/api/authentication-settings',
  saml2ProvidersList: '/api/saml2/config/list',
  oidcProvidersList: '/api/oidc/config/list',
  saml2Login: '/api/saml2/login',
  oidcLogin: '/api/oidc/login',
  saml2PluginEnabled: 'api/saml2/enabled',
};
