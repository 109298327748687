import { INITIAL_STATE_OPTIONS, ELEMENT_TYPES } from '../constants';

export class BaseFieldModel {
  constructor(field, page) {
    this.id = field.id || null;
    this.label = field.label || '';
    this.automationId = field.automationId || '';
    this.description = field.description || '';
    this.elementType = ELEMENT_TYPES.FIELD;

    this.initialState = field.initialState
      ? INITIAL_STATE_OPTIONS.find((o) => o.value === field.initialState)
      : INITIAL_STATE_OPTIONS[0];
    this.type = field.type;

    this.order = field.order;
    this.formPageId = field.pageId || page.id || null;
    this.payload = this.parsePayload(field.payload);
    this.hidden = this.payload.hidden;
    this.required = this.payload.required;
    this.disabled = this.payload.disabled;
  }

  parsePayload = (payload) => {
    let parsedPayload = '';
    try {
      if (payload) parsedPayload = JSON.parse(payload);
    } catch {
      // Handled on higher level
    }
    return parsedPayload;
  };
}
