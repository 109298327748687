import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseForm } from 'app-base-form';

import { registrationFormConfig } from './registrationFormConfig';

export const Registration = ({ authStore }) => {
  const { t } = useTranslation();

  const registrationHandler = (credentials, formActions) => {
    authStore.registrationSubmit(credentials, formActions);
  };

  return (
    <BaseForm preventUnmountIfFormIsDirty={false} {...registrationFormConfig(t)} submitHandler={registrationHandler} />
  );
};
