import styled, { css, keyframes } from 'styled-components';

const Spin = keyframes`
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
`;

const ldsRingAnitation = keyframes`
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
`;

export const StyledSpinner = styled.img`
  width: 85px;
  height: 85px;
  animation: ${Spin} 2s linear infinite;
  animation-direction: alternate;
`;

export const StyledLoader = styled.div`
  ${({ active }) => css`
    ${active &&
    css`
      z-index: 100;
      display: flex;
    `};
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: none;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);
  `}
`;

export const LdsRing = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    div {
      position: absolute;
      display: block;
      box-sizing: border-box;
      width: 12px;
      height: 12px;
      margin: 0px;
      border-radius: 50%;
      animation: ${ldsRingAnitation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border: 2px solid ${theme.color.action};
      border-color: ${theme.color.action} transparent transparent transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }

      &:nth-child(2) {
        animation-delay: -0.3s;
      }

      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  `}
`;
