import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { FormikConsumer } from 'formik';

import { notify } from 'app-toasts';

export class BaseFormDebug extends PureComponent {
  state = {
    formikDebugIsCollapse: false,
    formikDebugLeftPosition: 0,
    formikDebugTopPosition: 0,
    width: this.formikDebugRef?.current?.getBoundingClientRect().width ?? 0,
  };

  formikDebugRef = React.createRef();

  componentDidMount() {
    const formikDebugIsCollapse = JSON.parse(localStorage.getItem('formikDebugIsCollapse')) ?? false;
    const formikDebugTopPosition = localStorage.getItem('formikDebugTopPosition') ?? 0;
    const formikDebugLeftPosition = localStorage.getItem('formikDebugLeftPosition') ?? 0;

    this.setState({ formikDebugIsCollapse, formikDebugTopPosition, formikDebugLeftPosition });
  }

  componentDidUpdate() {
    const { formikDebugIsCollapse, formikDebugLeftPosition, formikDebugTopPosition } = this.state;
    this.savePositionInLocalStore({ formikDebugIsCollapse, formikDebugTopPosition, formikDebugLeftPosition });
  }

  get getScreenWidth() {
    const { width } = this.state;
    return width;
  }

  setPosition(x, y) {
    this.setState({ formikDebugLeftPosition: x, formikDebugTopPosition: y });
  }

  savePositionInLocalStore = (state) => {
    Object.keys(state).map((key) => localStorage.setItem(key, state[key]));
  };

  handleDrag = (event) => {
    const { width } = this.state;

    const maxLeft = document.documentElement.clientWidth;
    const maxTop = document.documentElement.clientHeight;

    this.setPosition(event.clientX, event.clientY);

    if (event.clientX > maxLeft - width / 3) {
      this.setPosition(maxLeft - width, 0);
    }
    if (event.clientX < 0) {
      this.setPosition(0, 0);
    }
    if (event.clientY > maxTop - 30) {
      this.setPosition(0, maxTop - 30);
    }
    if (event.clientY < 0) {
      this.setPosition(0, 0);
    }
  };

  render() {
    const { formikDebugIsCollapse, formikDebugLeftPosition, formikDebugTopPosition } = this.state;
    const width = this.getScreenWidth;

    return ReactDOM.createPortal(
      <div
        draggable
        onDragEnd={this.handleDrag}
        ref={this.formikDebugRef}
        style={{
          display: 'flex',
          flexDirection: 'column',

          zIndex: 9999,
          borderRadius: 4,
          background: '#f6f8fa',
          boxShadow: '0 0 1px  #eee inset',
          maxHeight: '80vh',
          height: formikDebugIsCollapse ? '30px' : '1000px',
          overflow: 'hidden',
          border: '1px solid rgb(85, 85, 85)',
          borderTop: 'none',
          borderTopRightRadius: 5,
          borderTopLeftRadius: 5,

          position: 'fixed',
          top: Number(formikDebugTopPosition),
          left: Number(formikDebugLeftPosition),
        }}
      >
        <div
          style={{
            textTransform: 'uppercase',
            fontSize: 11,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            fontWeight: 500,
            padding: '.5rem',
            background: '#555',
            color: '#fff',
            letterSpacing: '1px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            Formik State (
            <a
              style={{ color: 'white', margin: 2, textDecoration: 'underline' }}
              href="https://jaredpalmer.com/formik/docs/overview"
              rel="noopener noreferrer"
              target="_blank"
            >
              doc
            </a>
            ,
            <a
              style={{ color: 'white', margin: 2, textDecoration: 'underline' }}
              href="https://github.com/jaredpalmer/formik/tree/master/examples"
              rel="noopener noreferrer"
              target="_blank"
            >
              examples
            </a>
            )
          </div>
          <div>
            <button
              type="button"
              onClick={() => {
                localStorage.clear();
                notify('success', 'Local storage have been cleared');
              }}
            >
              LS.clear()
            </button>
            <button type="button" onClick={() => this.setPosition(0, 0)}>
              &#8598;
            </button>
            <button type="button" onClick={() => this.setPosition(document.documentElement.clientWidth - width, 0)}>
              &#8599;
            </button>
            <button type="button" onClick={() => this.setState({ formikDebugIsCollapse: !formikDebugIsCollapse })}>
              {formikDebugIsCollapse ? <>&#10065;</> : '-'}
            </button>
            <button type="button" onClick={() => localStorage.setItem('debug', 'false')}>
              x
            </button>
          </div>
        </div>
        <FormikConsumer>
          {({ onSubmit, validate, validationSchema, ...rest }) => {
            this.setState((prevState) => ({
              ...prevState,
              width: this.formikDebugRef.current?.getBoundingClientRect().width,
            }));
            return (
              <pre
                style={{
                  whiteSpace: 'pre',
                  padding: 5,
                  overflow: formikDebugIsCollapse ? 'hidden' : 'auto',
                  maxWidth: 550,
                }}
              >
                {JSON.stringify(rest, null, 3)}
              </pre>
            );
          }}
        </FormikConsumer>
      </div>,
      document.body,
    );
  }
}
