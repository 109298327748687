import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Row } from '@blueprism/ui-core';

import { UploadButton } from 'app-buttons';
import { TextEllipsis } from 'styled-containers';

export const UploadButtonField = ({
  active,
  allowCancel,
  allowDelete,
  allowDownload,
  allowUpload,
  clearField,
  disabled,
  downloadFile,
  fileExtensions,
  id,
  onFileUpload,
  uploaded,
  uploading,
  uploadRef,
  value,
}) => {
  const { t } = useTranslation();
  const acceptedExtensions = fileExtensions.map((extension) => `.${extension}`).join(', ');
  let buttonText = value ? 'common:EDIT' : 'UPLOAD';
  if (uploading) {
    if (uploaded === 0) {
      buttonText = 'common:FILE_PREPARATION';
    } else if (uploaded === 100) {
      buttonText = 'common:DATA_PREPARATION';
    } else {
      buttonText = 'common:UPLOADING';
    }
  }

  const deleteButtonVisible = allowDelete && value && !uploading;

  return (
    <Row justify="center" align="center" gap="xs">
      {value && (
        <Row
          justify="center"
          style={{
            minWidth: 0,
            overflowWrap: 'anywhere',
          }}
        >
          {!uploading && <TextEllipsis>{value.name.split('\\').pop()}</TextEllipsis>}
        </Row>
      )}
      {allowUpload && (
        <UploadButton
          active={active}
          ref={uploadRef}
          id={id}
          text={t(buttonText)}
          disabled={disabled || uploading}
          onChange={onFileUpload}
          accept={acceptedExtensions}
        />
      )}
      {deleteButtonVisible && (
        <Button className="remove-file" disabled={disabled} onClick={() => clearField()}>
          {t('common:GRID__DELETE')}
        </Button>
      )}
      {allowCancel && (
        <Button onClick={clearField} className="remove-file">
          {t('common:ANSWER__CANCEL')}
        </Button>
      )}
      {allowDownload && (
        <Button onClick={downloadFile} className="remove-file">
          {t('common:DOWNLOAD')}
        </Button>
      )}
    </Row>
  );
};

UploadButtonField.propTypes = {
  active: PropTypes.bool,
  allowCancel: PropTypes.bool,
  allowDelete: PropTypes.bool,
  allowDownload: PropTypes.bool,
  allowUpload: PropTypes.bool,
  clearField: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
  fileExtensions: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onFileUpload: PropTypes.func.isRequired,
  uploadRef: PropTypes.shape({ current: PropTypes.element }).isRequired,
  uploaded: PropTypes.number,
  uploading: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

UploadButtonField.defaultProps = {
  active: false,
  allowCancel: false,
  allowDelete: true,
  allowDownload: false,
  allowUpload: false,
  disabled: false,
  id: undefined,
  uploaded: 0,
  uploading: false,
  value: null,
};
