import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import * as R from 'ramda';
import { FormField } from '@blueprism/ui-core';

import { DATE_PICKER_MODE_NAMES } from 'app-date-picker/constants';
import { SingleDatePicker } from 'app-date-picker';

export const BaseFormFieldMonthDatePicker = (props) => {
  const {
    allowDates,
    className,
    containerElement,
    customDate,
    description,
    disabled,
    disabledDate,
    format: payloadFormat,
    formDisabled,
    futureDateRestriction,
    gap,
    label,
    onChange,
    pastDateRestriction,
    placeholder,
    required,
    ...rest
  } = props;
  const [field, meta, helpers] = useField(props);
  const { isSubmitting } = useFormikContext();
  const { name, value } = field;
  const { error, touched } = meta;
  const { setTouched, setValue } = helpers;

  const format = R.pathOr(payloadFormat, ['value'], payloadFormat);
  const isDisabled = isSubmitting || formDisabled || disabled;

  const changeFieldHandler = useCallback(
    (date) => {
      if (!touched) setTouched(true);

      setValue(date);
      onChange(date);
    },
    [onChange, setTouched, setValue, touched],
  );

  const displayError = touched && !!error;
  const computedLabel = label ? `${label}${required ? '\u00A0*' : ''}` : '';

  return useMemo(() => {
    return (
      <FormField
        label={computedLabel}
        htmlFor={name}
        helperText={description}
        errorText={error}
        error={displayError}
        gap={gap}
      >
        <SingleDatePicker
          {...rest}
          className={className}
          id={name}
          disabled={isDisabled}
          disabledDate={disabledDate}
          format={format}
          mode={DATE_PICKER_MODE_NAMES.MONTH}
          onDateChange={changeFieldHandler}
          placeholder={placeholder}
          value={value}
        />
      </FormField>
    );
  }, [
    changeFieldHandler,
    className,
    description,
    disabledDate,
    isDisabled,
    format,
    computedLabel,
    name,
    placeholder,
    required,
    value,
  ]);
};

export const BaseFormFieldMonthDatePickerPropTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  disabledDate: PropTypes.func,
  formDisabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  gap: PropTypes.string,
  placeholder: PropTypes.string,
};

export const BaseFormFieldMonthDatePickerShape = PropTypes.shape(BaseFormFieldMonthDatePickerPropTypes);

BaseFormFieldMonthDatePicker.propTypes = BaseFormFieldMonthDatePickerPropTypes;

BaseFormFieldMonthDatePicker.defaultProps = {
  className: '',
  description: null,
  disabledDate: null,
  formDisabled: false,
  label: '',
  onChange: () => null,
  required: false,
  gap: 'xs',
  placeholder: '',
};
