import * as R from 'ramda';

import { ELEMENT_TYPES } from 'app-base-form/constants';

import { BaseFieldModelFactory } from '../factories/BaseFieldModelFactory';
import { RuleModel } from './RuleModel';

export class PageModel {
  constructor({ page, pages, payload, rules }) {
    this.id = page.id;
    this.uniqueId = page.uniqueId;
    this.name = page.name;
    this.description = page.description;
    this.order = page.order;
    this.hidden = page.hidden;
    this.fields = R.sortBy(R.prop('order'))(
      page.fields.map((field) => {
        const FieldModel = BaseFieldModelFactory.getFieldModelByType(field.type);

        return new FieldModel(field, page);
      }),
    );
    this.rules = rules ? JSON.parse(rules).map((rule) => new RuleModel(rule, this.fields, pages, payload)) : [];
    this.initialRules = rules ? JSON.parse(rules) : [];

    this.elementType = ELEMENT_TYPES.PAGE;
    this.type = ELEMENT_TYPES.PAGE;
  }
}
