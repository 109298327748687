import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';

import { TextInput } from 'app-inputs';

import { StyledDescription, StyledFormField } from '../components';

/**
- Use BaseFormFieldText for all text input inside the form
* */
export const BaseFormFieldText = (props) => {
  const {
    allowClear,
    className,
    description,
    detailsText,
    disableAutoComplete,
    disabled,
    formDisabled,
    gap,
    icon,
    isFieldset,
    label,
    maskData,
    maxlength,
    onClear,
    onInput,
    placeholder,
    required,
    searchMode,
    summaryText,
    tooltipMessage,
    type,
  } = props;

  const [field, meta, helpers] = useField(props);
  const { isSubmitting } = useFormikContext();
  const { name, value } = field;
  const { error, touched } = meta;
  const { setTouched, setValue } = helpers;

  const computedLabel = label ? `${label}${required ? '\u00A0*' : ''}` : '';
  const displayError = touched && !!error;
  const isDisabled = isSubmitting || formDisabled || disabled;

  const onChangeHandler = useCallback(
    (val) => {
      if (!touched) setTouched(true);

      setValue(val);
      props.onChange(val);
    },
    [setValue, props.onChange, touched],
  );

  const onBlurHandler = useCallback(
    (val) => {
      if (!touched) setTouched(true);

      const trimValue = val.trim();

      setValue(trimValue);
      props.onBlur(trimValue);
    },
    [setValue, props.onBlur, touched],
  );

  return useMemo(() => {
    return (
      <StyledFormField
        label={computedLabel}
        htmlFor={name}
        icon={icon}
        errorText={error}
        error={displayError}
        gap={gap}
        isFieldset={isFieldset}
        tooltipMessage={tooltipMessage}
        helperText={<StyledDescription type="caption">{description}</StyledDescription>}
      >
        <TextInput
          allowClear={allowClear}
          detailsText={detailsText}
          disableAutoComplete={disableAutoComplete}
          maskData={maskData}
          searchMode={searchMode}
          summaryText={summaryText}
          onClear={onClear}
          onInput={onInput}
          className={className}
          disabled={isDisabled}
          displayError={displayError}
          id={name}
          maxLength={maxlength}
          name={name}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          placeholder={placeholder}
          type={type}
          value={value}
        />
      </StyledFormField>
    );
  }, [
    onBlurHandler,
    onChangeHandler,
    className,
    onClear,
    computedLabel,
    description,
    displayError,
    error,
    isDisabled,
    gap,
    icon,
    type,
    maxlength,
    name,
    onInput,
    placeholder,
    required,
    searchMode,
    value,
  ]);
};

export const BaseFormFieldTextPropTypes = {
  allowClear: PropTypes.bool,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  formDisabled: PropTypes.bool,
  icon: PropTypes.node,
  label: PropTypes.string,
  maskData: PropTypes.bool,
  maxlength: PropTypes.number,
  minlength: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  searchMode: PropTypes.bool,
  gap: PropTypes.string,
  type: PropTypes.string,
  disableAutoComplete: PropTypes.bool,
  isFieldset: PropTypes.bool,
  tooltipMessage: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onClear: PropTypes.func,
  /**
    The name of field.
    */
  name: PropTypes.string,
};

export const BaseFormFieldTextShape = PropTypes.shape(BaseFormFieldTextPropTypes);

BaseFormFieldText.propTypes = BaseFormFieldTextPropTypes;

BaseFormFieldText.defaultProps = {
  allowClear: false,
  className: '',
  containerClassName: '',
  description: null,
  disabled: false,
  formDisabled: false,
  icon: undefined,
  label: '',
  maskData: false,
  maxlength: 1000,
  minlength: 0,
  onBlur: () => null,
  onChange: () => null,
  onInput: () => null,
  onClear: () => null,
  placeholder: '',
  required: false,
  searchMode: false,
  gap: 'xs',
  type: 'text',
  disableAutoComplete: false,
  tooltipMessage: null,
  isFieldset: false,
};
