import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { CheckBox } from '@blueprism/ui-core';

import { isEmpty } from 'app-utils';
import { SELECTION_TYPES_ID } from 'app-base-form/constants';

import { StyledDescription, StyledBorderedBox, StyledFormField } from './components';

export const BaseFormFieldCheckboxGroup = (props) => {
  const {
    bordered,
    className,
    description,
    disabled: fieldDisabled,
    emptyLabel,
    formDisabled,
    gap,
    label,
    onChange,
    options,
    required,
    selectionType,
  } = props;
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(props);
  const { isSubmitting } = useFormikContext();
  const { name, value: activeOptions = [] } = field;
  const { error, touched } = meta;
  const { setTouched, setValue } = helpers;
  const isDisabled = isSubmitting || formDisabled || fieldDisabled;
  const displayError = touched && !!error;
  const computedLabel = label ? `${label}${required ? '\u00A0*' : ''}` : '';

  const checkValue = useCallback(
    (value) => {
      if (selectionType === SELECTION_TYPES_ID.SINGLE) {
        setValue([value]);
        return onChange([value]);
      }

      const newValue = isEmpty(activeOptions) ? [value] : [...activeOptions, value];

      setValue(newValue);
      onChange(newValue);
    },
    [activeOptions, onChange, selectionType, setValue],
  );

  const uncheckValue = useCallback(
    (value) => {
      const newValues = activeOptions.filter((option) => option !== value);
      const values = isEmpty(newValues) ? null : newValues;

      setValue(values);
      onChange(values);
    },
    [activeOptions, onChange, setValue],
  );

  const changeFieldHandler = useCallback(
    (checked, value) => {
      if (!touched) setTouched(true);

      if (checked) return checkValue(value);

      uncheckValue(value);
    },
    [checkValue, setValue, uncheckValue, touched, setTouched],
  );

  return useMemo(() => {
    return (
      <StyledFormField
        label={computedLabel}
        errorText={error}
        error={displayError}
        htmlFor={name}
        gap={gap}
        helperText={<StyledDescription type="caption">{description}</StyledDescription>}
      >
        <StyledBorderedBox bordered={bordered}>
          {options.map(({ disabled, label: optionLabel, translatableLabel, value }) => (
            <CheckBox
              labelHidden={emptyLabel}
              key={value}
              className={className}
              id={`${name}_${optionLabel}`}
              value={value}
              label={t(translatableLabel) || optionLabel}
              onChange={({ target }) => changeFieldHandler(target.checked, value)}
              checked={!isEmpty(activeOptions) && activeOptions.includes(value)}
              disabled={isDisabled || disabled}
            />
          ))}
        </StyledBorderedBox>
      </StyledFormField>
    );
  }, [
    activeOptions,
    className,
    description,
    isDisabled,
    emptyLabel,
    label,
    options,
    changeFieldHandler,
    required,
    selectionType,
  ]);
};

export const BaseFormFieldCheckboxGroupPropTypes = {
  bordered: PropTypes.bool,
  className: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
  emptyLabel: PropTypes.bool,
  formDisabled: PropTypes.bool,
  gap: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: MobxPropTypes.arrayOrObservableArray.isRequired,
  required: PropTypes.bool,
  selectionType: PropTypes.number.isRequired,
};

export const BaseFormFieldCheckboxGroupShape = PropTypes.shape(BaseFormFieldCheckboxGroupPropTypes);

BaseFormFieldCheckboxGroup.propTypes = BaseFormFieldCheckboxGroupPropTypes;

BaseFormFieldCheckboxGroup.defaultProps = {
  bordered: false,
  className: '',
  description: null,
  disabled: false,
  emptyLabel: false,
  formDisabled: false,
  gap: 'xs',
  label: '',
  onChange: () => null,
  required: false,
};
