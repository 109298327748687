import * as R from 'ramda';

export function getIndex(arr, currentId) {
  return arr.findIndex(({ id }) => {
    return String(id) === String(currentId);
  });
}

export function calcNextPageId(pageId, activePageId, pages) {
  const sortedPages = R.sortBy(R.prop('order'), pages);

  return R.cond([
    [R.equals('prev'), () => R.pathOr(activePageId, ['id'], sortedPages[getIndex(sortedPages, activePageId) - 1])],
    [R.equals('next'), () => R.pathOr(activePageId, ['id'], sortedPages[getIndex(sortedPages, activePageId) + 1])],
    [R.T, () => pageId],
  ])(pageId);
}
