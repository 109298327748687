export class FilePayloadModel {
  constructor({ file, height = 0, result, width = 0 }) {
    this.file = result;
    this.size = file.size;
    this.name = file.name;
    this.type = file.type;
    this.file = file;
    this.width = width;
    this.height = height;
  }
}
