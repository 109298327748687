import React from 'react';
import * as R from 'ramda';

import { BaseFormComponent } from './BaseFormComponent';
import { pickRenderedFields } from '../utils';
import { mapInitialFieldValues, mapValidationFieldSchema, provideFieldsValueResolver } from '../hocs';

const EnhancedBaseFormComponent = R.compose(
  mapValidationFieldSchema,
  mapInitialFieldValues,
  provideFieldsValueResolver(pickRenderedFields),
)(BaseFormComponent);

export const BaseFormContainer = (props) => <EnhancedBaseFormComponent {...props} />;
