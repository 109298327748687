import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export function getEditorContent(editor) {
  const currentContent = editor.getCurrentContent();
  const plainText = currentContent.getPlainText().trim();
  const descriptionHTML = plainText.length ? draftToHtml(convertToRaw(currentContent)) : '';

  return {
    description: plainText,
    descriptionHTML: descriptionHTML.replace(/&nbsp;/gi, ''),
  };
}

export function convertEditorContent(rawContent = '') {
  if (!rawContent) return EditorState.createEmpty();

  const { contentBlocks } = htmlToDraft(rawContent);
  const contentState = ContentState.createFromBlockArray(contentBlocks);
  const editorState = EditorState.createWithContent(contentState);

  return editorState;
}

export function prepareDraftEditorValue(descriptionHTML = '') {
  const editorState = convertEditorContent(descriptionHTML);
  const currentContent = editorState.getCurrentContent();
  const plainText = currentContent.getPlainText();

  return { description: plainText, descriptionHTML };
}
