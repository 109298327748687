// TODO Move this const to base forms, change file name
export const RULES_OPTIONS = {
  // IF
  IS: { value: 'IS', translatableLabel: 'IS' },
  EQUALS: { value: 'EQUALS', translatableLabel: 'EQUALS' },
  DOES_NOT_EQUAL: { value: 'DOES_NOT_EQUAL', translatableLabel: 'DOES_NOT_EQUAL' },
  CONTAINS: { value: 'CONTAINS', translatableLabel: 'CONTAINS' },
  DOES_NOT_CONTAIN: { value: 'DOES_NOT_CONTAIN', translatableLabel: 'DOES_NOT_CONTAIN' },
  IS_LESS_THAN: { value: 'IS_LESS_THAN', translatableLabel: 'IS_LESS_THAN' },
  IS_LESS_THAN_OR_EQUAL_TO: { value: 'IS_LESS_THAN_OR_EQUAL_TO', translatableLabel: 'IS_LESS_THAN_OR_EQUAL_TO' },
  IS_MORE_THAN: { value: 'IS_MORE_THAN', translatableLabel: 'IS_MORE_THAN' },
  IS_MORE_THAN_OR_EQUAL_TO: { value: 'IS_MORE_THAN_OR_EQUAL_TO', translatableLabel: 'IS_MORE_THAN_OR_EQUAL_TO' },
  // DATE
  // TODO: Need Move to data field model as set or normalize Value
  DATE_EQUALS: { value: 'DATE_EQUALS', translatableLabel: 'EQUALS', format: (format) => format },
  DATE_DOES_NOT_EQUAL: {
    value: 'DATE_DOES_NOT_EQUAL',
    translatableLabel: 'DOES_NOT_EQUAL',
    format: (format) => format,
  },
  DATE_IS_AFTER: { value: 'DATE_IS_AFTER', translatableLabel: 'IS_AFTER', format: (format) => format },
  DATE_IS_BEFORE: { value: 'DATE_IS_BEFORE', translatableLabel: 'IS_BEFORE', format: (format) => format },
  DATE_SET_VALUE_TO: { value: 'DATE_SET_VALUE_TO', translatableLabel: 'SET_VALUE_TO', format: (format) => format },

  DATE_IS_IN_MONTH: { value: 'DATE_IS_IN_MONTH', translatableLabel: 'IS_IN_MONTH', format: () => 'MMMM, YYYY' },
  DATE_IS_NOT_IN_MONTH: {
    value: 'DATE_IS_NOT_IN_MONTH',
    translatableLabel: 'IS_NOT_IN_MONTH',
    format: () => 'MMMM, YYYY',
  },
  DATE_IS_IN_YEAR: { value: 'DATE_IS_IN_YEAR', translatableLabel: 'IS_IN_YEAR', format: () => 'YYYY' },
  DATE_IS_NOT_IN_YEAR: { value: 'DATE_IS_NOT_IN_YEAR', translatableLabel: 'IS_NOT_IN_YEAR', format: () => 'YYYY' },
  DATE_STATE_IS: { value: 'DATE_STATE_IS', translatableLabel: 'STATE_IS' },
  // TIME
  TIME_EQUALS: { value: 'TIME_EQUALS', translatableLabel: 'EQUALS' },
  TIME_DOES_NOT_EQUAL: { value: 'TIME_DOES_NOT_EQUAL', translatableLabel: 'DOES_NOT_EQUAL' },
  TIME_IS_AFTER: { value: 'TIME_IS_AFTER', translatableLabel: 'IS_AFTER' },
  TIME_IS_BEFORE: { value: 'TIME_IS_BEFORE', translatableLabel: 'IS_BEFORE' },
  TIME_STATE_IS: { value: 'TIME_STATE_IS', translatableLabel: 'STATE_IS' },
  TIME_SET_VALUE_TO: { value: 'TIME_SET_VALUE_TO', translatableLabel: 'SET_VALUE_TO' },
  // UPLOAD
  FILE_SIZE_IS_GREATER_THAN: { value: 'FILE_SIZE_IS_GREATER_THAN', translatableLabel: 'FILE_SIZE_IS_GREATER_THAN' },
  FILE_SIZE_IS_LESS_THAN: { value: 'FILE_SIZE_IS_LESS_THAN', translatableLabel: 'FILE_SIZE_IS_LESS_THAN' },
  FILE_TYPE_IS: { value: 'FILE_TYPE_IS', translatableLabel: 'FILE_TYPE_IS' },
  FILE_TYPE_IS_NOT: { value: 'FILE_TYPE_IS_NOT', translatableLabel: 'FILE_TYPE_IS_NOT' },
  IMAGE_TYPE_IS: { value: 'IMAGE_TYPE_IS', translatableLabel: 'IMAGE_TYPE_IS' },
  IMAGE_TYPE_IS_NOT: { value: 'IMAGE_TYPE_IS_NOT', translatableLabel: 'IMAGE_TYPE_IS_NOT' },
  // DROPDOWN/CHECKBOX
  SELECTION_QUANTITY_IS: { value: 'SELECTION_QUANTITY_IS', translatableLabel: 'SELECTION_QUANTITY_IS' },
  SELECTION_QUANTITY_IS_NOT: { value: 'SELECTION_QUANTITY_IS_NOT', translatableLabel: 'SELECTION_QUANTITY_IS_NOT' },
  SELECTION_QUANTITY_IS_LESS_THAN: {
    value: 'SELECTION_QUANTITY_IS_LESS_THAN',
    translatableLabel: 'SELECTION_QUANTITY_IS_LESS_THAN',
  },
  SELECTION_QUANTITY_IS_MORE_THAN: {
    value: 'SELECTION_QUANTITY_IS_MORE_THAN',
    translatableLabel: 'SELECTION_QUANTITY_IS_MORE_THAN',
  },
  SELECTION_QUANTITY_IS_LESS_THAN_OR_EQUAL_TO: {
    value: 'SELECTION_QUANTITY_IS_LESS_THAN_OR_EQUAL_TO',
    translatableLabel: 'SELECTION_QUANTITY_IS_LESS_THAN_OR_EQUAL_TO',
  },
  SELECTION_QUANTITY_IS_MORE_THAN_OR_EQUAL_TO: {
    value: 'SELECTION_QUANTITY_IS_MORE_THAN_OR_EQUAL_TO',
    translatableLabel: 'SELECTION_QUANTITY_IS_MORE_THAN_OR_EQUAL_TO',
  },
  // TABLE
  NUMBER_OF_ROWS_ADDED_EQUALS: {
    value: 'NUMBER_OF_ROWS_ADDED_EQUALS',
    translatableLabel: 'NUMBER_OF_ROWS_ADDED_EQUALS',
  },
  NUMBER_OF_ROWS_ADDED_IS_MORE_THAN: {
    value: 'NUMBER_OF_ROWS_ADDED_IS_MORE_THAN',
    translatableLabel: 'NUMBER_OF_ROWS_ADDED_IS_MORE_THAN',
  },
  NUMBER_OF_ROWS_ADDED_IS_LESS_THAN: {
    value: 'NUMBER_OF_ROWS_ADDED_IS_LESS_THAN',
    translatableLabel: 'NUMBER_OF_ROWS_ADDED_IS_LESS_THAN',
  },

  // THEN
  STATE_IS: { value: 'STATE_IS', translatableLabel: 'STATE_IS' },
  SET_VALUE_TO: { value: 'SET_VALUE_TO', translatableLabel: 'SET_VALUE_TO' },
  SET_VALUES_TO: { value: 'SET_VALUES_TO', translatableLabel: 'SET_VALUES_TO' },
  NEEDS_APPROVAL: { value: 'NEEDS_APPROVAL', translatableLabel: 'NEEDS_APPROVAL' },
  DOESNT_NEED_APPROVAL: { value: 'DOESNT_NEED_APPROVAL', translatableLabel: 'DOESNT_NEED_APPROVAL' },
};

export const RULE_TYPE = Object.keys(RULES_OPTIONS).reduce((acc, option) => {
  acc[option] = RULES_OPTIONS[option].value;
  return acc;
}, {});
