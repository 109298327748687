import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Button } from '@blueprism/ui-core';

import { SpinnerIcon } from 'app-image-loading';
import { MODAL_MODES } from 'app-modal';

export const BaseFormSubmitButton = ({
  active,
  className,
  'data-testid': dataTestId,
  disableCheckDirty,
  disabled,
  fullWidth,
  label,
  mode,
}) => {
  if (mode === MODAL_MODES.VIEW) return null;

  const { dirty, isSubmitting, isValid } = useFormikContext();
  const { t } = useTranslation();
  const isFormValid = disableCheckDirty ? isValid : isValid && dirty;

  const isDisabled = disabled || isSubmitting || !isFormValid;

  return (
    <Button
      type="submit"
      className={className}
      disabled={isDisabled}
      active={active}
      data-testid={dataTestId}
      fullWidth={fullWidth}
      iconRight={isSubmitting && <SpinnerIcon margin="0 0 0 0.5rem" />}
    >
      {t(label)}
    </Button>
  );
};

BaseFormSubmitButton.propTypes = {
  'data-testid': PropTypes.string,
  active: PropTypes.bool,
  className: PropTypes.string,
  disableCheckDirty: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  mode: PropTypes.string,
};

BaseFormSubmitButton.defaultProps = {
  'data-testid': '',
  active: false,
  className: '',
  disableCheckDirty: false,
  disabled: false,
  fullWidth: false,
  label: 'common:SUBMIT',
  mode: null,
};
