import { ELEMENT_TYPES } from '../constants/ElementTypes';
import { FIELD_TYPES } from '../constants/fieldTypes';

// TODO: Delete it and use RULE_IF_VALUE_TYPES or RULE_THEN_TYPES ?
export const defineNumberProps = (element, fieldType, elementType) => {
  switch (`${elementType}${fieldType}`) {
    case `${ELEMENT_TYPES.FIELD}${FIELD_TYPES.NUMBER}`: {
      return element;
    }
    case `${ELEMENT_TYPES.FIELD}${FIELD_TYPES.TABLE}`:
      return {
        label: element.label,
        min: element.minRows,
        max: element.maxRows,
      };
    case `${ELEMENT_TYPES.FIELD}${FIELD_TYPES.DROPDOWN}`:
    case `${ELEMENT_TYPES.FIELD}${FIELD_TYPES.CHECKBOX_GROUP}`: {
      return {
        label: element.label,
        min: element.minSelection,
        max: element.maxSelection,
      };
    }
    case `${ELEMENT_TYPES.FIELD}${FIELD_TYPES.UPLOAD}`: {
      return {
        label: element.label,
        max: element.fileSize,
      };
    }
    case `${ELEMENT_TYPES.PRIORITY}${ELEMENT_TYPES.PRIORITY}`: {
      return element;
    }
    default:
      return null;
  }
};
