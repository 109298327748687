import styled, { css } from 'styled-components';
import { Row } from '@blueprism/ui-core';

export const StyledImgPreview = styled.div`
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 120px;
  height: 120px;
  svg {
    width: 100%;
    min-width: 120px;
    height: 120px;
  }

  .user-avatar-div.account-img {
    margin: 0;
  }

  .drop-placeholder-wrapper {
    width: 120px;
  }

  img {
    object-fit: contain;
    width: 100%;
    max-height: 120px;
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.color.highlight};
      cursor: not-allowed;

      & canvas {
        cursor: not-allowed !important;
      }
    `}
`;

export const ControlsContainer = styled(Row)`
  .remove-file {
    margin-left: 20px;
  }

  label {
    position: relative;
    margin: 0;
  }
`;
