import { BaseFieldModel } from './BaseFieldModel';
import { INITIAL_STATE } from '../constants';
import { numberOr } from '../utils';

export class NumberFieldModel extends BaseFieldModel {
  constructor(field, page) {
    super(field, page);

    this.setup();
  }

  setup() {
    this.value = numberOr(null, this.payload.value);
    this.isInteger = this.payload.isInteger; // TODO: discuss with BE
    this.placeholder = this.payload.placeholder || ''; // TODO: implement placeholder on BaseFormFieldNumber
    this.decimalScale = numberOr(null, this.payload.decimalScale);
    this.thousandSeparator = this.payload.thousandSeparator;
    this.min = numberOr(null, this.payload.min);
    this.max = numberOr(null, this.payload.max);
    this.step = numberOr(null, this.payload.step);
    this.prefix = this.payload.prefix || '';
  }

  getRequestModel() {
    return {
      ...this,
      initialState: this.initialState.value,
      payload: JSON.stringify({
        value: this.value,
        isInteger: this.isInteger,
        placeholder: this.placeholder,
        decimalScale: this.decimalScale,
        thousandSeparator: this.thousandSeparator,
        hidden: this.initialState.value === INITIAL_STATE.HIDDEN,
        required: this.initialState.value === INITIAL_STATE.MANDATORY,
        disabled: this.initialState.value === INITIAL_STATE.READ_ONLY,
        min: this.min,
        max: this.max,
        step: this.step,
        prefix: this.prefix,
      }),
    };
  }
}
