import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';

export const NumberFormatStyled = styled(NumberFormat)(
  ({ $error, theme }) => css`
    width: 100%;
    padding: ${theme.globalSizes.small} ${theme.globalSizes.xs};
    padding-right: 20px;
    color: ${theme.color.text};
    font-weight: ${$error ? theme.font.weight.bold : theme.font.weight.regular};
    font-size: 1rem;
    background-color: ${theme.color.paper};
    background-color: ${theme.color.highlight};
    background-image: ${$error ? theme.pattern.error : 'none'};
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 20%;
    border: none;
    border-bottom: 0.1rem solid ${$error ? theme.color.warning : theme.color.action};
    border-radius: ${theme.globalSizes.smallest};
    transition: 0.2s background ease;

    &::-webkit-calendar-picker-indicator {
      display: none;
    }

    &:focus {
      box-shadow: rgb(255 195 0) 0px 0px 0px 4px;
    }
  `,
);
