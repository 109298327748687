import styled from 'styled-components';

export const StyledUploadButton = styled.label`
  position: relative;
  display: inline-table;
  overflow: hidden;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
`;
