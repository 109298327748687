import { FIELD_TYPES } from 'app-base-form/constants';

export const forgotPasswordFormConfig = (t) => ({
  submitButtonLabel: t('SEND_EMAIL'),
  title: t('FORGOT_PASSWORD'),
  fields: [
    {
      type: FIELD_TYPES.TEXT,
      email: true,
      value: '',
      name: 'email',
      label: t('EMAIL_ADDRESS'),
      labelPlacement: 'left',
      required: true,
      maxlength: 64,
    },
  ],
});
