import styled, { css } from 'styled-components';
import { Cluster } from '@blueprism/ui-core';

export const StyledStickyCluster = styled(Cluster)`
  ${({ margin, padding, theme, zIndex }) => css`
    z-index: ${zIndex ?? 1};
    position: sticky;
    // TODO: write "top: 0" when find better solution how to fix markup bug
    top: -1px;
    padding: ${padding ?? '1rem 0'};
    margin: ${margin ?? '0 0 -1rem'};
    background-color: ${theme.color.paper};

    & > h1,
    h2,
    h3 {
      flex: 0 1 auto;
      word-break: break-word;
    }

    & + p {
      z-index: ${zIndex ? zIndex + 1 : 1};
    }
  `}
`;
