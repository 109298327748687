import { FIELD_TYPES } from './fieldTypes';

export const FIELD_RULE_HANDLERS = {
  [FIELD_TYPES.TEXT]: () => 'onBlur',
  [FIELD_TYPES.TEXTAREA]: () => 'onBlur',
  [FIELD_TYPES.NUMBER]: () => 'onBlur',
  [FIELD_TYPES.DATE]: () => 'onChange',
  [FIELD_TYPES.TIME]: () => 'onBlur',
  [FIELD_TYPES.UPLOAD]: () => 'onChange',
  [FIELD_TYPES.DROPDOWN]: () => 'onChange',
  [FIELD_TYPES.RADIO_GROUP]: () => 'onChange',
  [FIELD_TYPES.CHECKBOX_GROUP]: () => 'onChange',
  [FIELD_TYPES.CHECKBOX]: () => 'onChange',
  [FIELD_TYPES.TABLE]: () => 'onBlur',
};
