import { ELEMENT_TYPES } from './ElementTypes';

export const DEFAULT_THEN_ELEMENTS = {
  [ELEMENT_TYPES.SUBMISSION]: {
    id: ELEMENT_TYPES.SUBMISSION,
    type: ELEMENT_TYPES.SUBMISSION,
    translatableLabel: 'SUBMISSION',
    value: ELEMENT_TYPES.SUBMISSION,
    elementType: ELEMENT_TYPES.SUBMISSION,
  },
  [ELEMENT_TYPES.PRIORITY]: {
    id: ELEMENT_TYPES.PRIORITY,
    type: ELEMENT_TYPES.PRIORITY,
    value: ELEMENT_TYPES.PRIORITY,
    translatableLabel: 'PRIORITY',
    elementType: ELEMENT_TYPES.PRIORITY,
  },
  [ELEMENT_TYPES.SLA]: {
    id: ELEMENT_TYPES.SLA,
    type: ELEMENT_TYPES.SLA,
    value: ELEMENT_TYPES.SLA,
    translatableLabel: 'SLA',
    elementType: ELEMENT_TYPES.SLA,
  },
};
