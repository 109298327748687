import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Box } from '@blueprism/ui-core';

import { MODAL_MODES } from 'app-modal';
import { TabsGroup } from 'app-tabs-group';

export const EnhancedTabs = ({ activeTabId, changeTab, disableCheckDirty, disabled, mode, tabs }) => {
  const { dirty, errors } = useFormikContext();
  const currentTab = tabs.find(({ id }) => String(id) === String(activeTabId)) || tabs[0];
  let prevTabState = false;

  const enhancedTabsList = tabs.map((tab) => {
    const isViewMode = mode === MODAL_MODES.VIEW;
    const isTabActive = activeTabId === tab.id;

    if (isTabActive || isViewMode) {
      return { ...tab, disabled: false };
    }

    if (prevTabState) {
      return { ...tab, disabled: true };
    }

    const haveFields = currentTab && currentTab.fieldsForNext;
    const isFormNotInitialAndValid = haveFields && !currentTab.fieldsForNext.every((rule) => !errors[rule]);

    const tabDisabled = disableCheckDirty ? false : (dirty ? isFormNotInitialAndValid : true) || disabled;
    const nextTab = {
      ...tab,
      disabled: tabDisabled,
    };

    prevTabState = nextTab.disabled;

    return nextTab;
  });

  return (
    <Box width="100%" style={{ overflow: 'auto' }}>
      <TabsGroup currentPath={currentTab.link} items={enhancedTabsList} changeTab={changeTab} />
    </Box>
  );
};

EnhancedTabs.propTypes = {
  activeTabId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tabs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  changeTab: PropTypes.func.isRequired,
  mode: PropTypes.string,
  disabled: PropTypes.bool,
  disableCheckDirty: PropTypes.bool,
};

EnhancedTabs.defaultProps = {
  activeTabId: null,
  disableCheckDirty: false,
  disabled: false,
  mode: null,
};
