import * as R from 'ramda';
import moment from 'moment';

import { STATE_IS_OPTIONS, VISIBILITY_STATE_OPTIONS } from '../constants/ruleSelectOptions';

function mapElementExtensionsOptions({ fileExtensions, imageExtensions }) {
  return fileExtensions ? fileExtensions.map((extension) => ({ value: extension, label: extension })) : imageExtensions;
}

function filterOptionsByValues({ value: values = [] }) {
  function optionInValues(option) {
    return [option.value, option].some((v) => R.includes(v, values));
  }

  return function filter(options) {
    return R.filter(optionInValues, options);
  };
}

function findValueByProp(prop, { value }) {
  return R.find(R.propEq(prop, value));
}

function findValueByPropCurried(prop) {
  return R.curry(findValueByProp)(prop);
}

function mapUserModel({ id, label, translatableLabel, userName } = {}) {
  return {
    id,
    value: id,
    label: userName || label,
    translatableLabel,
  };
}

const findByValue = findValueByPropCurried('value');
const findById = findValueByPropCurried('id');

export function resolveRuleValue({ operator }) {
  return R.pathOr(operator.value, ['value', 'value'], operator);
}

export function resolveRuleValueForYear({ operator }) {
  return moment(operator.value);
}

function elementExtensionsResolver(resolveValue) {
  return ({ element: { payload }, operator }) => {
    const options = mapElementExtensionsOptions(payload);

    return resolveValue({ value: resolveRuleValue({ operator }) })(options);
  };
}

function payloadResolver({ data, property = 'options', mapResultFunc = (v) => v } = {}) {
  return (resolveValue) => ({ payload = {}, operator, element }) => {
    const { [property]: payloadData } = payload;
    const elementData = R.pathOr([], ['payload', property], element);
    const resolverPayload = data || payloadData || elementData;

    const result = resolveValue({ value: resolveRuleValue({ operator }) })(resolverPayload);

    return result && mapResultFunc(result);
  };
}

export const resolveExtensions = elementExtensionsResolver(findByValue);
export const resolveMultiSelect = payloadResolver()(filterOptionsByValues);
export const resolveSetValueTo = payloadResolver()(findByValue);
export const resolveSelect = payloadResolver({ data: STATE_IS_OPTIONS })(findByValue);
export const resolveSelectVisibility = payloadResolver({ data: VISIBILITY_STATE_OPTIONS })(findByValue);
export const resolveApprovers = payloadResolver({ property: 'approvers', mapResultFunc: mapUserModel })(findById);
export const resolveSubmitters = payloadResolver({ property: 'submitters', mapResultFunc: mapUserModel })(findById);
