import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { FormField, Stack } from '@blueprism/ui-core';
import * as R from 'ramda';

import { BaseTimePicker, DEFAULT_TIME } from 'app-time';
import { isDescendant } from 'app-base-form/utils';

import { StyledDescription } from './components';

const formatTimeChange = (value) => (value?.split(':').length === 2 ? `${value}:00` : value);
const formatTimeValue = (value) => (R.is(String, value) ? value : DEFAULT_TIME);

export const BaseFormFieldTime = (props) => {
  const {
    description,
    disabled,
    formDisabled,
    gap,
    icon,
    label,
    onBlur,
    onChange,
    required,
    showClear,
    validateOnBlur,
  } = props;

  const [field, meta, helpers] = useField(props);
  const { isSubmitting } = useFormikContext();
  const { name, value } = field;
  const { error, touched } = meta;
  const { setTouched, setValue } = helpers;

  const computedLabel = `${label}${required ? '\u00A0*' : ''}`;
  const isDisabled = isSubmitting || formDisabled || disabled;
  const displayError = touched && !!error;

  const changeFieldHandler = (newTime) => {
    if (!touched) setTouched(true);

    onChange(newTime);
    setValue(newTime);
  };

  const blurFieldHandler = () => {
    if (isDisabled) return;

    if (!touched && validateOnBlur) setTouched(true);

    onBlur(formatTimeChange(value));
    setValue(formatTimeChange(value));
  };

  const onBlurChanged = ({ relatedTarget }) => {
    const childComponentIsFocused = relatedTarget ? isDescendant(relatedTarget, name) : false;

    if (!childComponentIsFocused) blurFieldHandler();
  };

  return (
    <FormField
      label={computedLabel}
      htmlFor={name}
      icon={icon}
      errorText={error}
      error={displayError}
      gap={gap}
      helperText={<StyledDescription type="caption">{description}</StyledDescription>}
    >
      <Stack align="start" onBlur={onBlurChanged}>
        <BaseTimePicker
          disabled={isDisabled}
          id={name}
          value={formatTimeValue(value)}
          onChange={changeFieldHandler}
          showClear={showClear}
        />
      </Stack>
    </FormField>
  );
};

export const BaseFormFieldTimePropTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  formDisabled: PropTypes.bool,
  gap: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  showClear: PropTypes.bool,
  validateOnBlur: PropTypes.bool,
};

export const BaseFormFieldTimeShape = PropTypes.shape(BaseFormFieldTimePropTypes);

BaseFormFieldTime.propTypes = BaseFormFieldTimePropTypes;

BaseFormFieldTime.defaultProps = {
  description: null,
  disabled: false,
  formDisabled: false,
  gap: 'xs',
  icon: undefined,
  label: '',
  onBlur: () => null,
  onChange: () => null,
  required: false,
  showClear: true,
  validateOnBlur: true,
};
