import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import * as R from 'ramda';

import { CreatePassword } from './CreatePassword';

export const CreatePasswordContainer = R.compose(
  inject((stores) => ({
    authStore: stores.rootStore.authStore,
  })),
  observer,
)(CreatePassword);
