import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { BaseForm } from 'app-base-form';
import { CustomModal, MODAL_MODES } from 'app-modal';

export const AddEditLinkModal = ({ store }) => {
  const { initialValues, mode, onChange, setShowModal, showModal, translateFieldsConfig } = store;

  const { t } = useTranslation();

  const submitHandler = ({ link, selectionText }) => {
    onChange('link', selectionText, link, 'selectionText');
    setShowModal(false);
  };

  if (!showModal) return null;

  return (
    <CustomModal
      size="small"
      visible={showModal}
      translatableTitle={mode === MODAL_MODES.ADD ? 'ADD_LINK' : 'EDIT_LINK'}
    >
      <BaseForm
        fields={translateFieldsConfig(t)}
        paddingBottom="0"
        initialValues={initialValues}
        cancelFormHandler={() => setShowModal(false)}
        submitHandler={submitHandler}
        submitButtonLabel="SAVE"
      />
    </CustomModal>
  );
};

AddEditLinkModal.propTypes = {
  store: PropTypes.shape({
    mode: PropTypes.string,
    onChange: PropTypes.func,
    setShowModal: PropTypes.func,
    showModal: PropTypes.bool,
    translateFieldsConfig: PropTypes.func,
    initialValues: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
};
