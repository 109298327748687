export class AuthServerUrlModel {
  static getModelFromPayload = (p) => ({
    connect: p.connect,
    selfServe: p.selfServe,
    isInteractAvailable: p.isInteractAvailable,
  });

  constructor(payload = {}) {
    Object.assign(this, AuthServerUrlModel.getModelFromPayload(payload));
  }
}
