import React from 'react';
import PropTypes from 'prop-types';

import logo from './logo_sq.png';
import { LdsRing, StyledSpinner, StyledLoader } from './Loader.styled';

export const Loader = ({ active, image, style }) => {
  const calcStyle = Object.assign({}, style, {});

  return (
    <StyledLoader active={active} style={calcStyle}>
      <StyledSpinner src={image} alt="spinner" />
    </StyledLoader>
  );
};

Loader.defaultProps = {
  active: false,
  image: logo,
  style: {},
};

Loader.propTypes = {
  active: PropTypes.bool,
  image: PropTypes.oneOfType([PropTypes.instanceOf(Image), PropTypes.string, PropTypes.shape({})]),
  style: PropTypes.shape({}),
};

export const CircleLoader = ({ size, style }) => (
  <LdsRing style={{ width: size, height: size, ...style }} className="lds-ring">
    <div style={{ width: size, height: size }} />
    <div style={{ width: size, height: size }} />
    <div style={{ width: size, height: size }} />
    <div style={{ width: size, height: size }} />
  </LdsRing>
);

CircleLoader.defaultProps = {
  style: {},
};

CircleLoader.propTypes = {
  size: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
};
