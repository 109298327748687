import styled from 'styled-components';

export const StyledSettingsOptionComponent = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;

  label {
    margin-right: 7px;
  }

  .rc-select-selection--multiple .rc-select-search__field {
    margin-left: 8px;
    padding-left: 0;
  }
`;
