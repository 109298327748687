import { action, observable, reaction } from 'mobx';
import * as R from 'ramda';

import { INITIAL_CONTENT, INITIAL_OPTIONS } from './constants';

class AreYouSureModalStore {
  @observable isOpen = false;

  @observable content = INITIAL_CONTENT;

  @observable isСontentVisible = false;

  @observable options = INITIAL_OPTIONS;

  @observable _waitForAnswerResolve = null;

  constructor() {
    this.isContentVisibleReaction = reaction(
      () => this.isСontentVisible,
      (isVisible) => {
        if (!isVisible) this._reset();
      },
    );
  }

  @action
  onModalContentVisibilityChange = (isVisible) => {
    this.isСontentVisible = isVisible;
  };

  @action
  open = ({ content, options } = {}) => {
    this.isOpen = true;
    this.content = R.merge(this.content, content);
    this.options = R.merge(this.options, options);

    return new Promise((resolve) => {
      this._waitForAnswerResolve = resolve;
    });
  };

  @action
  close = (answer) => {
    this.isOpen = false;
    this._waitForAnswerResolve(answer);
  };

  @action
  _reset = () => {
    this.content = INITIAL_CONTENT;
    this.options = INITIAL_OPTIONS;
  };
}

export const areYouSureModalStore = new AreYouSureModalStore();
