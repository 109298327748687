import download from 'downloadjs';

export function downloadFileFromURL(url, fileName) {
  if (url) {
    const _fileName = fileName || url.split('/').pop().split('?')[0];
    const ajax = new XMLHttpRequest();
    ajax.open('GET', url, true);
    ajax.responseType = 'blob';
    ajax.onload = (event) => {
      download(event.target.response, _fileName, 'application/octet-stream');
    };
    setTimeout(() => {
      ajax.send();
    }, 0); // allows setting custom ajax headers using the return:
    return ajax;
  }
}
