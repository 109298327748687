export function compareStrings(string1, string2, ignoreCase, useLocale) {
  if (ignoreCase) {
    if (useLocale) {
      return string1.toLocaleLowerCase() === string2.toLocaleLowerCase();
    }

    return string1.toLowerCase() === string2.toLowerCase();
  }

  return string1 === string2;
}
