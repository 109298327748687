import { action, observable } from 'mobx';
import { HttpService } from 'app-http-service';
import { authServerErrorPrefix } from 'app-utils';

import { OIDC_TOGGLE_ACTIVE } from 'utils/oidcFeatureToggle';

import { ConfigurationStore } from './ConfigurationStore';
import { AuthServerLanguageStore } from './LanguageStore';
import { AuthStore } from './AuthStore';
import { routerStore } from './history';
import { URLS } from '../constants';

const authServerBaseURL = window.location.origin;

export class RootStore {
  constructor() {
    this.routerStore = routerStore;
    this.authServerHttpService = new HttpService({ baseURL: authServerBaseURL, errorPrefix: authServerErrorPrefix });

    this.setup();
  }

  @observable authenticationSettings = {
    nativeAuthenticationEnabled: true,
    activeDirectoryAuthenticationEnabled: false,
    groupBasedRoleMembershipEnabled: true,
    userBasedRoleMembershipEnabled: true,
    saml2AuthenticationEnabled: false,
    oidcAuthenticationEnabled: false,
  };

  @observable saml2ProvidersList = [];

  @observable oidcProvidersList = [];

  @observable numberOfLdapUsers;

  @observable saml2PluginEnabled = false;

  @action
  setup = async () => {
    this.languageStore = new AuthServerLanguageStore();
    this.configStore = new ConfigurationStore(this, this.authServerHttpService);

    await this.configStore.setupUser();
    await this.languageStore.setup();

    this.authStore = new AuthStore(this, this.authServerHttpService, this.languageStore);

    await this.getBlockingData();
  };

  @action getAuthenticationSettings = async () => {
    this.authenticationSettings = await this.authServerHttpService.get(`${URLS.authenticationSettings}`);
  };

  @action checkNumberOfLdapUsers = async () => {
    this.numberOfLdapUsers = await this.authServerHttpService.get(`${URLS.ldapUsersCount}`);
  };

  @action
  getSaml2Providers = async () => {
    const { items = [] } = await this.authServerHttpService.get(URLS.saml2ProvidersList);
    this.saml2ProvidersList = items;
  };

  @action
  getOidcProviders = async () => {
    if (!OIDC_TOGGLE_ACTIVE) return;

    const { items = [] } = await this.authServerHttpService.get(URLS.oidcProvidersList);
    this.oidcProvidersList = items;
  };

  @action
  getBlockingData = async () => {
    try {
      await Promise.all([
        this.getAuthenticationSettings(),
        this.checkNumberOfLdapUsers(),
        this.getSaml2Providers(),
        this.getOidcProviders(),
        this.verifySaml2PluginEnabled(),
      ]);
    } finally {
      this.configStore.setPending(false);
    }
  };

  @action
  verifySaml2PluginEnabled = async () => {
    this.saml2PluginEnabled = await this.authServerHttpService.get(URLS.saml2PluginEnabled);
  };
}
