import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '@blueprism/ui-core';
import cn from 'classnames';

export const BaseFormButton = ({
  align,
  buttonClassName,
  buttonHandler,
  buttonLabel,
  children,
  disabled,
  iconLeft,
  iconRight,
  noHover,
}) => {
  const { t } = useTranslation();

  return (
    <Button
      className={cn(buttonClassName, { [`button-${align}`]: align })}
      onClick={buttonHandler}
      disabled={disabled}
      noHover={noHover}
      iconLeft={iconLeft}
      iconRight={iconRight}
    >
      {children || t(buttonLabel)}
    </Button>
  );
};

BaseFormButton.propTypes = {
  buttonLabel: PropTypes.string,
  align: PropTypes.string,
  buttonClassName: PropTypes.string,
  buttonHandler: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  noHover: PropTypes.bool,
};

BaseFormButton.defaultProps = {
  buttonLabel: '',
  align: null,
  buttonClassName: '',
  buttonHandler: () => {},
  children: '',
  disabled: false,
  iconLeft: undefined,
  iconRight: undefined,
  noHover: false,
};
