import styled, { css } from 'styled-components';

export const ModalBox = styled.div`
  ${({ theme }) => css`
    padding: 2rem;
    text-align: center;
    background-color: ${theme.color.paper};
    border-radius: 2rem;
  `}
`;
