import * as R from 'ramda';
/**
 * If the given value Number, return it. Otherwise returns the provided default value.
 *
 * @param {(number|null|undefined)} defaultValue
 * @param {number} value
 *
 * @returns {number}
 */
export function numberOr(defaultValue, value) {
  return R.is(Number, value) ? value : defaultValue;
}
