import styled, { css } from 'styled-components';
import { Box } from '@blueprism/ui-core';

export const IconContainer = styled(Box)(
  (props) => css`
    position: absolute;
    top: ${props.top};
    right: ${props.right};
    bottom: ${props.right};
    left: ${props.left};
  `,
);

export const InvisibleInput = styled.input`
  opacity: 0;
  pointer-events: none;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
`;
