import styled from 'styled-components';
import { InputField, Row } from '@blueprism/ui-core';

export const StyledTimeInput = styled(InputField)`
  cursor: pointer;

  &::-webkit-calendar-picker-indicator {
    display: block;
  }
`;

export const StyledRow = styled(Row)`
  position: relative;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  margin-right: 30px;
  position: absolute;
  right: 0rem;
  height: 20px;
  top: 11px;
`;
