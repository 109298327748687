import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from '@blueprism/ui-core';

export const BaseFormElementGroupHeader = (props) => {
  const { gap, textType, value } = props;

  return (
    <Box gap={gap}>
      <Text type={textType}>{value}</Text>
    </Box>
  );
};

BaseFormElementGroupHeader.propTypes = {
  value: PropTypes.string,
  textType: PropTypes.string,
  gap: PropTypes.string,
};

BaseFormElementGroupHeader.defaultProps = {
  value: '',
  textType: 'h3',
  gap: 'xxs',
};
