import styled from 'styled-components';
import ReactSVG from 'react-svg';
import { Text } from '@blueprism/ui-core';

export const StyledReactSVG = styled(ReactSVG)`
  svg {
    width: 100%;
    height: 100%;
  }

  svg,
  path {
    fill: ${(props) => props.theme.color.action};
  }

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  border-radius: 4px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: 100% !important;
  }

  &:hover {
    box-shadow: none;
  }
`;

export const StyledText = styled(Text)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;
