import * as React from 'react';
import PropTypes from 'prop-types';
import { Cross } from '@blueprism/ui-icons';

import { isBrowserFirefox } from 'app-utils';

import { MAX_M_S, MIN_M_S, DEFAULT_TIME } from './constants';
import { StyledTimeInput, IconContainer, StyledRow } from './BaseTimePicker.styled';

export function BaseTimePicker({ disabled, id, onBlur, onChange, showClear, value }) {
  const isCrossNeeded = !disabled && showClear && !isBrowserFirefox && value;

  function handleChange(event) {
    onChange(event.currentTarget.value);
  }

  function handleCrossIconClick() {
    onChange('');
  }

  return (
    <StyledRow width="100%">
      <StyledTimeInput
        type="time"
        id={id}
        onChange={handleChange}
        onBlur={onBlur}
        value={value}
        min={MIN_M_S}
        max={MAX_M_S}
        disabled={disabled}
        step="1"
      />
      {isCrossNeeded && (
        <IconContainer onClick={handleCrossIconClick}>
          <Cross title="cross" size={22} />
        </IconContainer>
      )}
    </StyledRow>
  );
}

BaseTimePicker.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  showClear: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

BaseTimePicker.defaultProps = {
  value: DEFAULT_TIME,
  showClear: true,
  disabled: false,
  id: undefined,
  onChange: () => {},
  onBlur: () => {},
};
