import React from 'react';

import { BaseFormSubmitButton } from '../components/BaseFormSubmitButton';
import { BaseFormCancelButton } from '../components/BaseFormCancelButton';
import { BaseFormButton } from '../components/BaseFormButton';

export function renderBaseControlsButtons(props) {
  const {
    buttonClassName,
    buttonHandler,
    buttonLabel,
    cancelFormHandler,
    disableCheckDirty,
    formDisabled,
    secondaryButtonProps,
    submitButtonClassName,
    submitButtonLabel,
  } = props;

  return (
    <div className="button-group">
      <BaseFormSubmitButton
        label={submitButtonLabel}
        disabled={formDisabled}
        className={submitButtonClassName}
        disableCheckDirty={disableCheckDirty}
      />
      {cancelFormHandler && <BaseFormCancelButton cancelFormHandler={cancelFormHandler} />}
      {buttonHandler && (
        <BaseFormButton
          buttonLabel={buttonLabel}
          disabled={formDisabled}
          buttonHandler={buttonHandler}
          buttonClassName={buttonClassName}
          {...secondaryButtonProps}
        />
      )}
    </div>
  );
}
