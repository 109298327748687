import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FieldArray, useFormikContext } from 'formik';

import { BaseFormFieldArray } from './BaseFormFieldArray';

export const BaseFormFieldTable = (props) => {
  const { columns, description, disabled, formDisabled, label, name, required } = props;
  const { isSubmitting } = useFormikContext();

  const isDisabled = isSubmitting || disabled || formDisabled;

  return useMemo(() => {
    return <FieldArray name={name} render={(fieldProps) => <BaseFormFieldArray {...fieldProps} {...props} />} />;
  }, [columns, description, isDisabled, label, name, required]);
};

export const BaseFormFieldTablePropTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      checked: PropTypes.bool,
      value: PropTypes.string,
    }),
  ).isRequired,
  description: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
  formDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export const BaseFormFieldTableShape = PropTypes.shape(BaseFormFieldTablePropTypes);

BaseFormFieldTable.propTypes = BaseFormFieldTablePropTypes;

BaseFormFieldTable.defaultProps = {
  description: undefined,
  disabled: false,
  formDisabled: false,
  required: false,
};
