import * as R from 'ramda';

import { enhancedT } from 'app-i18n';

import { FIELD_TYPES } from './fieldTypes';
import { RULE_TYPE } from './RuleOptionsAndTypes';
import { SELECTION_TYPES_ID } from './selectionTypes';
import { ELEMENT_TYPES } from './ElementTypes';
import {
  resolveSelect,
  resolveRuleValue,
  resolveSetValueTo,
  resolveMultiSelect,
  resolveApprovers,
  resolveSelectVisibility,
  setFieldValue,
  setElementState,
} from '../utils';
import { STATE_IS_OPTIONS, VISIBILITY_STATE_OPTIONS } from './ruleSelectOptions';
import { ALLOW_DATE_TYPE } from './allowDateType';

const stateFieldConfig = {
  type: FIELD_TYPES.DROPDOWN,
  translatableLabel: 'STATE',
  options: STATE_IS_OPTIONS,
  required: true,
  value: STATE_IS_OPTIONS[0],
  resolveValue: (d) => d.value,
  resolveRuleValue: resolveSelect,
  applyRule: setElementState,
};

const stateIsVisibilityConfig = {
  type: FIELD_TYPES.DROPDOWN,
  required: true,
  translatableLabel: 'STATE',
  value: VISIBILITY_STATE_OPTIONS[0],
  options: VISIBILITY_STATE_OPTIONS,
  resolveValue: (d) => d.value,
  resolveRuleValue: resolveSelectVisibility,
  applyRule: setElementState,
};

export const RULE_THEN_VALUE_TYPES = {
  [ELEMENT_TYPES.FIELD]: {
    [FIELD_TYPES.TEXT]: {
      [RULE_TYPE.SET_VALUE_TO]: {
        value: '',
        type: FIELD_TYPES.TEXT,
        resolveRuleValue,
        applyRule: setFieldValue,
      },
      [RULE_TYPE.STATE_IS]: stateFieldConfig,
    },
    [FIELD_TYPES.TEXTAREA]: {
      [RULE_TYPE.SET_VALUE_TO]: {
        value: '',
        type: FIELD_TYPES.TEXTAREA,
        resolveRuleValue,
        applyRule: setFieldValue,
      },
      [RULE_TYPE.STATE_IS]: stateFieldConfig,
    },
    [FIELD_TYPES.NUMBER]: {
      [RULE_TYPE.SET_VALUE_TO]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        resolveRuleValue,
        applyRule: setFieldValue,
      },
      // TODO: WIP!
      [RULE_TYPE.STATE_IS]: stateFieldConfig,
    },
    [FIELD_TYPES.DATE]: {
      [RULE_TYPE.DATE_SET_VALUE_TO]: {
        type: FIELD_TYPES.DATE,
        required: true,
        checkValidationConflict: ({ allowDates, futureDateRestriction, pastDateRestriction }) => {
          return (
            allowDates !== ALLOW_DATE_TYPE.ANY ||
            R.is(Number, pastDateRestriction) ||
            R.is(Number, futureDateRestriction)
          );
        },
        resolveRuleValue,
        applyRule: setFieldValue,
      },
      [RULE_TYPE.DATE_STATE_IS]: stateFieldConfig,
    },
    [FIELD_TYPES.UPLOAD]: {
      [RULE_TYPE.STATE_IS]: stateFieldConfig,
    },
    [FIELD_TYPES.PARAGRAPH]: {
      type: FIELD_TYPES.PARAGRAPH,
      [RULE_TYPE.STATE_IS]: stateIsVisibilityConfig,
    },
    [FIELD_TYPES.TIME]: {
      [RULE_TYPE.TIME_SET_VALUE_TO]: {
        value: '00:00:00',
        type: FIELD_TYPES.TIME,
        required: true,
        resolveRuleValue,
        applyRule: setFieldValue,
      },
      [RULE_TYPE.TIME_STATE_IS]: stateFieldConfig,
    },
    [FIELD_TYPES.DROPDOWN]: {
      [RULE_TYPE.SET_VALUE_TO]: {
        value: '',
        type: FIELD_TYPES.DROPDOWN,
        required: true,
        resolveRuleValue: resolveSetValueTo,
        applyRule: setFieldValue,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.SINGLE,
      },
      [RULE_TYPE.SET_VALUES_TO]: {
        value: '',
        type: FIELD_TYPES.DROPDOWN,
        isMulti: true,
        required: true,
        resolveRuleValue: resolveMultiSelect,
        applyRule: setFieldValue,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.MULTI,
      },
      [RULE_TYPE.STATE_IS]: stateFieldConfig,
    },
    [FIELD_TYPES.RADIO_GROUP]: {
      [RULE_TYPE.STATE_IS]: stateFieldConfig,
    },
    [FIELD_TYPES.CHECKBOX_GROUP]: {
      [RULE_TYPE.STATE_IS]: stateFieldConfig,
    },
    [FIELD_TYPES.CHECKBOX]: {
      [RULE_TYPE.STATE_IS]: stateFieldConfig,
    },
    [FIELD_TYPES.IMAGE]: {
      [RULE_TYPE.STATE_IS]: stateFieldConfig,
    },
    [FIELD_TYPES.PASSWORD]: {
      [RULE_TYPE.SET_VALUE_TO]: {
        value: '',
        type: FIELD_TYPES.PASSWORD,
        resolveRuleValue,
        applyRule: setFieldValue,
      },
      [RULE_TYPE.STATE_IS]: stateFieldConfig,
    },
  },

  [ELEMENT_TYPES.PRIORITY]: {
    [ELEMENT_TYPES.PRIORITY]: {
      [RULE_TYPE.IS]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        isInteger: true,
        min: 1,
        max: 999,
        resolveRuleValue,
      },
    },
  },
  [ELEMENT_TYPES.SLA]: {
    [ELEMENT_TYPES.SLA]: {
      [RULE_TYPE.IS]: {
        value: '',
        type: FIELD_TYPES.TEXT,
        required: true,
        maxlength: 12,
        regex: {
          pattern: /[0-9]{3}.(2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]/,
          errorMessage: () =>
            enhancedT([
              'common:VALIDATION_FIELD_SHOULD_BE_FROM_FROM_TO',
              { from: '000.00:00:00', to: '999.23:59:59' },
              { field: 'common:VALUE' },
            ]),
        },
        resolveRuleValue,
      },
    },
  },
  [ELEMENT_TYPES.SUBMISSION]: {
    [ELEMENT_TYPES.SUBMISSION]: {
      [RULE_TYPE.NEEDS_APPROVAL]: {
        value: '',
        type: FIELD_TYPES.DROPDOWN,
        options: [],
        required: true,
        resolveValue({ fullName, id, translatableLabel }) {
          return {
            id,
            translatableLabel,
            value: id,
            label: fullName,
          };
        },
        resolveOptions(options) {
          return options.map(this.resolveValue);
        },
        resolveRuleValue: resolveApprovers,
      },
      [RULE_TYPE.DOESNT_NEED_APPROVAL]: {
        resolveRuleValue,
      },
    },
  },
  [ELEMENT_TYPES.PAGE]: {
    [ELEMENT_TYPES.PAGE]: {
      [RULE_TYPE.STATE_IS]: stateIsVisibilityConfig,
    },
  },
};
