import React, { Fragment } from 'react';

import { BaseFieldSkeletonFactory } from '../factories';
import { FIELD_TYPES } from '../constants';

export function BaseFormSkeleton({ skeletonCount = 3, skeletonType = FIELD_TYPES.TEXT, ...rest }) {
  return Array(skeletonCount)
    .fill({ type: skeletonType, ...rest })
    .map((fieldPayload, i) => <Fragment key={i}>{BaseFieldSkeletonFactory.build(fieldPayload)}</Fragment>);
}
