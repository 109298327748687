import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Row, StatusIndicator } from '@blueprism/ui-core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const ActiveDirectoryLogin = ({ authStore }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [status, setStatus] = React.useState('idle');
  const pendingFlag = React.useRef(false);

  const activeDirectoryLoginHandler = () => {
    const returnUrl = unescape(location.search).replace('?ReturnUrl=', '');

    authStore.activeDirectoryLogin({ returnUrl });
  };

  React.useEffect(() => {
    if (authStore.activeDirectoryLoginPending) {
      pendingFlag.current = true;
      setStatus('pending');
    }
  }, [authStore.activeDirectoryLoginPending]);

  return (
    <Row align="end">
      <Button disabled={authStore.loginPending} onClick={activeDirectoryLoginHandler} fullWidth>
        {t('LOG_IN_WITH_ACTIVE_DIRECTORY')}
      </Button>
      {authStore.activeDirectoryLoginPending && (
        <Box padding="1rem">
          <StatusIndicator status={status} />
        </Box>
      )}
    </Row>
  );
};

ActiveDirectoryLogin.propTypes = {
  authStore: PropTypes.shape({
    loginPending: PropTypes.bool,
    activeDirectoryLogin: PropTypes.func,
    activeDirectoryLoginPending: PropTypes.bool,
  }).isRequired,
};
