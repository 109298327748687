import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Trans } from 'react-i18next';
import FocusLock from 'react-focus-lock';
import { Stack, Text } from '@blueprism/ui-core';

import { TranslationTemplate } from 'app-i18n/TranslationTemplate';

// TODO: Delete commented lines if modal scrolling is not implemented
// import { ModalBody } from './components/ModalBody';
import { ModalFooter } from './components/ModalFooter';
import { StyledDialogModal } from './CustomModal.styled';

import './ModalStyles.scss';

export function CustomModal({
  cancelButtonLabel,
  cancelIsVisible,
  children,
  className,
  closeButton,
  contentHeight,
  contentWidth,
  dataTestid,
  footerContent,
  onClose,
  onContentVisibilityChange,
  overlay,
  padding,
  showFullTitle,
  size,
  subtitle,
  subtitleOptions,
  title,
  translatableTitle,
  visible,
}) {
  const modalSize = R.cond([
    [R.equals('large'), () => '900px'],
    [R.equals('medium'), () => '585px'],
    [R.equals('small'), () => '475px'],
    [R.equals('extra-small'), () => '375px'],
    [R.equals('regular'), () => '40rem'],
    [R.T, (res) => res],
  ])(size);

  const headerIsVisible = title || subtitle || translatableTitle;

  /**
   * TODO: @blueprism/ui-core [DialogModal] enhancement.
   */
  const [visibleModalContent, setVisibleModalContent] = useState(visible);
  const overlayDisplayObserverRef = useRef();
  const modalWrapperRef = useRef();

  function handleClickOnCross(event) {
    const clickedOnCross = event?.currentTarget?.type === 'button';
    if (clickedOnCross && visible) onClose();
  }

  useEffect(() => {
    /**
     * TODO: @blueprism/ui-core [DialogModal] enhancement.
     *
     * Code that removes modal content when the value of the "display"
     * attribute in Overlay is equal to "none"
     */
    if (modalWrapperRef.current && !overlayDisplayObserverRef.current) {
      overlayDisplayObserverRef.current = new MutationObserver((mutations) => {
        mutations.forEach((mutationRecord) => {
          const displayAttrValue = mutationRecord.target.getAttribute('display');

          if (displayAttrValue === 'none') {
            setVisibleModalContent(false);
            onContentVisibilityChange(false);
          } else {
            setVisibleModalContent(true);
            onContentVisibilityChange(true);
          }
        });
      });

      overlayDisplayObserverRef.current.observe(modalWrapperRef.current.firstElementChild, {
        attributes: true,
        attributeFilter: ['display'],
      });
    }
  }, [modalWrapperRef.current]);

  useEffect(() => {
    /**
     * TODO: @blueprism/ui-core [DialogModal] enhancement.
     */
    return () => {
      overlayDisplayObserverRef.current.disconnect();
    };
  }, []);

  const FooterContent =
    footerContent || (() => <ModalFooter closeModal={onClose} cancelButtonLabel={cancelButtonLabel} />);

  const headerText = title || <TranslationTemplate value={translatableTitle} />;

  return (
    <div ref={modalWrapperRef} className="modal">
      <StyledDialogModal
        className={className}
        width={modalSize}
        onClose={handleClickOnCross}
        overlay={overlay}
        visible={visible}
        data-testid={dataTestid}
        headerContent={
          headerIsVisible && (
            <Text truncated={!showFullTitle} type="h3">
              {headerText}
            </Text>
          )
        }
        footerContent={
          cancelIsVisible && (
            <FocusLock group="group">
              <FooterContent />
            </FocusLock>
          )
        }
        padding={padding}
        closeButton={closeButton}
        contentHeight={contentHeight}
        contentWidth={contentWidth}
      >
        {visibleModalContent && (
          <FocusLock autoFocus={false} noFocusGuards group="group">
            <Stack gap="base" padding="xxs" width="100%">
              {subtitle && (
                <Text type="h6">
                  <Trans i18nKey={subtitle}>{subtitleOptions}</Trans>
                </Text>
              )}
              {children}
            </Stack>
          </FocusLock>
        )}
      </StyledDialogModal>
    </div>
  );
}

CustomModal.propTypes = {
  cancelIsVisible: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  closeButton: PropTypes.bool,
  dataTestid: PropTypes.string,
  showFullTitle: PropTypes.bool,
  footerContent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  onClose: PropTypes.func,
  overlay: PropTypes.bool,
  padding: PropTypes.string,
  size: PropTypes.oneOf(['extra-small', 'small', 'medium', 'large', 'regular']),
  subtitle: PropTypes.string,
  title: PropTypes.string,
  translatableTitle: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  visible: PropTypes.bool,
  onContentVisibilityChange: PropTypes.func,
  subtitleOptions: PropTypes.arrayOf(PropTypes.element),
  contentHeight: PropTypes.string,
  contentWidth: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
};

CustomModal.defaultProps = {
  cancelIsVisible: false,
  children: null,
  className: '',
  closeButton: false,
  dataTestid: null,
  footerContent: null,
  onClose: () => {},
  overlay: true,
  padding: 'large',
  size: 'regular',
  subtitle: '',
  title: '',
  translatableTitle: '',
  visible: false,
  showFullTitle: true,
  onContentVisibilityChange: () => null,
  subtitleOptions: [],
  contentHeight: undefined,
  contentWidth: undefined,
  cancelButtonLabel: 'CANCEL',
};
