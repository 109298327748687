import * as R from 'ramda';

import { VALIDATIONS } from './Validations';

export const mapFieldValidationSchema = (field) => {
  const fieldKeys = R.keys(field);
  const fieldType = VALIDATIONS[field.type]._type;

  const validations = fieldKeys.reduce((fieldValidationsAcc, key) => {
    if (VALIDATIONS[field.type][key] && field[key]) {
      fieldValidationsAcc.push(VALIDATIONS[field.type][key](field[key], field.label, field));
    }

    return fieldValidationsAcc;
  }, []);

  return validations.reduce((fieldValidationsAcc, validation) => fieldValidationsAcc.concat(validation), fieldType);
};
