import styled from 'styled-components';
import ReactSVG from 'react-svg';

export const StyledSVG = styled(ReactSVG)`
  svg {
    min-width: 4rem !important;
    width: 4rem !important;
    height: 4rem !important;
  }

  svg,
  path {
    fill: ${(props) => props.theme.color.hover};
  }
`;
