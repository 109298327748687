import { FIELD_TYPES } from 'app-base-form/constants';

export const loginFormConfig = (t) => ({
  className: 'login-form',
  title: t('LOGIN_TITLE'),
  submitButtonLabel: t('LOG_IN'),

  buttonLabel: t('FORGOT_PASSWORD'),

  buttonClassName: 'reset-password-link',
  secondaryButtonProps: { noHover: true },
  fields: [
    {
      type: FIELD_TYPES.TEXT,
      value: '',
      name: 'username',
      label: t('USERNAME'),
      labelPlacement: 'left',
      required: true,
    },
    {
      type: FIELD_TYPES.PASSWORD,
      value: '',
      name: 'password',
      label: t('PASSWORD'),
      labelPlacement: 'left',
      required: true,
    },
  ],
});
