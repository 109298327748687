import { isEmpty } from 'app-utils';
import { MODES } from 'app-constants';
import { DEFAULT_TIME } from 'app-time';

import { BaseFieldModel } from './BaseFieldModel';
import { INITIAL_STATE } from '../constants';

export class TimeFieldModel extends BaseFieldModel {
  constructor(field, page) {
    super(field, page);

    this.mode = field.mode;
    this.setup();
  }

  setup() {
    const defaultValue = this.mode === MODES.ADD ? DEFAULT_TIME : '';

    this.value = this.payload.value || defaultValue;
  }

  resolveValue = (value) => {
    if (isEmpty(value)) return '';

    return value;
  };

  getRequestModel() {
    return {
      ...this,
      initialState: this.initialState.value,
      payload: JSON.stringify({
        value: this.value,
        hidden: this.initialState.value === INITIAL_STATE.HIDDEN,
        required: this.initialState.value === INITIAL_STATE.MANDATORY,
        disabled: this.initialState.value === INITIAL_STATE.READ_ONLY,
      }),
    };
  }
}
