export const ELEMENT_GROUP_TYPES = {
  FIELDS: 'FIELDS',
  PAGES: 'PAGES',
  DEFAULT_ELEMENTS: 'DEFAULT_ELEMENTS',
};

export const getElementGroupOptions = (isFieldsDisabled) => [
  { value: ELEMENT_GROUP_TYPES.FIELDS, translatableLabel: 'FIELDS', disabled: isFieldsDisabled },
  { value: ELEMENT_GROUP_TYPES.PAGES, translatableLabel: 'PAGES' },
  { value: ELEMENT_GROUP_TYPES.DEFAULT_ELEMENTS, translatableLabel: 'DEFAULT_ELEMENTS' },
];
