export const SIZE_KILOBYTE = 1024;
export const SIZE_MB = SIZE_KILOBYTE * SIZE_KILOBYTE;
export const SIZE_UNITS = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB'];

export const getFormattedFileSize = (val, maxFileSize) => {
  const isSizeGreaterThanMB = maxFileSize / SIZE_KILOBYTE >= 1;

  return {
    label: isSizeGreaterThanMB ? 'MB' : 'KB',
    value: isSizeGreaterThanMB ? val / SIZE_KILOBYTE : val,
  };
};

/**
 *
 * @param {number} bytes
 * @param {number} decimals
 *
 * Example of usage: formatBytes(SIZE_MB) => 1 MB
 */
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${SIZE_UNITS[i]}`;
}

function _throwErrorForConvertUnits(operator, value) {
  throw Error(`'${operator}' (${value}) unit is not implemented.`);
}

export function convertUnits({ from, to, value }) {
  if (from === 'Bytes') {
    if (to === 'KB') return value / SIZE_KILOBYTE;
    if (to === 'MB') return value / SIZE_MB;
    if (to === 'Bytes') return value;

    _throwErrorForConvertUnits('to', to);
  } else if (from === 'KB') {
    if (to === 'Bytes') return value * SIZE_KILOBYTE;
    if (to === 'MB') return value / SIZE_KILOBYTE;
    if (to === 'KB') return value;

    _throwErrorForConvertUnits('to', to);
  } else if (from === 'MB') {
    if (to === 'Bytes') return value * SIZE_MB;
    if (to === 'KB') return value * SIZE_KILOBYTE;
    if (to === 'MB') return value;

    _throwErrorForConvertUnits('to', to);
  } else {
    _throwErrorForConvertUnits('from', from);
  }
}

export const UNIT_TRANSLATE_KEYS = {
  Bytes: 'common:BYTES',
  KB: 'common:KB',
  MB: 'common:MB',
  GB: 'common:GB',
  TB: 'common:TB',
  PB: 'common:PB',
  EB: 'common:EB',
};
