import { schema } from 'normalizr';

export const field = new schema.Entity(
  'fields',
  {},
  {
    idAttribute: 'automationId',
    processStrategy: (value, parent) => {
      return {
        ...value,
        page: parent.name,
        fieldRules: parent.initialRules.reduce((acc, { id, IF }) => {
          if (IF.id !== value.automationId) return acc;

          return [...acc, id];
        }, []),
      };
    },
  },
);
