import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { FormField, Stack } from '@blueprism/ui-core';
import { withTranslation } from 'react-i18next';

import { MaskedInput } from '../MaskedInput';
import { patterns } from '../patterns';

const defaultInputComponent = (props) => <MaskedInput {...props} />;

const defaultValidation = () => true;

const getUpdatedValue = ({ event, inputType = 'from', props: { getValueFromChangeEvent }, value }) => {
  return {
    ...value,
    [inputType]: getValueFromChangeEvent(event),
  };
};

class RangeInputV7Component extends PureComponent {
  state = {
    value: {
      from: '',
      to: '',
    },
    // eslint-disable-next-line react/no-unused-state
    prevValue: {},
  };

  static getDerivedStateFromProps(props, state) {
    const isPropsChanged = props.value.from !== state.prevValue.from || props.value.to !== state.prevValue.to;

    if (isPropsChanged || props.forceUpdateStatus) {
      return { value: props.value, prevValue: props.value };
    }
    return null;
  }

  handleFromChange = (event) => {
    const value = event === null ? '' : event;
    this.updateValue('from', value);
  };

  handleToChange = (event) => {
    const value = event === null ? '' : event;
    this.updateValue('to', value);
  };

  handleCloseInput = (name) => {
    this.setState(
      (state) => ({ value: { ...state.value, [name]: '' } }),
      () => this.updateFiltersValue(),
    );
  };

  updateValue = (inputType, event) => {
    const { value } = this.state;
    const updatedValue = getUpdatedValue({
      inputType,
      event,
      props: this.props,
      value,
    });

    this.setState({ value: updatedValue });
  };

  updateFiltersValue = () => {
    const { isTimeInput, onChange, validation } = this.props;
    const { value: stateValue } = this.state;

    const fromIsValid = validation(stateValue.from);
    const toIsValid = validation(stateValue.to);
    const toAndFromEmpty = R.and(stateValue.from === '', stateValue.to === '');
    const toOrFromFull = R.or(
      stateValue.from !== '' && stateValue.to === '',
      stateValue.from === '' && stateValue.to !== '',
    );

    const toAndFromNotEmpty = R.or(toOrFromFull, !toAndFromEmpty);
    const toAndFromValid = R.and(fromIsValid, toIsValid) || toAndFromEmpty;

    if (isTimeInput && toAndFromEmpty) {
      onChange('');
    }

    if (toAndFromNotEmpty && toAndFromValid) {
      onChange(stateValue);
    }
  };

  inputComponent = () => {
    const {
      error,
      helperTextFrom,
      helperTextTo,
      inputClassName,
      inputComponent: Input,
      isInteger,
      labelFrom,
      labelTo,
      max,
      min,
      pattern,
      t,
      type,
      validation,
    } = this.props;

    const {
      value: { from, to },
    } = this.state;

    return (
      <Stack gap="large" width="100%">
        <FormField
          gap="base"
          label={t(labelFrom)}
          helperText={
            helperTextFrom ? t('common:DESCRIPTION_LEAVE_BLANK_FOR_0', { description: t(helperTextFrom) }) : ''
          }
        >
          <Input
            width={`${String(max).length}rem`}
            pattern={pattern}
            className={inputClassName}
            onChange={this.handleFromChange}
            onBlur={this.updateFiltersValue}
            value={from}
            type={type}
            onClose={() => this.handleCloseInput('from')}
            min={min}
            max={max}
            isInteger={isInteger}
            validation={validation}
            error={error}
          />
        </FormField>
        <FormField
          gap="base"
          label={t(labelTo)}
          helperText={
            helperTextTo ? t('common:DESCRIPTION_LEAVE_BLANK_FOR_NO_UPPER_LIMIT', { description: t(helperTextTo) }) : ''
          }
        >
          <Input
            width={`${String(max).length}rem`}
            pattern={pattern}
            className={inputClassName}
            onChange={this.handleToChange}
            onBlur={this.updateFiltersValue}
            value={to}
            type={type}
            onClose={() => this.handleCloseInput('to')}
            min={min}
            max={max}
            isInteger={isInteger}
            validation={validation}
            error={error}
          />
        </FormField>
      </Stack>
    );
  };

  render() {
    const { className, style } = this.props;

    return (
      <div className={`range-field ${className}`} style={style}>
        {this.inputComponent()}
      </div>
    );
  }
}

RangeInputV7Component.propTypes = {
  error: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  inputComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onChange: PropTypes.func.isRequired,
  validation: PropTypes.func,
  style: PropTypes.shape({}),
  pattern: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  isInteger: PropTypes.bool,
  isTimeInput: PropTypes.bool,
  helperTextFrom: PropTypes.string,
  helperTextTo: PropTypes.string,
  labelFrom: PropTypes.string,
  labelTo: PropTypes.string,
  t: PropTypes.func.isRequired,
  value: PropTypes.shape({ from: PropTypes.string, to: PropTypes.string }),
};

RangeInputV7Component.defaultProps = {
  error: false,
  className: '',
  inputClassName: 'input',
  inputComponent: defaultInputComponent,
  value: {
    from: '',
    to: '',
  },
  getValueFromChangeEvent: (event) => event.target.value,
  validation: defaultValidation,
  style: {},
  pattern: patterns.time,
  type: '',
  min: 0,
  max: 999999999,
  isInteger: false,
  isTimeInput: false,
  helperTextFrom: '',
  helperTextTo: '',
  labelFrom: '',
  labelTo: '',
};

export const RangeInputV7 = withTranslation()(RangeInputV7Component);
