import * as Yup from 'yup';
import * as R from 'ramda';

import { isEmpty } from 'app-utils';

import { VALIDATIONS } from './Validations';

export function combineValidations(fields) {
  const customSchema = () => {
    return Object.values(fields).reduce((fieldsAcc, field) => {
      if (field.hidden || field.disabled) return fieldsAcc;

      const fieldKeys = R.keys(field);
      const fieldType = VALIDATIONS[field.type]._type;

      const validations = fieldKeys.reduce((fieldValidationsAcc, key) => {
        if (!isEmpty(field[key]) && VALIDATIONS[field.type][key]) {
          if (key === 'matchRegEx') {
            field[key].forEach((matchRegEx) => {
              fieldValidationsAcc.push(VALIDATIONS[field.type][key](matchRegEx, field.label));
            });
          } else {
            fieldValidationsAcc.push(VALIDATIONS[field.type][key](field[key], field.label, field));
          }
        }
        return fieldValidationsAcc;
      }, []);

      const FieldSchema = validations.reduce(
        (fieldValidationsAcc, validation) => fieldValidationsAcc.concat(validation),
        fieldType,
      );

      return { ...fieldsAcc, [field.name]: FieldSchema };
    }, {});
  };

  return Yup.object().shape(customSchema());
}
