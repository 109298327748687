import * as React from 'react';

export const provideFieldsValueResolver = (valueModifier = (v) => v) => (OriginComponent) => (props) => {
  const BaseFormComponentWithResolveFieldsValue = () => {
    const resolveFieldsValue = (values) => {
      const { fields } = props;

      const fieldsValues = valueModifier(values, fields);

      Object.keys(fieldsValues).forEach((key) => {
        const fieldShouldResolveValue = Object.values(fields).find((f) => f.name === key && f.resolveValue);

        if (fieldShouldResolveValue) {
          Object.assign(fieldsValues, { [key]: fieldShouldResolveValue.resolveValue(fieldsValues[key]) });
        }
      });

      return fieldsValues;
    };

    return <OriginComponent {...props} resolveFieldsValue={resolveFieldsValue} />;
  };

  BaseFormComponentWithResolveFieldsValue.displayName = `resolveFieldsValue(${BaseFormComponentWithResolveFieldsValue()})`;

  return BaseFormComponentWithResolveFieldsValue();
};
