import axios from 'axios';
import * as R from 'ramda';

import { notify } from 'app-toasts';
import { authServerOverHubProxyUrl } from 'api_urls';
import { authServerErrorPrefix } from 'app-utils';

const RELOAD_EXPECTED_ERROR_CODES = [401, 403, 409];
const SILENT_EXPECTED_ERROR_CODES = [409];

export class HttpService {
  // TODO!: remove export of class instance,use singletons instead
  constructor({ baseURL, errorPrefix = '', withCredentials = false }) {
    this.errorPrefix = errorPrefix;
    this.setup();
    this.commonOptions = {
      baseURL,
      withCredentials,
    };
    this.silent = false;
  }

  makeRequest = async (requestProperties) => {
    const { silent } = requestProperties;

    try {
      const responseData = await this.axiosInstance(requestProperties);

      return responseData;
    } catch (error) {
      this.tryToNotifyError(error, silent);

      return Promise.reject(error);
    }
  };

  setup = () => {
    this.axiosInstance = axios.create();

    this.CancelToken = axios.CancelToken;

    /**
     * This interceptor responsible for disabling caching of requests on:
     * - Chrome 79+ ("Browser Back-Caching API");
     * - IE11 (nevermind, it's just Internet Explorer, live with this);
     */
    this.axiosInstance.interceptors.request.use(
      (config) => {
        const nextConfig = config;

        nextConfig.headers = {
          ...nextConfig.headers,
          'Cache-Control': 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0, private, max-age=0',
          Pragma: 'no-cache',
          Vary: 'X-Requested-With',
        };
        return nextConfig;
      },
      (error) => Promise.reject(error),
    );

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        const isReloadExpected = RELOAD_EXPECTED_ERROR_CODES.includes(R.path(['response', 'status'], error));

        if (isReloadExpected) {
          const { origin } = window.location;

          /* we need a global flag beacuse the reload can be
           * triggered by different instances of HttpService */
          if (!window.isReloading) {
            window.isReloading = true;
            window.location.assign(origin);

            /* we need to unset the flag for the cases where the
             * user cancels navigation in order to prevent loosing
             * the edition */
            setTimeout(() => {
              window.isReloading = false;
            }, 500);
          }
        }
        return Promise.reject(error);
      },
    );
  };

  createCancelableRequest = (url, requestMethod) => {
    let cancel;

    return (data, options, fullResponse = false) => {
      if (cancel) {
        cancel('Request was cancel');
      }

      return this.makeRequest({
        method: requestMethod,
        url,
        data,
        ...this.prepareOptions({
          ...options,
          cancelToken: new this.CancelToken(function executor(cancelLink) {
            cancel = cancelLink;
          }),
        }),
      })
        .then((response) => {
          cancel = null;
          return fullResponse ? response : this.resolveData(response);
        })
        .catch((err) => {
          const error = { ...err };

          if (axios.isCancel(err)) {
            error.isCanceled = true;
          } else {
            cancel = null;
          }

          throw error;
        });
    };
  };

  cancelablePostRequest = (url) => {
    return this.createCancelableRequest(url, 'POST');
  };

  cancelablePutRequest = (url) => {
    return this.createCancelableRequest(url, 'PUT');
  };

  cancelableGetRequest = (url) => {
    return this.createCancelableRequest(url, 'GET');
  };

  get = (url, options, fullResponse = false) => {
    return this.makeRequest({
      method: 'GET',
      url,
      ...this.prepareOptions(options),
    }).then((response) => (fullResponse ? response : this.resolveData(response)));
  };

  post = (url, data, options, fullResponse = false) => {
    return this.makeRequest({
      method: 'POST',
      url,
      data,
      ...this.prepareOptions(options),
    }).then((response) => (fullResponse ? response : this.resolveData(response)));
  };

  put = (url, data, options, fullResponse = false) => {
    return this.makeRequest({
      method: 'PUT',
      url,
      data,
      ...this.prepareOptions(options),
    }).then((response) => (fullResponse ? response : this.resolveData(response)));
  };

  patch = (url, data, options, fullResponse = false) => {
    return this.makeRequest({
      method: 'PATCH',
      url,
      data,
      ...this.prepareOptions(options),
    }).then((response) => (fullResponse ? response : this.resolveData(response)));
  };

  delete = (url, options, fullResponse = false) => {
    return this.makeRequest({
      method: 'DELETE',
      url,
      ...this.prepareOptions(options),
    }).then((response) => (fullResponse ? response : this.resolveData(response)));
  };

  prepareOptions = (options) => {
    const optionsHeaders = options?.headers || {};
    const commonHeaders = this.commonOptions.headers || {};
    const headers = { ...commonHeaders, ...optionsHeaders };
    const mergedOptions = R.merge(this.commonOptions, options);

    return { ...mergedOptions, headers };
  };

  resolveData = (response) => response.data;

  resolveErrorCode = (error) => R.path(['response', 'data', 'errorCode'], error);

  defaultAction = (response) => response;

  addResponseInterceptor = ({ onError, onSuccess }) => {
    const actionOnSuccess = onSuccess || this.defaultAction;
    this.axiosInstance.interceptors.response.use(actionOnSuccess, onError);
  };

  tryToNotifyError = (error, _silent = false) => {
    const errorCode = this.resolveErrorCode(error);
    const shouldBeSilent = SILENT_EXPECTED_ERROR_CODES.includes(errorCode);
    const silent = shouldBeSilent || _silent;
    const isCancelErr = axios.isCancel(error);

    const defaultMessage = 'common:SOMETHING_WENT_WRONG';
    const errorMessage = R.is(Number, errorCode) ? `${this.errorPrefix}${errorCode}` : defaultMessage;

    if (!silent && !isCancelErr) {
      notify(error.status || 'error', errorMessage);
    }

    if (errorMessage === defaultMessage && !isCancelErr) {
      console.error('An error has occurred:', error);
    }
  };
}

export const authServerHttpService = new HttpService({
  baseURL: authServerOverHubProxyUrl,
  errorPrefix: authServerErrorPrefix,
  withCredentials: true,
});
