import React from 'react';
import * as R from 'ramda';

import { withBlobStorage, withFormFilesSize } from 'app-base-form/hocs';

import { BaseFormFieldUploadComponent } from './BaseFormFieldUploadComponent';

const EnhancedBaseFormFieldUpload = R.compose(withBlobStorage, withFormFilesSize)(BaseFormFieldUploadComponent);

export const BaseFormFieldUploadContainer = (props) => <EnhancedBaseFormFieldUpload {...props} />;
