import styled from 'styled-components';
import { FormField } from '@blueprism/ui-core';

export const TimePickerContainer = styled(FormField)`
  margin-top: 0.75rem;

  div[class*='FormField'] {
    padding-left: unset;
  }

  div[class*='FieldLabel'] {
    display: block;
    position: relative;
  }
`;

export const SeparatorContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1.5rem;
`;
