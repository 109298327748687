import React, { useEffect } from 'react';
import cn from 'classnames';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';

export const HyperLinkControls = inject('store')(
  observer(
    ({
      config: {
        link: { icon: iconLink },
        unlink: { icon: iconUnlink },
      },
      currentState,
      expanded,
      onChange,
      showLinkControls,
      store,
    }) => {
      const { setInitialValues, setOnChange, setShowModal } = store;
      const { link, selectionText } = currentState;

      useEffect(() => {
        setOnChange(onChange);
      }, []);

      useEffect(() => {
        setInitialValues(currentState);
      }, [currentState]);

      if (!showLinkControls) return null;

      return (
        <div className="buttonWrapper">
          <button
            className={cn('button', 'container', { active: expanded })}
            type="button"
            onMouseDown={() => setShowModal(true)}
          >
            <img className="draft-toolbar-icon" alt="icon" src={iconLink} />
          </button>
          <button
            className={cn('button', 'container')}
            type="button"
            disabled={!(selectionText && link?.target)}
            onMouseDown={() => onChange('unlink')}
          >
            <img className="draft-toolbar-icon" alt="icon" src={iconUnlink} />
          </button>
        </div>
      );
    },
  ),
);

HyperLinkControls.propTypes = {
  store: PropTypes.shape({
    setInitialValue: PropTypes.func,
    showLinkControls: PropTypes.bool.isRequired,
    setOnChange: PropTypes.func,
    setShowModal: PropTypes.func,
  }),
  expanded: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  currentState: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  config: PropTypes.shape({
    link: PropTypes.shape({
      icon: PropTypes.string,
    }),
    unlink: PropTypes.shape({
      icon: PropTypes.string,
    }),
  }).isRequired,
};
