import * as R from 'ramda';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';

import { AddEditLinkModal } from './AddEditLinkModal';

export const AddEditLinkModalContainer = R.compose(
  inject(({ hyperLinkStore }) => ({
    store: hyperLinkStore,
  })),
  observer,
)(AddEditLinkModal);
