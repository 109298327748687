import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '@blueprism/ui-core';
import { useFormikContext } from 'formik';

export const BaseFormCancelButton = ({ cancelFormHandler, cancelLabel, className }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  return (
    <Button className={className} onClick={() => cancelFormHandler(formik)}>
      {t(cancelLabel)}
    </Button>
  );
};

BaseFormCancelButton.propTypes = {
  cancelLabel: PropTypes.string,
  className: PropTypes.string,
  cancelFormHandler: PropTypes.func,
};

BaseFormCancelButton.defaultProps = {
  cancelLabel: 'common:ANSWER__CANCEL',
  className: '',
  cancelFormHandler: () => {},
};
