export const MODES = {
  ADD: 'ADD_MODE',
  EDIT: 'EDIT_MODE',
  VIEW: 'VIEW_MODE',
  VIEW_PAGE: 'VIEW_PAGE_MODE',
  VIEW_SECTION: 'VIEW_SECTION_MODE',
  DELETE: 'DELETE_MODE',
  IMPORT: 'IMPORT_MODE',
  CLONE: 'CLONE_MODE',
};

export const URLS_MODES = {
  ADD: 'add',
  EDIT: 'edit',
  VIEW: 'view',
  CREATE: 'create',
};
