import React from 'react';

export const InteractIcon = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 45.84 39.37">
      <path
        className="st0 icon-body"
        d="M0.533,3.387c0-1.6,1.3-2.9,2.9-2.9h39c1.6,0,2.9,1.3,2.9,2.9v22.9c0,1.6-1.3,2.9-2.9,2.9h-39 c-1.6,0-2.9-1.3-2.9-2.9V3.387z"
      />
      <line className="st0 icon-lines" x1="9.833" y1="38.687" x2="36.133" y2="38.687" />
      <line className="st0 icon-lines" x1="26.333" y1="11.087" x2="37.333" y2="11.087" />
      <line className="st0 icon-lines" x1="26.333" y1="15.587" x2="37.333" y2="15.587" />
      <line className="st0 icon-lines" x1="26.333" y1="19.987" x2="37.333" y2="19.987" />
      <line className="st0 icon-lines" x1="23.033" y1="38.687" x2="23.033" y2="29.187" />
      <path className="st0 icon-lines" d="M20.433,23.487v-2.5c0-3.6-2.9-6.5-6.5-6.5s-6.5,2.9-6.5,6.5v2.5H20.433z" />
      <path
        className="st0 icon-lines"
        d="M13.933,5.587c-2.4,0-4.3,1.9-4.3,4.3s1.9,4.3,4.3,4.3s4.3-1.9,4.3-4.3S16.333,5.587,13.933,5.587 M13.933,5.587c-2.4,0-4.3,1.9-4.3,4.3s1.9,4.3,4.3,4.3s4.3-1.9,4.3-4.3S16.333,5.587,13.933,5.587 M13.933,5.587 c-2.4,0-4.3,1.9-4.3,4.3s1.9,4.3,4.3,4.3s4.3-1.9,4.3-4.3S16.333,5.587,13.933,5.587"
      />
    </svg>
  );
};
