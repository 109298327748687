/**
 * NuGet package info localizer
 * @param {string} plugin
 * @param {string} language
 *
 * We can use Object.fromEntries here instead of reducing but IE ¯\_(ツ)_/¯
 */

import { APP_LANGUAGES } from '../constants/index';

export const getLocalizedPluginInfo = (pluginName, language) => {
  if (pluginName?.length === 0) {
    return;
  }

  const splittedLanguages = pluginName.split('|');

  if (splittedLanguages.length === 1) {
    return pluginName;
  }

  const languagePairs = splittedLanguages.reduce((acc, current) => {
    const nextAcc = acc;
    const [lang, value] = current.split('__');
    nextAcc[lang] = value;

    return nextAcc;
  }, {});

  return languagePairs[language];
};

export const momentMeridiemIsPmSettings = {
  meridiem(hour, minute, isLower) {
    if (hour > 11) {
      return isLower ? 'pm' : 'PM';
    }
    return isLower ? 'am' : 'AM';
  },
  isPM(input) {
    return `${input}`.toLowerCase().charAt(0) === 'p';
  },
};

export const getLanguageById = (searchId) => APP_LANGUAGES.find(({ id }) => id === searchId);
