import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import uploadImage from '../assets/upload.svg';
import { StyledSVG } from './DragDropPreview.styled';

const DragDropPreview = ({ darkenBackground, pasteMode, showDnDHelperText }) => {
  const { t } = useTranslation();

  return (
    <div className={cn('drop-placeholder-container', { darken: darkenBackground })}>
      <div className="drop-placeholder-wrapper">
        {showDnDHelperText && (
          <>
            <StyledSVG src={uploadImage} />
            <span className="drop-placeholder-text">
              {t(pasteMode ? 'common:DROP_OR_PASTE_THE_IMAGE' : 'common:DROP_THE_IMAGE')}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

DragDropPreview.propTypes = {
  pasteMode: PropTypes.bool.isRequired,
  showDnDHelperText: PropTypes.bool,
  darkenBackground: PropTypes.bool,
};

DragDropPreview.defaultProps = {
  showDnDHelperText: true,
  darkenBackground: false,
};

export default DragDropPreview;
