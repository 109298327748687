import moment from 'moment';
import * as R from 'ramda';

import { SIZE_MB, isEmpty } from 'app-utils';

const timeFormat = 'hh:mm:ss';

function formatTime(time) {
  return moment(time, timeFormat);
}

export function contains({ ruleValue, value }) {
  return ruleValue && ruleValue.includes(value);
}

export function doesNotContain(props) {
  return !contains(props);
}

export function equals({ ruleValue, value }) {
  if (R.isNil(value) && R.isNil(ruleValue)) return true;

  return value === ruleValue;
}

export function doesNotEqual(props) {
  return !equals(props);
}

export function lessThen({ ruleValue, value }) {
  if (R.isNil(ruleValue)) return false;

  return ruleValue < value;
}

export function lessThenOrEqual({ ruleValue, value }) {
  if (R.isNil(ruleValue)) return false;

  return ruleValue <= value;
}

export function moreThen({ ruleValue, value }) {
  return ruleValue > value;
}

export function moreThenOrEqual({ ruleValue, value }) {
  if (R.isNil(ruleValue)) return false;

  return ruleValue >= value;
}

export function isAfterTime({ ruleValue, value }) {
  return formatTime(ruleValue).isAfter(formatTime(value));
}

export function isBeforeTime({ ruleValue, value }) {
  return formatTime(ruleValue).isBefore(formatTime(value));
}

export function uploadTypeIs({ payload, ruleValue, value: { value } }) {
  const ruleValueName = ruleValue.name || payload.name;
  const type = ruleValueName.split('.').pop();

  return value === payload.type || value === type;
}

export function uploadTypeIsNot(props) {
  return !uploadTypeIs(props);
}

export function fileSizeGreaterThen({ ruleValue, value }) {
  const valueInMb = value * SIZE_MB;
  return ruleValue.size > valueInMb;
}

export function fileSizeLessThen(props) {
  return !fileSizeGreaterThen(props);
}

// TODO: WIP! rename value and ruleValue to the ifValue and fieldValue!!!!!
export function equalsByObjValue({ ruleValue: fieldValue, value: ifValue }) {
  return !isEmpty(fieldValue) && R.eqProps('value', ifValue, fieldValue);
}

export function doesNotEqualByValue(props) {
  return !equalsByObjValue(props);
}

export function multiContains({ ruleValue, value }) {
  return R.compose(R.equals(0), R.length, R.differenceWith(R.eqProps('value')))(value, ruleValue || []);
}

export function multiDoesNotContain(props) {
  return !multiContains(props);
}

export function quantityIs({ ruleValue: fieldValue, value: ifValue }) {
  const valueLength = isEmpty(fieldValue) ? 0 : R.length(fieldValue);

  return valueLength === ifValue;
}

export function quantityIsNot(props) {
  return !quantityIs(props);
}

export function quantityIsLessThen({ ruleValue, value }) {
  if (isEmpty(ruleValue)) return value > 0;

  return R.length(ruleValue) < value;
}

export function quantityIsMoreThen({ ruleValue, value }) {
  return R.length(ruleValue) > value;
}

export function quantityIsLessThenOrEqual({ ruleValue: fieldValue, value: ifValue }) {
  const valueLength = isEmpty(fieldValue) ? 0 : R.length(fieldValue);

  return valueLength <= ifValue;
}

export function quantityIsMoreThenOrEqual({ ruleValue: fieldValue, value: ifValue }) {
  const valueLength = isEmpty(fieldValue) ? 0 : R.length(fieldValue);

  return valueLength >= ifValue;
}

// TODO: WIP! rename value and ruleValue to the ifValue and fieldValue!!!!!
export function equalsByValue({ ruleValue: fieldValue, value: ifValue }) {
  return ifValue.value === fieldValue;
}

// TODO: WIP! rename value and ruleValue to the ifValue and fieldValue!!!!!
export function dontEqualsByValue(props) {
  return !equalsByValue(props);
}

export function equalsByValueArray({ ruleValue: fieldValue, value: ifValue }) {
  return ifValue.value === R.path(['0'], fieldValue);
}

export function dontEqualsByValueArray(props) {
  return !equalsByValueArray(props);
}

export function flatMultiContains({ ruleValue: fieldValue, value: ifValue }) {
  const ifValues = ifValue.map(({ value }) => value);

  return R.compose(R.equals(0), R.length, R.difference)(ifValues, fieldValue || []);
}

export function doesNotFlatMultiContain(props) {
  return !flatMultiContains(props);
}

export function equalDates({ ruleValue, value }) {
  return moment(value).isSame(ruleValue, 'date');
}

export function doesNotEqualDates(props) {
  return !equalDates(props);
}

export function dateIsAfter({ ruleValue, value }) {
  return moment(ruleValue).isAfter(value, 'date');
}

export function dateIsBefore({ ruleValue, value }) {
  return moment(ruleValue).isBefore(value, 'date');
}

export function dateIsInMonth({ ruleValue, value }) {
  return moment(ruleValue).isSame(value, 'month');
}

export function dateIsNotInMonth(props) {
  return !dateIsInMonth(props);
}

export function dateIsInYear({ ruleValue, value }) {
  return moment(ruleValue).isSame(value, 'year');
}

export function dateIsNotInYear(props) {
  return !dateIsInYear(props);
}

export function compareValueWithObjectValue({ value, ruleValue = {} }) {
  return String(value) === String(ruleValue.value);
}

export function equalsCheckbox({ ruleValue: fieldValue, value: ifValue }) {
  return fieldValue === ifValue.value;
}
