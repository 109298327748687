import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Prompt, useHistory } from 'react-router-dom';
import { Box, Text, Row, Button } from '@blueprism/ui-core';

import { TranslationTemplate } from 'app-i18n/TranslationTemplate';

import { CustomModal } from '../../CustomModal';

export const LeavingOrReloadRouteModal = ({ isNavigationBlocked, isSubmitting, question, subQuestion }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const { push } = useHistory();

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && isNavigationBlocked && !isSubmitting) {
      setModalVisible(true);
      setLastLocation(nextLocation);

      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      push(lastLocation);
    }
  }, [confirmedNavigation, lastLocation]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const unloadEvent = event;

      unloadEvent.preventDefault();
      unloadEvent.returnValue = '';
    };

    if (isNavigationBlocked) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isNavigationBlocked]);

  return (
    <>
      <Prompt when={isNavigationBlocked} message={handleBlockedNavigation} />
      <CustomModal
        size="small"
        visible={modalVisible}
        translatableTitle="ARE_YOU_SURE"
        onClose={closeModal}
        padding="large"
        dataTestid="leaving-or-reload-route-modal"
        cancelIsVisible
        footerContent={() => (
          <Row justify="between">
            <Box>
              <Button onClick={closeModal} data-testid="no-button">
                <TranslationTemplate value="NO" />
              </Button>
            </Box>
            <Box>
              <Button onClick={handleConfirmNavigationClick} active data-testid="yes-button">
                <TranslationTemplate value="YES" />
              </Button>
            </Box>
          </Row>
        )}
      >
        <Box padding="0.5rem">
          <Text type="h6">
            <TranslationTemplate value={question} />
          </Text>
          {subQuestion && (
            <Text type="h6">
              <TranslationTemplate value={subQuestion} />
            </Text>
          )}
        </Box>
      </CustomModal>
    </>
  );
};

LeavingOrReloadRouteModal.propTypes = {
  isNavigationBlocked: PropTypes.bool.isRequired,
  question: PropTypes.string,
  subQuestion: PropTypes.string,
};

LeavingOrReloadRouteModal.defaultProps = {
  question: 'ARE_YOU_SURE_YOU_WANT_TO_DECLINE_THE_CHANGES',
  subQuestion: '',
};
