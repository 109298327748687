export const STATE_IS_VALUE = {
  OPTIONAL: 0,
  MANDATORY: 1,
  READ_ONLY: 2,
  HIDDEN: 3,
};

const OPTION_HIDDEN = { value: STATE_IS_VALUE.HIDDEN, translatableLabel: 'RULES_STATE_HIDDEN' };
const OPTION_OPTIONAL = { value: STATE_IS_VALUE.OPTIONAL, translatableLabel: 'RULES_STATE_OPTIONAL' };
const OPTION_READ_ONLY = { value: STATE_IS_VALUE.READ_ONLY, translatableLabel: 'RULES_STATE_READ_ONLY' };
const OPTION_MANDATORY = { value: STATE_IS_VALUE.MANDATORY, translatableLabel: 'RULES_STATE_MANDATORY' };
const OPTION_VISIBLE = { value: STATE_IS_VALUE.OPTIONAL, translatableLabel: 'RULES_STATE_VISIBLE' };

export const STATE_IS_OPTIONS = [OPTION_OPTIONAL, OPTION_MANDATORY, OPTION_READ_ONLY, OPTION_HIDDEN];

export const VISIBILITY_STATE_OPTIONS = [OPTION_HIDDEN, OPTION_VISIBLE];

export const STATE_IS_PROPERTY = {
  [STATE_IS_VALUE.OPTIONAL]: { required: false, disabled: false, hidden: false },
  [STATE_IS_VALUE.MANDATORY]: { required: true, disabled: false, hidden: false },
  [STATE_IS_VALUE.READ_ONLY]: { required: false, disabled: true, hidden: false },
  [STATE_IS_VALUE.HIDDEN]: { required: false, disabled: true, hidden: true },
};
