import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseForm } from 'app-base-form';

import { forgotPasswordFormConfig } from './forgotPasswordFormConfig';

export const ForgotPassword = ({ authStore }) => {
  const { t } = useTranslation();

  const forgotPasswordHandler = (credentials, formActions) => {
    authStore.forgotPassword(credentials, formActions);
  };

  return (
    <BaseForm
      preventUnmountIfFormIsDirty={false}
      {...forgotPasswordFormConfig(t)}
      submitHandler={forgotPasswordHandler}
    />
  );
};
