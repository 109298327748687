import { useEffect } from 'react';

export function useScrollWhenPageChanged(page, anchorElement, formContainerElement) {
  useEffect(() => {
    setTimeout(() => {
      if (!anchorElement || !formContainerElement) return;
      formContainerElement.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 100);
  }, [page]);
}
