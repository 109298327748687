import moment from 'moment';

export function dateFormatInterpolator(value, format) {
  if (!format) {
    return value;
  }

  if (value instanceof Date) return moment(value).format(format);

  return value;
}
