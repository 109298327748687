import { action, observable } from 'mobx';

import { AuthServerUrlModel } from 'models';

import { URLS } from '../constants';

export class ConfigurationStore {
  @observable appUrls = {};

  @observable isAuthenticated = null;

  @observable pending = false;

  constructor(rootStore, httpService) {
    this.rootStore = rootStore;
    this.httpService = httpService;
  }

  setupUser = async () => {
    this.setPending(true);

    const userConfig = await this.getUserConfiguration();

    await this.configUser(userConfig);

    return userConfig;
  };

  @action
  getUserConfiguration = async () => {
    const userConfig = await this.httpService.get(URLS.userConfiguration);

    return userConfig;
  };

  @action
  configUser = (userConfig = {}) => {
    const { appUrls, isAuthenticated } = userConfig;

    [this.appUrls, this.isAuthenticated] = [new AuthServerUrlModel(appUrls), Boolean(isAuthenticated)];
  };

  @action
  setPending = (status) => {
    this.pending = status;
  };

  @action
  changeIsAuthenticated = (status) => {
    this.isAuthenticated = status;
  };
}
