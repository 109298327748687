import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Cluster } from '@blueprism/ui-core';

import { Tooltip } from 'app-tooltip';

import { InteractIcon, HubIcon, AppButton } from './components';

export const Welcome = ({ appUrls: { connect, isInteractAvailable, selfServe } }) => {
  const { t } = useTranslation();

  function enterApplicationHandler(link, isClickAllowed = true) {
    if (isClickAllowed) window.location.href = link;
  }

  function handleConnectClick() {
    enterApplicationHandler(connect);
  }

  function handleInteractClick() {
    enterApplicationHandler(selfServe, isInteractAvailable);
  }

  return (
    <>
      <Cluster justify="center" width="100%" gap="medium" padding="small">
        {connect && <AppButton icon={<HubIcon />} onClick={handleConnectClick} href={connect} label="Hub" />}
        {selfServe && (
          <AppButton
            icon={<InteractIcon />}
            onClick={handleInteractClick}
            href={selfServe}
            tooltip={!isInteractAvailable ? t('LICENSE_HAS_EXPIRED') : undefined}
            label="Interact"
            disabled={!isInteractAvailable}
          />
        )}
      </Cluster>
      <Tooltip />
    </>
  );
};

Welcome.propTypes = {
  appUrls: PropTypes.shape({
    connect: PropTypes.string,
    selfServe: PropTypes.string,
    isInteractAvailable: PropTypes.bool,
  }).isRequired,
};
