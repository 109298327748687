import React, { useEffect, useState } from 'react';

import { UPLOAD_STORAGES } from '../constants';

export const BlobStorageContext = React.createContext();

const defaultStorage = UPLOAD_STORAGES.AZURE;

export const provideBlobStorage = (OriginComponent) => ({ children, httpServices, uploadStrategy, ...rest }) => {
  const ComponentWithProvidedBlobStorage = () => {
    const [blobStorage, setBlobStorage] = useState(defaultStorage);

    useEffect(() => {
      if (!uploadStrategy) return;

      const uploadStorage = UPLOAD_STORAGES[uploadStrategy];
      uploadStorage.setHttpService(httpServices);

      setBlobStorage(uploadStorage);

      return blobStorage.resetStore;
    }, [uploadStrategy, blobStorage]);

    return (
      <BlobStorageContext.Provider value={{ blobStorage }}>
        <OriginComponent {...rest} uploadStrategy={uploadStrategy} />
      </BlobStorageContext.Provider>
    );
  };

  ComponentWithProvidedBlobStorage.displayName = `provideUploadStorage (${ComponentWithProvidedBlobStorage()})`;

  return ComponentWithProvidedBlobStorage();
};
