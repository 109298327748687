import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Avatar, Box } from '@blueprism/ui-core';

export const UserAvatar = ({ imageUrl, size }) => {
  const { height, width } = R.cond([
    [R.equals('extra-large'), () => ({ width: '50px', height: '50px' })],
    [R.equals('large'), () => ({ width: '41px', height: '41px' })],
    [R.equals('medium'), () => ({ width: '35px', height: '35px' })],
    [R.equals('small'), () => ({ width: '20px', height: '20px' })],
    [R.T, () => ''],
  ])(size);

  return (
    <Box width={width} height={height}>
      <Avatar image={imageUrl} />
    </Box>
  );
};

UserAvatar.propTypes = {
  imageUrl: PropTypes.string,
  size: PropTypes.oneOf(['extra-large', 'large', 'medium', 'small']),
};

UserAvatar.defaultProps = {
  imageUrl: null,
  size: 'medium',
};
