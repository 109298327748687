import moment from 'moment';

import 'moment/locale/ru';
import 'moment/locale/zh-cn';
import 'moment/locale/ja';
import 'moment/locale/pt';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/en-gb';

import { momentMeridiemIsPmSettings } from './utils';

export const langLocaleMap = {
  en: 'en-gb',
  zh: 'zh-cn',
  ja: 'ja',
  de: 'de',
  fr: 'fr',
  es: 'es',
};

export const setDefaultLocale = (lang) => moment.updateLocale(langLocaleMap[lang], momentMeridiemIsPmSettings);
