import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import * as R from 'ramda';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Lightbulb } from '@blueprism/ui-icons';

import { Datalist, MultipleSelectionDatalist } from 'app-datalist';
import { ToggleTip } from 'app-toggle-tip';
import { useDeepCompareMemoize } from 'app-utils';

import { StyledDescription, StyledFormField } from './components';

export const BaseFormFieldSelect = (props) => {
  const {
    canCreateItem,
    dependsOn,
    description,
    detailsText,
    disabled,
    disableInput,
    formDisabled,
    gap,
    helperTextLoadedOptionEmpty,
    helperTextYouSelectedMaxCountValues,
    icon,
    isMulti,
    label,
    loadOptions,
    maxValues,
    onBlur,
    onChange,
    options,
    required,
    summaryText,
    ...rest
  } = props;

  const [optionsLength, setOptionsLength] = useState(options ? options.length : 0);

  const isOptionsListEmpty = optionsLength === 0;

  const { isSubmitting, values } = useFormikContext();
  const [field, meta, helpers] = useField(props);
  const { name, value } = field;
  const { error, touched } = meta;
  const { setTouched, setValue } = helpers;

  const { t } = useTranslation();

  const changeFieldHandler = useCallback(
    async (val) => {
      if (!touched) setTouched(true);

      onChange(val);
      await setValue(val);
    },
    [onChange, setValue, setTouched, touched],
  );

  const blurFieldHandler = useCallback(
    (val) => {
      onBlur(val);
    },
    [onBlur],
  );

  const loadSelectOptions = useMemo(() => {
    return (searchQuery) => loadOptions(searchQuery, value, values);
  }, useDeepCompareMemoize([loadOptions, value, R.pick(dependsOn, values)]));

  const SelectComponent = isMulti ? MultipleSelectionDatalist : Datalist;
  const computedLabel = label ? `${label}${required ? '\u00A0*' : ''}` : '';
  const displayError = touched && !!error;
  const isDisabled = isSubmitting || formDisabled || disabled;

  const isSelectedMaxValues = isMulti && maxValues && value?.length === maxValues;

  const getHelperTextForSelect = () => {
    if (isSelectedMaxValues) {
      return t(helperTextYouSelectedMaxCountValues);
    }

    if (!canCreateItem && loadOptions && isOptionsListEmpty) {
      return t(helperTextLoadedOptionEmpty);
    }

    if (loadOptions && canCreateItem) {
      return t('TYPE_TO_FIND_AN_EXISTING_CATEGORY_OR_CREATE_A_NEW');
    }

    if (loadOptions) {
      return t('common:TYPE_TO_FIND_ANOTHER_ONE');
    }

    return description;
  };

  return useMemo(() => {
    return (
      <StyledFormField
        label={computedLabel}
        htmlFor={name}
        icon={icon}
        helperText={<StyledDescription type="caption">{getHelperTextForSelect()}</StyledDescription>}
        errorText={error}
        error={displayError}
        gap={gap}
        className={isMulti && 'multiple-selection-datalist'}
      >
        <>
          {detailsText && summaryText && (
            <ToggleTip detailsText={detailsText} summaryText={summaryText} icon={<Lightbulb size={16} />} />
          )}
          <SelectComponent
            {...rest}
            setOptionsLength={setOptionsLength}
            id={name}
            name={name}
            options={options}
            value={value}
            required={required}
            disableInput={disableInput || isSelectedMaxValues}
            disabled={isDisabled}
            onSelect={changeFieldHandler}
            onBlur={blurFieldHandler}
            loadOptions={loadOptions && loadSelectOptions}
          />
        </>
      </StyledFormField>
    );
  }, [
    SelectComponent,
    blurFieldHandler,
    changeFieldHandler,
    description,
    isDisabled,
    gap,
    icon,
    label,
    loadSelectOptions,
    name,
    options,
    required,
    value,
  ]);
};

export const BaseFormFieldSelectPropTypes = {
  label: PropTypes.string,
  dependsOn: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.string),
  description: PropTypes.string,
  detailsText: PropTypes.string,
  summaryText: PropTypes.string,
  canCreateItem: PropTypes.bool,
  helperTextLoadedOptionEmpty: PropTypes.string,
  helperTextYouSelectedMaxCountValues: PropTypes.string,
  loadOptions: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  options: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  isMulti: PropTypes.bool,
  formDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  icon: PropTypes.node,
  gap: PropTypes.string,
};

export const BaseFormFieldSelectShape = PropTypes.shape(BaseFormFieldSelectPropTypes);

BaseFormFieldSelect.propTypes = BaseFormFieldSelectPropTypes;

BaseFormFieldSelect.defaultProps = {
  label: '',
  description: '',
  detailsText: '',
  summaryText: '',
  dependsOn: [],
  canCreateItem: false,
  helperTextLoadedOptionEmpty: 'common:NO_DATA_AVAILABLE',
  helperTextYouSelectedMaxCountValues: 'common:YOUVE_REACHED_THE_MAX_OPTIONS_VALUE',
  loadOptions: null,
  isMulti: false,
  required: false,
  formDisabled: false,
  disabled: false,
  disableInput: false,
  onChange: () => {},
  onBlur: () => {},
  icon: undefined,
  gap: 'xs',
  maxValues: null,
};
