import React, { useState } from 'react';
import * as R from 'ramda';

import { convertUnits } from 'app-utils';

export const FormFilesSizeContext = React.createContext();

export const provideFormFilesSize = (OriginComponent) => ({ children, maxFilesSize, ...rest }) => {
  const ComponentWithProvidedFormFilesSize = () => {
    const [formFilesSize, setFormFilesSize] = useState(null);

    if (maxFilesSize) {
      const _totalSizeInBytes = Object.values(rest.formik.values).reduce((total, value) => {
        if (value && R.is(Number, value.size)) {
          return total + value.size;
        }
        return total;
      }, 0);

      const convertedMaxInBytes = convertUnits({
        value: maxFilesSize.value,
        from: maxFilesSize.unit || 'MB',
        to: 'Bytes',
      });

      if (
        R.isNil(formFilesSize) ||
        formFilesSize.total !== _totalSizeInBytes ||
        formFilesSize.max !== convertedMaxInBytes
      ) {
        const free = convertedMaxInBytes - _totalSizeInBytes;

        setFormFilesSize({
          free,
          unit: 'Bytes',
          max: convertedMaxInBytes,
          total: _totalSizeInBytes,
          maxFilesSize: { ...maxFilesSize, unit: maxFilesSize.unit || 'MB' },
          freeInMB: convertUnits({ value: free, from: 'Bytes', to: 'MB' }).toFixed(2),
        });
      }

      return (
        <FormFilesSizeContext.Provider value={{ formFilesSize }}>
          <OriginComponent {...rest} />
        </FormFilesSizeContext.Provider>
      );
    }

    return <OriginComponent {...rest} />;
  };

  ComponentWithProvidedFormFilesSize.displayName = `provideFormFilesSize (${ComponentWithProvidedFormFilesSize()})`;

  return ComponentWithProvidedFormFilesSize();
};
