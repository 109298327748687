import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import { Translation } from 'react-i18next';

import { isIterable } from 'app-utils';

export const TranslationTemplate = ({ value }) => {
  const renderText = (t) => {
    if (!isIterable(value)) {
      return t(value);
    }

    const [key, params = {}, translatableParams = {}] = value;
    const translatedParams = Object.keys(translatableParams).reduce(
      (o, k) => ({ ...o, [k]: t(translatableParams[k]) }),
      {},
    );
    return t(key, { ...params, ...translatedParams });
  };

  return <Translation>{(t) => renderText(t)}</Translation>;
};

TranslationTemplate.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
    MobxPropTypes.observableArrayOf(PropTypes.shape({})),
  ]).isRequired,
};
