import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { useFormikContext } from 'formik';

import { MODAL_MODES } from 'app-modal';
import {
  BaseFormSubmitButton as SubmitButton,
  BaseFormButton as Button,
  BaseFormCancelButton as ButtonCancel,
} from 'app-base-form';
import { isEmpty } from 'app-utils';

export function WizardButtonsGroup(props) {
  const {
    cancelFormHandler,
    changePageHandler,
    disableCheckDirty,
    iconLeft,
    iconRight,
    mode,
    nextButtonLabel,
    pageList,
    prevButtonLabel,
    submitButtonClassName,
    submitButtonLabel,
  } = props;
  const { errors } = useFormikContext();
  let { activePage } = props;

  activePage = String(activePage);

  const isFirstPage = activePage === String(pageList[0].id);
  const isLastPage = activePage === String(pageList[pageList.length - 1].id);
  const currentPage = pageList.find((item) => String(item.id) === activePage);
  const isPageNotEmpty = currentPage && !isEmpty(currentPage.fieldsForNext);
  const isPageFieldsValid = isPageNotEmpty && currentPage.fieldsForNext.some((rule) => errors[rule]);

  const nextDisabledState = R.cond([
    [R.equals(MODAL_MODES.ADD), () => isPageFieldsValid],
    [R.equals(MODAL_MODES.EDIT), () => isPageFieldsValid],
    [R.equals(MODAL_MODES.VIEW), () => false],
  ])(mode);

  const setNextPage = () => changePageHandler('next');
  const setPrevPage = () => changePageHandler('prev', false);

  return (
    <div className="button-group">
      {isLastPage && (
        <SubmitButton
          className={submitButtonClassName}
          label={submitButtonLabel}
          mode={mode}
          disableCheckDirty={disableCheckDirty}
        />
      )}
      {!isLastPage && (
        <Button
          align="right"
          disabled={nextDisabledState}
          buttonHandler={setNextPage}
          buttonLabel={nextButtonLabel}
          iconRight={iconRight}
        />
      )}
      {!isFirstPage && (
        <Button align="left" buttonHandler={setPrevPage} buttonLabel={prevButtonLabel} iconLeft={iconLeft} />
      )}
      {cancelFormHandler && <ButtonCancel cancelFormHandler={cancelFormHandler} />}
    </div>
  );
}

WizardButtonsGroup.propTypes = {
  activePage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cancelFormHandler: PropTypes.func,
  changePageHandler: PropTypes.func.isRequired,
  disableCheckDirty: PropTypes.bool,
  mode: PropTypes.string,
  nextButtonLabel: PropTypes.string,
  pageList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  prevButtonLabel: PropTypes.string,
  submitButtonClassName: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
};

WizardButtonsGroup.defaultProps = {
  activePage: null,
  submitButtonLabel: '',
  nextButtonLabel: '',
  prevButtonLabel: '',
  mode: null,
  cancelFormHandler: null,
  disableCheckDirty: false,
  submitButtonClassName: null,
  iconLeft: undefined,
  iconRight: undefined,
};
