import { observable, action, computed } from 'mobx';

import { MODAL_MODES } from './constants';
import { waitForAnswer } from './components/AreYouSureModal';

export class BaseModalStore {
  @observable isModalOpen = false;

  @observable modalMode = null;

  @observable isModalPending = false;

  @observable modalPayload = {};

  @action
  toggleModalWithMode = ({ mode = null, payload }) => {
    this.modalMode = mode;
    this.toggleModal();
    this.modalPayload = payload;
  };

  @action
  toggleModal = () => {
    this.isModalOpen = !this.isModalOpen;
  };

  @action
  setModalPayload = (payload) => {
    this.modalPayload = payload;
  };

  @action
  setModalPending = (state) => {
    this.isModalPending = state;
  };

  @action
  setModalMode = (mode) => {
    this.modalMode = mode;
  };

  @computed
  get payload() {
    return this.modalPayload;
  }

  @action
  clearModalStore = () => {
    this.modalMode = null;
    this.isModalOpen = false;
    this.isModalPending = false;

    setTimeout(() => {
      this.modalPayload = {};
    }, 500);
  };

  askForDeclineChanges = () => {
    if (!this.modalMode || this.modalMode === MODAL_MODES.VIEW) return true;

    const content = {
      question: ['ARE_YOU_SURE_YOU_WANT_TO_DECLINE_THE_CHANGES'],
      title: 'ARE_YOU_SURE',
    };

    return waitForAnswer({ content });
  };
}
