import styled, { css } from 'styled-components';

export const InlineBadge = styled.span`
  ${(props) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2rem;
    height: 2rem;
    margin-right: 10px;
    padding: 0 0.375rem;
    white-space: nowrap;
    border-radius: 1.5rem;
    transition: all 0.2s ease-in-out;
    color: ${props.theme.color.paper};
    background-color: ${props.theme.color.action};
    font-size: 0.75rem;
    font-weight: 900;

    ${props.relative &&
    css`
      position: relative;
      top: ${props.top};
      bottom: ${props.bottom};
      right: ${props.right};
      left: ${props.left};
    `}
  `}
`;
