import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Formik, Form } from 'formik';
import * as R from 'ramda';

import { isDebugMode } from 'app-debug';
import { BaseFormDebug } from 'app-base-form';
import { LeavingOrReloadRouteModal } from 'app-modal/components/LeavingOrReloadRouteModal';

import { renderBaseFormBody } from './renderersBaseForm';
import { renderBaseFields } from './renderBaseFields';
import { renderBaseControlsButtons } from './renderBaseControlsButtons';

export const BaseFormComponent = (props) => {
  const {
    className,
    disabled,
    elementsChanges,
    enableReinitialize,
    fields,
    getValidationSchema,
    height,
    initialRulesApply,
    initialTouched,
    initialValues,
    innerRef,
    isPending,
    onKeyDown,
    onSubmit,
    paddingBottom,
    preventUnmountIfFormIsDirty,
    render,
    renderControlsButtons,
    resolveFieldsValue,
    rules,
    submitHandler,
    title,
    validateOnMount,
    validationSchema,
    waitForRulesToApply,
    ...rest
  } = props;

  const getChangedFields = (values) => {
    return Object.keys(values).reduce((acc, key) => {
      if (!R.equals(values[key], initialValues[key])) {
        acc[key] = true;
      }
      return acc;
    }, {});
  };

  const innerSubmitHandler = (values, actions) => {
    const resolvedValues = resolveFieldsValue(values);
    const changedFields = getChangedFields(resolvedValues);

    submitHandler(resolvedValues, {
      ...actions,
      getChangedFields: () => changedFields,
      getChangedConfig: () => elementsChanges,
      resolveFieldsValue,
      waitForRulesToApply,
      initialRulesApply,
    });
  };
  const baseValidationSchema = validationSchema || getValidationSchema();
  const formikRef = innerRef || useRef();

  useEffect(() => {
    formikRef.current.validateForm();
  }, [baseValidationSchema]);

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      initialTouched={initialTouched}
      validationSchema={baseValidationSchema}
      onSubmit={onSubmit || innerSubmitHandler}
      validateOnMount={validateOnMount}
      autoComplete="off"
    >
      {(formik) => {
        return (
          <Form
            onKeyDown={onKeyDown}
            className={cn('formik', [className], { titled: title })}
            style={{ height, paddingBottom }}
          >
            {title && (
              <div>
                <h2 className="form-header-title">{title}</h2>
              </div>
            )}
            {/* TODO: remove class '.formik' when all forms will be formik */}
            {render({
              ...rest,
              isPending,
              fields,
              formDisabled: disabled,
              formik,
              getChangedConfig: () => elementsChanges,
              renderControlsButtons,
              resolveFieldsValue,
              waitForRulesToApply,
            })}
            {preventUnmountIfFormIsDirty && (
              <LeavingOrReloadRouteModal isNavigationBlocked={formik.dirty} isSubmitting={formik.isSubmitting} />
            )}
            {isDebugMode() && <BaseFormDebug />}
          </Form>
        );
      }}
    </Formik>
  );
};

BaseFormComponent.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  elementsChanges: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]),
  enableReinitialize: PropTypes.bool,
  fields: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]),
  getValidationSchema: PropTypes.func.isRequired,
  height: PropTypes.string,
  initialTouched: PropTypes.shape({}),
  initialValues: PropTypes.shape({}).isRequired,
  initialRulesApply: PropTypes.func,
  isPending: PropTypes.bool,
  onKeyDown: PropTypes.func,
  onSubmit: PropTypes.func,
  paddingBottom: PropTypes.string,
  preventUnmountIfFormIsDirty: PropTypes.bool,
  render: PropTypes.func,
  renderFields: PropTypes.func,
  renderControlsButtons: PropTypes.func,
  resolveFieldsValue: PropTypes.func.isRequired,
  rules: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]),
  submitHandler: PropTypes.func,
  title: PropTypes.string,
  validateOnMount: PropTypes.bool,
  validationSchema: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  waitForRulesToApply: PropTypes.func,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.shape({}) })]),
};

BaseFormComponent.defaultProps = {
  className: '',
  disabled: false,
  elementsChanges: [],
  enableReinitialize: false,
  isPending: false,
  fields: [],
  height: 'auto',
  onSubmit: null,
  paddingBottom: '36px',
  render: renderBaseFormBody,
  renderFields: renderBaseFields,
  renderControlsButtons: renderBaseControlsButtons,
  rules: [],
  submitHandler: () => null,
  title: '',
  validateOnMount: false,
  initialTouched: {},
  validationSchema: null,
  waitForRulesToApply: () => null,
  initialRulesApply: () => null,
  preventUnmountIfFormIsDirty: true,
  onKeyDown: () => null,
  innerRef: undefined,
};
