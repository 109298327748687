import styled, { css } from 'styled-components';

import { Spinner } from 'styled-containers';

export const DataListContainer = styled.div`
  ${({ loading }) => css`
    position: relative;
    z-index: 0;

    input {
      -webkit-transform: rotateZ(360deg);
      -webkit-font-smoothing: antialiased;
    }

    div:first-of-type svg {
      display: ${(props) => (props.required ? 'none' : 'initial')};
    }

    ${loading &&
    css`
      div svg,
      div:first-of-type svg {
        display: none;
      }
    `}
  `}
`;

export const StyledTagContainer = styled.li`
  ${({ disabled, theme }) => css`
    width: auto;
    max-width: 100%;

    > div {
      width: 100%;
    }

    ${disabled &&
    css`
      > div {
        box-shadow: 0 2px 1px -1px ${theme.color.disabled};
      }

      svg {
        path {
          fill: ${theme.color.disabled};
        }
      }

      button {
        display: none;
      }

      > div {
        padding: 0 0.5rem;
      }
    `}
  `}
`;

export const IconContainer = styled(Spinner)(
  (props) => css`
    cursor: ${props.disabled ? 'not-allowed' : 'inherit'};
    position: absolute;
    right: 0.5rem;
    bottom: 0.8rem;
    transform: ${props.visible ? 'scale(1)' : 'scale(0)'};
    opacity: ${props.visible ? 1 : 0};
    transition: all 0.3s cubic-bezier(0.2, 1, 0.6, 1.25);
    z-index: ${props.visible ? 1 : 0};

    svg {
      display: block !important;
    }
  `,
);
