import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { Slider, Text, Row, Button, Center } from '@blueprism/ui-core';
import { ArrowRight } from '@blueprism/ui-icons';

import { StyledFormField } from './components';

export const BaseFormFieldSlider = (props) => {
  const {
    additionalActionCallback,
    additionalActionLabel,
    disabled,
    disabledAdditionalAction,
    gap,
    helperText,
    icon,
    isAdditionalActionShown,
    label,
    maxValue,
    minValue,
    name,
    onChange,
    required,
    step,
    units,
  } = props;

  const [field, meta, helpers] = useField(props);
  const { isSubmitting } = useFormikContext();
  const { value } = field;
  const { error, touched } = meta;
  const { setTouched, setValue } = helpers;

  const displayError = touched && !!error;
  const isDisabled = isSubmitting || disabled;

  const onChangedHandler = (val) => {
    if (!touched) setTouched(true);

    const changedValue = Number(val.target.value);

    setValue(changedValue);
    onChange(changedValue);
  };

  const computedLabel = label ? `${label}${required ? '\u00A0*' : ''}` : '';

  const isMaxValue = value === maxValue;

  return (
    <StyledFormField
      label={computedLabel}
      htmlFor={name}
      icon={icon}
      helperText={helperText}
      errorText={error}
      error={displayError}
      gap={gap}
    >
      <>
        <Row align="center" justify="center">
          <Center>
            <Text>{`${value}${units}`}</Text>
          </Center>
          {isAdditionalActionShown && (
            <Button
              onClick={additionalActionCallback}
              iconRight={<ArrowRight />}
              disabled={disabledAdditionalAction || !isMaxValue}
            >
              {additionalActionLabel}
            </Button>
          )}
        </Row>
        <Slider
          id={name}
          name={name}
          min={minValue}
          max={maxValue}
          step={step}
          value={value}
          onChange={onChangedHandler}
          disabled={isDisabled}
        />
      </>
    </StyledFormField>
  );
};

export const BaseFormFieldSliderPropTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  additionalActionCallback: PropTypes.func,
  additionalActionLabel: PropTypes.string,
  isAdditionalActionShown: PropTypes.bool,
  disabledAdditionalAction: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  gap: PropTypes.string,
  icon: PropTypes.node,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  units: PropTypes.string,
  step: PropTypes.number,
};

export const BaseFormFieldSliderShape = PropTypes.shape(BaseFormFieldSliderPropTypes);

BaseFormFieldSlider.propTypes = BaseFormFieldSliderPropTypes;

BaseFormFieldSlider.defaultProps = {
  onChange: () => {},
  additionalActionCallback: () => {},
  disabledAdditionalAction: false,
  additionalActionLabel: 'NEXT',
  isAdditionalActionShown: false,
  required: false,
  disabled: false,
  helperText: null,
  gap: 'xs',
  icon: null,
  maxValue: 100,
  minValue: 0,
  units: '%',
  step: 1,
};
