import React from 'react';
import PropTypes from 'prop-types';
import { Cluster, FormField, Placeholder } from '@blueprism/ui-core';

export const TableFieldSkeleton = ({ description }) => (
  <FormField
    gap="xs"
    label={<Placeholder height="24px" width="100px" />}
    helperText={description && <Placeholder height="18px" width="50%" />}
  >
    <Cluster gap="base" data-testid="table-skeleton">
      {Array.from(Array(3).keys()).map((k) => (
        <Placeholder height="18px" width="calc(33.3% - 1rem)" key={k} />
      ))}
      {Array.from(Array(9).keys()).map((k) => (
        <Placeholder height="41px" width="calc(33.3% - 1rem)" key={k} />
      ))}
    </Cluster>
  </FormField>
);

TableFieldSkeleton.propTypes = {
  description: PropTypes.string,
};

TableFieldSkeleton.defaultProps = {
  description: null,
};
