import moment from 'moment';
import * as R from 'ramda';

import { DATE_FORMATS, ALLOW_DATE_TYPE, INITIAL_STATE } from 'app-base-form/constants';
import { isEmpty } from 'app-utils';

import { BaseFieldModel } from './BaseFieldModel';
import { initializeDateValue, numberOr } from '../utils';

export class DateFieldModel extends BaseFieldModel {
  constructor(field, page) {
    super(field, page);

    this.setup();
  }

  setup() {
    this.allowDates = this.payload.allowDates || ALLOW_DATE_TYPE.ANY;
    this.customDate = this.payload.customDate || '';
    this.format = this.payload.format || DATE_FORMATS[0];
    this.fromTodaysDate = numberOr(null, this.payload.fromTodaysDate);
    this.futureDateRestriction = numberOr(null, this.payload.futureDateRestriction);
    this.initialValue = this.payload.initialValue || '';
    this.pastDateRestriction = numberOr(null, this.payload.pastDateRestriction);
    this.placeholder = this.payload.placeholder || '';
    this.value = this.payload ? initializeDateValue(this.payload) : '';
  }

  assignValue = ({ format, value }) => {
    if (isEmpty(value)) return '';

    return moment(value, format.value);
  };

  resolveValue = (v) => {
    if (isEmpty(v)) return '';
    return moment(v).format(this.format.value);
  };

  processValueForDraft = (value) => {
    function checkDateRestrictions({ allowDates, futureDateRestriction, pastDateRestriction }) {
      return (
        allowDates !== ALLOW_DATE_TYPE.ANY || R.is(Number, pastDateRestriction) || R.is(Number, futureDateRestriction)
      );
    }

    const haveRestriction = checkDateRestrictions(this);

    return haveRestriction ? '' : value;
  };

  getRequestModel() {
    return {
      ...this,
      initialState: this.initialState.value,
      payload: JSON.stringify({
        customDate: this.customDate,
        initialValue: this.initialValue,
        format: this.format,
        placeholder: this.placeholder,
        fromTodaysDate: this.fromTodaysDate,
        allowDates: this.allowDates,
        hidden: this.initialState.value === INITIAL_STATE.HIDDEN,
        required: this.initialState.value === INITIAL_STATE.MANDATORY,
        disabled: this.initialState.value === INITIAL_STATE.READ_ONLY,
        pastDateRestriction: this.pastDateRestriction,
        futureDateRestriction: this.futureDateRestriction,
      }),
    };
  }
}
