import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Alert } from '@blueprism/ui-icons';

import { AvatarPicker } from './AvatarPicker';
import { FormFieldAvatarPicker } from './BaseFormFieldAvatarPicker.styled';

export const BaseFormFieldAvatarPicker = (props) => {
  const { description, label, onChange, ...rest } = props;

  const [field, meta, helpers] = useField(props);
  const { name, value } = field;
  const { touched } = meta;
  const { setTouched, setValue } = helpers;

  const changeFieldHandler = (val, payload) => {
    if (!touched) setTouched(true);

    setValue(val);
    onChange(val, payload);
  };

  return (
    <FormFieldAvatarPicker
      id={name}
      htmlFor="upload-file"
      label={label}
      helperText={description}
      icon={<Alert size={16} color="#fff" />}
    >
      <AvatarPicker {...rest} image={value} setImage={changeFieldHandler} />
    </FormFieldAvatarPicker>
  );
};

export const BaseFormFieldAvatarPickerPropTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  maxFileSize: PropTypes.number,
  maxImageHeight: PropTypes.number,
  maxImageWidth: PropTypes.number,
  onChange: PropTypes.func,
  editButtonText: PropTypes.string,
};

export const BaseFormFieldAvatarPickerShape = PropTypes.shape(BaseFormFieldAvatarPickerPropTypes);

BaseFormFieldAvatarPicker.propTypes = BaseFormFieldAvatarPickerPropTypes;

BaseFormFieldAvatarPicker.defaultProps = {
  className: '',
  description: '',
  label: 'PROFILE_PICTURE',
  editButtonText: 'UPLOAD',
  maxFileSize: 10,
  maxImageHeight: 1000,
  maxImageWidth: 1000,
  onChange: () => {},
};
