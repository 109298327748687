import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { BaseForm } from 'app-base-form';
import { getObjectFromSearch } from 'app-utils';

import { createPasswordFormConfig } from './createPasswordFormConfig';

export const CreatePassword = ({ authStore }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const createPasswordHandler = (credentials, formActions) => {
    const searchObject = getObjectFromSearch(location);
    searchObject.emailConfirmationCode = decodeURIComponent(searchObject.emailConfirmationCode);

    authStore.createPassword({ ...credentials, ...searchObject }, formActions);
  };

  return (
    <BaseForm
      preventUnmountIfFormIsDirty={false}
      {...createPasswordFormConfig(t)}
      submitHandler={createPasswordHandler}
    />
  );
};
