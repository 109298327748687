import { createGlobalStyle } from 'styled-components';

export const RichEditorStyles = createGlobalStyle`
  .public-Draft {
    &-StyleDefault-block {
      p,
      span,
      b,
      i,
      u,
      code {
        word-wrap: break-word;
      }
    }

    &Editor {
      &Placeholder-root {
        position: absolute;
        padding: ${({ theme }) => `${theme.globalSizes.small} ${theme.globalSizes.xs}`};
        color: rgba(0, 0, 0, 0.4);
      }

      &-content {
        box-sizing: border-box;
        width: 100%;
        max-width: 100%;
        height: 156px;
        min-height: 120px;
        padding: ${({ theme }) => `${theme.globalSizes.small} ${theme.globalSizes.xs}`};
        overflow-x: auto;
        box-shadow: ${({ isFocus }) => (isFocus ? 'rgb(255 195 0) 0px 0px 0px 4px' : 'none')};
        border-radius: ${({ theme }) => `${theme.globalSizes.smallest}`};
        color: ${({ theme }) => theme.color.text};
        font-weight: ${({ error, theme }) => (error ? theme.font.weight.bold : theme.font.weight.regular)};
        font-size: 1rem;
        cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'text')};
        background-color: ${({ theme }) => theme.color.highlight};
        background-image: ${({ error, theme }) => (error ? theme.pattern.error : 'none')};
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: 100% 0.5rem;
        border: none;
        border-bottom: 0.1rem solid ${({ error, theme: { color } }) => (error ? color.warning : color.action)}};
        outline: none;
        transition: 0.2s ease-out;
        resize: none;
      }
    }
  }

  .rdw-editor-toolbar.toolbar {
    z-index: 2;
    display: flex;
    width: fit-content;
    justify-content: flex-start;
    box-sizing: border-box;
    background: ${({ theme }) => theme.color.paper};
    margin-bottom: 5px;

    &.disabled {
      > div {
        cursor: not-allowed;
        opacity: 0.7;
        pointer-events: none;
      }
    }

    .rdw-inline-wrapper {
      display: flex;
      flex-direction: row;
    }

    &:after {
      margin-left: -4px;
      border-width: 4px;
      border-top-color: #333;
    }

    &:before {
      margin-left: -6px;
      border-width: 6px;
      border-top-color: #111;
    }
  }

  .button.container{
    color: ${({ theme }) => theme.color.text};
  }

  .rdw-inline-wrapper,
  .buttonWrapper {
    display: flex;
    flex-direction: row;
    color: ${({ theme }) => theme.color.text};

    svg {
      fill: ${({ theme }) => theme.color.text};
    }

    background: ${({ theme }) => theme.color.paper};

    &.disabled {
      pointer-events: none;

      .rdw-option-wrapper {
        pointer-events: none;
      }
    }

    .rdw-option-wrapper,.button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 34px;
      padding: 0;
      font-size: 18px;
      vertical-align: bottom;
      background: ${({ theme }) => theme.color.paper};
      border: 0;
      cursor: pointer;

      &.bold {
        font-weight: 700;
      }

      &.underline {
        text-decoration: underline;
      }

      &.italic {
        font-style: italic;
      }

      &.container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & .color-preview {
          width: 18px;
          height: 5px;

          &.highlight {
            background: #ff0;
          }
        }
      }

      &:disabled {
      cursor: default;
      }

      &:hover:enabled,
      &:focus {
        color: ${({ theme }) => theme.color.paper};
        background: ${({ theme }) => theme.color.action};
        outline: 0;

        svg {
          fill: ${({ theme }) => theme.color.paper};
        }
      }
    }

    &:first-child {
      overflow: hidden;
    }

    .active {
      color: ${({ theme }) => theme.color.paper};
      background: ${({ theme }) => theme.color.action};

      svg {
        fill: ${({ theme }) => theme.color.paper};
      }
    }
  }

  .color-picker {
    position: relative;

    .button-color-picker {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .react-color-picker {
      position: absolute;
      top: 36px;
      z-index: 2;
      display: none;
      opacity: 0;
      transition: opacity 1s linear;

      &.open {
        display: block;
        opacity: 1;
      }

      .container-default-color {
        display: block;
        position: relative;
        top: 6px;
        width: 100%;
        height: 72px;
        font-size: 0px;
        border-top: ${({ theme }) =>
          theme.name === 'dark' ? `1px solid rgba(255, 255, 255, 0.2)` : `1px solid rgba(0, 0, 0, 0.2)`};
        border-right: ${({ theme }) =>
          theme.name === 'dark' ? `1px solid rgba(255, 255, 255, 0.2)` : `1px solid rgba(0, 0, 0, 0.2)`};
        border-left: ${({ theme }) =>
          theme.name === 'dark' ? `1px solid rgba(255, 255, 255, 0.2)` : `1px solid rgba(0, 0, 0, 0.2)`};
        z-index: 10;
        background: ${({ theme }) => theme.color.paper};

        p {
          padding-left: 5px;
        }


        .styled-default-color {
          display: block;
          position: relative;
          width: 25px;
          height: 25px;
          margin: 5px;
          font-size: 0px;
          background: ${({ theme }) => theme.color.text};
          cursor: pointer;
        }
      }
    }

    .github-picker {
      position: absolute;
      z-index: 9;
      width: 112px !important;
      background: ${({ theme }) => theme.color.paper} !important;
      border: ${({ theme }) =>
        theme.name === 'dark' ? `1px solid rgba(255, 255, 255, 0.2)` : `1px solid rgba(0, 0, 0, 0.2)`} !important;
      border-radius: 0 !important;
    }
  }

  *::selection {
    color: inherit;
    background-color: rgba(177, 177, 177, 0.33);
  }

  &.disabled {
    cursor: not-allowed;
  }

  .input-block {
    .editor {
      p,
      span {
        word-break: break-word;
      }
    }
  }
`;
