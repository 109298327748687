import React from 'react';

import { FormFilesSizeContext } from 'app-base-form';

export const withFormFilesSize = (OriginComponent) => (props) => {
  const ComponentWithFormFilesSize = () => {
    return (
      <FormFilesSizeContext.Consumer>
        {({ formFilesSize } = {}) => <OriginComponent {...props} formFilesSize={formFilesSize} />}
      </FormFilesSizeContext.Consumer>
    );
  };

  ComponentWithFormFilesSize.displayName = `withFormFilesSize (${ComponentWithFormFilesSize()})`;

  return ComponentWithFormFilesSize();
};
