/**
 * DEPLOYMENT_TYPE provided to global variable from .env
 */
export const ACTIVE_DEPLOYMENT = DEPLOYMENT_TYPE;

/**
 * When changing deployment types, be sure to synchronize
 * with the variables in the file SharedComponents/app-utils/defineDeploymentType.js
 */

export const DEPLOYMENT_TYPES = {
  ON_PREMISE: 'ON_PREMISE',
  CLOUD: 'CLOUD',
};
