import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import * as R from 'ramda';

import { ResetPassword } from './ResetPassword';

export const ResetPasswordContainer = R.compose(
  inject((stores) => ({
    authStore: stores.rootStore.authStore,
  })),
  observer,
)(ResetPassword);
