import { isEmpty } from 'app-utils';

import { UPLOAD_TYPES_ID, INITIAL_STATE } from '../constants';
import { BaseFieldModel } from './BaseFieldModel';

export class UploadFieldModel extends BaseFieldModel {
  constructor(field, page) {
    super(field, page);

    this.setup();
  }

  setup() {
    this.value = this.payload.value || '';
    this.fileSize = this.payload.fileSize || '';
    this.fileExtensions = isEmpty(this.payload.fileExtensions) ? [] : this.payload.fileExtensions;
    this.imageExtensions = this.payload.imageExtensions || [];
    this.imageSize = this.payload.imageSize || '1920px*1080px';
    this.uploadTypes = this.payload.uploadTypes || UPLOAD_TYPES_ID.FILE;
  }

  getRequestModel() {
    let fileExtensions = Array.isArray(this.fileExtensions)
      ? this.fileExtensions
      : this.fileExtensions.split(', ').filter(Boolean);
    fileExtensions = isEmpty(fileExtensions) ? null : fileExtensions;

    return {
      ...this,
      initialState: this.initialState.value,
      payload: JSON.stringify({
        fileSize: this.fileSize,
        imageSize: this.imageSize,
        uploadTypes: this.uploadTypes,
        fileExtensions,
        imageExtensions: this.imageExtensions,
        hidden: this.initialState.value === INITIAL_STATE.HIDDEN,
        required: this.initialState.value === INITIAL_STATE.MANDATORY,
        disabled: this.initialState.value === INITIAL_STATE.READ_ONLY,
      }),
    };
  }

  resolveValue = (value) => {
    const _value = value;

    if (_value) delete _value.file;

    return _value;
  };
}
