import React, { useCallback, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, FormField, Row, Stack, Table } from '@blueprism/ui-core';

import { numberOr, isDescendant } from 'app-base-form/utils';
import { isEmpty } from 'app-utils';

import { StyledDescription } from '../components';
import { BaseFormFieldText } from '../BaseFormFieldText';
import { StyledColumnDescription, StyledTableContainer } from './BaseFormFieldTable.styled';

const DEFAULT_MAX_ROWS = 100;
const DEFAULT_MIN_ROWS = 1;

export const BaseFormFieldArray = (props) => {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const {
    columns,
    description,
    disabled,
    formDisabled,
    gap,
    label,
    maxRows,
    minRows,
    onBlur,
    push,
    remove,
    required,
  } = props;

  const [field, meta, helpers] = useField(props);
  const { isSubmitting } = useFormikContext();
  const { name, value = [] } = field;
  const { error, touched } = meta;
  const { setTouched } = helpers;

  const newValueModel = columns.reduce((acc, { value: columnValue }) => {
    acc[columnValue] = '';
    return acc;
  }, {});

  const isDisabled = isSubmitting || disabled || formDisabled;

  const valuesLength = Object.keys(value).length;
  const haveErrorsForField = !isEmpty(error);

  const maxRowsCount = numberOr(DEFAULT_MAX_ROWS, maxRows);
  const minRowsCount = numberOr(DEFAULT_MIN_ROWS, minRows);
  const canNotAddRow = valuesLength >= maxRowsCount || isDisabled || haveErrorsForField;
  const canNotDeleteRow = valuesLength <= minRowsCount || isDisabled;

  const blurFieldHandler = useCallback(() => {
    if (isDisabled) return;

    onBlur(value);
  }, [isDisabled, onBlur, value]);

  // This fix caused  by react issue https://github.com/facebook/react/issues/9142. Onblur event doesn't fired when button is removed from DOM.
  useEffect(() => {
    if (touched) {
      blurFieldHandler();
    }
  }, [valuesLength]);

  const addNewRow = () => {
    push(newValueModel);

    setTimeout(() => {
      if (!tableRef.current) return;

      tableRef.current.scrollTop = tableRef.current.scrollHeight;
    }, 0);
  };

  function removeRow(index) {
    setTouched(true);
    remove(index);
  }

  const actionColumn = {
    header: t('ACTIONS'),
    accessor: 'actions',
    width: '105px',
  };

  const actionButton = (valueIndex) => ({
    actions: (
      <Button disabled={canNotDeleteRow} onClick={() => removeRow(valueIndex)}>
        {t('common:REMOVE')}
      </Button>
    ),
  });

  const mappedColumns = columns
    .map(({ checked, label: columnLabel, value: accessor }) => ({
      header: (
        <>
          <span>{columnLabel}</span>
          {checked && <StyledColumnDescription>{t('common:EMPTY_VALUE_ALLOWED')}</StyledColumnDescription>}
        </>
      ),
      accessor,
      id: accessor,
    }))
    .concat(actionColumn);

  const mappedValues = useMemo(
    () =>
      value.map((rowValue, valueIndex) => {
        const mappedValuesRow = Object.keys(rowValue).reduce((acc, key) => {
          const fieldName = `${name}[${valueIndex}][${key}]`;
          acc[key] = (
            <div className="field-table-text-group">
              <BaseFormFieldText
                isFieldset
                key={fieldName}
                name={fieldName}
                disabled={isDisabled}
                maxlength={50}
                value={value[key]}
              />
            </div>
          );
          return acc;
        }, {});

        return { ...mappedValuesRow, ...actionButton(valueIndex) };
      }),
    [value.length, canNotDeleteRow, isDisabled],
  );

  const computedLabel = label ? `${label}${required ? '\u00A0*' : ''}` : '';

  const onBlurChanged = ({ relatedTarget }) => {
    const childComponentIsFocused = relatedTarget && isDescendant(relatedTarget, name);

    if (!childComponentIsFocused) {
      blurFieldHandler();
    }
  };

  return (
    <Stack gap="xs" id={name} onBlur={onBlurChanged}>
      <FormField
        label={computedLabel}
        htmlFor={name}
        gap={gap}
        helperText={<StyledDescription type="caption">{description}</StyledDescription>}
      >
        <StyledTableContainer ref={tableRef}>
          <Table rowData={mappedValues} columns={mappedColumns} />
        </StyledTableContainer>
      </FormField>
      <Row justify="end">
        <Button disabled={canNotAddRow} onClick={addNewRow}>
          {t('common:ADD_ROW')}
        </Button>
      </Row>
    </Stack>
  );
};

BaseFormFieldArray.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      checked: PropTypes.bool,
      value: PropTypes.string,
    }),
  ).isRequired,
  description: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
  formDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  onBlur: PropTypes.func,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  required: PropTypes.bool,
  gap: PropTypes.string,
};
BaseFormFieldArray.defaultProps = {
  description: null,
  disabled: false,
  formDisabled: false,
  maxRows: DEFAULT_MAX_ROWS,
  minRows: DEFAULT_MIN_ROWS,
  onBlur: () => null,
  required: false,
  gap: 'xs',
};
