import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

export const Tooltip = (props) => <ReactTooltip {...props} />;

Tooltip.propTypes = {
  place: PropTypes.string,
  type: PropTypes.string,
  effect: PropTypes.string,
  id: PropTypes.string,
};

Tooltip.defaultProps = {
  place: 'bottom',
  type: 'dark',
  effect: 'solid',
  id: undefined,
};
