import React from 'react';
import PropTypes from 'prop-types';
import { FormField, Placeholder, Row, Stack } from '@blueprism/ui-core';

export const CheckboxFieldSkeleton = ({ description, gap, options, roundedOption }) => {
  const calcOptions = options || Array.from(Array(3).keys());

  return (
    <FormField
      gap="xs"
      label={<Placeholder height="24px" width="100px" />}
      helperText={description && <Placeholder height="18px" width="50%" />}
    >
      <Stack gap={gap}>
        {calcOptions.map((key) => (
          <Row key={key} gap="small" height="48px" align="center">
            <Placeholder rounded={roundedOption} height="40px" width="40px" />
            <Placeholder height="24px" width="100px" />
          </Row>
        ))}
      </Stack>
    </FormField>
  );
};

CheckboxFieldSkeleton.propTypes = {
  gap: PropTypes.string,
  description: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  roundedOption: PropTypes.bool,
};

CheckboxFieldSkeleton.defaultProps = {
  gap: 'none',
  description: null,
  options: null,
  roundedOption: false,
};
