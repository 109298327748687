import React from 'react';

export const mapInitialFieldValues = (OriginComponent) => (props) => {
  const BaseFormComponentWithInitialFieldValues = () => {
    const { fields } = props;
    let { initialValues } = props;

    if (!initialValues) {
      const { _initialValues } = Object.values(fields).reduce(
        (obj, { name, value }) => {
          return {
            _initialValues: {
              ...obj._initialValues,
              [name]: value,
            },
          };
        },
        { _initialValues: {} },
      );
      initialValues = _initialValues;
    }

    return <OriginComponent {...props} initialValues={initialValues} />;
  };

  BaseFormComponentWithInitialFieldValues.displayName = `combineInitialFieldValues(${BaseFormComponentWithInitialFieldValues()})`;

  return BaseFormComponentWithInitialFieldValues();
};
