import { RULES_OPTIONS } from '../constants/RuleOptionsAndTypes';

// TODO: Delete it and use RULE_IF_VALUE_TYPES or RULE_THEN_TYPES ?
export const defineDropdownProps = (optionValue, payload = {}) => {
  const { maxSelection, minSelection } = payload;

  switch (optionValue) {
    case RULES_OPTIONS.STATE_IS.value:
      return {};
    case RULES_OPTIONS.SET_VALUES_TO.value:
      return { maxSelection, minSelection };
    default:
      return { maxSelection };
  }
};
