import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { EditorState } from 'draft-js';
import ReactMarkdown from 'react-markdown';

import { getEditorContent } from '../utils';

export function RichTextTemplate({ value }) {
  const template = R.is(EditorState, value) ? getEditorContent(value).descriptionHTML : value;

  return <ReactMarkdown escapeHtml={false} source={template} />;
}

RichTextTemplate.propTypes = {
  value: PropTypes.shape({}).isRequired,
};
