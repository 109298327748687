import React from 'react';
import { toast, cssTransition } from 'react-toastify';
import { Text, Box } from '@blueprism/ui-core';
import { Tick, Alert } from '@blueprism/ui-icons';

import { enhancedT } from 'app-i18n';

import { StyledToastRow } from './StyledToastRow.styled';

const commonOptions = {
  draggable: true,
  pauseOnHover: true,
  closeOnClick: true,
  hideProgressBar: true,
  position: 'top-right',
  transition: cssTransition({
    enter: 'zoomIn',
    exit: 'zoomOut',
    duration: 0,
  }),
};

const ToasterContent = ({ align, enhancedText, Icon, size, type }) => {
  const isSuccessful = type === 'success' || type === 200;

  return (
    <StyledToastRow align={align} isSuccessful={isSuccessful}>
      <Box padding="0 10px 0 0">
        <Icon size={size} />
      </Box>
      <Text>{enhancedText}</Text>
    </StyledToastRow>
  );
};

class CustomNotify {
  constructor() {
    this.toastsId = [];
    this.isLegacyToasts = false;
  }

  setIsLegacyToasts = (state) => {
    this.isLegacyToasts = state;
  };

  selectToastType = (type, text, options) => {
    const enhancedText = enhancedT(text);

    switch (type) {
      case 'success':
      case 200: {
        return this.showToast(
          this.isLegacyToasts
            ? enhancedText
            : ToasterContent({ Icon: Tick, enhancedText, align: 'center', size: 24, type }),
          toast.success,
          options,
        );
      }
      case 'error':
      case 500:
      case 400: {
        return this.showToast(
          this.isLegacyToasts
            ? enhancedText
            : ToasterContent({ Icon: Alert, enhancedText, align: 'start', size: 18, type }),
          toast.error,
          options,
        );
      }
      default:
        return this.showToast(text, toast, options);
    }
  };

  dismiss = () => {
    toast.dismiss(this.toastsId[0]);
  };

  notify = async (type, text, options) => {
    if (this.toastsId.length < 3) {
      this.selectToastType(type, text, options);
    } else {
      this.dismiss();
      this.selectToastType(type, text, { delay: 300, ...options });
    }
  };

  showToast(content, tostProp, options = {}) {
    const newToastId = tostProp(content, {
      ...commonOptions,
      ...options,
      onOpen: () => {
        this.toastsId = [...this.toastsId, newToastId];
      },
      onClose: () => {
        this.toastsId = this.toastsId.filter((el) => el !== newToastId);
      },
    });
  }
}

export const { notify, setIsLegacyToasts } = new CustomNotify();
