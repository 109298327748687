import styled, { css } from 'styled-components';
import { Box } from '@blueprism/ui-core';

export const ComboBoxContainer = styled(Box)(
  ({ theme }) => css`
    z-index: ${theme.layer.panels};
    background-color: ${theme.color.paper};
    height: max-content;
  `,
);
