import * as R from 'ramda';
import moment from 'moment';

import { DATE_VALUE_TYPE } from '../constants/dateValueType';

export const initializeDateValue = ({ customDate, fromTodaysDate, initialValue, value }) => {
  if (!R.isNil(value)) return value;

  switch (initialValue.value) {
    case DATE_VALUE_TYPE.TODAY:
      return moment().startOf('day');
    case DATE_VALUE_TYPE.TODAY_PLUS:
      return moment().startOf('day').add(fromTodaysDate, 'days');
    case DATE_VALUE_TYPE.TODAY_MINUS:
      return moment().startOf('day').subtract(fromTodaysDate, 'days');
    case DATE_VALUE_TYPE.CUSTOM:
      return moment(customDate).startOf('day');
    default:
      return '';
  }
};
