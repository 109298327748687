import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import * as R from 'ramda';

import { Welcome } from './Welcome';

export const WelcomeContainer = R.compose(
  inject((stores) => ({
    appUrls: stores.rootStore.configStore.appUrls,
  })),
  observer,
)(Welcome);
