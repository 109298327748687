import { observable, action, computed } from 'mobx';

import { waitForAnswer } from 'app-modal/components/AreYouSureModal';
import { MODES } from 'app-constants';

const DEFAULT_DECLINE_MESSAGE = {
  question: ['ARE_YOU_SURE_YOU_WANT_TO_DECLINE_THE_CHANGES'],
  title: 'ARE_YOU_SURE',
};

export class BaseFormStore {
  @observable mode = null;

  @observable isPending = true;

  @observable isVisible = false;

  @computed
  get isEditModeEnabled() {
    return this.mode === MODES.EDIT;
  }

  /**
   *
   * @param {boolean} state
   */
  @action
  setPending = (state) => {
    this.isPending = state;
  };

  /**
   *
   * @param {string} mode
   */
  @action
  setMode = (mode) => {
    this.mode = mode;
  };

  /**
   *
   * @param {boolean} mode
   */
  @action
  setVisibility = (state) => {
    this.isVisible = state;
  };

  @action
  clearServiceStore = () => {
    this.mode = null;
    this.isPending = true;
    this.isVisible = false;
  };

  /**
   *
   * @param {boolean} dirty
   * @param {{question:Array<string>,title:string}} customMessage
   */
  askForDeclineChanges = async ({ dirty, content = DEFAULT_DECLINE_MESSAGE, customPrecondition = false }) => {
    const shallDeclineWindowPopup = dirty && this.mode !== MODES.VIEW;

    if (!customPrecondition && !shallDeclineWindowPopup) return true;

    return waitForAnswer({ content });
  };
}
