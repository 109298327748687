import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobXPropTypes } from 'mobx-react';
import { toJS } from 'mobx';

import { APP_LANGUAGES_LIST } from 'app-i18n';
import { ComboBox } from 'app-combobox';

import { ComboBoxContainer } from './LanguageSelect.styled';

export function LanguageSelect({ languageStore }) {
  const { availableLanguages, changeLanguage, currentLanguage } = languageStore;

  const selectedLanguage = availableLanguages.find(({ value }) => value === currentLanguage);

  function handleSelect({ value }) {
    changeLanguage(value);
  }

  return (
    <ComboBoxContainer>
      <ComboBox name="languages" options={toJS(availableLanguages)} value={selectedLanguage} onSelect={handleSelect} />
    </ComboBoxContainer>
  );
}

LanguageSelect.propTypes = {
  languageStore: PropTypes.shape({
    changeLanguage: PropTypes.func.isRequired,
    currentLanguage: PropTypes.oneOf(APP_LANGUAGES_LIST),
    availableLanguages: MobXPropTypes.observableArray(PropTypes.string),
  }).isRequired,
};
