import React from 'react';

import { FIELD_TYPES } from 'app-base-form/constants';

import {
  ImageFieldSkeleton,
  BaseFieldSkeleton,
  TimeFieldSkeleton,
  CheckboxFieldSkeleton,
  TableFieldSkeleton,
} from '../BaseFormSkeleton/components';

const getSkeletonByType = (type) => {
  switch (type) {
    case FIELD_TYPES.TIME:
      return (props) => <TimeFieldSkeleton {...props} />;
    case FIELD_TYPES.CHECKBOX:
    case FIELD_TYPES.CHECKBOX_GROUP:
      return (props) => <CheckboxFieldSkeleton {...props} />;
    case FIELD_TYPES.RADIO_GROUP:
      return (props) => <CheckboxFieldSkeleton {...props} gap="xs" roundedOption />;
    case FIELD_TYPES.TEXTAREA:
      return (props) => <BaseFieldSkeleton {...props} height="156px" />;
    case FIELD_TYPES.DATE:
    case FIELD_TYPES.DATE_MONTH:
      return (props) => <BaseFieldSkeleton {...props} height="46px" />;
    case FIELD_TYPES.TABLE:
      return (props) => <TableFieldSkeleton {...props} height="156px" />;
    case FIELD_TYPES.IMAGE:
      return (props) => <ImageFieldSkeleton {...props} height="156px" />;
    default:
      return (props) => <BaseFieldSkeleton {...props} />;
  }
};

export class BaseFieldSkeletonFactory {
  static applyFieldMiddlewares = ({ type, ...rest }) => {
    return getSkeletonByType(type)({
      ...rest,
    });
  };

  static build(props) {
    return this.applyFieldMiddlewares(props);
  }
}
