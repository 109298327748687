import React from 'react';
import { PropTypes } from 'prop-types';
import { Line, Circle } from 'rc-progress';

export const ProgressBar = ({ hideInProgress, mode, progress, ...rest }) => {
  const shouldBeRendered = !hideInProgress.includes(progress);

  if (!shouldBeRendered) return null;

  return (
    <>
      {mode === 'line' && <Line percent={progress} {...rest} />}
      {mode === 'circle' && <Circle percent={progress} {...rest} />}
    </>
  );
};

ProgressBar.propTypes = {
  mode: PropTypes.string,
  progress: PropTypes.number,
  strokeColor: PropTypes.string,
  trailColor: PropTypes.string,
  strokeWidth: PropTypes.number,
  hideInProgress: PropTypes.arrayOf(PropTypes.number),
};

ProgressBar.defaultProps = {
  mode: 'line',
  progress: 0,
  strokeColor: '#85D262',
  trailColor: 'rgba(40, 86, 101, 0.1)',
  strokeWidth: 2,
  hideInProgress: [0, 100],
};
