import React from 'react';

import { baseFormRulesManagerStore } from '../BaseFormRulesManager/BaseFormRulesManagerStore';

export const RulesManagerStoreContext = React.createContext();

export const provideRulesManagerStore = (OriginComponent) => (props) => {
  const ComponentWithProvidedRulesManagerStore = () => {
    return (
      <RulesManagerStoreContext.Provider value={{ rulesManagerStore: baseFormRulesManagerStore }}>
        <OriginComponent {...props} />
      </RulesManagerStoreContext.Provider>
    );
  };

  ComponentWithProvidedRulesManagerStore.displayName = `provideRulesManagerStore (${ComponentWithProvidedRulesManagerStore()})`;

  return ComponentWithProvidedRulesManagerStore();
};
