import React, { useState, useContext, useRef } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { GithubPicker } from 'react-color';
import cn from 'classnames';
import { Text } from '@blueprism/ui-core';
import { ThemeContext } from 'styled-components';

import { parseHex } from 'app-utils/string/parseHex';
import { DEFAULT_COLOR_PALETTE } from 'app-text-editor/constants/editorStyles';

export function ColorPickerButton({
  currentState: { bgColor, color },
  disabled,
  doCollapse,
  doExpand,
  expanded,
  onChange,
  onExpandEvent,
}) {
  const pickerRef = useRef();

  const colorPickerArray = Object.values(DEFAULT_COLOR_PALETTE);

  const {
    color: { text: defaultColor },
  } = useContext(ThemeContext);

  const setTextColor = ({ hex }, _, preventClosing) => {
    const rgbColor = hex && parseHex(hex, { format: 'css' });

    if (rgbColor !== color) onChange('color', rgbColor);

    if (preventClosing) doExpand();
  };

  const [prevColor, setPrevColor] = useState(color || '');

  const revertedColors = R.invertObj(DEFAULT_COLOR_PALETTE);

  function rgbConverter() {
    if (!pickerRef.current) return;

    const picker = pickerRef.current.querySelector('.github-picker');

    if (!picker) return;

    Array.from(picker.childNodes).map((node) => {
      if (node.tagName !== 'DIV') {
        const {
          firstChild: {
            firstChild: { firstChild: button },
          },
        } = node;

        if (button.title.includes('rgb')) {
          button.setAttribute('title', revertedColors[button.title]);
        }
      }

      return node;
    });
  }

  function handleClickAway() {
    if (!expanded) return;
    if (prevColor === color) onChange('color', prevColor);

    doCollapse();
  }

  function handleToggleHighlightClick() {
    onChange('bgcolor', !bgColor ? 'rgb(255,254,13)' : '');
  }

  function handleTogglePickerClick() {
    if (expanded) return doCollapse();

    setPrevColor(color);
    rgbConverter();
    return onExpandEvent(true);
  }

  function handleSwatchHover({ hex }, event) {
    event.stopPropagation();
    setTextColor({ hex }, event, true);
  }

  return (
    <>
      <div className={cn('buttonWrapper', { active: bgColor })}>
        <button
          className={cn('button', 'container', { active: bgColor })}
          type="button"
          onClick={handleToggleHighlightClick}
        >
          <span className="icon icon-marker" />
          <div className="color-preview highlight" />
        </button>
      </div>
      <div className={cn('buttonWrapper color-picker', { disabled })}>
        <OutsideClickHandler onOutsideClick={handleClickAway}>
          <div ref={pickerRef} className={cn('react-color-picker', { open: expanded })} onClick={handleClickAway}>
            <div className="container-default-color">
              <Text type="caption">Default:</Text>
              <span
                title="Default"
                className="styled-default-color"
                onMouseOver={(event) => handleSwatchHover({ hex: '' }, event)}
                onFocus={(event) => handleSwatchHover({ hex: '' }, event)}
                onClick={() => setTextColor({ hex: '' })}
              />
              <Text type="caption">Colors:</Text>
            </div>
            <GithubPicker
              triangle="hide"
              colors={colorPickerArray}
              onSwatchHover={handleSwatchHover}
              onChangeComplete={setTextColor}
            />
          </div>
          <button className="button container" type="button" onMouseDown={handleTogglePickerClick}>
            <span className="draft-toolbar-icon color-picker-icon">A</span>
            <div className="color-preview" style={{ background: color || defaultColor }} />
          </button>
        </OutsideClickHandler>
      </div>
    </>
  );
}

ColorPickerButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  currentState: PropTypes.shape({
    bgColor: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
  doCollapse: PropTypes.func,
  doExpand: PropTypes.func,
  onExpandEvent: PropTypes.func,
  expanded: PropTypes.bool,
};

ColorPickerButton.defaultProps = {
  disabled: false,
  expanded: false,
  onExpandEvent: () => null,
  doExpand: () => null,
  doCollapse: () => null,
};
