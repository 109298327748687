import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useField, useFormikContext } from 'formik';
import { FormField } from '@blueprism/ui-core';

import { numberOr } from 'app-base-form/utils';
import { NumberFormat } from 'app-inputs';

import { StyledDescription } from './components';

const MIN_NUMBER = 0;
const MAX_NUMBER = 99999999999999;

export const BaseFormFieldNumberFormat = (props) => {
  const {
    allowEmpty,
    allowNegative,
    allowValuesLessThanMin,
    className,
    decimalScale,
    description,
    disabled,
    fixedDecimalScale,
    formDisabled,
    gap,
    isInteger,
    label,
    labelPlacement,
    max,
    min,
    onBlur,
    onChange,
    placeholder,
    prefix,
    required,
    step,
    style,
    thousandSeparator,
    width,
  } = props;
  const [field, meta, helpers] = useField(props);
  const { isSubmitting } = useFormikContext();
  const { name, value = undefined } = field;
  const { error, touched } = meta;
  const { setTouched, setValue } = helpers;

  const computedLabel = `${label}${required ? '\u00A0*' : ''}`;
  const displayError = touched && !!error;
  const isDisabled = isSubmitting || formDisabled || disabled;

  const changeFieldHandler = useCallback(
    (val) => {
      if (value === val) return;

      if (!touched) setTouched(true);

      setValue(val);
      onChange(val);
    },
    [onChange, setTouched, setValue, touched],
  );

  const blurFieldHandler = useCallback(
    (val) => {
      if (!touched) setTouched(true);

      setValue(val);
      onBlur(val);
    },
    [onBlur, setTouched, setValue, touched],
  );

  const calcClass = cn('input', { 'is-invalid-input': touched && error }, className);

  const calcDecimalScale = numberOr(undefined, decimalScale);

  return useMemo(() => {
    return (
      <FormField
        label={computedLabel}
        htmlFor={name}
        errorText={error}
        error={displayError}
        helperText={<StyledDescription type="caption">{description}</StyledDescription>}
        gap={gap}
      >
        <NumberFormat
          style={style}
          id={name}
          value={numberOr(null, value)}
          className={calcClass}
          placeholder={placeholder}
          onBlur={blurFieldHandler}
          onChange={changeFieldHandler}
          disabled={isDisabled}
          decimalScale={calcDecimalScale}
          min={numberOr(MIN_NUMBER, min)}
          max={numberOr(MAX_NUMBER, max)}
          step={numberOr(1, step)}
          prefix={prefix}
          allowEmpty={allowEmpty}
          isInteger={isInteger}
          allowNegative={allowNegative}
          fixedDecimalScale={fixedDecimalScale}
          thousandSeparator={thousandSeparator}
          allowValuesLessThanMin={allowValuesLessThanMin}
          width={width}
        />
      </FormField>
    );
  }, [
    min,
    max,
    name,
    step,
    value,
    label,
    style,
    prefix,
    required,
    calcClass,
    isInteger,
    allowEmpty,
    description,
    placeholder,
    allowNegative,
    labelPlacement,
    isDisabled,
    blurFieldHandler,
    calcDecimalScale,
    fixedDecimalScale,
    thousandSeparator,
    changeFieldHandler,
    allowValuesLessThanMin,
  ]);
};

export const BaseFormFieldNumberFormatPropTypes = {
  allowEmpty: PropTypes.bool,
  allowNegative: PropTypes.bool,
  allowValuesLessThanMin: PropTypes.bool,
  className: PropTypes.string,
  decimalScale: PropTypes.number,
  description: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
  fixedDecimalScale: PropTypes.bool,
  formDisabled: PropTypes.bool,
  isInteger: PropTypes.bool,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  required: PropTypes.bool,
  step: PropTypes.number,
  style: PropTypes.shape({}),
  thousandSeparator: PropTypes.bool,
  gap: PropTypes.string,
  width: PropTypes.string,
};

export const BaseFormFieldNumberFormatShape = PropTypes.shape(BaseFormFieldNumberFormatPropTypes);

BaseFormFieldNumberFormat.propTypes = BaseFormFieldNumberFormatPropTypes;

BaseFormFieldNumberFormat.defaultProps = {
  allowEmpty: false,
  allowNegative: false,
  allowValuesLessThanMin: true,
  className: '',
  decimalScale: undefined,
  description: null,
  disabled: false,
  fixedDecimalScale: false,
  formDisabled: false,
  isInteger: false,
  label: '',
  width: '',
  labelPlacement: 'top',
  max: MAX_NUMBER,
  min: MIN_NUMBER,
  onBlur: () => null,
  onChange: () => null,
  placeholder: '',
  prefix: undefined,
  required: false,
  step: 1,
  style: undefined,
  thousandSeparator: undefined,
  gap: 'xs',
};
