import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { patterns } from '../patterns';

export function timeMask(value) {
  const chars = value.split('');
  const hours = [/[0-2]/, chars[0] === '2' ? /[0-3]/ : /[0-9]/];

  const minutes = [/[0-5]/, /[0-9]/];

  return hours.concat(':').concat(minutes);
}

export class MaskedInput extends PureComponent {
  static propTypes = {
    pattern: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    value: '',
    pattern: patterns.any,
    onChange: () => undefined,
  };

  handleChange = (event) => {
    if (!event.target.validity.valid) {
      return;
    }
    const { onChange } = this.props;

    onChange({
      ...event,
      target: {
        ...event.target,
        value: event.target.value,
      },
    });
  };

  render() {
    return <input {...this.props} onChange={this.handleChange} />;
  }
}
