import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Box, Button, Stack } from '@blueprism/ui-core';
import { Translation } from 'react-i18next';

import { UploadButton } from 'app-buttons';
import { Loader } from 'app-loader';
import { UserAvatar } from 'app-user-avatar';
import { getFormattedFileSize } from 'app-utils';
import mimeTypesDic from 'app-utils/mimeTypes.dic';
import { notify } from 'app-toasts';
import { SVGIcon } from 'app-svg-icon';
import { enhancedT } from 'app-i18n';

import DragDropPreview from './components/DragDropPreview';
import { FilePayloadModel } from './models';
import { extentMimeTypesWithJPEG } from './utils';
import { ControlsContainer, StyledImgPreview } from './imagePicker.styled';

import './imagePicker.scss';

const SIZE_KILOBYTE = 1024;
const TIFF_FORMAT = 'tif';

const ImagePicker = (props) => {
  const {
    active,
    additionalActions,
    allowCancel,
    allowClear,
    avatarSize,
    className,
    disabled,
    editButtonText,
    image,
    loading,
    maxFileSize,
    pasteMode,
    preview,
    showDefaultAvatar,
    showDnDHelperText,
    showMaxSizeInfo,
    uploading,
  } = props;
  let { mimeTypes } = props;

  mimeTypes = extentMimeTypesWithJPEG(mimeTypes);

  const clearImage = () => {
    const { clearImage: clearImageCallback } = props;

    clearImageCallback();
  };

  const getActualValueAndLabel = (val) => {
    return getFormattedFileSize(val, maxFileSize);
  };

  const validateDimensions = (validatedImage) => {
    const { maxImageHeight, maxImageWidth } = props;

    if (validatedImage.width > maxImageWidth || validatedImage.height > maxImageHeight) {
      notify('error', ['common:IMAGE_DIMENSION_SHOULD_BE_NOT_BIGGER_THAN', { maxImageWidth, maxImageHeight }]);

      return false;
    }

    return true;
  };

  const getFormats = (formatsMimeTypes) => {
    const formats = formatsMimeTypes.map((type) => mimeTypesDic[type]);
    const lastItem = formats.pop();

    return [formats.join(', '), lastItem].join(` ${enhancedT('common:OR')} `);
  };

  const validateFile = (file) => {
    if (file.size > SIZE_KILOBYTE * maxFileSize || !mimeTypes.includes(file.type)) {
      notify('error', [
        'common:PLEASE_SELECT_FILES_NOT_BIGGER_THAN',
        {
          mimeTypes: getFormats(mimeTypes),
          size: `${getActualValueAndLabel(maxFileSize).value}${getActualValueAndLabel(maxFileSize).label}`,
        },
      ]);

      return false;
    }

    return true;
  };

  const setFileWithValidation = (file) => {
    const { setImage } = props;
    if (!validateFile(file)) return;
    const reader = new FileReader();
    reader.onload = ({ target: { result } }) => {
      const newImage = new Image();
      newImage.src = result;
      newImage.onload = () => {
        if (validateDimensions(newImage)) {
          const payload = new FilePayloadModel({
            file,
            result,
            height: newImage.height,
            width: newImage.width,
          });
          setImage(result, payload);
        }
      };
    };
    reader.readAsDataURL(file);
  };

  const onPasteListener = (event) => {
    const { items } = event.clipboardData || event.originalEvent.clipboardData;

    let blob = null;
    for (let index = 0; index < items.length; index += 1) {
      if (items[index].type.includes('image')) {
        blob = items[index].getAsFile();
      }
    }

    if (blob !== null) {
      setFileWithValidation(blob);
    }
  };

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (pasteMode) {
      document.addEventListener('paste', onPasteListener);
    }

    return () => {
      if (pasteMode) {
        document.removeEventListener('paste', onPasteListener);
      }
    };
  }, []);

  useEffect(() => {
    if (!image && image === '') {
      if (isFirstRun.current) {
        isFirstRun.current = false;

        return;
      }

      clearImage();
    }
  }, [image]);

  const setImagePayload = (file, result, newImage) => {
    const { setImage } = props;
    let payload = new FilePayloadModel({
      file,
      result,
    });
    if (newImage && validateDimensions(newImage)) {
      payload = new FilePayloadModel({
        ...payload,
        height: newImage.height,
        width: newImage.width,
      });
    }
    setImage(result, payload);
  };

  const isTiffFileFormat = (file) => {
    if (file.type) {
      return file.type.toLowerCase().indexOf(TIFF_FORMAT) >= 0;
    }
    return false;
  };

  const onSelectFile = (event) => {
    event.persist();

    if (event.target.files && event.target.files[0]) {
      const [file] = event.target.files;

      if (isTiffFileFormat(file)) {
        const reader = new FileReader();
        reader.onload = ({ target: { result } }) => {
          setImagePayload(file, result, null);
        };
        reader.readAsDataURL(file);
      } else {
        setFileWithValidation(file);
      }
      // eslint-disable-next-line
      event.target.value = '';
    }
  };

  const onDrop = useCallback((droppedFiles) => {
    if (droppedFiles && droppedFiles[0]) {
      const [file] = droppedFiles;
      setFileWithValidation(file);
    }
  }, []);

  const { getRootProps, isDragActive } = useDropzone({ onDrop, disabled });

  const renderImageContainer = () => {
    if (image && image.includes('.svg')) {
      return <SVGIcon src={image} />;
    }

    if (image && image.includes('image/tiff')) {
      return <p className="tiff-cannot-be-viewed-placeholder">{enhancedT('common:TIFF_CANNOT_BE_VIEWED')}</p>;
    }

    const isImageMightBeCached = image === '' || image === null || image.startsWith('data:');

    const cacheBustedImage = image?.includes('?') ? `${image}&v=1` : `${image}?v=1`;
    const url = isImageMightBeCached ? image : cacheBustedImage;

    return <img src={url} alt="" />;
  };

  const acceptedFileExtensions = mimeTypes
    .map((mType) => {
      const fileExtension = mimeTypesDic[mType];

      if (!fileExtension) {
        throw Error(`mimeTypesDic doesn't have such mimeType: ${mType}`);
      }

      return `.${fileExtension.toLowerCase()}`;
    })
    .join(', ');

  const isImageNotSelected = !image && !showDefaultAvatar;
  const isDropPreviewShown = isImageNotSelected || isDragActive;
  const willHelperTextShow = showDnDHelperText && isImageNotSelected && !disabled;

  const fileSize = `${getActualValueAndLabel(maxFileSize).value}${getActualValueAndLabel(maxFileSize).label}`;

  return (
    <Stack gap="xs" className={className}>
      <Stack justify="center" align="center">
        {preview && (
          <Box className="imgPreview-wrap" width="100%">
            <StyledImgPreview {...getRootProps({ disabled })}>
              {loading && <Loader active />}
              <>
                {isDropPreviewShown && (
                  <DragDropPreview
                    pasteMode={pasteMode}
                    darkenBackground={isDragActive}
                    showDnDHelperText={willHelperTextShow}
                  />
                )}
                {image && renderImageContainer()}
                {!image && showDefaultAvatar && <UserAvatar size={avatarSize} />}
              </>
            </StyledImgPreview>

            {!disabled && showMaxSizeInfo && (
              <p className="preview-max-size">
                <Translation>{(t) => t('MAX_FILE_SIZE', { fileSize })}</Translation>
              </p>
            )}
          </Box>
        )}
      </Stack>
      <ControlsContainer gap="xs" justify="end">
        {!disabled && (
          <UploadButton
            text={editButtonText}
            disabled={uploading}
            onChange={onSelectFile}
            accept={acceptedFileExtensions}
            active={active}
          />
        )}
        {additionalActions}
        {!disabled && allowClear && !allowCancel && (
          <Button onClick={clearImage} disabled={disabled}>
            <Translation>{(t) => t('common:GRID__DELETE')}</Translation>
          </Button>
        )}
        {!disabled && allowCancel && (
          <Button onClick={clearImage} className="remove-file">
            <Translation>{(t) => t('common:ANSWER__CANCEL')}</Translation>
          </Button>
        )}
      </ControlsContainer>
    </Stack>
  );
};

ImagePicker.propTypes = {
  image: PropTypes.string,
  mimeTypes: PropTypes.arrayOf(PropTypes.string),
  setImage: PropTypes.func.isRequired,
  editButtonText: PropTypes.string,
  className: PropTypes.string,
  maxImageWidth: PropTypes.number,
  maxImageHeight: PropTypes.number,
  maxFileSize: PropTypes.number,
  active: PropTypes.bool,
  allowClear: PropTypes.bool,
  allowCancel: PropTypes.bool,
  clearImage: PropTypes.func,
  preview: PropTypes.bool,
  loading: PropTypes.bool,
  showDnDHelperText: PropTypes.bool,
  showDefaultAvatar: PropTypes.bool,
  showMaxSizeInfo: PropTypes.bool,
  pasteMode: PropTypes.bool,
  hideButton: PropTypes.bool,
  disabled: PropTypes.bool,
  uploading: PropTypes.bool,
  avatarSize: PropTypes.string,
  additionalActions: PropTypes.node,
};

ImagePicker.defaultProps = {
  image: '',
  editButtonText: enhancedT('EDIT_AVATAR'),
  className: '',
  mimeTypes: Object.keys(mimeTypesDic),
  maxImageWidth: 200,
  maxImageHeight: 200,
  maxFileSize: 30,
  active: true,
  allowClear: false,
  allowCancel: false,
  loading: false,
  preview: true,
  clearImage: () => {},
  showDnDHelperText: true,
  showDefaultAvatar: false,
  showMaxSizeInfo: true,
  pasteMode: false,
  hideButton: false,
  disabled: false,
  uploading: false,
  avatarSize: 'medium',
  additionalActions: null,
};

export default ImagePicker;
