import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

export const BaseFormElementHorizontalSeparator = (props) => {
  const { className, paddingBottom, paddingTop } = props;

  const styles = {
    marginTop: paddingTop,
    marginBottom: paddingBottom,
  };

  return <hr style={styles} className={cn('', [`${className}`])} />;
};

BaseFormElementHorizontalSeparator.propTypes = {
  className: PropTypes.string,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
};

BaseFormElementHorizontalSeparator.defaultProps = {
  className: '',
  paddingTop: 5,
  paddingBottom: 5,
};
