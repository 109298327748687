import * as Yup from 'yup';

import { FIELD_TYPES } from '../constants/fieldTypes';
import { defineNumberProps } from './defineNumberProps';
import { defineDropdownProps } from './defineDropdownProps';
import { mapFieldValidationSchema } from './mapFieldValidationSchema';

export const generateRuleValueSchema = (configForType, element, optionValue) => {
  const { label, payload } = element;
  switch (configForType.type) {
    case FIELD_TYPES.TEXT:
    case FIELD_TYPES.TEXTAREA:
    case FIELD_TYPES.DATE: {
      return mapFieldValidationSchema({ label, ...payload, ...configForType });
    }
    case FIELD_TYPES.DATE_MONTH:
    case FIELD_TYPES.DATE_YEAR: {
      return mapFieldValidationSchema({ label, ...payload, ...configForType });
    }
    case FIELD_TYPES.NUMBER: {
      const numberProps = defineNumberProps(payload, element.type, element.elementType);
      return mapFieldValidationSchema({ ...numberProps, ...configForType, label });
    }
    case FIELD_TYPES.TIME:
    case FIELD_TYPES.DROPDOWN: {
      const dropdownProps = defineDropdownProps(optionValue, payload);

      return mapFieldValidationSchema({ ...dropdownProps, ...configForType, label });
    }
    default: {
      return Yup.mixed();
    }
  }
};
