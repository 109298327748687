import * as R from 'ramda';

import { isEmpty } from 'app-utils';

import { PageModel } from './PageModel';
import { assignConfigChanges } from '../utils';
import { UPLOAD_STRATEGIES } from '../constants';

export class FormModel {
  constructor(form, payload) {
    this.setup(form, payload);
  }

  setup(form, payload) {
    this.id = form.id;
    this.name = form.name;
    this.status = form.status;
    this.imagePath = form.imagePath;
    this.uploadStrategy = form.isOnPremise ? UPLOAD_STRATEGIES.FILE_SERVICE : UPLOAD_STRATEGIES.AZURE;
    this.description = form.description;
    this.formVersionId = form.formVersionId;
    this.submissionId = form.submissionId;
    this.isLatestFormVersion = form.isLatestFormVersion;
    this.lastCompleted = form.lastCompleted;
    this.submitterId = form.submitterId;
    this.configChanges = form.configChanges && JSON.parse(form.configChanges);
    this.pages = this.setupPages(form, payload);
    this.initialValues = this.assignInitialValues(form.formValues);
  }

  assignInitialValues = (values) => {
    if (isEmpty(values)) return null;

    const initialValues = R.map(JSON.parse, values);

    this.pages.forEach(({ fields }) => {
      fields.forEach((field) => {
        const { assignValue, automationId } = field;

        if (field.assignValue) {
          const value = assignValue({ ...field, value: initialValues[automationId] });

          Object.assign(initialValues, { [automationId]: value });
        }
      });
    });

    return initialValues;
  };

  setupPages = (form, payload) => {
    let _form = form;

    if (_form.configChanges) _form = assignConfigChanges(_form);

    const pagesForPageModel = _form.pages.map((page) => new PageModel({ page, pages: _form.pages }));
    const pages = _form.pages.map(
      (page) =>
        new PageModel({
          page,
          pages: pagesForPageModel,
          rules: page.rules,
          payload,
        }),
    );

    return R.sortBy(R.prop('order'))(pages);
  };
}
