import moment from 'moment';

export const dateSorter = (array, field) =>
  array.sort((a, b) => moment(b[field]).valueOf() - moment(a[field]).valueOf());

export const SEC = 1;
export const SEC_IN_MINS = 60 * SEC;
export const SEC_IN_HRS = 60 * SEC_IN_MINS;

export * from './constants';
export * from './BaseTimePicker';
