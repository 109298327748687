import i18n from 'i18next';
import { observable, action } from 'mobx';

import { bootstrapI18n, authServerOptions, APP_LANGUAGES } from 'app-i18n';

import { languageParse } from '../utils';

const languageItem = 'authServerLanguage';

export class AuthServerLanguageStore {
  @observable currentLanguage = 'en';

  constructor() {
    this._registerMissingKeyEvent();
  }

  availableLanguages = APP_LANGUAGES;

  setLanguageToLocalStorage = (nextLang) => {
    localStorage.setItem(languageItem, nextLang);
  };

  getLanguageFromLocalStorage = () => localStorage.getItem(languageItem) || false;

  @action
  setup = async () => {
    const defaultLanguage = this.getLanguageFromLocalStorage() || languageParse(navigator.language);

    await bootstrapI18n(authServerOptions, defaultLanguage);
    this.currentLanguage = defaultLanguage;
  };

  getCurrentLng = () => i18n.language;

  getSupportedLanguages = () => i18n.languages;

  @action
  changeLanguage = (language) => {
    i18n.changeLanguage(language, (err) => {
      if (err) return console.error('Something went wrong with changeLanguage', err);

      this.setLanguageToLocalStorage(language);
      this.currentLanguage = this.getCurrentLng();
    });
  };

  _registerMissingKeyEvent = () => {
    i18n.on('missingKey', (languages, namespace, key, res) => {
      // eslint-disable-next-line
      console.error('MissingKeyInfo');
      // eslint-disable-next-line
      console.table({
        namespace,
        lngs: languages.join(', '),
        key,
        res,
      });
    });
  };
}
