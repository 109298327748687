import { RULE_TYPE } from './RuleOptionsAndTypes';
import { SELECTION_TYPES_ID } from './selectionTypes';
import { UPLOAD_TYPES_ID } from './uploadTypes';
import { ELEMENT_TYPES } from './ElementTypes';
import { FIELD_TYPES } from './fieldTypes';
import {
  resolveRuleValue,
  resolveRuleValueForYear,
  resolveSetValueTo,
  resolveExtensions,
  resolveSubmitters,
  resolveMultiSelect,
  resolveSelect,
  equals,
  doesNotEqual,
  contains,
  doesNotContain,
  lessThen,
  lessThenOrEqual,
  moreThen,
  moreThenOrEqual,
  isAfterTime,
  isBeforeTime,
  uploadTypeIs,
  uploadTypeIsNot,
  fileSizeGreaterThen,
  fileSizeLessThen,
  equalsByObjValue,
  doesNotEqualByValue,
  multiContains,
  multiDoesNotContain,
  quantityIs,
  quantityIsNot,
  quantityIsLessThen,
  quantityIsMoreThen,
  quantityIsLessThenOrEqual,
  quantityIsMoreThenOrEqual,
  equalsByValue,
  dontEqualsByValue,
  equalsByValueArray,
  dontEqualsByValueArray,
  flatMultiContains,
  doesNotFlatMultiContain,
  equalDates,
  doesNotEqualDates,
  dateIsAfter,
  dateIsBefore,
  dateIsInMonth,
  dateIsNotInMonth,
  dateIsInYear,
  dateIsNotInYear,
  compareValueWithObjectValue,
  equalsCheckbox,
} from '../utils';

export const RULE_IF_VALUE_TYPES = {
  [ELEMENT_TYPES.FIELD]: {
    [FIELD_TYPES.TEXT]: {
      [RULE_TYPE.CONTAINS]: {
        value: '',
        required: true,
        type: FIELD_TYPES.TEXT,
        resolveRuleValue,
        checkRuleTrigger: contains,
      },
      [RULE_TYPE.DOES_NOT_CONTAIN]: {
        value: '',
        required: true,
        type: FIELD_TYPES.TEXT,
        resolveRuleValue,
        checkRuleTrigger: doesNotContain,
      },
      [RULE_TYPE.EQUALS]: {
        value: '',
        type: FIELD_TYPES.TEXT,
        resolveRuleValue,
        checkRuleTrigger: equals,
      },
      [RULE_TYPE.DOES_NOT_EQUAL]: {
        value: '',
        type: FIELD_TYPES.TEXT,
        resolveRuleValue,
        checkRuleTrigger: doesNotEqual,
      },
    },
    [FIELD_TYPES.TEXTAREA]: {
      [RULE_TYPE.CONTAINS]: {
        value: '',
        required: true,
        type: FIELD_TYPES.TEXTAREA,
        resolveRuleValue,
        checkRuleTrigger: contains,
      },
      [RULE_TYPE.DOES_NOT_CONTAIN]: {
        value: '',
        required: true,
        type: FIELD_TYPES.TEXTAREA,
        resolveRuleValue,
        checkRuleTrigger: doesNotContain,
      },
      [RULE_TYPE.EQUALS]: {
        value: '',
        type: FIELD_TYPES.TEXTAREA,
        resolveRuleValue,
        checkRuleTrigger: equals,
      },
      [RULE_TYPE.DOES_NOT_EQUAL]: {
        value: '',
        type: FIELD_TYPES.TEXTAREA,
        resolveRuleValue,
        checkRuleTrigger: doesNotEqual,
      },
    },
    [FIELD_TYPES.NUMBER]: {
      [RULE_TYPE.EQUALS]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        resolveRuleValue,
        checkRuleTrigger: equals,
      },
      [RULE_TYPE.DOES_NOT_EQUAL]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        resolveRuleValue,
        checkRuleTrigger: doesNotEqual,
      },
      [RULE_TYPE.IS_LESS_THAN]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        resolveRuleValue,
        checkRuleTrigger: lessThen,
      },
      [RULE_TYPE.IS_LESS_THAN_OR_EQUAL_TO]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        resolveRuleValue,
        checkRuleTrigger: lessThenOrEqual,
      },
      [RULE_TYPE.IS_MORE_THAN]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        resolveRuleValue,
        checkRuleTrigger: moreThen,
      },
      [RULE_TYPE.IS_MORE_THAN_OR_EQUAL_TO]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        resolveRuleValue,
        checkRuleTrigger: moreThenOrEqual,
      },
    },
    [FIELD_TYPES.DATE]: {
      [RULE_TYPE.DATE_EQUALS]: {
        type: FIELD_TYPES.DATE,
        required: true,
        resolveRuleValue,
        checkRuleTrigger: equalDates,
      },
      [RULE_TYPE.DATE_DOES_NOT_EQUAL]: {
        type: FIELD_TYPES.DATE,
        required: true,
        resolveRuleValue,
        checkRuleTrigger: doesNotEqualDates,
      },
      [RULE_TYPE.DATE_IS_AFTER]: {
        type: FIELD_TYPES.DATE,
        required: true,
        resolveRuleValue,
        checkRuleTrigger: dateIsAfter,
      },
      [RULE_TYPE.DATE_IS_BEFORE]: {
        type: FIELD_TYPES.DATE,
        required: true,
        resolveRuleValue,
        checkRuleTrigger: dateIsBefore,
      },
      [RULE_TYPE.DATE_IS_IN_MONTH]: {
        type: FIELD_TYPES.DATE_MONTH,
        mode: 'month',
        required: true,
        resolveRuleValue,
        format: 'MMMM, YYYY',
        checkRuleTrigger: dateIsInMonth,
      },
      [RULE_TYPE.DATE_IS_NOT_IN_MONTH]: {
        type: FIELD_TYPES.DATE_MONTH,
        mode: 'month',
        required: true,
        resolveRuleValue,
        format: 'MMMM, YYYY',
        checkRuleTrigger: dateIsNotInMonth,
      },
      [RULE_TYPE.DATE_IS_IN_YEAR]: {
        type: FIELD_TYPES.DATE_YEAR,
        required: true,
        mode: 'year',
        format: 'YYYY',
        min: 1000,
        max: 9999,
        resolveRuleValue: resolveRuleValueForYear,
        checkRuleTrigger: dateIsInYear,
      },
      [RULE_TYPE.DATE_IS_NOT_IN_YEAR]: {
        type: FIELD_TYPES.DATE_YEAR,
        required: true,
        mode: 'year',
        format: 'YYYY',
        min: 1000,
        max: 9999,
        resolveRuleValue: resolveRuleValueForYear,
        checkRuleTrigger: dateIsNotInYear,
      },
    },
    [FIELD_TYPES.UPLOAD]: {
      [RULE_TYPE.FILE_SIZE_IS_GREATER_THAN]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        decimalScale: 0,
        min: 1,
        resolveRuleValue,
        checkRuleTrigger: fileSizeGreaterThen,
        checkValidationConflict: ({ uploadTypes }) => uploadTypes !== UPLOAD_TYPES_ID.FILE,
      },
      [RULE_TYPE.FILE_SIZE_IS_LESS_THAN]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        decimalScale: 0,
        min: 1,
        resolveRuleValue,
        checkRuleTrigger: fileSizeLessThen,
        checkValidationConflict: ({ uploadTypes }) => uploadTypes !== UPLOAD_TYPES_ID.FILE,
      },
      [RULE_TYPE.FILE_TYPE_IS]: {
        value: '',
        type: FIELD_TYPES.DROPDOWN,
        required: true,
        resolveRuleValue: resolveExtensions,
        checkRuleTrigger: uploadTypeIs,
        checkValidationConflict: ({ uploadTypes }) => uploadTypes !== UPLOAD_TYPES_ID.FILE,
      },
      [RULE_TYPE.FILE_TYPE_IS_NOT]: {
        value: '',
        type: FIELD_TYPES.DROPDOWN,
        required: true,
        resolveRuleValue: resolveExtensions,
        checkRuleTrigger: uploadTypeIsNot,
        checkValidationConflict: ({ uploadTypes }) => uploadTypes !== UPLOAD_TYPES_ID.FILE,
      },
      [RULE_TYPE.IMAGE_TYPE_IS]: {
        value: '',
        type: FIELD_TYPES.DROPDOWN,
        required: true,
        resolveRuleValue: resolveExtensions,
        checkRuleTrigger: uploadTypeIs,
        checkValidationConflict: ({ uploadTypes }) => uploadTypes !== UPLOAD_TYPES_ID.IMAGE,
      },
      [RULE_TYPE.IMAGE_TYPE_IS_NOT]: {
        value: '',
        type: FIELD_TYPES.DROPDOWN,
        required: true,
        resolveRuleValue: resolveExtensions,
        checkRuleTrigger: uploadTypeIsNot,
        checkValidationConflict: ({ uploadTypes }) => uploadTypes !== UPLOAD_TYPES_ID.IMAGE,
      },
    },
    [FIELD_TYPES.TIME]: {
      [RULE_TYPE.TIME_EQUALS]: {
        value: '00:00:00',
        type: FIELD_TYPES.TIME,
        required: true,
        resolveRuleValue,
        checkRuleTrigger: equals,
      },
      [RULE_TYPE.TIME_DOES_NOT_EQUAL]: {
        value: '00:00:00',
        type: FIELD_TYPES.TIME,
        required: true,
        resolveRuleValue,
        checkRuleTrigger: doesNotEqual,
      },
      [RULE_TYPE.TIME_IS_AFTER]: {
        value: '00:00:00',
        type: FIELD_TYPES.TIME,
        required: true,
        resolveRuleValue,
        checkRuleTrigger: isAfterTime,
      },
      [RULE_TYPE.TIME_IS_BEFORE]: {
        value: '00:00:00',
        type: FIELD_TYPES.TIME,
        required: true,
        resolveRuleValue,
        checkRuleTrigger: isBeforeTime,
      },
    },
    [FIELD_TYPES.DROPDOWN]: {
      [RULE_TYPE.EQUALS]: {
        value: '',
        type: FIELD_TYPES.DROPDOWN,
        required: true,
        resolveRuleValue: resolveSetValueTo,
        checkRuleTrigger: equalsByObjValue,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.SINGLE,
      },
      [RULE_TYPE.DOES_NOT_EQUAL]: {
        value: '',
        type: FIELD_TYPES.DROPDOWN,
        required: true,
        resolveRuleValue: resolveSetValueTo,
        checkRuleTrigger: doesNotEqualByValue,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.SINGLE,
      },
      [RULE_TYPE.CONTAINS]: {
        value: '',
        type: FIELD_TYPES.DROPDOWN,
        required: true,
        isMulti: true,
        resolveRuleValue: resolveMultiSelect,
        checkRuleTrigger: multiContains,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.MULTI,
      },
      [RULE_TYPE.DOES_NOT_CONTAIN]: {
        value: '',
        type: FIELD_TYPES.DROPDOWN,
        required: true,
        isMulti: true,
        resolveRuleValue: resolveMultiSelect,
        checkRuleTrigger: multiDoesNotContain,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.MULTI,
      },
      [RULE_TYPE.SELECTION_QUANTITY_IS]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        decimalScale: 0,
        resolveRuleValue,
        checkRuleTrigger: quantityIs,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.MULTI,
      },
      [RULE_TYPE.SELECTION_QUANTITY_IS_NOT]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        decimalScale: 0,
        resolveRuleValue,
        checkRuleTrigger: quantityIsNot,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.MULTI,
      },
      [RULE_TYPE.SELECTION_QUANTITY_IS_LESS_THAN]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        decimalScale: 0,
        resolveRuleValue,
        checkRuleTrigger: quantityIsLessThen,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.MULTI,
      },
      [RULE_TYPE.SELECTION_QUANTITY_IS_MORE_THAN]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        decimalScale: 0,
        resolveRuleValue,
        checkRuleTrigger: quantityIsMoreThen,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.MULTI,
      },
      [RULE_TYPE.SELECTION_QUANTITY_IS_LESS_THAN_OR_EQUAL_TO]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        decimalScale: 0,
        resolveRuleValue,
        checkRuleTrigger: quantityIsLessThenOrEqual,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.MULTI,
      },
      [RULE_TYPE.SELECTION_QUANTITY_IS_MORE_THAN_OR_EQUAL_TO]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        decimalScale: 0,
        resolveRuleValue,
        checkRuleTrigger: quantityIsMoreThenOrEqual,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.MULTI,
      },
    },
    [FIELD_TYPES.TABLE]: {
      [RULE_TYPE.NUMBER_OF_ROWS_ADDED_EQUALS]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        decimalScale: 0,
        resolveRuleValue,
        checkRuleTrigger: quantityIs,
      },
      [RULE_TYPE.NUMBER_OF_ROWS_ADDED_IS_MORE_THAN]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        decimalScale: 0,
        resolveRuleValue,
        checkRuleTrigger: quantityIsMoreThen,
      },
      [RULE_TYPE.NUMBER_OF_ROWS_ADDED_IS_LESS_THAN]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        decimalScale: 0,
        resolveRuleValue,
        checkRuleTrigger: quantityIsLessThen,
      },
    },
    [FIELD_TYPES.RADIO_GROUP]: {
      [RULE_TYPE.EQUALS]: {
        value: '',
        type: FIELD_TYPES.DROPDOWN,
        required: true,
        resolveRuleValue: resolveSetValueTo,
        checkRuleTrigger: equalsByValue,
      },
      [RULE_TYPE.DOES_NOT_EQUAL]: {
        value: '',
        type: FIELD_TYPES.DROPDOWN,
        required: true,
        resolveRuleValue: resolveSetValueTo,
        checkRuleTrigger: dontEqualsByValue,
      },
    },
    [FIELD_TYPES.CHECKBOX_GROUP]: {
      [RULE_TYPE.EQUALS]: {
        value: '',
        type: FIELD_TYPES.DROPDOWN,
        required: true,
        resolveRuleValue: resolveSetValueTo,
        checkRuleTrigger: equalsByValueArray,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.SINGLE,
      },
      [RULE_TYPE.DOES_NOT_EQUAL]: {
        value: '',
        type: FIELD_TYPES.DROPDOWN,
        required: true,
        resolveRuleValue: resolveSetValueTo,
        checkRuleTrigger: dontEqualsByValueArray,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.SINGLE,
      },
      [RULE_TYPE.CONTAINS]: {
        value: '',
        type: FIELD_TYPES.DROPDOWN,
        required: true,
        isMulti: true,
        resolveRuleValue: resolveMultiSelect,
        checkRuleTrigger: flatMultiContains,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.MULTI,
      },
      [RULE_TYPE.DOES_NOT_CONTAIN]: {
        value: '',
        type: FIELD_TYPES.DROPDOWN,
        required: true,
        isMulti: true,
        resolveRuleValue: resolveMultiSelect,
        checkRuleTrigger: doesNotFlatMultiContain,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.MULTI,
      },
      [RULE_TYPE.SELECTION_QUANTITY_IS]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        decimalScale: 0,
        resolveRuleValue,
        checkRuleTrigger: quantityIs,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.MULTI,
      },
      [RULE_TYPE.SELECTION_QUANTITY_IS_NOT]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        decimalScale: 0,
        resolveRuleValue,
        checkRuleTrigger: quantityIsNot,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.MULTI,
      },
      [RULE_TYPE.SELECTION_QUANTITY_IS_LESS_THAN]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        decimalScale: 0,
        resolveRuleValue,
        checkRuleTrigger: quantityIsLessThen,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.MULTI,
      },
      [RULE_TYPE.SELECTION_QUANTITY_IS_MORE_THAN]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        decimalScale: 0,
        resolveRuleValue,
        checkRuleTrigger: quantityIsMoreThen,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.MULTI,
      },
      [RULE_TYPE.SELECTION_QUANTITY_IS_LESS_THAN_OR_EQUAL_TO]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        decimalScale: 0,
        resolveRuleValue,
        checkRuleTrigger: quantityIsLessThenOrEqual,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.MULTI,
      },
      [RULE_TYPE.SELECTION_QUANTITY_IS_MORE_THAN_OR_EQUAL_TO]: {
        value: null,
        type: FIELD_TYPES.NUMBER,
        required: true,
        decimalScale: 0,
        resolveRuleValue,
        checkRuleTrigger: quantityIsMoreThenOrEqual,
        checkValidationConflict: ({ selectionType }) => selectionType !== SELECTION_TYPES_ID.MULTI,
      },
    },
    [FIELD_TYPES.CHECKBOX]: {
      [RULE_TYPE.EQUALS]: {
        value: '',
        type: FIELD_TYPES.DROPDOWN,
        required: true,
        resolveRuleValue: resolveSelect,
        checkRuleTrigger: equalsCheckbox,
      },
    },
  },
  [ELEMENT_TYPES.SUBMITTER]: {
    [ELEMENT_TYPES.SUBMITTER]: {
      [RULE_TYPE.IS]: {
        value: '',
        type: FIELD_TYPES.DROPDOWN,
        required: true,
        options: [],
        resolveValue({ fullName, id }) {
          return {
            id,
            value: id,
            label: fullName,
          };
        },
        resolveOptions(options) {
          return options.map(this.resolveValue);
        },
        resolveRuleValue: resolveSubmitters,
        checkRuleTrigger: compareValueWithObjectValue,
      },
    },
  },
};
