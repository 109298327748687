import { FIELD_TYPES } from 'app-base-form/constants';

export const createPasswordFormConfig = (t) => ({
  title: t('SETUP_PASSWORD_TITLE'),
  submitButtonLabel: t('common:SAVE'),
  fields: [
    {
      type: FIELD_TYPES.PASSWORD,
      value: '',
      name: 'password',
      label: t('PASSWORD'),
      labelPlacement: 'left',
      required: true,
      isStrict: true,
      minlength: 8,
      maxlength: 32,
    },
    {
      type: FIELD_TYPES.CONFIRM_PASSWORD,
      value: '',
      name: 'confirmPassword',
      label: t('CONFIRM_PASSWORD'),
      labelPlacement: 'left',
      required: true,
      minlength: 8,
      maxlength: 32,
    },
  ],
});
