import styled, { css } from 'styled-components';
import { Stack, Row, Box } from '@blueprism/ui-core';

import background from 'src/assets/images/login.svg';

export const StyledStack = styled(Stack)(
  ({ theme }) => css`
    background-image: url(${background});
    background-color: ${theme.color.action};
    background-position: center;
    background-size: cover;
    image-rendering: optimizeQuality;
  `,
);

export const Header = styled(Row)(
  ({ theme }) => css`
    z-index: ${theme.layer.panels};
  `,
);

export const LogoContainer = styled(Box)`
  img {
    height: 100px;

    @media (max-width: 1200px) {
      height: 70px;
    }
  }
`;

export const AccountForm = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;

  @media (max-width: 992px) {
    width: 100%;
  }

  .formik .input-block-left {
    label {
      padding-right: 10px;
    }

    input {
      width: 190px;
    }
  }
`;
