import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { NumberFormat } from 'app-inputs';

export const YearDatePicker = ({ id, onBlur, onDateChange, value }) => {
  const [year, changeYear] = useState(null);

  useEffect(() => {
    if (!value) {
      changeYear(null);
      return;
    }

    changeYear(value.year());
  }, [value]);

  const onYearChanged = (yearValue) => {
    changeYear(yearValue);

    if (yearValue) {
      const formattedDate = moment()
        .utc()
        .year(yearValue)
        .month(0)
        .date(1)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0);

      onDateChange(formattedDate);
    } else {
      onDateChange(null);
    }
  };

  return <NumberFormat max={9999} value={year} id={id} onBlur={onBlur} allowEmpty onChange={onYearChanged} />;
};

YearDatePicker.propTypes = {
  onDateChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onBlur: PropTypes.func,
};

YearDatePicker.defaultProps = {
  value: null,
  onBlur: () => null,
};
