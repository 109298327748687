import * as Yup from 'yup';

import { enhancedT } from 'app-i18n';
import { passwordValidation, containPasswordWordValidation } from 'app-utils/validation/newValidation';
import { replaceSpecialSymbols, isEmpty } from 'app-utils';

import { VALIDATION_MESSAGES } from '../constants/ValidationMessages';
import { FIELD_TYPES } from '../constants/fieldTypes';
import { requiredRichTextarea } from './validationMethods';

export const VALIDATIONS = {
  [FIELD_TYPES.TEXT]: {
    _type: Yup.string()
      .transform((v) => (v === '' ? null : v))
      .nullable(),
    required: (val, text) => {
      return val
        ? Yup.string().trim().nullable().required(VALIDATION_MESSAGES.getReqMsg(text))
        : Yup.string().trim().nullable();
    },
    minlength: (val) => !val || Yup.string().trim().nullable().min(val, VALIDATION_MESSAGES.getMinLenMsg(val)),
    maxlength: (val) => !val || Yup.string().trim().nullable().max(val, VALIDATION_MESSAGES.getMaxLenMsg(val)),
    email: () => Yup.string().trim().nullable().email(VALIDATION_MESSAGES.getEmailMsg()),
    matchRegEx: (val) => Yup.string().trim().nullable().matches(val.pattern, val.errorMessage),
    regex: ({ errorMessage, pattern }) => {
      return pattern
        ? Yup.string()
            .nullable()
            .transform((v) => (v === '' ? null : v))
            .matches(new RegExp(pattern, 'm'), { message: errorMessage, excludeEmptyString: true })
        : Yup.string()
            .trim()
            .transform((v) => (v === '' ? null : v))
            .nullable();
    },
    allowedChars: (val) => {
      if (val && val.length > 0) {
        const pattern = replaceSpecialSymbols(val);

        return Yup.string()
          .nullable()
          .matches(new RegExp(`^[${pattern}]+$`, 'm'), {
            message: VALIDATION_MESSAGES.getAllowedCharsMsg(val),
            excludeEmptyString: true,
          });
      }

      return Yup.string().trim().nullable();
    },
    nonAllowedChars: (val) => {
      if (val && val.length > 0) {
        const pattern = replaceSpecialSymbols(val);
        return Yup.string()
          .nullable()
          .matches(new RegExp(`^[^${pattern}]+$`, 'm'), {
            message: VALIDATION_MESSAGES.getNonAllowedCharsMsg(val),
            excludeEmptyString: true,
          });
      }

      return Yup.string().trim().nullable();
    },
  },
  [FIELD_TYPES.TEXTAREA]: {
    _type: Yup.string()
      .transform((v) => (v === '' ? null : v))
      .nullable(),
    required: (val, text) => {
      return val
        ? Yup.string().trim().nullable().required(VALIDATION_MESSAGES.getReqMsg(text))
        : Yup.string().trim().nullable();
    },
    minlength: (val) => !val || Yup.string().trim().nullable().min(val, VALIDATION_MESSAGES.getMinLenMsg(val)),
    maxlength: (val) => !val || Yup.string().trim().nullable().max(val, VALIDATION_MESSAGES.getMaxLenMsg(val)),
    email: () => Yup.string().trim().nullable().email(VALIDATION_MESSAGES.getEmailMsg()),
    matchRegEx: (val) => Yup.string().trim().nullable().matches(val.pattern, val.errorMessage),
    regex: ({ errorMessage, pattern }) => {
      return pattern
        ? Yup.string()
            .nullable()
            .transform((v) => (v === '' ? null : v))
            .matches(new RegExp(pattern, 'm'), { message: errorMessage, excludeEmptyString: true })
        : Yup.string()
            .trim()
            .transform((v) => (v === '' ? null : v))
            .nullable();
    },
    allowedChars: (val) => {
      if (val && val.length > 0) {
        const pattern = replaceSpecialSymbols(val);

        return Yup.string()
          .nullable()
          .matches(new RegExp(`^[${pattern}]+$`, 'g'), {
            message: VALIDATION_MESSAGES.getAllowedCharsMsg(val),
            excludeEmptyString: true,
          });
      }

      return Yup.string().trim().nullable();
    },
    nonAllowedChars: (val) => {
      if (val && val.length > 0) {
        const pattern = replaceSpecialSymbols(val);
        return Yup.string()
          .nullable()
          .matches(new RegExp(`^[^${pattern}]+$`, 'g'), {
            message: VALIDATION_MESSAGES.getNonAllowedCharsMsg(val),
            excludeEmptyString: true,
          });
      }

      return Yup.string().trim().nullable();
    },
  },
  [FIELD_TYPES.NUMBER]: {
    _type: Yup.number().nullable(),
    required: (val, text) => {
      return val ? Yup.number().nullable().required(VALIDATION_MESSAGES.getReqMsg(text)) : Yup.number().nullable();
    },
    max: (val) => Yup.number().nullable().max(val, VALIDATION_MESSAGES.getMaxNumberMsg(val)),
    min: (val) => Yup.number().nullable().min(val, VALIDATION_MESSAGES.getMinNumberMsg(val)),
  },
  [FIELD_TYPES.PASSWORD]: {
    _type: Yup.string().nullable(),
    isStrict: (val, text) => {
      return val
        ? Yup.string()
            .trim()
            .test('string', VALIDATION_MESSAGES.getPwdErrorCharacterMsg(text), passwordValidation)
            .test('string', VALIDATION_MESSAGES.getPwdErrorContainMsg(text), containPasswordWordValidation)
        : Yup.string().trim();
    },
    required: (val, text) => {
      return val ? Yup.string().trim().required(VALIDATION_MESSAGES.getReqMsg(text)) : Yup.string().trim();
    },
    minlength: (val, text) => Yup.string().trim().min(val, VALIDATION_MESSAGES.getMinLenMsg(val, text)),
    maxlength: (val, text) => Yup.string().trim().max(val, VALIDATION_MESSAGES.getMaxLenMsg(val, text)),
  },
  [FIELD_TYPES.CONFIRM_PASSWORD]: {
    _type: Yup.string().nullable(),
    required: (val, text) => {
      return val
        ? Yup.string()
            .trim()
            .oneOf([Yup.ref('password')], VALIDATION_MESSAGES.getConfirmPwdMsg())
            .required(VALIDATION_MESSAGES.getReqMsg(text))
        : Yup.string().trim();
    },
  },
  [FIELD_TYPES.DROPDOWN]: {
    _type: Yup.mixed().nullable(),
    required: (val, text) => {
      return val
        ? Yup.mixed()
            .nullable()
            .test('isNotEmpty', VALIDATION_MESSAGES.getReqMsg(text), (obj) => !isEmpty(obj))
        : Yup.mixed().nullable();
    },
    minSelection: (minSelection) =>
      Yup.mixed()
        .nullable()
        .test('minSelection', VALIDATION_MESSAGES.getMinSelectionMsg(minSelection), (value) => {
          if (isEmpty(value)) {
            return true;
          }

          return value.length >= minSelection;
        }),
    maxSelection: (maxSelection) =>
      Yup.mixed()
        .nullable()
        .test('maxSelection', VALIDATION_MESSAGES.getMaxSelectionMsg(maxSelection), (value) => {
          if (isEmpty(value)) {
            return true;
          }

          return value.length <= maxSelection;
        }),
  },

  [FIELD_TYPES.CHECKBOX]: {
    _type: Yup.mixed().nullable(),
    required: (val, text) => {
      return val
        ? Yup.mixed().nullable().oneOf([true], VALIDATION_MESSAGES.getReqMsg(text))
        : Yup.mixed().nullable().oneOf([true, false]);
    },
  },
  [FIELD_TYPES.SWITCH]: {
    _type: Yup.mixed().nullable(),
    required: (val, text) => {
      return val
        ? Yup.mixed().nullable().oneOf([true], VALIDATION_MESSAGES.getReqMsg(text))
        : Yup.mixed().nullable().oneOf([true, false]);
    },
  },
  [FIELD_TYPES.CHECKBOX_GROUP]: {
    _type: Yup.array().nullable(),
    required: (val, text) => {
      return val
        ? Yup.array()
            .nullable()
            .test('isNotEmpty', VALIDATION_MESSAGES.getReqMsg(text), (obj) => !isEmpty(obj))
        : Yup.array().nullable();
    },
    minSelection: (val) => Yup.array().nullable().min(val, VALIDATION_MESSAGES.getMinSelectionMsg(val)),
    maxSelection: (val) => Yup.array().nullable().max(val, VALIDATION_MESSAGES.getMaxSelectionMsg(val)),
  },

  [FIELD_TYPES.RADIO_GROUP]: {
    _type: Yup.string().nullable(),
    // TODO: delete after implementation
    required: (val, text) => {
      return val ? Yup.string().nullable().required(VALIDATION_MESSAGES.getReqMsg(text)) : Yup.string().nullable();
    },
  },

  [FIELD_TYPES.IMAGE]: {
    _type: Yup.string().nullable(),
    required: (val, text) => {
      return val ? Yup.string().nullable().required(VALIDATION_MESSAGES.getReqMsg(text)) : Yup.string().nullable();
    },
  },

  [FIELD_TYPES.SLIDER]: {
    _type: Yup.number().nullable(),
  },

  [FIELD_TYPES.DATE]: {
    _type: Yup.string().nullable(),
    required: (val, text) => {
      return val ? Yup.string().nullable().required(VALIDATION_MESSAGES.getReqMsg(text)) : Yup.string().nullable();
    },
  },

  [FIELD_TYPES.TIME]: {
    _type: Yup.string().nullable(),
    required: (val, text) => {
      return val ? Yup.string().nullable().required(VALIDATION_MESSAGES.getReqMsg(text)) : Yup.string().nullable();
    },
  },
  [FIELD_TYPES.HEADER]: {
    _type: Yup.string().notRequired(),
  },
  [FIELD_TYPES.HORIZONTAL_RULE]: {
    _type: Yup.mixed().notRequired(),
  },
  [FIELD_TYPES.TEMPLATE]: {
    _type: Yup.mixed().notRequired(),
  },
  [FIELD_TYPES.PARAGRAPH]: {
    _type: Yup.mixed().notRequired(),
  },
  [FIELD_TYPES.TABLE]: {
    _type: Yup.array().nullable(),
    required: (val, text, field) => {
      const schema = Yup.string().max(50, VALIDATION_MESSAGES.getMaxLenMsg(50));
      const customSchema = field.columns.reduce((columnAcc, column) => {
        const columnSchema = column.checked
          ? schema
          : schema.required(VALIDATION_MESSAGES.getReqMsg(enhancedT('common:VALUE')));
        return { ...columnAcc, [column.value]: columnSchema };
      }, {});

      return val ? Yup.array().of(Yup.object().shape(customSchema)) : Yup.array();
    },
  },
  [FIELD_TYPES.UPLOAD]: {
    _type: Yup.object().nullable().notRequired(),
    required: (val, text) => {
      return val ? Yup.object().nullable().required(VALIDATION_MESSAGES.getReqMsg(text)) : Yup.object().nullable();
    },
  },
  [FIELD_TYPES.RICH_TEXTAREA]: {
    _type: Yup.object().notRequired(),
    required: (val, text) => {
      return val
        ? Yup.object().nullable().test('isRichEditorHasText', VALIDATION_MESSAGES.getReqMsg(text), requiredRichTextarea)
        : Yup.object().nullable();
    },
  },
  [FIELD_TYPES.DATE_MONTH]: {
    _type: Yup.mixed().nullable(),
    required: (val, text) => {
      return val
        ? Yup.mixed()
            .nullable()
            .test('isNotEmpty', VALIDATION_MESSAGES.getReqMsg(text), (obj) => !isEmpty(obj))
        : Yup.mixed().nullable();
    },
  },
  [FIELD_TYPES.DATE_YEAR]: {
    _type: Yup.object().nullable(),
    required: (val, text) => {
      return val ? Yup.object().nullable().required(VALIDATION_MESSAGES.getReqMsg(text)) : Yup.object().nullable();
    },
    max: (maxVal, text, field) =>
      Yup.object()
        .nullable()
        .test(
          'isBiggerThanMax',
          enhancedT([
            'common:VALIDATION_FIELD_SHOULD_BE_FROM_FROM_TO',
            { field: enhancedT('common:VALUE'), from: field.min, to: field.max },
          ]),
          (value) => value && value.year() <= maxVal,
        ),
    min: (minVal, text, field) =>
      Yup.object()
        .nullable()
        .test(
          'isLessThanMin',
          enhancedT([
            'common:VALIDATION_FIELD_SHOULD_BE_FROM_FROM_TO',
            { field: enhancedT('common:VALUE'), from: field.min, to: field.max },
          ]),
          (value) => value && value.year() >= minVal,
        ),
  },
  [FIELD_TYPES.COMBOBOX]: {
    _type: Yup.mixed().nullable(),
    required: (val, text) => {
      return val
        ? Yup.mixed()
            .nullable()
            .test('isNotEmpty', VALIDATION_MESSAGES.getReqMsg(text), (obj) => !isEmpty(obj))
        : Yup.mixed().nullable();
    },
    minSelection: (minSelection) =>
      Yup.mixed()
        .nullable()
        .test('minSelection', VALIDATION_MESSAGES.getMinSelectionMsg(minSelection), (value) => {
          if (isEmpty(value)) {
            return true;
          }

          return value.length >= minSelection;
        }),
    maxSelection: (maxSelection) =>
      Yup.mixed()
        .nullable()
        .test('maxSelection', VALIDATION_MESSAGES.getMaxSelectionMsg(maxSelection), (value) => {
          if (isEmpty(value)) {
            return true;
          }

          return value.length <= maxSelection;
        }),
  },
};
