export const getPositionElement = (el) => {
  let element = el;
  let xPos = 0;
  let yPos = 0;

  while (element) {
    xPos += element.offsetLeft;
    yPos += element.offsetTop;

    element = element.offsetParent;
  }

  return {
    xPosition: xPos,
    yPosition: yPos,
  };
};
