import styled, { css } from 'styled-components';
import { Box } from '@blueprism/ui-core';

import { Spinner } from 'styled-containers';

export const StyledComboBoxOptionMassage = styled(Box)`
  border-top: 1px solid #d4d4d4;
`;

export const StyledComboBoxOptionLoading = styled(Box)`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d4d4d4;
`;

export const IconContainer = styled(Spinner)(
  ({ disabled, visible }) => css`
    height: fit-content;
    cursor: ${disabled ? 'not-allowed' : 'inherit'};
    transform: ${visible ? 'scale(1)' : 'scale(0)'};
    opacity: ${visible ? 1 : 0};
    transition: all 0.3s cubic-bezier(0.2, 1, 0.6, 1.25);
    z-index: ${visible ? 1 : 0};
  `,
);
