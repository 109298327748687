import uuidv4 from 'uuid/v4';

export class ChangedFieldModel {
  constructor(props) {
    this.setup(props);
  }

  setup(props) {
    this.id = uuidv4();
    this.payload = props.payload;
    this.fieldPayload = props.fieldPayload;
    this.fieldValue = props.value;
    this.fieldHandlerType = props.fieldHandlerType;
    this.changedAt = new Date();
    this.fieldType = props.fieldType;
  }
}
