const missingTranslationPlaceholder = (value, key, options, translator) => {
  const {
    language,
    resourceStore: { data },
  } = translator;

  if (!value) {
    const { ns: defaultNamespace } = options;
    const {
      options: { defaultNS },
    } = translator;

    const [localisationKeyPair] = key;
    const localisationInfoGroup = localisationKeyPair.split(':');

    const externalNamespaceExist = localisationInfoGroup.length > 1;

    const namespace = externalNamespaceExist ? localisationInfoGroup[0] : defaultNamespace || defaultNS;
    const localisationKey = localisationInfoGroup.length > 1 ? localisationInfoGroup[1] : localisationKeyPair;

    const englishTranslation = data.en?.[namespace]?.[localisationKey];

    if (!englishTranslation) {
      return value;
    }

    const elongatedEnglishTranslation = translator
      .translate(englishTranslation, options)
      .split('')
      .map((char) => char.repeat(3))
      .join('');

    return `[${language.toUpperCase()}]${elongatedEnglishTranslation}`;
  }

  return value;
};

export const missingTransaltionPlaceholderPostProccessor = {
  type: 'postProcessor',
  name: 'missingTranslationPlaceholder',
  process: missingTranslationPlaceholder,
};
