import { VALIDATION_TYPE, INITIAL_STATE } from '../constants';
import { BaseFieldModel } from './BaseFieldModel';
import { numberOr } from '../utils';

export class TextFieldModel extends BaseFieldModel {
  constructor(field, page) {
    super(field, page);

    this.setup();
  }

  setup() {
    this.value = this.payload.value || '';
    this.maskData = this.payload.maskData || false;
    this.purgeData = this.payload.purgeData || false;
    this.placeholder = this.payload.placeholder || '';
    this.validationType = this.payload.validationType || VALIDATION_TYPE.SIMPLE;
    this.minlength = numberOr(null, this.payload.minlength);
    this.maxlength = numberOr(null, this.payload.maxlength);
    this.allowedChars = this.payload.allowedChars || '';
    this.nonAllowedChars = this.payload.nonAllowedChars || '';
    this.regex = {
      pattern: this.payload.regex ? this.payload.regex.pattern : '',
      errorMessage: this.payload.regex ? this.payload.regex.errorMessage : '',
    };
  }

  getRequestModel() {
    return {
      ...this,
      initialState: this.initialState.value,
      isKey: this.isKey.value,
      payload: JSON.stringify({
        value: this.value,
        maskData: this.maskData,
        purgeData: this.purgeData,
        placeholder: this.placeholder,
        validationType: this.validationType,
        hidden: this.initialState.value === INITIAL_STATE.HIDDEN,
        required: this.initialState.value === INITIAL_STATE.MANDATORY,
        disabled: this.initialState.value === INITIAL_STATE.READ_ONLY,
        minlength: this.validationType === VALIDATION_TYPE.SIMPLE ? this.minlength : null,
        maxlength: this.validationType === VALIDATION_TYPE.SIMPLE ? this.maxlength : null,
        allowedChars: this.validationType === VALIDATION_TYPE.SIMPLE ? this.allowedChars : '',
        nonAllowedChars: this.validationType === VALIDATION_TYPE.SIMPLE ? this.nonAllowedChars : '',
        regex: {
          pattern: this.validationType === VALIDATION_TYPE.REGEX ? this.regex.pattern : '',
          errorMessage: this.validationType === VALIDATION_TYPE.REGEX ? this.regex.errorMessage : '',
        },
      }),
    };
  }
}
