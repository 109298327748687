import { isEmpty } from 'app-utils';

import { SELECTION_TYPES_ID } from '../constants/selectionTypes';

export function initializeDropdownValue({ options, selectionType, value }) {
  if (isEmpty(value)) return '';

  if (selectionType === SELECTION_TYPES_ID.SINGLE) return options.find((option) => option.value === value);

  if (selectionType === SELECTION_TYPES_ID.MULTI) return options.filter((option) => value.includes(option.value));
}
