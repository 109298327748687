import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import MomentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { SingleDatePicker as ReactSingleDatePicker, CalendarDay } from 'react-dates';
import { CaretRight, CrossLarge, ArrowLeft, ArrowRight } from '@blueprism/ui-icons';

import { formatTime, formatTimeParseZone, formatTimeShortParseZone, formatTimeShort } from 'app-utils';
import { TimePickerContainer } from 'app-date-picker/components';
import { RoundButton } from 'app-buttons';

import { getCalendarPosition } from '../utils';
import {
  TIME_FORMAT_OUT,
  TYPE_TIME_FORMAT as type,
  DATE_FORMAT_IN,
  BaseTimePicker,
  DEFAULT_TIME,
} from '../../app-time';
import { CALENDAR_POSITION, DATE_PICKER_MODES, DATE_PICKER_MODE_NAMES } from '../constants';
import { StyledDatePicker } from '../StyledDatePicker.styled';

export const SingleDatePicker = (props) => {
  const {
    appendToBody,
    disabled,
    disabledDate,
    format: datePickerFormat,
    id,
    mode,
    numberOfMonths,
    onDateChange,
    parseZoneDisable,
    placeholder,
    readOnly,
    showClear,
    showTime,
    showTimeClear,
    timeLabel,
    updateTime,
    value,
  } = props;

  const { t } = useTranslation();

  const getFormattedTime = () => {
    if (!moment(value).seconds()) {
      return parseZoneDisable ? formatTimeShort(value) : formatTimeShortParseZone(value);
    }

    return parseZoneDisable ? formatTime(value) : formatTimeParseZone(value);
  };

  const { isOutsideRange, shouldHighlight } = DATE_PICKER_MODES[mode];
  const format = datePickerFormat || DATE_PICKER_MODES[mode].format;

  const refContainer = useRef(null);
  const [focused, changeFocused] = useState(false);
  const [calendarPosition, changeCalendarPosition] = useState(CALENDAR_POSITION.down);
  const [hoveredDay, changeHoveredDay] = useState(null);
  const [timeValue, setTimeValue] = useState(getFormattedTime());

  const helperText = t('THIS_INPUT_MUST_HAVE_A_VALUE_IN_THE_FORMAT_TYPE', { type });

  useEffect(() => {
    if (disabled && focused) changeFocused(false);
  }, [disabled]);

  const fillTimeHandler = (newTime) => {
    const newTimeValue = newTime.currentTarget.value;
    const newValue = moment(value);

    if (!newTimeValue) {
      setTimeValue(DEFAULT_TIME);

      const momentTime = moment(DEFAULT_TIME, TIME_FORMAT_OUT);
      newValue.set({
        hour: momentTime.get('hour'),
        minute: momentTime.get('minute'),
        second: momentTime.get('second'),
      });
    }

    onDateChange(newValue);
  };

  const changeTimeHandler = (newTime) => {
    setTimeValue(newTime);
    const newValue = moment.utc(value);

    if (newValue) {
      const momentTime = moment(newTime, TIME_FORMAT_OUT);
      newValue.set({
        hour: momentTime.get('hour'),
        minute: momentTime.get('minute'),
        second: momentTime.get('second'),
      });
    }

    onDateChange(newValue);
  };

  const updateDate = (newValue) => {
    if (newValue !== null && updateTime) {
      const momentTime = moment(timeValue, TIME_FORMAT_OUT);
      newValue.set({
        hour: momentTime.get('hour'),
        minute: momentTime.get('minute'),
        second: momentTime.get('second'),
      });
      return newValue;
    }

    return moment.utc(newValue).format(DATE_FORMAT_IN);
  };

  const handleDatePickerFocused = ({ focused: focusedState }) => {
    const position = getCalendarPosition(refContainer);

    changeFocused(focusedState);

    if (position !== calendarPosition) {
      changeCalendarPosition(position);
    }
  };

  const renderCalendarDay = (date) => {
    if (mode === DATE_PICKER_MODE_NAMES.DAY) {
      return <CalendarDay {...date} />;
    }

    const modifiers = new Set(date.modifiers);
    const { day } = date;
    if (hoveredDay && day && shouldHighlight(day, hoveredDay)) {
      modifiers.add('hovered-span');
    }

    if (value && day && shouldHighlight(day, value)) {
      modifiers.add('selected');
    }

    return (
      <CalendarDay
        {...date}
        modifiers={modifiers}
        onDayMouseEnter={changeHoveredDay}
        onDayMouseLeave={() => changeHoveredDay(null)}
      />
    );
  };

  // TODO:do not remove. Needed for testing of proptypes to pass
  const caretIconProps = process.env.NODE_ENV === 'test' ? undefined : { customArrowIcon: <CaretRight size={32} /> };
  let momentDate = null;
  if (value) {
    momentDate = parseZoneDisable ? moment(value) : moment.parseZone(value);
  }

  return (
    <StyledDatePicker ref={refContainer}>
      <ReactSingleDatePicker
        placeholder={placeholder}
        renderCalendarDay={renderCalendarDay}
        date={momentDate}
        id={id}
        isOutsideRange={disabledDate || isOutsideRange}
        onDateChange={(date) => onDateChange(date ? updateDate(date) : null)}
        focused={focused}
        onFocusChange={handleDatePickerFocused}
        showClearDate={showClear}
        displayFormat={format}
        disabled={disabled}
        readOnly={readOnly}
        appendToBody={appendToBody}
        transitionDuration={0}
        numberOfMonths={numberOfMonths}
        customCloseIcon={<CrossLarge size={16} />}
        navPrev={
          <RoundButton circle>
            <ArrowLeft title="arrow-left" size={16} />
          </RoundButton>
        }
        navNext={
          <RoundButton circle>
            <ArrowRight size={16} />
          </RoundButton>
        }
        navPosition="navPositionBottom"
        openDirection={calendarPosition}
        hideKeyboardShortcutsPanel
        noBorder
        {...caretIconProps}
      />
      {showTime && value && (
        <TimePickerContainer
          gap="xxs"
          label={t(timeLabel)}
          key={`${timeLabel}_${id}`}
          htmlFor={timeLabel}
          helperText={helperText}
        >
          <BaseTimePicker
            onBlur={fillTimeHandler}
            onChange={changeTimeHandler}
            id={timeLabel}
            disabled={disabled}
            value={timeValue}
            showClear={showTimeClear}
          />
        </TimePickerContainer>
      )}
    </StyledDatePicker>
  );
};

SingleDatePicker.propTypes = {
  onDateChange: PropTypes.func.isRequired,
  showTimeClear: PropTypes.bool,
  showClear: PropTypes.bool,
  value: PropTypes.oneOfType([MomentPropTypes.momentObj, PropTypes.instanceOf(Date), PropTypes.string]),
  mode: PropTypes.string,
  numberOfMonths: PropTypes.number,
  timeLabel: PropTypes.string,
  disabledDate: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  updateTime: PropTypes.bool,
  showTime: PropTypes.bool,
  format: PropTypes.string,
  appendToBody: PropTypes.bool,
  parseZoneDisable: PropTypes.bool,
  readOnly: PropTypes.bool,
};

SingleDatePicker.defaultProps = {
  value: null,
  mode: DATE_PICKER_MODE_NAMES.DAY,
  disabledDate: null,
  appendToBody: false,
  showClear: false,
  showTimeClear: true,
  timeLabel: 'TIME_LABEL',
  disabled: false,
  numberOfMonths: 2,
  placeholder: '',
  id: uuidv4(),
  showTime: false,
  format: null,
  parseZoneDisable: true,
  updateTime: false,
  readOnly: false,
};
