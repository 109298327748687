import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import * as R from 'ramda';

import { Login } from './Login';

export const LoginContainer = R.compose(
  inject(({ rootStore }) => ({
    authStore: rootStore.authStore,
    authenticationSettings: rootStore.authenticationSettings,
    numberOfLdapUsers: rootStore.numberOfLdapUsers,
    saml2ProvidersList: rootStore.saml2ProvidersList,
    oidcProvidersList: rootStore.oidcProvidersList,
    saml2PluginEnabled: rootStore.saml2PluginEnabled,
  })),
  observer,
)(Login);
