import { action, observable, computed } from 'mobx';

import { FIELD_TYPES } from 'app-base-form/constants';
import { MODAL_MODES } from 'app-modal';

const initialValues = { selectionText: '', link: '' };

export class HyperLinkStore {
  @observable showModal = false;

  @observable onChange = () => {};

  @observable initialValues = initialValues;

  @computed
  get mode() {
    return this.initialValues.link ? MODAL_MODES.EDIT : MODAL_MODES.ADD;
  }

  @action setInitialValues = (values) => {
    this.initialValues = { ...values, link: values.link?.target };
  };

  @action setOnChange = (onChange) => {
    this.onChange = onChange;
  };

  @action
  setShowModal = (nextState) => {
    this.showModal = nextState;
  };

  @action
  resetStore = () => {
    this.initialValues = initialValues;
    this.showModal = false;
    this.onChange = () => {};
  };

  translateFieldsConfig = (t) => [
    {
      label: t('TEXT'),
      type: FIELD_TYPES.TEXT,
      name: 'selectionText',
      value: '',
      required: true,
      maxlength: 500,
    },
    {
      label: t('LINK'),
      type: FIELD_TYPES.TEXT,
      value: '',
      name: 'link',
      required: true,
      maxlength: 500,
    },
  ];
}
