import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Row, Text } from '@blueprism/ui-core';

import { TranslationTemplate } from 'app-i18n/TranslationTemplate';

import { CustomModal } from '../../CustomModal';
import { areYouSureModalStore } from './areYouSureModalStore';
import { StyledStackModalContent } from './AreYouSureModal.styled';

export const AreYouSureModal = observer(() => {
  const {
    close,
    content: { graphic, noLabel, question, renderContent, subQuestion, title, yesLabel },
    isOpen,
    onModalContentVisibilityChange,
    options: { overlayClass, padding, size },
  } = areYouSureModalStore;

  const noHandler = () => close(false);
  const yesHandler = () => close(true);

  return (
    <CustomModal
      className={overlayClass}
      size={size}
      visible={isOpen}
      translatableTitle={title}
      onClose={noHandler}
      padding={padding}
      dataTestid="are-you-sure"
      onContentVisibilityChange={onModalContentVisibilityChange}
      cancelIsVisible
      footerContent={() => (
        <Row justify="between">
          <Box>
            {noLabel && (
              <Button onClick={noHandler} data-testid="no-button">
                <TranslationTemplate value={noLabel} />
              </Button>
            )}
          </Box>
          <Box>
            <Button onClick={yesHandler} active data-testid="yes-button">
              <TranslationTemplate value={yesLabel} />
            </Button>
          </Box>
        </Row>
      )}
    >
      <Box>
        <StyledStackModalContent align="center" justify="center">
          {graphic && <img alt="" src={graphic} />}
          <Text type="h6">
            <TranslationTemplate value={question} />
          </Text>
        </StyledStackModalContent>
        {subQuestion && (
          <Text type="h6">
            <TranslationTemplate value={subQuestion} />
          </Text>
        )}
      </Box>
      {renderContent()}
    </CustomModal>
  );
});
