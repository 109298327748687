import styled from 'styled-components';
import { Stack } from '@blueprism/ui-core';

export const StyledStackModalContent = styled(Stack)`
  span {
    width: 100%;
    overflow: hidden;
    white-space: normal;
    text-align: left;
    text-overflow: ellipsis;
  }
`;
