import { action, observable } from 'mobx';
import {
  Aborter,
  BlobURL,
  StorageURL,
  ServiceURL,
  ContainerURL,
  BlockBlobURL,
  AnonymousCredential,
  uploadBrowserDataToBlockBlob,
} from '@azure/storage-blob';

import { notify } from 'app-toasts';
import { SIZE_MB, SIZE_KILOBYTE } from 'app-utils';

import { getFileNameAndExtension } from '../utlis';
import { URLS } from '../constants';

export class AzureBlob {
  @observable baseUrl;

  @observable isUploading = {};

  @observable uploadingProgress = {};

  @observable httpService = null;

  @observable _aborters = {};

  @action uploadFile = (file, uuid) => {
    return new Promise(async (resolve) => {
      const options = this._getOptions(file, uuid);
      const aborter = this._getAndSetupAborter(uuid);
      const blobName = this._formatFileName(file.name, uuid);
      const blobURL = BlobURL.fromContainerURL(this.containerURL, blobName);
      const blockBlobURL = BlockBlobURL.fromBlobURL(blobURL);

      try {
        const result = await uploadBrowserDataToBlockBlob(aborter, file, blockBlobURL, options);

        if (result.errorCode) {
          notify('error', 'common:SERVER_ERROR_OCCURRED_PLEASE_TRY_AGAIN_LATER_OR_CO');
          resolve({ error: result });
        } else {
          const payload = {
            ...result,
            link: this.getBlobBaseLink(blobName),
            extension: getFileNameAndExtension(blobName)[1],
          };

          // A little delay before sucess reset, to improve UX
          setTimeout(() => {
            resolve({ result: payload });
          }, 200);
        }
      } catch (err) {
        resolve({ error: err });
        this.reset(uuid);
      }
    });
  };

  @action reset = (uuid) => {
    this.uploadingProgress = { ...this.uploadingProgress, [uuid]: 0 };
    this.setUploading(false, uuid);

    if (this._aborters[uuid]) {
      this._aborters[uuid].abort();
    }
  };

  @action getBlobBaseLink = (blobName) => `${this.baseBlobUrl}/${this.containerName}/${blobName}`;

  @action setHttpService = (httpService) => {
    this.httpService = httpService;
  };

  @action _onProgressChange = (blobSize, uuid) => {
    return ({ loadedBytes }) => {
      const progress = parseInt((loadedBytes / blobSize) * 100, 10);

      this.uploadingProgress = {
        ...this.uploadingProgress,
        [uuid]: parseInt(progress, 10),
      };
    };
  };

  @action _setup({ accountSas, baseUrl, container } = {}) {
    this.baseBlobUrl = `https://${baseUrl}`;
    this.containerName = container;

    const pipeline = StorageURL.newPipeline(new AnonymousCredential(), {
      retryOptions: { maxTries: 3 }, // Retry options
    });
    const serviceURL = new ServiceURL(`${this.baseBlobUrl}${accountSas}`, pipeline);

    this.containerURL = ContainerURL.fromServiceURL(serviceURL, this.containerName);
  }

  _getOptions = (file, uuid) => {
    return {
      parallelism: 20,
      blockSize: this._getBlockSize(file.size),
      progress: this._onProgressChange(file.size, uuid),
    };
  };

  @action _getAndSetupAborter = (uuid) => {
    const aborter = new Aborter();
    this._aborters[uuid] = aborter;
    return aborter;
  };

  _getBlockSize = (fileSize) => (fileSize > SIZE_MB * 32 ? SIZE_MB * 4 : SIZE_KILOBYTE * 512);

  @action setUploading = (value, uuid) => {
    this.isUploading = { ...this.isUploading, [uuid]: value };
  };

  @action _getTokens = (httpService) => {
    return new Promise(async (resolve) => {
      try {
        const { baseUrl, container, token: readAccessToken } = await httpService.get(
          `${URLS.interactPrivateStorate}/read-access-token`,
        );
        const { token: writeAccessToken } = await httpService.get(`${URLS.interactPrivateStorate}/write-access-token`);

        this.readAccessToken = readAccessToken;
        this.writeAccessToken = writeAccessToken;

        resolve({
          baseUrl,
          container,
          accountSas: this.writeAccessToken,
        });
      } catch (err) {
        resolve(err);
      }
    });
  };

  _formatFileName = (name, uuid) => {
    const [fileName, fileExtension] = getFileNameAndExtension(name);
    return `${fileName}.${uuid}.${fileExtension}`;
  };

  @action
  resetStore = () => {
    this.httpService = null;
    this._tokensAreLoading = false;
  };
}

export const AzureBlobStore = new AzureBlob();
