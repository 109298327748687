import { stringify } from 'query-string';

export const getSearch = (params) => {
  return params ? `?${stringify(params)}` : '';
};

let localBaseApiUrl = '';
let localAuthServerBaseURL = '';
let localHubUrl = '';
let localInteractUrl = '';

if (process.env.NODE_ENV === 'development') {
  import('./apiUrls.development').then(({ devAuthServerBaseURL, devBaseApiUrl, devHubBaseURL, devInteractBaseURL }) => {
    localBaseApiUrl = devBaseApiUrl;
    localAuthServerBaseURL = devAuthServerBaseURL;
    localHubUrl = devHubBaseURL;
    localInteractUrl = devInteractBaseURL;
  });
}

export const baseApiUrl = localBaseApiUrl;
export const authServerBaseURL = localAuthServerBaseURL;
export const hubBaseURL = localHubUrl;
export const interactBaseURL = localInteractUrl;

export const authServerProxyUrl = '/api/proxy/authentication-server';
export const fileStorageProxyUrl = '/api/proxy/file-storage-api';
export const notificationCenterProxyUrl = 'api/proxy/notification-center';

export const hubOverInteractProxyUrl = `${interactBaseURL}/api/proxy/hub`;
export const interactOverHubProxyUrl = `${hubBaseURL}/api/proxy/interact`;
export const authServerOverInteractProxyUrl = `${interactBaseURL}${authServerProxyUrl}`;
export const authServerOverHubProxyUrl = `${hubBaseURL}${authServerProxyUrl}`;

export const baseImagePath = `${baseApiUrl}/Img/DefaultTheme`;
