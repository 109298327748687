export const SELECTION_TYPES_ID = {
  SINGLE: 0,
  MULTI: 1,
  BOTH: 2,
};

export const SELECTION_TYPES = [
  { value: SELECTION_TYPES_ID.SINGLE, translatableLabel: 'SINGLE' },
  { value: SELECTION_TYPES_ID.MULTI, translatableLabel: 'MULTIPLE' },
];
