import * as R from 'ramda';

import { LANGUAGES } from '../constants';

export const languageParse = (navigatorLanguage) => {
  return R.cond([
    [(value) => value.includes(LANGUAGES.ENGLISH), R.always(LANGUAGES.ENGLISH)],
    [(value) => value.includes(LANGUAGES.JAPANESE), R.always(LANGUAGES.JAPANESE)],
    [(value) => value.includes(LANGUAGES.CHINESE), R.always(LANGUAGES.CHINESE)],
    [(value) => value.includes(LANGUAGES.GERMAN), R.always(LANGUAGES.GERMAN)],
    [(value) => value.includes(LANGUAGES.ESPANIOL), R.always(LANGUAGES.ESPANIOL)],
    [(value) => value.includes(LANGUAGES.FRENCH), R.always(LANGUAGES.FRENCH)],
    [R.T, R.always(LANGUAGES.ENGLISH)],
  ])(navigatorLanguage);
};
