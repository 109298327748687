import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';

import { isIterable } from 'app-utils';
import { isDevelopment } from 'app-debug';

import { dateFormatInterpolator } from './interpolators';
import { i18n } from './i18nextInstance';
import { setDefaultLocale } from './setDefaultLocale';
import { missingTransaltionPlaceholderPostProccessor } from './post-processors';

let backendLoadPath;

try {
  backendLoadPath = `/locales/{{ns}}/{{lng}}/{{ns}}-{{lng}}.json?v=${BUILD_SALT}`;
} catch {
  backendLoadPath = `/locales/{{ns}}/{{lng}}/{{ns}}-{{lng}}.json`;
}

const fallbackLng = 'en';

export const connectOptions = {
  ns: ['common', 'connect', 'connectapi'],
  defaultNS: 'connect',
  backend: { loadPath: backendLoadPath },
};
export const authServerOptions = {
  ns: ['common', 'auth-server'],
  defaultNS: 'auth-server',
  backend: { loadPath: backendLoadPath },
};
export const interactOptions = {
  ns: ['common', 'selfserve'],
  defaultNS: 'selfserve',
  backend: { loadPath: backendLoadPath },
};

export const withLanguageFromWindow = () =>
  (window.customizationSettings && window.customizationSettings.language) ||
  (isDevelopment && localStorage.getItem('devLng')) ||
  fallbackLng;

export async function bootstrapI18n(options, lng = fallbackLng) {
  const isDebug = isDevelopment && (localStorage.getItem('debugLng') || false);

  setDefaultLocale(lng);

  await i18n
    .use(XHR)
    .use(initReactI18next)
    .use(missingTransaltionPlaceholderPostProccessor)
    .use(intervalPlural)
    .init({
      lng,
      fallbackLng,
      debug: isDebug,
      saveMissing: false,
      interpolation: {
        escapeValue: false,
        format: dateFormatInterpolator,
      },
      react: {
        wait: true,
        useSuspense: false,
      },
      postProcess: ['missingTranslationPlaceholder'],
      ...options,
    });
}

export const enhancedT = (text) => {
  if (!isIterable(text)) {
    return i18n.t(text);
  }

  const [key, params = {}, translatableParams = {}] = text;

  const translatedParams = Object.entries(translatableParams).reduce((acc, [localeKey, value]) => {
    if (isIterable(value)) {
      return { ...acc, [localeKey]: i18n.t(...value) };
    }

    return { ...acc, [localeKey]: i18n.t(value) };
  }, {});

  return i18n.t(key, { ...params, ...translatedParams });
};

export const bootstrapI18nForHub = (lang) => bootstrapI18n(connectOptions, lang);
export const bootstrapI18nForAuthServerPage = () => bootstrapI18n(authServerOptions, withLanguageFromWindow());
