export function getObjectFromSearch(location) {
  let searchObject = {};

  const { search } = location;

  if (!search) return searchObject;

  search
    .substring(1)
    .split('&')
    .forEach((keyValueString) => {
      const keyValueArray = keyValueString.split('=');
      searchObject = {
        ...searchObject,
        [keyValueArray[0]]: keyValueArray[1],
      };
    });

  return searchObject;
}
