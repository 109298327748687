import styled, { css } from 'styled-components';
import { Box, FormField } from '@blueprism/ui-core';

export const StyledFormField = styled(FormField)`
  position: relative;

  label {
    width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    svg {
      pointer-events: auto;
    }
  }

  input {
    -webkit-transform: rotateZ(360deg);
    -webkit-font-smoothing: antialiased;
  }
`;

export const StyledBorderedBox = styled(Box)`
  ${({ bordered, theme }) =>
    bordered &&
    css`
      padding: 10px 10px 5px;
      border: 1px solid ${theme.color.structure};
      border-radius: 5px;
    `}
`;

export const StyledColorPickerContainer = styled(Box)`
  input {
    width: 100%;
    height: 45px;
  }
`;

export const StyledCheckBoxField = styled(FormField)`
  position: relative;
  margin-top: auto;
  label {
    width: fit-content;

    svg {
      pointer-events: auto;
    }
  }

  input {
    -webkit-transform: rotateZ(360deg);
    -webkit-font-smoothing: antialiased;
  }
`;
