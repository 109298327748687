import { BaseFieldModel } from './BaseFieldModel';
import { numberOr } from '../utils';

export class HorizontalRuleFieldModel extends BaseFieldModel {
  constructor(field, page) {
    super(field, page);

    this.setup();
  }

  setup() {
    this.value = {
      paddingTop: numberOr(null, Number(this.payload.paddingTop)),
      paddingBottom: numberOr(null, Number(this.payload.paddingBottom)),
    };
    this.paddingTop = numberOr(null, Number(this.payload.paddingTop));
    this.paddingBottom = numberOr(null, Number(this.payload.paddingBottom));
  }

  getRequestModel() {
    return {
      ...this,
      initialState: this.initialState.value,
      payload: JSON.stringify({
        paddingTop: this.paddingTop,
        paddingBottom: this.paddingBottom,
        hidden: false,
      }),
    };
  }
}
