import React from 'react';
import PropTypes from 'prop-types';
import { FormField, Placeholder, Stack } from '@blueprism/ui-core';

export const ImageFieldSkeleton = ({ description }) => (
  <FormField
    gap="xs"
    label={<Placeholder height="24px" width="100px" />}
    helperText={description && <Placeholder height="18px" width="50%" />}
  >
    <Stack gap="base" align="center">
      <Placeholder height="120px" width="120px" />
      <Placeholder height="24px" width="120px" padding="0 0 2px 0" />
      <Placeholder height="40px" width="90px" />
    </Stack>
  </FormField>
);

ImageFieldSkeleton.propTypes = {
  description: PropTypes.string,
};

ImageFieldSkeleton.defaultProps = {
  description: null,
};
