import { useRef } from 'react';
import * as R from 'ramda';

export const useDeepCompareMemoize = (value) => {
  const ref = useRef();

  if (!R.equals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};
