import * as R from 'ramda';

import { BaseFieldModel } from './BaseFieldModel';
import { INITIAL_STATE } from '../constants';
import { numberOr } from '../utils';

export class TableFieldModel extends BaseFieldModel {
  constructor(field, page) {
    super(field, page);

    this.setup();
  }

  setup() {
    this.initialRowCount = numberOr(null, this.payload.initialRowCount);
    this.minRows = numberOr(null, this.payload.minRows);
    this.maxRows = numberOr(null, this.payload.maxRows);
    this.columns = this.payload.columns || [
      {
        label: '',
        checked: false,
        isIdBind: true,
        value: '',
      },
    ];
    this.value = this.payload.value;
  }

  generateInitialValues() {
    const newValueModel = this.columns.reduce((acc, { value: columnValue }) => {
      acc[columnValue] = '';
      return acc;
    }, {});

    const initialRowCount = R.isNil(this.initialRowCount) ? 1 : this.initialRowCount;
    const rowCount = initialRowCount < this.minRows ? this.minRows : initialRowCount;

    return new Array(rowCount).fill(newValueModel);
  }

  getRequestModel() {
    return {
      ...this,
      initialState: this.initialState.value,
      payload: JSON.stringify({
        initialRowCount: this.initialRowCount,
        minRows: this.minRows,
        maxRows: this.maxRows,
        columns: this.columns.map(({ isIdBind, ...rest }) => ({ ...rest })),
        hidden: this.initialState.value === INITIAL_STATE.HIDDEN,
        required: this.initialState.value === INITIAL_STATE.MANDATORY,
        disabled: this.initialState.value === INITIAL_STATE.READ_ONLY,
        value: this.generateInitialValues(),
      }),
    };
  }
}
