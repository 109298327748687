import { schema } from 'normalizr';

import { field } from './field';
import { rule } from './rule';

export const page = new schema.Entity(
  'pages',
  {
    fields: [field],
    rules: [rule],
  },
  {
    idAttribute: 'name',
  },
);
