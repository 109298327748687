import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { ComboBoxOption } from '@blueprism/ui-pattern-combobox';

import { StyledComboBox, StyledComboBoxList } from './ComboBox.styled';
import { ComboBoxOptionLoading, ComboBoxOptionMessage } from './components';

export const ComboBox = ({
  allowNonExistingValues,
  ariaLabel,
  clearButtonLabel,
  clearOnceSelected,
  descriptionPropertyName,
  disabled,
  error,
  filterOption,
  helperTextLoadedOptionEmpty,
  hideClearButton,
  id,
  isOpenOnFocus,
  isTransparent,
  itemWrapper: ItemWrapper,
  labelPropertyName,
  loading: propLoading,
  loadOptions,
  maxLength,
  name,
  onBlur,
  onSelect,
  openButtonLabel,
  options,
  portal,
  required,
  searchDescription,
  value,
  valuePropertyName,
  zIndex,
  zIndexButton,
}) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [innerOptions, setInnerOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);

  const emptyOptionFooter = isDirty ? t(helperTextLoadedOptionEmpty) : t('common:NO_RESULTS_FOUND');
  const isAsyncMode = !!loadOptions;
  const isLoading = propLoading || loading;

  const translateOptionLabel = React.useCallback(
    (option) => (option.translatableLabel ? t(option.translatableLabel) : option[labelPropertyName]),
    [t, labelPropertyName],
  );

  const translateOptionDescription = React.useCallback(
    (option) => (option.translatableDescription ? t(option.translatableDescription) : option[descriptionPropertyName]),
    [t, descriptionPropertyName],
  );

  const mapOptionsToList = (option) => {
    const translatedLabel = translateOptionLabel(option);
    const translatedDescription = translateOptionDescription(option);

    return <ItemWrapper key={translatedLabel} label={translatedDescription} value={translatedLabel} />;
  };

  const filterSearchValues = (option, searchTerm) => {
    const inputValueLowerCase = searchTerm && searchTerm.toLowerCase();

    const labelResult = translateOptionLabel(option).toLowerCase().includes(inputValueLowerCase);
    const descriptionResult = searchDescription
      ? translateOptionDescription(option).toLowerCase().includes(inputValueLowerCase)
      : false;

    return labelResult || descriptionResult;
  };

  const valueResolver = (nextValue) => {
    if (!nextValue) return '';

    return nextValue[labelPropertyName] || t(nextValue.translatableLabel) || '';
  };

  const createOption = (newValue) => {
    const optionExists = options.find((option) => option[labelPropertyName] === newValue);

    if (!optionExists) onSelect({ [valuePropertyName]: null, [labelPropertyName]: newValue });
  };

  const comboBoxOptions = useMemo(() => {
    const searchOptions = isDirty ? filterSearchValues : () => true;

    return innerOptions
      .filter(filterOption)
      .filter((x) => searchOptions(x, inputValue))
      .map(mapOptionsToList);
  }, [innerOptions, inputValue, isDirty, filterOption]);

  const loadAndSetOptions = async (nextSearchValue) => {
    setLoading(true);

    let _options = options;

    if (isAsyncMode) {
      _options = await loadOptions(nextSearchValue);
    }

    setInnerOptions(_options || []);
    setLoading(false);
  };

  const debouncedLoadOptions = useCallback(debounce(loadAndSetOptions, 1000), [loadOptions]);

  const handleSelect = useCallback(
    (nextValue) => {
      setInputValue(nextValue);
    },
    [innerOptions, onSelect],
  );

  const handleChange = (event) => {
    const isTriggerByKeyBoard = typeof event !== 'string';
    const nextSearchValue = isTriggerByKeyBoard ? event?.target?.value : event;

    if (!isDirty && isTriggerByKeyBoard) setDirty(true);

    setInputValue(nextSearchValue);

    if (isAsyncMode) debouncedLoadOptions(nextSearchValue);
  };

  const handleOnFocus = () => {
    if (isAsyncMode) loadAndSetOptions('');
  };

  const handleOnBlur = (event) => {
    const inputRef = event.target;

    setTimeout(() => {
      if (isDirty) setDirty(false);

      onBlur(event);

      if (inputRef.value === valueResolver(value)) return;

      if (inputRef.value === '') {
        if (!required) {
          onSelect(null);
        } else {
          const initialValue = valueResolver(value);

          setInputValue(initialValue);
        }
      } else {
        const foundOption = innerOptions.find(
          (option) => inputRef.value === translateOptionLabel(option, labelPropertyName),
        );

        if (foundOption) {
          onSelect(foundOption);
        } else if (allowNonExistingValues) {
          createOption(inputRef.value);
        } else {
          const initialValue = valueResolver(value);

          setInputValue(initialValue);
        }

        if (clearOnceSelected) setInputValue('');
      }
    }, 250);
  };

  const handleClear = () => {
    setInputValue('');

    if (!required) onSelect(null);
  };

  useEffect(() => {
    loadAndSetOptions();
  }, [loadOptions]);

  useEffect(() => {
    if (!isAsyncMode) {
      setInnerOptions(options);
    }
  }, [options]);

  useEffect(() => {
    const initialValue = valueResolver(value);

    setInputValue(initialValue);
  }, [value]);

  return (
    <StyledComboBox
      name={name}
      id={id || name}
      value={inputValue}
      aria-label={ariaLabel}
      maxLength={maxLength}
      hideClearButton={required || hideClearButton}
      disabled={disabled}
      error={error}
      openOnFocus={isOpenOnFocus}
      onSelect={handleSelect}
      onChange={handleChange}
      onClear={handleClear}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      clearButtonLabel={t(clearButtonLabel)}
      openButtonLabel={t(openButtonLabel)}
      isTransparent={isTransparent}
      role="status"
      aria-live="polite"
      zIndexButton={zIndexButton}
    >
      <StyledComboBoxList portal={portal} zIndex={zIndex}>
        {isLoading && <ComboBoxOptionLoading visible={isLoading} disabled={disabled} />}
        {!isLoading &&
          (comboBoxOptions.length ? (
            <>
              {comboBoxOptions}
              {isAsyncMode && <ComboBoxOptionMessage text={t('common:TYPE_TO_FIND_ANOTHER_ONE')} />}
            </>
          ) : (
            <ComboBoxOptionMessage text={emptyOptionFooter} />
          ))}
      </StyledComboBoxList>
    </StyledComboBox>
  );
};

ComboBox.propTypes = {
  ariaLabel: PropTypes.string,
  required: PropTypes.bool,
  descriptionPropertyName: PropTypes.string,
  valuePropertyName: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hideClearButton: PropTypes.bool,
  filterOption: PropTypes.func,
  id: PropTypes.string,
  itemWrapper: PropTypes.func,
  labelPropertyName: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
  loadOptions: PropTypes.func,
  isOpenOnFocus: PropTypes.bool,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    MobxPropTypes.observableArrayOf(PropTypes.object),
  ]),
  portal: PropTypes.bool,
  searchDescription: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string]),
  helperTextLoadedOptionEmpty: PropTypes.string,
  loading: PropTypes.bool,
  clearOnceSelected: PropTypes.bool,
  allowNonExistingValues: PropTypes.bool,
  clearButtonLabel: PropTypes.string,
  openButtonLabel: PropTypes.string,
  zIndex: PropTypes.number,
  zIndexButton: PropTypes.number,
  isTransparent: PropTypes.bool,
};

ComboBox.defaultProps = {
  ariaLabel: null,
  required: true,
  hideClearButton: false,
  descriptionPropertyName: 'description',
  valuePropertyName: 'value',
  helperTextLoadedOptionEmpty: 'common:NO_DATA_AVAILABLE',
  clearButtonLabel: 'common:CLEAR_SELECTION',
  openButtonLabel: 'common:OPEN_OPTIONS',
  disabled: false,
  error: false,
  filterOption: () => true,
  id: null,
  itemWrapper: ComboBoxOption,
  labelPropertyName: 'label',
  maxLength: 255,
  name: '',
  onSelect: () => {},
  onBlur: () => {},
  loadOptions: null,
  isOpenOnFocus: true,
  options: [],
  portal: false,
  searchDescription: false,
  value: null,
  loading: false,
  clearOnceSelected: false,
  allowNonExistingValues: false,
  zIndex: 5,
  zIndexButton: 1,
  isTransparent: true,
};
