import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Avatar } from '@blueprism/ui-core';

import mimeTypesDic from 'app-utils/mimeTypes.dic';
import { getFormattedFileSize } from 'app-utils';
import { UploadButton } from 'app-buttons';
import { notify } from 'app-toasts';

import { FilePayloadModel } from './models';
import { extentMimeTypesWithJPEG } from './utils';
import { ProfilePicture } from './BaseFormFieldAvatarPicker.styled';

const SIZE_KILOBYTE = 1024;

export const AvatarPicker = (props) => {
  const { className, disabled, editButtonText, image, title, uploading } = props;

  const { t } = useTranslation();

  const getActualValueAndLabel = (val) => {
    const { maxFileSize } = props;

    return getFormattedFileSize(val, maxFileSize);
  };

  const validateDimensions = (validatedImage) => {
    const { maxImageHeight, maxImageWidth } = props;

    if (validatedImage.width > maxImageWidth || validatedImage.height > maxImageHeight) {
      notify('error', ['common:IMAGE_DIMENSION_SHOULD_BE_NOT_BIGGER_THAN', { maxImageWidth, maxImageHeight }]);

      return false;
    }

    return true;
  };

  const getFormats = (formatMimeTypes) => formatMimeTypes.map((type) => mimeTypesDic[type]).join(` ${t('common:OR')} `);

  const validateFile = (file) => {
    const { maxFileSize } = props;

    if (file.size > SIZE_KILOBYTE * maxFileSize || !props.mimeTypes.includes(file.type)) {
      notify('error', [
        'common:PLEASE_SELECT_FILES_NOT_BIGGER_THAN',
        {
          mimeTypes: getFormats(props.mimeTypes),
          size: `${getActualValueAndLabel(maxFileSize).value}${getActualValueAndLabel(maxFileSize).label}`,
        },
      ]);

      return false;
    }

    return true;
  };

  const onSelectFile = (event) => {
    const { setImage } = props;
    event.persist();

    if (event.target.files && event.target.files[0]) {
      const [file] = event.target.files;

      if (!validateFile(file)) return;

      const reader = new FileReader();

      reader.onload = ({ target: { result } }) => {
        const newImage = new Image();
        newImage.src = result;

        newImage.onload = () => {
          if (validateDimensions(newImage)) {
            const payload = new FilePayloadModel({
              file,
              result,
              height: newImage.height,
              width: newImage.width,
            });

            setImage(result, payload);
          }
          // eslint-disable-next-line
          event.target.value = '';
        };
      };

      reader.readAsDataURL(file);
    }
  };

  let { mimeTypes } = props;

  mimeTypes = extentMimeTypesWithJPEG(mimeTypes);

  const acceptedFileExtensions = mimeTypes
    .map((mType) => {
      const fileExtension = mimeTypesDic[mType];

      if (!fileExtension) {
        throw Error(`mimeTypesDic doesn't have such mimeType: ${mType}`);
      }

      return `.${fileExtension.toLowerCase()}`;
    })
    .join(', ');

  const isImageMightBeCached = image === '' || image === null || image.startsWith('data:');
  const cacheBustedImage = image?.includes('?') ? `${image}&v=1` : `${image}?v=1`;

  const url = isImageMightBeCached ? image : cacheBustedImage;

  return (
    <>
      <ProfilePicture>
        <Avatar className={className} image={url} title={title} />
      </ProfilePicture>
      {!disabled && (
        <Row justify="end">
          <UploadButton
            text={t(editButtonText)}
            disabled={uploading}
            onChange={onSelectFile}
            accept={acceptedFileExtensions}
          />
        </Row>
      )}
    </>
  );
};

AvatarPicker.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  mimeTypes: PropTypes.arrayOf(PropTypes.string),
  setImage: PropTypes.func.isRequired,
  editButtonText: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  maxImageWidth: PropTypes.number,
  maxImageHeight: PropTypes.number,
  maxFileSize: PropTypes.number,
};

AvatarPicker.defaultProps = {
  className: '',
  title: '',
  image: '',
  editButtonText: 'UPLOAD',
  disabled: false,
  maxFileSize: 10,
  maxImageHeight: 1000,
  maxImageWidth: 1000,
  mimeTypes: Object.keys(mimeTypesDic),
};
