import styled, { css } from 'styled-components';
import { ComboBox, ComboBoxList } from '@blueprism/ui-pattern-combobox';

export const StyledComboBox = styled(ComboBox)`
  background-color: ${({ isTransparent, theme }) => !isTransparent && theme.color.paper};

  ${({ zIndexButton }) => css`
    > button {
      z-index: ${zIndexButton};
    }
  `}
`;

export const StyledComboBoxList = styled(ComboBoxList)`
  ${({ zIndex }) => css`
    z-index: ${zIndex};
    position: absolute;
    width: 100%;
  `}
`;

export const StyledTagContainer = styled.li`
  ${({ disabled, theme }) => css`
    width: auto;
    max-width: 100%;

    > div {
      width: 100%;
    }

    ${disabled &&
    css`
      > div {
        box-shadow: 0 2px 1px -1px ${theme.color.disabled};
      }

      svg {
        path {
          fill: ${theme.color.disabled};
        }
      }

      > div {
        padding: 0 0.5rem;
      }
    `}
  `}
`;
