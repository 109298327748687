import React from 'react';
import { isEmail } from 'validator';

import { enhancedT } from 'app-i18n';

const ValidHostnameRegex = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/;

const checkIfFieldIsNonRequiredAndEmpty = (nonrequired, value) => {
  if (nonrequired && !value) return true;
};

export const required = (value, { label }) => {
  if (!value || !value.toString().trim().length) {
    return <p className="validation-message">{enhancedT(['common:VALIDATION_IS_REQUIRED', { field: label }])}</p>;
  }
};

export const hostname = (value) => {
  if (!ValidHostnameRegex.test(value)) {
    return <p className="validation-message">{enhancedT('INVALID_FORMAT_OF_HOST_NAME')}</p>;
  }
};

export const eng = (value, { label }) => {
  const isOnlyEngLetters = /^[a-zA-Z]+$/.test(value);
  if (!isOnlyEngLetters) {
    return <p className="validation-message">{enhancedT(['FIELD_IS_ONLY_ENGLISH_LETTERS', { label }])}</p>;
  }
};

export const lettersDigitsAndSpace = (value, { label }) => {
  const isOnlyLettersDigitsAndSpace = /^[a-zA-Z0-9\s]*$/.test(value);
  if (!isOnlyLettersDigitsAndSpace) {
    return <p className="validation-message">{enhancedT(['FIELD_IS_ONLY_CONSISTS_LETTERS', { label }])}</p>;
  }
};

export const backendError = (value, props) => {
  const { backenderror } = props;
  if (backenderror) {
    return <p className="validation-message">{enhancedT(backenderror)}</p>; //! TODO CHECK WTF IS THAT
  }
};

export const betwNumbers = (value, props) => {
  const { label, maxnumber, minnumber } = props;

  if (value === '') return;

  if (Number.isNaN(Number(value)) || /\s/g.test(value))
    return <p className="validation-message">{enhancedT(['FIELD_CONTAINS_ONLY_NUMBERS', { label }])}</p>;

  if (value > maxnumber || value < minnumber) {
    return (
      <p className="validation-message">
        {enhancedT(['FIELD_IS_BETWEEN_MIN_MAX', { label, min: minnumber, max: maxnumber }])}
      </p>
    );
  }
};

export const minValueLength = (value, props) => {
  const { label, minvaluelength, nonrequired } = props;
  if (checkIfFieldIsNonRequiredAndEmpty(nonrequired, value)) return null;

  if (value.toString().trim().length < minvaluelength) {
    return (
      <p className="validation-message">
        {enhancedT(['FIELD-IS-MAX-LENGTH-label-should-contain-minimum-m', { label, minLength: minvaluelength }])}
      </p>
    );
  }
};

export const maxValueLength = (value, props) => {
  const { label, maxvaluelength } = props;

  if (value && value.toString().trim().length > maxvaluelength) {
    return (
      <p className="validation-message">{enhancedT(['FIELD_IS_MAX_LENGTH', { label, maxLength: maxvaluelength }])}</p>
    );
  }
};

export const isEqual = (value, props, components) => {
  const bothUsed = components.password[0].isUsed || components.confirmPassword[0].isUsed;
  const bothChanged = components.password[0].isChanged || components.confirmPassword[0].isChanged;

  if ((bothChanged || bothUsed) && components.password[0].value !== components.confirmPassword[0].value) {
    return <p className="validation-message">{enhancedT('PASSWORDS_ARE_NOT_EQUAL')}</p>;
  }
};

export const email = (value, { label }) => {
  if (!isEmail(value)) {
    return <p className="validation-message">{enhancedT(['ITEM_IS_INVALID', { name: label || 'Email' }])}</p>;
  }
};

export const passwordValidation = (value) => {
  const oneLetterDigitSymbol = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])/;
  const containsLowerCase = /[a-z]/;
  const containsUpperCase = /[A-Z]/;
  const containsMinSymbols = /^.{8,}$/;

  return [containsLowerCase, containsUpperCase, oneLetterDigitSymbol, containsMinSymbols].every((regExp) =>
    regExp.test(value),
  );
};

export const containPasswordWordValidation = (value) => {
  return /^((?!password).)*$/i.test(value);
};

export const passwordVal = (value, props) => {
  const { label, nonrequired } = props;
  if (checkIfFieldIsNonRequiredAndEmpty(nonrequired, value)) return null;

  if (!passwordValidation(value)) {
    return <p className="validation-message">{enhancedT(['FIELD_SHOULD_CONTAIN_CHARACTERS', { label }])}</p>;
  }
};

export const numbers = (value, { label }) => {
  const containLetters = /\D/.test(value);
  if (containLetters) {
    return <p className="validation-message">{enhancedT(['FIELD_SHOULD_CONTAIN_NUMBERS', { label }])}</p>;
  }
};

export const numbersAndLetters = (value, { label }) => {
  const containsLettersAndNumbers = /^[A-Za-z0-9]+$/.test(value);
  if (!containsLettersAndNumbers) {
    return <p className="validation-message">{enhancedT(['FIELD_SHOULD_CONTAIN_NUMBERS_LETTERS', { label }])}</p>;
  }
};

export const numbersAndLettersAndSpacesNumberIsNotFirst = (value, { label }) => {
  const containsLettersAndNumbers = /^[A-Za-z][A-Za-z0-9 ]+$/.test(value);
  if (!containsLettersAndNumbers) {
    return <p className="validation-message">{enhancedT(['FIELD_SHOULD_CONTAIN_CHARACTERS_SPECIAL', { label }])}</p>;
  }
};

export const wholeNumbers = (value, { label }) => {
  const isWholeNumber = /^(\s*|\d+)$/.test(value);
  if (!isWholeNumber) {
    return <p className="validation-message">{enhancedT(['FIELD_IS_ONLY_WHOLE_NUMBERS', { label }])}</p>;
  }
};
