export const FIELD_TYPES = {
  TEXT: 0,
  NUMBER: 1,
  DATE: 2,
  TIME: 3,
  UPLOAD: 4,
  PARAGRAPH: 5,
  HORIZONTAL_RULE: 6,
  DROPDOWN: 7,
  RADIO_GROUP: 8,
  CHECKBOX_GROUP: 9,
  TABLE: 10,

  TEXTAREA: 11,
  CHECKBOX: 12,
  IMAGE: 13,
  PASSWORD: 14,
  CONFIRM_PASSWORD: 15,
  SLIDER: 16,
  HEADER: 17,
  TEMPLATE: 18,
  RICH_TEXTAREA: 19,
  DATE_MONTH: 20,
  COMBOBOX: 21,

  DATE_YEAR: 22,
  SWITCH: 23,
};
