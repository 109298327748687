import React from 'react';

export const HubIcon = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 45.847 39.234">
      <path
        className="st0"
        d="M0.573,3.387c0-1.6,1.3-2.9,2.9-2.9h39c1.6,0,2.9,1.3,2.9,2.9v22.9c0,1.6-1.3,2.9-2.9,2.9h-39 c-1.6,0-2.9-1.3-2.9-2.9C0.573,26.287,0.573,3.387,0.573,3.387z"
      />
      <line className="st0" x1="9.873" y1="38.687" x2="36.173" y2="38.687" />
      <line className="st0" x1="23.073" y1="38.687" x2="23.073" y2="29.187" />
      <path className="st0" d="M14.173,9.887c0,2.1-1.7,3.8-3.8,3.8s-3.8-1.7-3.8-3.8s1.7-3.8,3.8-3.8" />
      <path className="st0" d="M6.673,21.587c0-2.1,1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8s-1.7,3.8-3.8,3.8" />
      <line className="st0" x1="20.373" y1="25.387" x2="40.073" y2="25.387" />
      <line className="st0" x1="25.273" y1="15.787" x2="25.273" y2="25.387" />
      <line className="st0" x1="30.173" y1="21.187" x2="30.173" y2="25.387" />
      <line className="st0" x1="35.173" y1="12.987" x2="35.173" y2="25.387" />
      <line className="st0" x1="20.373" y1="7.887" x2="20.373" y2="25.387" />
    </svg>
  );
};
