import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const Logout = ({ authStore, configStore }) => {
  const history = useHistory();

  const signOffHandler = async () => {
    const urlToSignOut = await authStore.logout(window.location.search);

    if (urlToSignOut) authStore.http.get(urlToSignOut);

    configStore.changeIsAuthenticated(false);
    history.push('/account/login');
  };

  useEffect(() => {
    signOffHandler();
  }, []);

  return null;
};
