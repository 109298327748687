/**
 * function which check is variable have iterable property
 * @param {any} iterable
 */
export function isIterable(iterable) {
  if (iterable == null) {
    return false;
  }
  if (typeof iterable === 'string') {
    return false;
  }
  return typeof iterable[Symbol.iterator] === 'function';
}
