import React from 'react';
import { PropTypes } from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import { Cluster, Tag } from '@blueprism/ui-core';
import * as R from 'ramda';

import { TextEllipsis } from 'styled-containers';
import { isEmpty } from 'app-utils';

import { ComboBox } from './ComboBox';
import { StyledTagContainer } from './ComboBox.styled';

export const MultipleComboBox = ({
  allowOptionToBeRemoved,
  disabled,
  disableInput,
  handleDeleteItem,
  id,
  labelPropertyName,
  loadOptions,
  name,
  onSelect,
  options,
  value,
  valuePropertyName,
  ...rest
}) => {
  const handleSelect = (option) => {
    if (option) {
      const newValue = value ? [...value, option] : [option];
      onSelect(newValue);
    }
  };

  const handleDelete = (dataOptions) => {
    if (!allowOptionToBeRemoved(dataOptions) || disabled) return;

    handleDeleteItem();
    onSelect(value.filter((val) => val[labelPropertyName] !== dataOptions[labelPropertyName]));
  };

  const filterOption = (datalistOptions) =>
    !R.is(Array, value) ||
    isEmpty(value) ||
    !value.some((item) => item[valuePropertyName] === datalistOptions[valuePropertyName]);

  return (
    <>
      <ComboBox
        {...rest}
        name={name}
        id={id}
        options={options || []}
        loadOptions={loadOptions}
        onSelect={handleSelect}
        filterOption={filterOption}
        disabled={disableInput || disabled}
        labelPropertyName={labelPropertyName}
        valuePropertyName={valuePropertyName}
        clearOnceSelected
      />
      {R.is(Array, value) && !isEmpty(value) && (
        <Cluster element="ul" padding="small" spacing="small" gap="xs">
          {value.map((option) => (
            <StyledTagContainer key={option[labelPropertyName]} disabled={disabled || !allowOptionToBeRemoved(option)}>
              <Tag
                element="div"
                closeable={allowOptionToBeRemoved(option) && !disabled}
                onCloseClick={() => handleDelete(option)}
              >
                <TextEllipsis width="auto">{option[labelPropertyName]}</TextEllipsis>
              </Tag>
            </StyledTagContainer>
          ))}
        </Cluster>
      )}
    </>
  );
};

MultipleComboBox.propTypes = {
  onSelect: PropTypes.func.isRequired,
  loadOptions: PropTypes.func,
  handleDeleteItem: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), MobxPropTypes.observableArray]),
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), MobxPropTypes.observableArray]),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  allowOptionToBeRemoved: PropTypes.func,
  disabled: PropTypes.bool,
  disableInput: PropTypes.bool,
  labelPropertyName: PropTypes.string,
  valuePropertyName: PropTypes.string,
};

MultipleComboBox.defaultProps = {
  loadOptions: null,
  options: null,
  value: [],
  id: null,
  name: null,
  allowOptionToBeRemoved: () => true,
  handleDeleteItem: () => {},
  disabled: false,
  disableInput: false,
  labelPropertyName: 'label',
  valuePropertyName: 'value',
};
