import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Toolbar, ButtonTabGroup, ToolbarButton } from '@blueprism/ui-core';
import { useHistory } from 'react-router-dom';
import { Page } from '@blueprism/ui-icons';

import { useDeepCompareMemoize } from 'app-utils';

export const TabsGroup = ({ basePathname, changeTab, currentPath, items }) => {
  const { push } = useHistory();
  const [activeTab, setActiveTab] = useState(null);

  const handleTabChange = (value) => {
    setActiveTab(Number(value));
  };

  const handleExistingRoute = () => {
    items.forEach((item, index) => {
      const valuePrefix = basePathname ? `${basePathname}/` : '';

      if (String(currentPath) === valuePrefix + item.link) {
        handleTabChange(index);
      }
    });
  };

  useEffect(() => {
    handleExistingRoute();
  }, []);

  useEffect(() => {
    handleExistingRoute();
  }, [currentPath, useDeepCompareMemoize(items)]);

  useEffect(() => {
    const nextPath = items[activeTab]?.link;

    if (nextPath) {
      if (changeTab) {
        changeTab(nextPath);
      } else {
        const redirectTo = basePathname ? `${basePathname}/${items[activeTab]?.link}` : items[activeTab]?.link;

        push(redirectTo);
      }
    }
  }, [activeTab]);

  const onButtonTabChange = ({ currentTarget: { value } }) => {
    handleTabChange(value);
  };

  return (
    <Toolbar>
      <ButtonTabGroup onChange={onButtonTabChange} selectedIndex={activeTab}>
        {items.map(({ icon: Icon = Page, label, disabled }) => (
          <ToolbarButton label={<span>{label}</span>} icon={<Icon />} key={label} disabled={disabled} />
        ))}
      </ButtonTabGroup>
    </Toolbar>
  );
};

TabsGroup.propTypes = {
  basePathname: PropTypes.string,
  changeTab: PropTypes.func,
  currentPath: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    }),
  ).isRequired,
};

TabsGroup.defaultProps = {
  basePathname: null,
  changeTab: null,
};
