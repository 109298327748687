import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as Numeric from 'react-numeric-input';

import './index.scss';

export class NumericInput extends PureComponent {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    allowClear: PropTypes.bool,
  };

  static defaultProps = {
    value: '',
    onClose: null,
    allowClear: true,
  };

  render() {
    const { allowClear, onChange, onClose, value, ...rest } = this.props;

    return (
      <div className="input-numeric-wrap">
        <Numeric value={value} noStyle onChange={onChange} className="input-numeric" precision={2} {...rest} />
        {allowClear && !!value && String(value) !== '' && (
          <i
            className="icon icon-close"
            onClick={() => {
              if (onClose) {
                onClose();
              } else {
                onChange('');
              }
            }}
          />
        )}
      </div>
    );
  }
}

export { NumberInput } from './NumberInput';
