import React, { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import { Box, Stack, Text } from '@blueprism/ui-core';
import ReactMarkdown from 'react-markdown';

import { MODAL_MODES } from 'app-modal';
import { ImageManager } from 'app-svg-icon';

import { FormFieldsContainer } from '../components/FormFieldsContainer';
import { EnhancedTabs } from './components/EnhancedTabsComponent';
import { StyledDescriptions, StyledText, StyledStickyFormWizardNavigation } from './FormWizard.styled';
import { useScrollWhenPageChanged } from '../hooks';
import { provideBlobStorage, provideFormFilesSize, provideFieldsValueResolver } from '../hocs';
import { processValuesForDraft, calcNextPageId } from '../utils';

function FormWizardComponent(props) {
  const {
    activePage,
    activePageId,
    allowNavigateWithError,
    cancelFormHandler,
    description: formDescription,
    fields,
    fieldWidth,
    getChangedConfig,
    imagePath,
    mode,
    name: formName,
    normalizeFieldProps,
    pages,
    renderControlsButtons,
    renderFields,
    renderFormActions,
    resolveFieldsValue,
    setActivePageId,
    stickyHeader,
    t,
    updateFormData,
    visiblePages,
    waitForRulesToApply,
    ...rest
  } = props;
  const isViewMode = mode === MODAL_MODES.VIEW;
  const formik = useFormikContext();

  const wizardPages = visiblePages.reduce(
    (acc, page) => ({
      ...acc,
      [page.id]: {
        pageName: page.name,
        pageDescription: page.description,
        fields: R.map(
          normalizeFieldProps,
          R.filter((f) => page.fields.includes(f.name), fields),
        ),
      },
    }),
    {},
  );
  const pageList = visiblePages.map(({ hidden, id, name }) => ({
    id,
    hidden,
    link: id,
    label: name,
    fieldsForNext: R.map(
      ({ automationId, name: fieldsName }) => automationId || fieldsName,
      Object.values(wizardPages[id].fields),
    ),
  }));
  const pageHeaderRef = useRef();
  const pageContainer = useRef();

  useScrollWhenPageChanged(activePageId, pageHeaderRef.current, pageContainer.current);

  const currentValidation = (activePage && activePage.validate) || (() => true);

  const changePageHandler = async (anchor, validatePage = true) => {
    const { payload, validate } = await waitForRulesToApply();
    const _pages = R.pathOr(pageList, ['visiblePages'], payload);

    let isFormValid = true;

    if (!allowNavigateWithError && validatePage) {
      isFormValid = await validate();
    }

    if (!isFormValid) return;

    const nextPageId = calcNextPageId(anchor, activePageId, _pages);

    if (nextPageId === activePageId) return;

    if (isViewMode) {
      return setActivePageId(nextPageId);
    }

    const validationResult = await currentValidation(formik);

    if (validationResult) {
      setActivePageId(nextPageId);
    }
  };

  useEffect(() => {
    if (!wizardPages[activePageId] && R.not(R.isEmpty(visiblePages))) {
      changePageHandler(R.path(['0', 'id'], visiblePages), false);
    }
  }, [activePageId, visiblePages.length]);

  const { fields: caclFields, pageDescription, pageName } =
    wizardPages[activePageId] || wizardPages[R.path(['0', 'id'], visiblePages)] || [];

  const isFullWidth = fieldWidth === '100%';

  return (
    <Stack gap="large" height="100%" style={{ overflow: 'auto' }} ref={pageContainer}>
      {R.not(R.isEmpty(visiblePages)) &&
        renderFormActions({
          getChangedConfig,
          resolveFieldsValue,
          waitForRulesToApply,
        })}
      <StyledStickyFormWizardNavigation sticky={stickyHeader} gap="base" variation="left" padding="small">
        {imagePath && <ImageManager src={imagePath} />}
        {formName && <div className="block-header form-title">{formName}</div>}
        {formDescription && <Text>{formDescription}</Text>}
        {R.isEmpty(visiblePages) ? (
          <StyledText align="center">{t('NO_DATA_TO_DISPLAY')}</StyledText>
        ) : (
          <EnhancedTabs
            mode={mode}
            tabs={pageList}
            disabled={!isViewMode}
            activeTabId={activePageId}
            changeTab={changePageHandler}
          />
        )}
      </StyledStickyFormWizardNavigation>
      <Stack gap="large" padding="small">
        {R.not(R.isEmpty(visiblePages)) && (
          <>
            <Box ref={pageHeaderRef}>
              <StyledText type="h5">{pageName}</StyledText>
              <StyledDescriptions className="page-description">
                <ReactMarkdown escapeHtml={false} source={pageDescription} />
              </StyledDescriptions>
            </Box>
            <FormFieldsContainer gap="large" fullWidth={isFullWidth} fieldWidth={fieldWidth}>
              {!R.isEmpty(fields) &&
                renderFields({
                  ...rest,
                  fields: caclFields,
                  activePage,
                  updateFormData,
                })}
              {renderControlsButtons({
                mode,
                pageList,
                activePage: activePageId,
                changePageHandler,
                cancelFormHandler,
              })}
            </FormFieldsContainer>
          </>
        )}
      </Stack>
    </Stack>
  );
}

FormWizardComponent.propTypes = {
  activePage: PropTypes.shape({
    validate: PropTypes.func,
  }),
  activePageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  allowNavigateWithError: PropTypes.bool,
  cancelFormHandler: PropTypes.func,
  description: PropTypes.string,
  fieldWidth: PropTypes.string,
  fields: PropTypes.shape({}).isRequired,
  getChangedConfig: PropTypes.func.isRequired,
  imagePath: PropTypes.string,
  mode: PropTypes.string,
  name: PropTypes.string,
  normalizeFieldProps: PropTypes.func,
  pages: PropTypes.shape({}),
  renderControlsButtons: PropTypes.func.isRequired,
  renderFields: PropTypes.func.isRequired,
  renderFormActions: PropTypes.func,
  resolveFieldsValue: PropTypes.func,
  setActivePageId: PropTypes.func.isRequired,
  stickyHeader: PropTypes.bool,
  t: PropTypes.func.isRequired,
  updateFormData: PropTypes.func.isRequired,
  visiblePages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  waitForRulesToApply: PropTypes.func.isRequired,
};

FormWizardComponent.defaultProps = {
  activePage: null,
  activePageId: null,
  allowNavigateWithError: false,
  cancelFormHandler: null,
  description: null,
  fieldWidth: '100%',
  imagePath: null,
  stickyHeader: true,
  mode: '',
  name: null,
  normalizeFieldProps: (field) => field,
  pages: null,
  renderFormActions: () => null,
  resolveFieldsValue: (v) => v,
};

export const FormWizard = R.compose(
  withTranslation(),
  provideBlobStorage,
  provideFormFilesSize,
  provideFieldsValueResolver(processValuesForDraft),
)(FormWizardComponent);
