import React, { useEffect } from 'react';
import { ToastContainer as ToastifyToastContainer } from 'react-toastify';
import cn from 'classnames';

import { setIsLegacyToasts } from './CustomNotify';
import 'react-toastify/dist/ReactToastify.css';
import { StyledToastContainer } from './StyledToastContainer.styled';

export const ToastContainer = ({ isLegacyToasts }) => {
  useEffect(() => {
    setIsLegacyToasts(isLegacyToasts);
  }, [isLegacyToasts]);

  return (
    <StyledToastContainer>
      <ToastifyToastContainer
        position="top-right"
        toastClassName={cn({ 'custom-toast': !isLegacyToasts })}
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
      />
    </StyledToastContainer>
  );
};
